import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { FC, PropsWithChildren } from 'react';

const TabPanel: FC<PropsWithChildren<{ value: number; index: number }>> = ({ children, value, index }) => (
  <Typography
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
  >
    <Box p={1} minHeight={200}>
      {children}
    </Box>
  </Typography>
);

export default TabPanel;
