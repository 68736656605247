import React from 'react';
import { Navigate } from 'react-router-dom';

import AromaViz from './admin/aroma/AromaViz';
import ContextsList from './admin/contexts/ContextsList';
import DataList from './admin/data/DataList';
import JobsList from './admin/jobs/JobList';
import ProjectFlow from './admin/projects/ProjectFlow/ProjectFlow';
import ProjectList from './admin/projects/ProjectList';
import { IngredientBarcodes } from './features/datasources/IngredientBarcodes';
import JobDetail from './features/jobs/components/JobDetail';

const routes = [
  {
    path: '/',
    element: <Navigate to="/projects" />,
  },
  {
    path: '/projects/:idProject/phases/:phaseId/runs/:runId',
    element: <ProjectFlow />,
  },
  {
    path: '/projects/:idProject/phases/:phaseId/runs',
    element: <ProjectFlow />,
  },
  {
    path: '/projects/:idProject/show',
    element: <ProjectFlow />,
  },
  {
    path: '/projects/:idProject/jobs/:idJob/*',
    element: <JobDetail />,
  },
  {
    path: '/projects/*',
    element: <ProjectList />,
  },
  {
    path: '/contexts/*',
    element: <ContextsList />,
  },
  {
    path: '/jobs/*',
    element: <JobsList />,
  },
  {
    path: '/data/ingredients/:version/code',
    element: <IngredientBarcodes />,
  },
  {
    path: '/data/aroma',
    element: <AromaViz />,
    children: [
      {
        path: ':version',
        element: <AromaViz />,
      },
    ],
  },
  {
    path: '/data',
    element: <DataList />,
  },
];

export default routes;
