import '../../../../../layout/run-config.scss';

import { WarningOutlined } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React, { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import ErrorNotification from '../../../../../components/ErrorNotification';
import Loading from '../../../../../components/Loading';
import {
  resetConceptGenerationConfig,
  setActiveStep,
  setRunError,
  setSelectedIngredientsV3,
  showConfigurationWorkflow,
  SLICE_CONCEPT_GENERATION,
  SLICE_CONCEPT_GENERATION_V3,
  SLICE_PROJECT_WORKFLOW,
} from '../../../../../features/project/store';
import { BackendLoadingStatus, InputGenerationType } from '../../../../../features/project/types/common';
import {
  APIConceptTemplate,
  ConceptGenerationWFStepsV3,
  emptyIngredientSlot,
  freeIngredientSlot,
} from '../../../../../features/project/types/concept-generation';
import { getTerritoryDefinitions, updateConceptGenerationConfig } from '../../../../../features/project/utils';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { ConceptGenerationWFVersion, ShowConceptGenWorkflow } from '../_utils/types';
import ConceptTemplatesView from './_components/ConceptTemplates';
import SelectIngredients from './_components/SelectIngredients';
import SensoryRemappingStep from './_components/SensoryRemapping';

interface RunConfigFlowProps {
  projectId: string;
  phaseId: string;
  isPhaseReadOnly: boolean;
}

/* eslint-disable prefer-object-spread */
const RunConfigFlowV3: FC<RunConfigFlowProps> = ({ projectId, phaseId, isPhaseReadOnly }) => {
  const dispatch = useAppDispatch();
  const { loading, runId, runs, error: apiError } = useAppSelector((store) => store[SLICE_PROJECT_WORKFLOW]);
  const conceptGenerationConfigCommonState = useAppSelector((store) => store[SLICE_CONCEPT_GENERATION]);
  const { activeStep, initialIngredients, ingredientsLoadingStatus } = conceptGenerationConfigCommonState;
  const conceptGenerationConfigV3State = useAppSelector((store) => store[SLICE_CONCEPT_GENERATION_V3]);
  const {
    selectedIngredientIds,
    sensoryRemapping,
    templates,
    territoryDefinitionsLoadingState,
    territoryDefinitionsWarningMessage,
    selectedTerritoryDefinition,
  } = conceptGenerationConfigV3State;
  const {
    inputId,
    readOnly,
    error: runError,
    loading: runLoading,
  } = runId ? runs[runId] : { inputId: undefined, readOnly: false, error: undefined, loading: false };
  const prevProjectId = useRef<string | undefined>(projectId);

  const isFirstStep = activeStep === ConceptGenerationWFStepsV3.SelectIngredients;
  const isLastStep = activeStep === ConceptGenerationWFStepsV3.ConceptTemplates;
  const areIngredientsLoading =
    ingredientsLoadingStatus === BackendLoadingStatus.Loading ||
    ingredientsLoadingStatus === BackendLoadingStatus.Initial;
  const areTerritoriesLoading =
    territoryDefinitionsLoadingState === BackendLoadingStatus.Loading ||
    territoryDefinitionsLoadingState === BackendLoadingStatus.Initial;

  const [errorMsg, setErrorMsg] = useState<string | ReactNode | FC | null>(null);

  /**
   * This callback is called when the user clicks on the "Save" button.
   * If the configuration is valid, it can be saved in the backend
   */
  const onWorkflowComplete = useCallback<() => void>(async () => {
    // In practice all following conditions should be true
    // but we check them anyway to make the linter happy
    if (runId && runs[runId] && selectedTerritoryDefinition) {
      const sensory_remapping = Object.fromEntries(sensoryRemapping);

      const apiTemplates: APIConceptTemplate[] = [];
      templates.forEach((t) => {
        const { ingredientSlots, territories } = t;
        const noFreeIngredients = ingredientSlots.filter((i) => i === freeIngredientSlot.ingredientId).length;
        const bases = ingredientSlots.filter(
          (i) => i !== freeIngredientSlot.ingredientId && i !== emptyIngredientSlot.ingredientId,
        );

        const apiTemplate: APIConceptTemplate = {
          bases: bases.map((b) => ({ values: [b] })),
          concept_territory_names: territories,
          no_free_ingredients: noFreeIngredients,
        };

        apiTemplates.push(apiTemplate);
      });

      const config = {
        version: ConceptGenerationWFVersion.v3,
        input_type: InputGenerationType.Wizard,
        ingredients: selectedIngredientIds,
        sensory_remapping,
        concept_territories_definition_name: selectedTerritoryDefinition?.name,
        concept_territories_definition_version: selectedTerritoryDefinition?.version,
        templates: apiTemplates,
      };
      const isUpdateSuccessful = await updateConceptGenerationConfig(
        dispatch,
        runId,
        phaseId,
        projectId,
        inputId,
        config,
        runs[runId].readOnly,
      );
      if (isUpdateSuccessful) {
        dispatch(resetConceptGenerationConfig());
      }
    } else {
      throw new Error('Not all conditions are met to save the configuration. This should never occur.');
    }
  }, [
    runId,
    dispatch,
    inputId,
    projectId,
    phaseId,
    runs,
    selectedIngredientIds,
    sensoryRemapping,
    templates,
    selectedTerritoryDefinition,
  ]);

  /**
   * This callback is called when the user clicks on the "Next" or "Finish" button.
   */
  const onNextWorkflowStep = useCallback<() => void>(() => {
    if (isLastStep) {
      onWorkflowComplete();
    } else {
      dispatch(setActiveStep(activeStep + 1));
    }
  }, [activeStep, dispatch, isLastStep, onWorkflowComplete]);

  /**
   * This callback is called when the user clicks the "Back" button.
   */
  const handleBack = useCallback<() => void>(() => {
    if (runId) {
      dispatch(setRunError({ runId, error: undefined }));
      if (isFirstStep) {
        // hides the configuration workflow and sets active step to 0
        dispatch(resetConceptGenerationConfig());
      } else {
        dispatch(setActiveStep(activeStep - 1));
      }
    }
  }, [dispatch, activeStep, isFirstStep, runId]);

  /**
   * This effect will set the error message
   * if state variable `error` changes in the redux store
   */
  useEffect(() => setErrorMsg(apiError || null), [apiError]);

  /**
   * Effect for fetching the territory definitions when this component is initialized
   */
  useEffect(() => {
    if (territoryDefinitionsLoadingState === BackendLoadingStatus.Initial || prevProjectId.current !== projectId) {
      getTerritoryDefinitions(dispatch, projectId);
    }
  }, [dispatch, projectId, territoryDefinitionsLoadingState]);

  const displayWarningMessage = useCallback(
    () =>
      territoryDefinitionsWarningMessage !== '' &&
      territoryDefinitionsWarningMessage !== undefined &&
      territoryDefinitionsWarningMessage !== null,
    [territoryDefinitionsWarningMessage],
  );

  if (areIngredientsLoading) {
    return <Loading message="Loading ingredients" />;
  }
  if (areTerritoriesLoading) {
    return <Loading message="Loading territories" />;
  }
  return (
    <Paper data-id-cypress="runConfigV3TerritoriesWizard" className="RunConfig__wizardWrapper">
      <Box>
        <Box display={'flex'} alignItems={'center'}>
          <Typography variant="h4" style={{ fontWeight: 'bold' }}>
            Composition Wizard
          </Typography>
          {readOnly && (
            <Box marginLeft={1} display={'flex'} alignItems={'center'}>
              <Typography
                className="WithWarning"
                color={'error'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <WarningOutlined />
                This run is read-only
              </Typography>
            </Box>
          )}
        </Box>

        {displayWarningMessage() && (
          <Box marginLeft={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Typography
              data-id-cypress="territoryWarningMessage"
              className="WithWarning"
              color={'error'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <WarningOutlined />
              {territoryDefinitionsWarningMessage}
            </Typography>
          </Box>
        )}
        {!displayWarningMessage() && (
          <Box className="RunConfig__flowData">
            {activeStep === ConceptGenerationWFStepsV3.SelectIngredients && (
              <SelectIngredients
                setSelectedIngredients={setSelectedIngredientsV3}
                dispatch={dispatch}
                ingredients={initialIngredients || []}
                selectedIngredientIds={selectedIngredientIds || []}
                loading={loading}
                readOnly={readOnly}
              />
            )}
            {activeStep === ConceptGenerationWFStepsV3.SensoryRemapping && (
              <SensoryRemappingStep
                selectedIngredientIds={selectedIngredientIds}
                sensoryRemapping={sensoryRemapping}
                isReadOnly={readOnly}
              />
            )}
            {activeStep === ConceptGenerationWFStepsV3.ConceptTemplates && runId && (
              <ConceptTemplatesView runId={runId} readOnly={readOnly} />
            )}
          </Box>
        )}
        <Paper className="RunConfig__dialogActions">
          <Button
            disabled={loading}
            onClick={() => dispatch(showConfigurationWorkflow(ShowConceptGenWorkflow.hide))}
            className="RunConfig__backButton"
          >
            Back to generate concept
          </Button>
          {!isFirstStep && (
            <Button disabled={loading || runLoading} onClick={handleBack} className="RunConfig__backButton">
              Back
            </Button>
          )}
          {!displayWarningMessage() && (
            <Button
              data-id-cypress="nextButtonV3"
              disabled={loading || !!runError || (readOnly && isLastStep) || (isPhaseReadOnly && isLastStep)}
              variant="contained"
              color="primary"
              onClick={isLastStep ? onWorkflowComplete : onNextWorkflowStep}
            >
              {isLastStep ? 'Save' : 'Next'}
            </Button>
          )}
        </Paper>
        <ErrorNotification error={errorMsg || null} handleClose={() => setErrorMsg(null)} />
      </Box>
    </Paper>
  );
};

export default RunConfigFlowV3;
