import { Backdrop, Box, CircularProgress, LinearProgress, unstable_useId } from '@mui/material';
import {
  DataGrid,
  DataGridProps,
  GridEditRowsModel,
  GridFooter,
  GridFooterContainer,
  GridSortItem,
  GridToolbar,
} from '@mui/x-data-grid';
import { DataGridPropsWithoutDefaultValue } from '@mui/x-data-grid/models/props/DataGridProps';
import React, { FC, memo, ReactNode, useEffect, useMemo, useState } from 'react';

import VizContainer from '../../../../features/datasources/containers/VizContainer';
import { GridSelectionModel } from '../../../../features/datasources/types';
import { SelectedIngredient } from '../../../../features/project/types/concept-generation';
import { LemmaTableRow } from '../../../../features/project/types/metrics-calculation';
import { IngredientDBIngredientTypeWithSensoryRemapping } from '../../../../types';

export interface IngredientTableProps<T> {
  actions?: ReactNode | null;
  ingredients: T[];
  error?: string;
  identifier: string;
  setSelectedIngredients?: (itemsId: GridSelectionModel, rows?: any[]) => void;
  initialSelection?: GridSelectionModel;
  initialSorting?: GridSortItem[];
  height?: string | number;
  isReadOnly?: boolean;
}

export type GridProps = IngredientTableProps<
  IngredientDBIngredientTypeWithSensoryRemapping | LemmaTableRow | SelectedIngredient
> &
  Omit<DataGridProps, 'rows'> &
  Omit<DataGridPropsWithoutDefaultValue, 'rows'>;

const IngredientTable: FC<GridProps> = ({
  components,
  ingredients,
  identifier,
  setSelectedIngredients,
  initialSelection = [],
  initialSorting = [],
  onEditRowsModelChange = (editRowsModel: GridEditRowsModel) => editRowsModel,
  height,
  pagination = true,
  autoHeight = true,
  editMode = 'row',
  pageSize = undefined,
  actions,
  error = '',
  density = 'standard',
  disableSelectionOnClick = true,
  checkboxSelection = true,
  isReadOnly = false,
  ...rest
}: GridProps) => {
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [sortModel, setSortModel] = useState<GridSortItem[]>([]);
  const otherProps = {
    ...rest,
    editMode,
    pageSize,
    disableSelectionOnClick,
    checkboxSelection,
    autoHeight,
    onEditRowsModelChange,
    pagination,
  };
  const allowedKeys = [
    'Enter',
    'Backspace',
    'Delete',
    'Escape',
    'ArrowDown',
    'ArrowUp',
    'ArrowLeft',
    'ArrowRight',
    'Tab',
  ];

  // prepare rows and filter duplications
  const rows = useMemo(
    () =>
      ingredients.map((item) => ({
        ...item,
        id: item[identifier as keyof typeof item],
      })),
    [identifier, ingredients],
  );

  useEffect(() => {
    setSelectionModel(initialSelection);
  }, [initialSelection]);

  useEffect(() => {
    const fields = otherProps?.columns?.map((c) => c.field);
    const sortingToApply = initialSorting?.filter((i) => fields.includes(i.field)) || [];
    setSortModel((s) => (sortingToApply.length && !s.length ? sortingToApply : s));
  }, [otherProps?.columns, initialSorting]);

  return (
    <VizContainer error={error} height={otherProps?.autoHeight ? height : undefined}>
      <div data-id-cypress="ingredientTable" style={{ minHeight: height, minWidth: '100%' }}>
        <DataGrid
          style={{ minHeight: height, height: '100%' }}
          selectionModel={selectionModel}
          keepNonExistentRowsSelected={true}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
            setSelectedIngredients?.(newSelectionModel, rows);
          }}
          onCellKeyDown={(value, event) => {
            if (!allowedKeys.includes(event.key)) event.stopPropagation();
          }}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          components={{
            Toolbar: GridToolbar,
            Footer: (props) => (
              <GridFooterContainer {...props}>
                <GridFooter {...props} />
                <Box>{actions}</Box>
              </GridFooterContainer>
            ),
            LoadingOverlay: () => (
              <>
                <LinearProgress color="primary" />
                <Backdrop
                  open={true}
                  style={{
                    zIndex: 1,
                    position: 'absolute',
                    opacity: 1,
                    background: 'rgb(255, 255, 255)',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexDirection: 'column',
                  }}
                >
                  {Array.from({ length: 10 }).map((e, i) => (
                    <Box
                      key={unstable_useId()}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: `100%`,
                        height: '80px',
                        border: '1px solid #ddd',
                        borderRadius: '3px',
                        position: 'relative',
                        marginTop: '-1px',
                        animation: `pulse 1.5s calc(${i}*0.1s) ease-in-out infinite`,
                      }}
                    ></Box>
                  ))}
                  <CircularProgress
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  />
                </Backdrop>
              </>
            ),
            ...components,
          }}
          {...(otherProps as DataGridProps)}
          density={density}
          rows={rows}
          isRowSelectable={() => !isReadOnly}
        />
      </div>
    </VizContainer>
  );
};

IngredientTable.defaultProps = {
  setSelectedIngredients: () => null,
  height: '60vh',
  error: undefined,
  actions: null,
  initialSelection: [],
  initialSorting: [],
  isReadOnly: false,
};

export default memo<typeof IngredientTable>(IngredientTable) as typeof IngredientTable;
