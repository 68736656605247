import '../../../layout/common.scss';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import React, { MouseEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { ProjectType } from '../../../types';
import { ErrorAction, OutputAction, ProjectAction, StepAction } from '../reducer';

const pageToIndex = [
  'config',
  'contexts',
  'output1',
  'adjustmappings',
  'output2',
  'contextweights',
  'output3',
  'done',
  'shouldneverhappen',
];

const getSteps = (projectType: string) => {
  if (projectType === 'PROJECT_TYPE_CFI') {
    return [
      'Configure project',
      'Define contexts',
      'Ingredient selection', // output1
      'Adjust mapping',
      'CFI attributes', // output2
      'Concept definition',
      'Concept generation', // output3
    ];
  }
  return ['Configure project'];
};

interface FlowButtonsProps {
  activeStep: number;
  project: ProjectType;
  dispatch: (action: ProjectAction | OutputAction | StepAction | ErrorAction) => void;
  actionButton?: React.ReactElement;
  backDisabled?: boolean;
  nextDisabled?: boolean;
}

const FlowButtons: React.FC<FlowButtonsProps> = ({
  activeStep,
  project,
  dispatch,
  actionButton,
  backDisabled,
  nextDisabled,
}) => {
  const steps = getSteps(project.type);
  const history = useNavigate();
  const pageName = pageToIndex[activeStep];
  const nextPageName = pageToIndex[activeStep + 1];
  const nextPageUrl = `/projects/${project.projectId}/show/?page=${nextPageName}`;

  const advanceStep = async (event: MouseEvent) => {
    event.preventDefault();
    dispatch({ type: 'StepCompleted', stepName: pageName });
    history(nextPageUrl);
  };

  return (
    <Box className="common__flowButtons">
      <Paper>
        <Button
          disabled={backDisabled}
          component={Link}
          to={
            activeStep !== 0 ? `/projects/${project.projectId}/show/?page=${pageToIndex[activeStep - 1]}` : '/projects/'
          }
          className="common__button"
        >
          Back
        </Button>
        {actionButton && actionButton}
        <Button
          disabled={activeStep === steps.length || nextDisabled}
          variant="contained"
          color="primary"
          onClick={advanceStep}
          className="common__button"
        >
          Next
        </Button>
      </Paper>
    </Box>
  );
};

FlowButtons.defaultProps = {
  actionButton: undefined,
  backDisabled: undefined,
  nextDisabled: undefined,
};

export default FlowButtons;
