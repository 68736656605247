import './Layout.scss';

import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import { User } from 'firebase/auth';
import * as React from 'react';
import { PropsWithChildren } from 'react';

import UserMenu from '../components/UserMenu';
import { useLayout } from '../context/LayoutContext';
import BreadCrumbs from './BreadCrumbs';
import Sidebar from './Sidebar';

interface LayoutProps {
  user: User | null;
}

// const CustomSidebar = (props: any) => <Sidebar {...props} size={200} />;

const Layout: React.FC<PropsWithChildren<LayoutProps>> = ({ children, user }) => {
  const { toggleSidebar, layout } = useLayout();

  return (
    <>
      <Box displayPrint="none">
        <AppBar position="fixed" className="Layout__appBar">
          <Toolbar>
            <IconButton
              edge="start"
              className="Layout__menuButton"
              color="inherit"
              aria-label="menu"
              onClick={() => toggleSidebar()}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className="Layout__title">
              {layout.pageTitle}
            </Typography>
            {user && <UserMenu user={user} />}
          </Toolbar>
        </AppBar>
      </Box>

      <Box display="flex" className="main-content">
        <Box displayPrint="none" className="side-bar-wrapper">
          <Sidebar isOpen={true} />
        </Box>
        <Box sx={{ flexGrow: 1 }} px={4} className="Layout__content main-content-wrapper">
          <Box py={3} displayPrint="none">
            <BreadCrumbs />
          </Box>
          <Box py={2}>{children}</Box>
        </Box>
      </Box>
    </>
  );
};

export default Layout;
