import './Sidebar.scss';

import { Drawer, Toolbar } from '@mui/material';
import classNames from 'classnames';
import React from 'react';

import { useLayout } from '../context/LayoutContext';
import Menu from './Menu';

interface SidebarProps {
  isHidden?: boolean;
  isOpen?: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ isHidden, isOpen: isOpenProp }) => {
  const { layout } = useLayout();
  const isOpen = layout.isSidebarOpened !== undefined ? layout.isSidebarOpened : isOpenProp;
  const drawerClass = classNames('Sidebar__drawer', isOpen ? 'Sidebar__drawerPaper' : 'Sidebar__drawerClosed');

  return (
    <Drawer
      variant={isHidden ? 'temporary' : 'permanent'}
      anchor="left"
      className={drawerClass}
      classes={{ paper: drawerClass }}
      open={true}
    >
      <Toolbar />
      <Menu onMenuClick={() => null} />
    </Drawer>
  );
};

Sidebar.defaultProps = {
  isHidden: undefined,
  isOpen: undefined,
};

export default Sidebar;
