import './IngredientBarcodes.scss';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import JsBarcode from 'jsbarcode';
import { decompress } from 'lzutf8';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import Loading from '../../components/Loading';
import { useAppDispatch, useAppSelector } from '../../store';
import { DataSourceType } from '../../types';
import { changeIngredientDbVersion, SLICE_DATASOURCE_NAME } from './store';
import { sliceIntoChunks } from './utils';

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

type BarcodeWrappedProps = {
  value: string;
  text: string;
};

type BarcodePageProps = {
  lookup: Map<number | string, string>;
  ingredientIds: number[];
  itemsPerPage?: number;
};

const BarcodeWrapped: React.FC<BarcodeWrappedProps> = ({ value, text }) => {
  const inputEl = React.useRef(null);
  React.useEffect(() => {
    JsBarcode(inputEl.current, value, { text });
  }, [value, text]);
  return <svg ref={inputEl} />;
};

const BarcodePage: React.FC<BarcodePageProps> = ({ lookup, ingredientIds, itemsPerPage = 16 }) => {
  /* eslint-disable react/jsx-key */
  const groups = ingredientIds
    .map((ingredientId) => (
      <div key={ingredientId} style={{ height: '54mm' }} className="barcode-item">
        <BarcodeWrapped
          value={ingredientId ? String(ingredientId) : ''}
          text={`${lookup.get(ingredientId)} (${ingredientId})`}
        />
      </div>
    ))
    .reduce((r: any[], element, index) => {
      if (index % itemsPerPage === 0) {
        r.push([]);
      }

      r[r.length - 1].push(element);
      return r;
    }, [])
    .map((rowContent) => (
      <div key={rowContent} className="IngredientBarcodes__grid print-page-grid">
        {rowContent}
      </div>
    ));
  /* eslint-enable react/jsx-key */

  return <div className="IngredientBarcodes__page print-page">{groups}</div>;
};

BarcodePage.defaultProps = {
  itemsPerPage: 16,
};

export const IngredientBarcodes: React.FC<{}> = () => {
  const { version } = useParams<{ version: string }>();
  const ingredientIds = useQuery().get('ingredientIds');
  const [lookup, setLookup] = useState<Map<number | string, string>>(new Map());
  const [ingredientsLoading, setIngredientsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [storedIngredients, setStoredIngredients] = useState<any>([]);
  const localStorageAddress = `CFI_PROJECT_KEY_DATASOURCE_${DataSourceType.IngredientDB}`;
  const state = useAppSelector((store: any) => store[SLICE_DATASOURCE_NAME]);

  const ingredientIdsParsed = (ingredientIds ?? '')
    .split(',')
    .filter((x) => x !== '')
    .map((x) => parseInt(x, 10));

  const getIngredientsFromApi = async () => {
    if (version) {
      dispatch(
        changeIngredientDbVersion({
          sourceType: DataSourceType.IngredientDB,
          version,
        }),
      );
    }
  };

  useEffect(() => {
    setIngredientsLoading(state.loading);
  }, [state.loading]);

  useEffect(() => {
    // check if the ingredients are in the local storage
    if (storedIngredients.length <= 0) {
      getIngredientsFromApi();
      if (localStorage.getItem(localStorageAddress)) {
        setStoredIngredients(
          JSON.parse(
            decompress(localStorage.getItem(localStorageAddress), {
              inputEncoding: 'StorageBinaryString',
              outputEncoding: 'String',
            }) || '{}',
          ),
        );
      }
    } else {
      if (version && storedIngredients[version]) {
        setLookup(storedIngredients[version].reduce((acc: any, x: any) => acc.set(x.ingredientId, x.name), new Map()));
      }
      setIngredientsLoading(false);
    }
    // if not disabled, it will run on every render
    // eslint-disable-next-line
  }, [storedIngredients, ingredientsLoading]);

  if (ingredientsLoading) {
    return <Loading />;
  }

  return (
    <>
      {ingredientIdsParsed.length > 0
        ? sliceIntoChunks(ingredientIdsParsed, 16).map((ids: number[]) => (
            <BarcodePage key={ids?.toString()} ingredientIds={ids} lookup={lookup} />
          ))
        : 'No ingredients specified'}
      <Box displayPrint="none">
        <Button
          variant="contained"
          color="primary"
          disabled={ingredientIdsParsed.length <= 0}
          onClick={() => window.print()}
        >
          Print
        </Button>
      </Box>
    </>
  );
};

export default IngredientBarcodes;
