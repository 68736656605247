import axios from 'axios';

import {
  GenericInput,
  GenericInputConfig,
  GenericInputRequest,
  GenericInputResponse,
} from '../../../../features/project/types/common';
import {
  BuildActionRecordType,
  buildActionUrl,
  TYPE_DUPLICATE_INPUT_RUN,
  TYPE_LOAD_PHASE_BY_ID,
  TYPE_LOAD_RUNS,
  TYPE_PHASE_RUN_INPUT,
  TYPE_PROJECT_BY_ID,
} from '../../../../shared/url';
import { createDependingPhase, ingredientToString } from '../../../../shared/utils';
import { IngredientDBIngredientType, ProjectType } from '../../../../types';
import { getProjectBookmark } from '../../bookmark';
import { PhaseType, ProjectPhaseType, RunType, RunTypeWithInputId } from './types';

const MAX_LEMMAS_PER_PAYLOAD = 1000;

export interface StepDataResponse {
  project?: ProjectType;
  phase?: PhaseType | undefined;
  runs?: RunType[] | undefined;
  selectedRun: RunType | undefined;
  dependingPhaseRuns?: RunType[] | undefined;
}

/**
 * Fetch required for flow step basic data: project and phase info.
 *
 * @param get
 * @param projectId
 * @param phaseId
 * @param runId
 */
export async function loadFlowStepData(
  projectId: string,
  phaseId?: string | undefined | null,
  runId?: string | undefined | null,
): Promise<StepDataResponse> {
  const projectResult: { project?: ProjectType } = await axios
    .get(buildActionUrl({ projectId }, TYPE_PROJECT_BY_ID))
    .then((res) => res.data);
  let phaseResult: { phase?: PhaseType; outputGenerationByRunId?: any } | undefined;
  let runs: RunType[] = [];
  let dependingPhaseRuns: RunType[] = [];
  let selectedRun;
  let nextPhaseId = projectResult?.project?.firstPhaseId;

  if (projectResult?.project) {
    projectResult.project.workflowConfig = projectResult.project?.workflowConfig || [];
  }

  if (phaseId) {
    phaseResult = await axios
      .get(buildActionUrl({ projectId, phaseId }, TYPE_LOAD_PHASE_BY_ID))
      .then((res) => res.data);
    const phaseRuns = await getAllRuns(projectId, phaseId);
    runs = sortRunsByCreateAt(phaseRuns || []);
    selectedRun = runs[0];

    if (phaseResult?.phase) {
      phaseResult.phase.type = phaseResult.phase.type || ProjectPhaseType.ScopeDefinition;
    }

    if (projectResult?.project && phaseResult?.outputGenerationByRunId && phaseResult.phase?.dependsOnRuns[0]) {
      projectResult.project.outputGenerationByRunId =
        phaseResult.outputGenerationByRunId[phaseResult.phase.dependsOnRuns[0]];
    }

    if (runId) {
      selectedRun = runs.filter((run) => run?.runId === runId)[0] || undefined;
    }

    nextPhaseId = selectedRun?.dependingPhases?.[0];
  }

  if (nextPhaseId) {
    // @TODO IF TO RUN RESPONSE WILL BE ADDED "depending_phase_latest_run_id" - REMOVE THIS API CALL
    dependingPhaseRuns = (await getAllRuns(projectId, nextPhaseId)) || [];
  }

  return {
    project: projectResult?.project,
    phase: phaseResult?.phase || undefined,
    selectedRun,
    runs,
    dependingPhaseRuns: sortRunsByCreateAt(dependingPhaseRuns || []),
  };
}

/**
 * Get back url.
 *
 * @param projectId
 * @param phaseType
 * @param phaseId
 * @param runId
 */
export function getBackUrl(
  projectId: string,
  phaseType?: ProjectPhaseType | string | undefined,
  phaseId?: string | undefined,
  runId?: string | undefined,
): string | null {
  let backUrl = null;
  const isConfigStep = phaseType === ProjectPhaseType.ProjectConfiguration;

  if (phaseType === ProjectPhaseType.ScopeDefinition || (projectId && !phaseId && !runId)) {
    backUrl = `/projects/${projectId}/show`;
  }

  if ((projectId && phaseId) || isConfigStep) {
    const bookmark = getProjectBookmark(projectId);

    if (!phaseId) {
      return '/projects';
    }

    const data = isConfigStep ? (bookmark as BuildActionRecordType) : { projectId, phaseId, runId };

    if (phaseType === ProjectPhaseType.ModelPreparation) {
      data.runId = undefined;
    }

    if (data) {
      backUrl = `/projects/${data.projectId}/phases/${data.phaseId}/runs`;
      if (data.runId) {
        backUrl += `/${data.runId}`;
      }
    }
  }
  return backUrl;
}

/**
 * Get next url.
 *
 * @param projectId
 * @param firstPhaseId
 * @param phaseType
 * @param dependingPhase
 * @param dependingPhaseRun
 */
export function getNextUrl(
  projectId: string,
  firstPhaseId?: string | undefined,
  phaseType?: ProjectPhaseType | string | undefined,
  dependingPhase?: string | undefined,
  dependingPhaseRun?: string | undefined,
): string | null {
  let nextUrl = null;
  let phaseId = dependingPhase;
  let runId = dependingPhaseRun;

  if (phaseType === ProjectPhaseType.ProjectConfiguration) {
    phaseId = firstPhaseId;
  }

  if (phaseType === ProjectPhaseType.MetricsCalculation) {
    runId = undefined;
  }

  if (projectId && phaseId) {
    nextUrl = runId
      ? `/projects/${projectId}/phases/${phaseId}/runs/${runId}`
      : `/projects/${projectId}/phases/${phaseId}/runs`;
  }

  return nextUrl;
}

/**
 * Create run.
 *
 * @param post
 * @param projectId
 * @param phaseId
 * @param runName
 */
export async function createRun(
  projectId?: string,
  phaseId?: string,
  runName?: string,
): Promise<RunTypeWithInputId | null> {
  if (!projectId || !phaseId) return null;

  const runResult: { run?: RunType | null; inputId?: string | null } = await axios
    .post(`${buildActionUrl({ projectId, phaseId }, TYPE_LOAD_RUNS)}`, {
      runName: runName || '',
    })
    .then((res) => res.data);

  return runResult?.run ? { run: runResult.run, inputId: runResult?.inputId || '' } : null;
}

/**
 * Duplicate run.
 *
 * @param post
 * @param projectId
 * @param phaseId
 * @param runId
 * @param runName
 */

export async function duplicateRun(
  projectId?: string,
  phaseId?: string,
  runId?: string,
  runName?: string,
): Promise<RunTypeWithInputId | null> {
  if (!projectId || !phaseId || !runId) return null;
  const url = `${buildActionUrl({ projectId, phaseId, runId }, TYPE_DUPLICATE_INPUT_RUN)}`;
  const axiosResponse = await axios.post(url, {
    target_run_name: runName || '',
  });
  const runResult: { run?: RunType | null; inputId?: string | null } = axiosResponse?.data;

  return runResult?.run ? { run: runResult.run, inputId: runResult?.inputId || '' } : null;
}

/**
 * Fetch all runs for phase.
 *
 * @param get
 * @param projectId
 * @param phaseId
 */
export async function getAllRuns(projectId?: string, phaseId?: string): Promise<null | RunType[]> {
  if (!projectId || !phaseId) {
    return null;
  }

  const runsResult = await axios
    .get(`${buildActionUrl({ projectId, phaseId }, TYPE_LOAD_RUNS)}`)
    .then((res) => res.data);
  return runsResult?.runs || null;
}

/**
 * Sort phase runs by created at date.
 *
 * @param runs
 */
export function sortRunsByCreateAt(runs: RunType[]): RunType[] {
  return runs || [];
}

/**
 * Sort phase runs by created at date.
 *
 * @param isInput
 * @param sheetCode
 * @param run
 */
export function getDataSheetUrl(
  outputType: 'input_preparation' | 'output_generation',
  sheetCode: string,
  run: RunType | null,
): string {
  if (sheetCode && run) {
    return run?.outputs?.[outputType]?.jobOutput?.resources?.[sheetCode]?.sheets?.sheets?.[0]?.sheetUrl || '';
  }

  return '';
}

/**
 * Check if next url empty prepare it and create the next phase if required.
 *
 * @param url
 * @param post
 * @param phaseType
 * @param currentRun
 */
export async function proceedToNextPhase(
  url: string | null,
  phaseType: ProjectPhaseType | string | undefined,
  currentRun: RunType | null,
): Promise<string | null> {
  const { runId, projectId, phaseId, dependingPhases } = currentRun || {};

  if (!url && projectId && phaseId) {
    let nextPhaseId = dependingPhases?.[0] || undefined;

    if (!nextPhaseId) {
      const phaseResponse = await createDependingPhase(projectId, phaseId, runId || '');
      nextPhaseId = phaseResponse?.projectPhase?.phaseId || undefined;
    }

    url = getNextUrl(projectId, undefined, phaseType, nextPhaseId);
  }
  return url;
}

/**
 * split an array into chunks of size
 *
 */
function chunk(arr: any[], size: number): any[][] {
  return Array.from({ length: Math.ceil(arr.length / size) }, (_: any, i: number) =>
    arr.slice(i * size, i * size + size),
  );
}

/**
 * Assuming there is only 1 input per run, this will return the first one from a list call
 * when the lemmas are incomplete, the remaining lemmas will be added with subsequent get calls
 */
export async function getGenericInput(
  projectId: string,
  phaseId: string,
  runId: string,
): Promise<GenericInput | undefined> {
  const listPayload = { genericPhase: { listGenericInput: {} } };
  const url = buildActionUrl({ projectId, runId, phaseId }, TYPE_PHASE_RUN_INPUT);
  const axiosResponse = await axios.post(url, listPayload);
  const response = axiosResponse.data as GenericInputResponse;

  if (!response) {
    throw new Error('Operation failed, server did not respond');
  }
  const list = response.genericPhase.listGenericInput;
  if (!list) {
    throw new Error('Invalid response from server');
  }
  const genericInput = list.genericInputs?.[0];
  if (genericInput?.config?.has_more_lemmas) {
    await getExtraLemmas(url, genericInput);
  }
  return genericInput;
}

/**
 * Retrieve the missing lemmas and add them to the generic input config
 */
async function getExtraLemmas(url: string, genericInput: GenericInput): Promise<void> {
  const inputId = genericInput?.inputId;
  if (!inputId) {
    throw new Error('inputId missing in response');
  }
  let lemmas = genericInput.config?.lemmas;
  const collectedLemmas: any[] = [];
  let hasMoreLemmas: boolean;
  /* eslint-disable no-await-in-loop */
  do {
    if (!lemmas || lemmas.length === 0) {
      throw new Error('Lemmas missing in response');
    }
    const offset = lemmas[lemmas.length - 1].lemma;
    const payload: GenericInputRequest = {
      genericPhase: {
        getGenericInput: { inputId, offset, limit: MAX_LEMMAS_PER_PAYLOAD },
      },
    };
    const axiosResponse = await axios.post(url, payload);
    const response = axiosResponse.data as GenericInputResponse;
    if (!response) {
      // is this required?
      throw new Error('Operation failed, server did not respond');
    }
    const config = response.genericPhase?.getGenericInput?.genericInput.config;
    if (!config) {
      throw new Error('Missing getGenericInput config in response from server');
    }
    ({ lemmas } = config);
    const firstLemma = lemmas?.shift();
    if (firstLemma?.lemma !== offset) {
      throw new Error('First lemma in response does not match offset');
    }
    if (lemmas?.length) {
      collectedLemmas.push(...lemmas);
    }
    if (config.has_more_lemmas === undefined) {
      throw new Error('Missing has_more_lemmas in response');
    }
    hasMoreLemmas = config.has_more_lemmas;
  } while (hasMoreLemmas);
  genericInput.config?.lemmas?.push(...collectedLemmas);
}

/**
 * Update the generic input for the given project, phase, run and inputId
 * If inputId is not given, a new generic input is created
 */
export async function updateGenericInput(
  projectId: string,
  phaseId: string,
  runId: string,
  genericInput: GenericInput,
  inputId?: string,
): Promise<GenericInput | undefined> {
  if (!inputId) return createGenericInput(projectId, phaseId, runId, genericInput);

  const url = buildActionUrl({ projectId, runId, phaseId }, TYPE_PHASE_RUN_INPUT);

  // only execute this code when there are lemmas to update
  if (genericInput.config?.lemmas) {
    const configs = extractLemmaBatches(genericInput.config);
    return updateLemmas(configs, inputId, url);
  }
  return processBatch(url, {
    genericPhase: { updateGenericInput: genericInput },
  });
}

/**
 * Create a new generic input for the given project, phase and run
 */
export async function createGenericInput(
  projectId: string,
  phaseId: string,
  runId: string,
  genericInput: GenericInput,
): Promise<GenericInput | undefined> {
  const url = buildActionUrl({ projectId, runId, phaseId }, TYPE_PHASE_RUN_INPUT);
  const configs = extractLemmaBatches(genericInput.config);
  const config0 = configs.shift();
  const payload0 = {
    genericPhase: { createGenericInput: { config: config0 } },
  };
  let result = await processBatch(url, payload0);
  const inputId = result?.inputId;
  if (!inputId) throw new Error('Failed to update generic input: missing inputId');

  if (config0?.lemmas && configs.length !== 0) {
    const lemmas0 = result?.config?.lemmas;
    if (!lemmas0) throw new Error('Failed to update generic input: missing lemmas in create response');
    result = await updateLemmas(configs, inputId, url);
    const lemmasRest = result?.config?.lemmas;
    if (!lemmasRest) throw new Error('Failed to update generic input: missing lemmas in update response');
    lemmasRest.push(...lemmas0);
  }
  return result;
}

/**
 * Update the given lemmas in the generic input and
 * return the updated generic input including the complete list of lemmas
 * One sequential post request is sent for each batch of lemmas
 *
 * @param configs the list of configs with the lemma batches to update
 * @param inputId the id of the generic input to update
 * @param url the url to post to
 * @returns the updated generic input including the complete list of lemmas merged from the post returns of the given list of configs
 */
async function updateLemmas(configs: GenericInputConfig[], inputId: string, url: string): Promise<GenericInput> {
  const savedLemmas: any[] = [];
  const payloadsRest = configs.map((config) => ({
    genericPhase: { updateGenericInput: { config, inputId } },
  }));
  let result: GenericInput | undefined;
  /* eslint-disable no-await-in-loop */
  for (let i = 0; i < payloadsRest.length; i += 1) {
    const payload = payloadsRest[i];
    result = await processBatch(url, payload);
    const lemmas = result?.config?.lemmas;
    if (!lemmas) throw new Error('Failed to update generic input');
    savedLemmas.push(...lemmas);
  }
  if (!result) throw new Error('Failed to update generic input');
  const { config } = result;
  if (!config) throw new Error('Missing config in response');
  if (config.has_more_lemmas) {
    throw new Error('Failed to update generic input, has_more_lemmas is true');
  }
  config.lemmas = savedLemmas;
  return result;
}

/**
 * Split the lemmas into batches of MAX_LEMMAS_PER_PAYLOAD
 * and return an array of configs with the batches
 * If it does not contain lemmas (or less than MAX_LEMMAS_PER_PAYLOAD), the original config is returned
 */
function extractLemmaBatches(genericConfig?: GenericInputConfig): GenericInputConfig[] {
  if (!genericConfig) throw new Error('Invalid generic input config');
  const { lemmas } = genericConfig;
  if (!lemmas || lemmas.length <= MAX_LEMMAS_PER_PAYLOAD) return [genericConfig];

  const lemma_batches = chunk(lemmas, MAX_LEMMAS_PER_PAYLOAD);
  return lemma_batches.map((lemma_batch, index) => ({
    ...genericConfig,
    lemmas: lemma_batch,
    is_complete: index === lemma_batches.length - 1,
  }));
}

/**
 * Send one generic input request to the server and return the genericInput
 *
 * @param url the url to post to
 * @param payload the parameters to post
 * @returns the returned genericInput
 */
async function processBatch(url: string, payload: GenericInputRequest): Promise<GenericInput | undefined> {
  // deduce genericInputKey from payload
  const genericInputKeys = Object.keys(payload.genericPhase);
  if (genericInputKeys.length !== 1) throw new Error('Invalid payload');
  const genericInputKey = genericInputKeys[0];

  try {
    const axiosResponse = await axios.post(url, payload);
    const response = axiosResponse.data;
    if (!response) {
      throw new Error('Operation failed, server did not respond');
    }
    return response.genericPhase[genericInputKey]?.genericInput;
  } catch (error: any) {
    throw new Error(error.response || error.message || 'Unknown error occurred');
  }
}

export class IngredientDBMap {
  ingredientMap: Map<number, IngredientDBIngredientType>;

  ingredients: IngredientDBIngredientType[];

  dropdownMap: Map<string, IngredientDBIngredientType>;

  dropdownIngredientIdMap: Map<number, string>;

  dropdownOptions: string[];

  version: string;

  constructor(ingredients: IngredientDBIngredientType[], version: string) {
    this.ingredients = ingredients;
    this.version = version;
    this.dropdownOptions = [];

    this.ingredientMap = new Map();
    ingredients.forEach((i) => {
      this.ingredientMap.set(Number(i.ingredientId), i);
    });

    this.dropdownMap = new Map();
    this.dropdownIngredientIdMap = new Map();
    ingredients.forEach((i) => {
      const ingredientString = ingredientToString<IngredientDBIngredientType>('ingredientId')(i);
      this.dropdownMap.set(ingredientString, i);
      this.dropdownIngredientIdMap.set(Number(i.ingredientId), ingredientString);
      this.dropdownOptions.push(ingredientString);
    });
  }

  searchIngredient(query: string): IngredientDBIngredientType | undefined {
    return this.dropdownMap.get(query);
  }

  searchIngredientById(ingredientId: number): IngredientDBIngredientType | undefined {
    return this.ingredientMap.get(ingredientId);
  }
}
