import { Autocomplete, TextField } from '@mui/material';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';
import React from 'react';

const TerritoriesEditCell: React.FC<{
  availableTerritories: string[];
  row: GridRenderEditCellParams;
}> = ({ availableTerritories, row }) => {
  const apiRef = useGridApiContext();

  return (
    <Autocomplete
      id="territories-multiselect"
      multiple={true}
      disableCloseOnSelect={true}
      options={availableTerritories}
      filterOptions={() => availableTerritories}
      value={row.territories as string[]}
      onChange={async (event, newValue) => {
        const templateId = row.id as number;
        // Use grid api to update the value, but update the store onRowChange
        await apiRef.current.setEditCellValue({
          id: templateId,
          field: 'territories',
          value: newValue,
        });
      }}
      renderInput={(params) => <TextField {...params} autoFocus={true} variant="outlined" />}
      fullWidth={true}
    />
  );
};

export default TerritoriesEditCell;
