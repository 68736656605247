import './DocLink.scss';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React, { FC } from 'react';

interface DocLInkProps {
  link?: string;
  text?: string;
}

const DocLink: FC<DocLInkProps> = ({ link, text }) => (
  <Tooltip
    placement="top"
    title={
      <>
        {text || 'Documentation'}
        {': '}
        <a href={link} className="DocLink__link" target="_blank" rel="noreferrer">
          link
        </a>
      </>
    }
  >
    <Box className="DocLink__buttonContainer">
      <a href={link} target="_blank" rel="noreferrer" aria-label="link">
        <IconButton className="DocLink__iconButton">
          <HelpOutlineIcon className="DocLink__icon" />
        </IconButton>
      </a>
    </Box>
  </Tooltip>
);

DocLink.defaultProps = {
  link: '#',
  text: undefined,
};

export default DocLink;
