import '../../layout/common.scss';

import { CircularProgress, Dialog, Grid, MenuItem, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios, { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { DocLink, DocLinkUrl, DocTypeEnum } from '../../features/docs';
import { buildActionUrl, TYPE_CLONE_PROJECT, TYPE_COMPANIES, TYPE_PROJECTS } from '../../shared/url';
import { ProjectCompanyType, ProjectType } from '../../types';

// TODO: move this under the project feature (and reducer)
const projectIdToString = (project: NewProjectIdType) => {
  const strippedSuffix = project.projectIdSuffix.replace(/([^a-z0-9])/gi, '_');
  const strippedProjectNr = project.projectNr.replace(/([^a-z0-9])/gi, '_');
  const strippedCompany = project.company.replace(/([^a-z0-9])/gi, '_');

  const suffix = strippedSuffix ? `_${strippedSuffix}` : '';
  const projectId = `${strippedProjectNr || '<project_nr>'}${suffix}_${strippedCompany || '<company>'}`.toLowerCase();
  return projectId.slice(0, 40);
};

type NewProjectIdType = {
  projectNr: string;
  company: string;
  projectIdSuffix: string;
  projectType: string;
};

type SetupProjectDialogProps = {
  handleClose: () => void;
  handleSubmit: (newProject: NewProjectIdType, originalProjectId: string | null) => void;
  originalProject: string | null;
  loading: boolean;
  companyNames: string[];
};

const initialNewProjectId: NewProjectIdType = {
  projectNr: '',
  company: '',
  projectIdSuffix: '',
  projectType: 'PROJECT_TYPE_CFI',
};

const checkProjectPattern = (project: NewProjectIdType) => {
  if (!project.projectNr || project.projectNr.match('[0-9]{4}_[0-9]+') === null) return false;
  if (!project.company) return false;
  return true;
};

const SetupProjectDialog: React.FC<SetupProjectDialogProps> = ({
  handleClose,
  handleSubmit,
  originalProject,
  loading,
  companyNames,
}) => {
  const cloneProject = !!originalProject;
  const [newProject, setNewProject] = useState(initialNewProjectId);
  const handleChange = (field: string) => (event: { target: { value: string } }) => {
    setNewProject({ ...newProject, [field]: event.target.value });
  };

  const isProjectIdValid = checkProjectPattern(newProject);
  const [error, setError] = useState<string | null>(null);

  const submitProject = () => {
    if (checkProjectPattern(newProject)) {
      return handleSubmit(newProject, originalProject);
    }

    return setError('Project is not valid');
  };

  return (
    <>
      <DialogTitle id="form-dialog-title">
        {cloneProject ? `Clone ${originalProject}` : 'Create project'}{' '}
        <DocLink link={DocLinkUrl[DocTypeEnum.CreateCloneProjectDialog]} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will create a new customer project. Note that the project id can&lsquo;t be changed afterwards.
        </DialogContentText>
        <Grid container={true} spacing={3} direction="row" justifyContent="flex-start" alignItems="center">
          <Grid container={true} item={true} direction="row" justifyContent="space-between" mt={2}>
            <Grid item={true} xs={7}>
              <TextField
                autoFocus={true}
                id="new_project_nr"
                label="Project Nr (eg. 2021_999)"
                value={newProject.projectNr}
                onChange={handleChange('projectNr')}
                type="string"
                fullWidth={true}
                error={newProject.projectNr !== '' && newProject.projectNr.match('[0-9]{4}_[0-9]+') === null}
                variant="outlined"
              />
            </Grid>
            <Grid item={true} xs={4}>
              <TextField
                id="new_project_suffix"
                label="Suffix (optional)"
                value={newProject.projectIdSuffix}
                onChange={handleChange('projectIdSuffix')}
                type="number"
                fullWidth={true}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container={true} item={true} direction="row" justifyContent="space-between" alignItems="center">
            <Grid item={true} xs={7}>
              <Autocomplete
                fullWidth={true}
                options={companyNames}
                data-id-cypress="projectCompanyName"
                onChange={(e, value: string | null) => {
                  setNewProject({ ...newProject, company: value || '' });
                }}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) => <TextField {...params} label="Company" variant="outlined" />}
              />
            </Grid>
            <Grid item={true} xs={4}>
              <TextField
                fullWidth={true}
                select={true}
                id="new_project_type"
                label="Project Type"
                value={newProject.projectType}
                onChange={handleChange('projectType')}
                type="string"
                InputProps={{
                  readOnly: cloneProject,
                  classes: {
                    input: 'common__textField',
                  },
                }}
                variant="outlined"
              >
                {['CFI', 'OTHER', 'DASHBOARD'].map((projectType) => (
                  <MenuItem key={projectType} value={`PROJECT_TYPE_${projectType}`}>
                    {projectType.toLowerCase()}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
        <Box marginTop={3}>
          <TextField
            error={!isProjectIdValid}
            id="new_project_id"
            label="Generated project id"
            value={projectIdToString(newProject)}
            type="string"
            fullWidth={true}
            InputProps={{
              readOnly: true,
              classes: {
                input: 'common__textField',
              },
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={submitProject} data-id-cypress="createProjectDialogButton" color="primary" disabled={loading}>
          {cloneProject ? 'Clone' : 'Create'}
        </Button>
      </DialogActions>
      {error && (
        <Dialog open={true} onClose={() => setError(null)}>
          <DialogTitle>An error has occurred</DialogTitle>
          <DialogContent>{error}</DialogContent>
          <DialogActions>
            <Button onClick={() => setError(null)}>
              Ok
              {loading && <CircularProgress size={24} className="common__buttonProgress" />}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

const ProjectCreate: React.FC<{
  onCancel: any;
  originalProject?: ProjectType;
}> = ({ onCancel, originalProject }) => {
  // const provider = useDataProvider();
  const [companyNames, setCompanyNames] = useState<string[] | null>(null);
  const history = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const addProject = (newProject: NewProjectIdType, originalProjectId: string | null) => {
    setLoading(true);
    const cloneUrl = buildActionUrl({ projectId: originalProjectId as string }, TYPE_CLONE_PROJECT);
    axios
      .post(
        originalProjectId ? cloneUrl : buildActionUrl({}, TYPE_PROJECTS),
        originalProjectId
          ? {
              new_company: newProject.company,
              new_project_id: projectIdToString(newProject),
              new_project_nr: newProject.projectNr,
              new_project_type: newProject.projectType,
            }
          : {
              company: newProject.company,
              project_id: projectIdToString(newProject),
              project_nr: newProject.projectNr,
              type: newProject.projectType,
            },
      )
      .then((res: AxiosResponse) => res.data)
      .then((result: { project?: ProjectType }) => {
        setLoading(false);
        if (result.project) {
          history(
            generatePath('/projects/:idProject/show', {
              idProject: result.project.projectId,
            }),
            {
              replace: true,
            },
          );
        }
      });
  };

  useEffect(() => {
    if (companyNames === null) {
      const url = buildActionUrl({}, TYPE_COMPANIES);
      axios
        .get(url)
        .then((res: AxiosResponse) => res.data)
        .then((response: { companies?: ProjectCompanyType[] }) => {
          setCompanyNames(response?.companies?.map((c) => c.name) || []);
        });
    }
  }, [companyNames]);

  return (
    <SetupProjectDialog
      handleClose={onCancel}
      handleSubmit={addProject}
      loading={loading}
      companyNames={companyNames || []}
      originalProject={originalProject?.projectId || ''}
    />
  );
};

ProjectCreate.defaultProps = { originalProject: undefined };

export default ProjectCreate;
