import { RecipeFiltersType, RecipeSelectionType, SocialPostSelectionType, SocialTagErrorItem } from '../../../types';
import { ContextStateType } from '../reducer';

export const isDataNotSaved = (state: ContextStateType): boolean =>
  Boolean(state.contextVersion) &&
  (String(state.description || '') !== String(state?.contextVersion?.description || '') ||
    (state.contextVersion?.socialPostsVersion &&
      state.socialPostVersion !== state.contextVersion?.socialPostsVersion) ||
    (state.contextVersion?.socialPosts && state.socialPostsSelections !== state.contextVersion?.socialPosts) ||
    (state.contextVersion?.recipesVersion && state.recipesVersion !== state.contextVersion?.recipesVersion) ||
    (state.contextVersion?.recipes && state.recipeSelections !== state.contextVersion?.recipes) ||
    (state.contextVersion?.dishTypesVersion !== null &&
      state.dishTypesVersion !== state.contextVersion?.dishTypesVersion));

export const isLoading = (state: ContextStateType): boolean =>
  state.loadingStatuses.init ||
  state.loadingStatuses.socialCategory ||
  state.loadingStatuses.socialTagsCheck ||
  state.loadingStatuses.recipeFilters ||
  state.loadingStatuses.socialCategoryTags ||
  state.loadingStatuses.dishTypesList ||
  state.isSaving;

export const isPublished = (state: ContextStateType): boolean =>
  state.contextVersion?.status === 'CONTEXT_VERSION_STATUS_PUBLISHED';

export const isArchived = (state: ContextStateType): boolean =>
  state.contextVersion?.status === 'CONTEXT_VERSION_STATUS_ARCHIVED';

export const isFormErrors = (state: ContextStateType): boolean => state.recipeError || state.socialErrors !== null;

export const isSaveDisabled = (state: ContextStateType, isCloning: boolean): boolean =>
  (!isCloning && (isArchived(state) || isPublished(state))) ||
  state.isSaving ||
  isFormErrors(state) ||
  isLoading(state);

export const isArchiveDisabled = (state: ContextStateType): boolean =>
  isArchived(state) || state.isSaving || isFormErrors(state) || isLoading(state);

export const isPublishDisabled = (state: ContextStateType): boolean =>
  isDataNotSaved(state) ||
  isArchived(state) ||
  isPublished(state) ||
  state.isSaving ||
  isFormErrors(state) ||
  isLoading(state);

export const isEditingDisabled = (state: ContextStateType): boolean => isPublished(state) || isArchived(state);

export const isNoTagOrFiltersSelected = (
  recipeSelections: RecipeSelectionType[],
  recipeFilters: RecipeFiltersType,
): boolean =>
  !recipeFilters || !recipeFilters.dishTypes || !recipeFilters.dishTypes.length || !recipeSelections?.length;

export const isNotInListSelected = (
  recipeSelections: RecipeSelectionType[],
  recipeFilters: RecipeFiltersType,
): RecipeSelectionType | undefined =>
  recipeSelections.find(
    (r) =>
      !recipeFilters.dishTypes.includes(r.dishType) ||
      (r.tasteType && !recipeFilters.tasteTypes.includes(r.tasteType)) ||
      (r.regionType && !recipeFilters.regionTypes.includes(r.regionType)),
  );

export const getNewSocialError = (
  socialErrors: SocialTagErrorItem[] | null,
  socialPostsSelections: SocialPostSelectionType[],
): SocialTagErrorItem[] | null =>
  socialErrors
    ?.filter((error) =>
      socialPostsSelections.find((selection) => selection.category === error.category && selection.posts.length > 0),
    )
    .map((error) => ({
      ...error,
      tags: error.tags.filter((tag) =>
        socialPostsSelections.find(
          (selection) =>
            selection.category === error.category && selection.posts.map((i: { tag: string }) => i.tag).includes(tag),
        ),
      ),
    }))
    .filter((error) => error.tags.length > 0) || null;
