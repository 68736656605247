import { Icon } from '@mui/material';
import React from 'react';

import { DataManIconType, DataManListSort, DataManListSortOptions } from './types';

export const getIconFromProp = (icon: DataManIconType): JSX.Element => {
  const renderComponent = icon;
  if (React.isValidElement(renderComponent)) {
    return renderComponent;
  }
  if (typeof renderComponent === 'function') {
    return <>{renderComponent({})}</>;
  }

  return <Icon>icon</Icon>;
};

export const getSortableOptions = (sort: DataManListSortOptions, columnIdentifier: string): DataManListSort | null => {
  if (!sort || !sort.length) return null;
  for (let i = 0; i < sort.length; i++) {
    if (sort[i].field === columnIdentifier) return sort[i];
  }

  return null;
};
