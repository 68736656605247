import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/types/types-external';

import { ConceptGenerationWFVersion } from '../../../admin/projects/ProjectFlow/ConceptGeneration/_utils/types';
import { BackendLoadingStatus, GenericInputConfig } from '../types/common';
import {
  APIConceptTemplate,
  ConceptGenerationConfigV3,
  ConceptTemplate,
  ConceptTerritoryDefinition,
  ConceptTerritoryDefinitionOption,
  emptyIngredientSlot,
  freeIngredientSlot,
  SelectedIngredient,
  SensoryRemapping,
} from '../types/concept-generation';

export interface ConceptGenerationWorkflowV3State {
  territoryDefinitions: ConceptTerritoryDefinition[];
  territoryDefinitionsLoadingState: BackendLoadingStatus;
  territoryDefinitionsWarningMessage?: string;
  selectedTerritoryDefinition?: ConceptTerritoryDefinitionOption;

  selectedIngredientIds: number[];
  sensoryRemapping: SensoryRemapping;
  templates: Map<number, ConceptTemplate>;
  maxIngredientSlots: number;
}

export const defaultConceptGenerationWFV3State: ConceptGenerationWorkflowV3State = {
  territoryDefinitions: [],
  territoryDefinitionsLoadingState: BackendLoadingStatus.Initial,
  territoryDefinitionsWarningMessage: '',
  selectedIngredientIds: [],
  sensoryRemapping: new Map(),
  templates: new Map(),
  maxIngredientSlots: 2,
};

/**
 * Resets some state variables to their default values
 * The state variables which are not reset are the ones which are
 * dependent on the selected project. They only need to change when the project changes.
 * This is managed by component RunFlowConfigV3
 * @param state
 */
function resetState(state: WritableDraft<ConceptGenerationWorkflowV3State>) {
  state.selectedIngredientIds = defaultConceptGenerationWFV3State.selectedIngredientIds;
  state.sensoryRemapping = defaultConceptGenerationWFV3State.sensoryRemapping;
  state.templates = defaultConceptGenerationWFV3State.templates;
  state.maxIngredientSlots = defaultConceptGenerationWFV3State.maxIngredientSlots;
}

/**
 * This function sets the sensory remappings based on what's returned from the API
 * @param state
 * @param apiSensoryRemapping
 */
function setStateSensoryRemapping(
  state: WritableDraft<ConceptGenerationWorkflowV3State>,
  apiSensoryRemapping: { [key: number]: number } | undefined,
) {
  if (apiSensoryRemapping) {
    const map = new Map();
    Object.keys(apiSensoryRemapping).forEach((key) => {
      map.set(Number(key), apiSensoryRemapping[Number(key)]);
    });
    state.sensoryRemapping = map;
  } else {
    state.sensoryRemapping = defaultConceptGenerationWFV3State.sensoryRemapping;
  }
}

/**
 * This function determines the maximum number of ingredients slots used in the templates
 * It sets this value in state variable maxIngredientSlots
 * @param state
 * @param apiTemplates
 */
function setStateMaxIngredients(
  state: WritableDraft<ConceptGenerationWorkflowV3State>,
  apiTemplates: APIConceptTemplate[],
) {
  // determine max number of ingredients
  let maxIngredientSlots = 0;
  // loop over all apiTemplates
  apiTemplates.forEach((apiTemplate) => {
    const numIngredients = apiTemplate.bases.length + apiTemplate.no_free_ingredients;
    if (numIngredients > maxIngredientSlots) {
      maxIngredientSlots = numIngredients;
    }
  });
  state.maxIngredientSlots = maxIngredientSlots;
}

/**
 * This function explodes the APITemplates into all possible permutations
 * This needs to happen because in the UI we do not support that notation of templates
 */
function explodeAPITemplateBases(apiTemplate: APIConceptTemplate): number[][] {
  let allTemplateSlots: number[][] = [];
  if (apiTemplate.bases.length !== 0) {
    apiTemplate.bases.forEach((base) => {
      if (allTemplateSlots.length === 0) {
        // expand by setting the first slot as the first ingredient
        // of the template
        base.values.forEach((value) => {
          allTemplateSlots.push([value]);
        });
      } else {
        const newSlots: number[][] = [];
        // expand by adding all possible ingredients of the next slot to
        // each of the existing templates
        allTemplateSlots.forEach((slot) => {
          base.values.forEach((value) => {
            newSlots.push([...slot, value]);
          });
        });
        allTemplateSlots = newSlots;
      }
    });
    return allTemplateSlots;
  }
  return [[]];
}

/**
 * This function sets the templates in the state variable
 * Before settings the state, the API Concept Template type needs
 * to be converted into the internal Concept Template type
 * @param state
 * @param apiTemplates
 */
function setStateTemplates(state: WritableDraft<ConceptGenerationWorkflowV3State>, apiTemplates: APIConceptTemplate[]) {
  const templates = new Map();
  // loop over all apiTemplates
  apiTemplates.forEach((apiTemplate) => {
    // calculate all permutations so they can be shown in the frontend
    const allTemplateSlots: number[][] = explodeAPITemplateBases(apiTemplate);

    // loop over all permutations and add 1 template per permutation
    // to the internal structure
    allTemplateSlots.forEach((templateSlots) => {
      const slots = [...templateSlots];
      // add free ingredient slots
      for (let i = 0; i < apiTemplate.no_free_ingredients; i++) {
        slots.push(freeIngredientSlot.ingredientId);
      }
      // add empty ingredient slots
      for (let i = slots.length; i < state.maxIngredientSlots; i++) {
        slots.push(emptyIngredientSlot.ingredientId);
      }

      const template = {
        ingredientSlots: slots,
        territories: [...apiTemplate.concept_territory_names].sort(),
      };
      templates.set(templates.size, template);
    });
  });
  state.templates = templates;
}

export const SLICE_CONCEPT_GENERATION_V3 = 'concept_generation_V3';
export const conceptGenerationV3Slice = createSlice({
  name: SLICE_CONCEPT_GENERATION_V3,
  initialState: defaultConceptGenerationWFV3State,
  reducers: {
    setTerritoryDefinitions: (state, action: PayloadAction<ConceptTerritoryDefinition[]>) => {
      state.territoryDefinitions = action.payload;
      state.territoryDefinitionsLoadingState = BackendLoadingStatus.Loaded;
    },
    setTerritoryDefinitionsWarningMessage: (state, action: PayloadAction<string>) => {
      state.territoryDefinitionsWarningMessage = action.payload;
      state.territoryDefinitionsLoadingState = BackendLoadingStatus.Loaded;
    },
    setTerritoryDefinitionsLoadingState: (state, action: PayloadAction<BackendLoadingStatus>) => {
      state.territoryDefinitionsLoadingState = action.payload;
    },
    setConceptGenerationConfig: (state, action: PayloadAction<GenericInputConfig | undefined>) => {
      if (state.territoryDefinitions) {
        if (action.payload && action.payload?.version === ConceptGenerationWFVersion.v3) {
          const genericInput = action.payload as ConceptGenerationConfigV3;
          const {
            ingredients,
            sensory_remapping,
            templates: apiTemplates,
            concept_territories_definition_name,
            concept_territories_definition_version,
          } = genericInput;
          state.selectedIngredientIds = ingredients;

          setStateSensoryRemapping(state, sensory_remapping);

          if (
            concept_territories_definition_name &&
            // version can be 0, so we need to check if it is defined
            concept_territories_definition_version !== undefined &&
            apiTemplates
          ) {
            setStateMaxIngredients(state, apiTemplates);
            setStateTemplates(state, apiTemplates);

            // set selected territory definition with empty territories
            const foundDefinition: ConceptTerritoryDefinitionOption = {
              name: concept_territories_definition_name,
              // always convert version to number: needed for reverse compabitility
              version: Number(concept_territories_definition_version),
            };
            state.selectedTerritoryDefinition = foundDefinition;
          } else {
            state.templates = defaultConceptGenerationWFV3State.templates;
            state.maxIngredientSlots = defaultConceptGenerationWFV3State.maxIngredientSlots;
            state.selectedTerritoryDefinition = undefined;
          }
        } else {
          resetState(state);
        }
      } else {
        throw Error('Territory definitions not loaded');
      }
    },
    setSelectedIngredients: (state, { payload: { items } }: PayloadAction<{ items: SelectedIngredient[] }>) => {
      state.selectedIngredientIds = items.map((item) => item.ingredient_id);
    },
    setSensoryRemapping: (
      state,
      {
        payload: { ingredientId, sensoryIngredientId },
      }: PayloadAction<{
        ingredientId: number;
        sensoryIngredientId: number | undefined;
      }>,
    ) => {
      if (sensoryIngredientId) {
        state.sensoryRemapping.set(ingredientId, sensoryIngredientId);
      } else {
        state.sensoryRemapping.delete(ingredientId);
      }
    },
    addUpdateTemplate: (
      state,
      {
        payload: { templateId, template },
      }: PayloadAction<{
        templateId: number | undefined;
        template: ConceptTemplate;
      }>,
    ) => {
      if (templateId !== undefined) {
        state.templates.set(templateId, template);
      } else {
        state.templates.set(state.templates.size, template);
      }
    },
    deleteTemplate: (state, { payload: { templateId } }: PayloadAction<{ templateId: number }>) => {
      state.templates.delete(templateId);
    },
    resetTemplates: (state) => {
      state.templates = defaultConceptGenerationWFV3State.templates;
    },
    setMaxIngredientSlots: (state, { payload: { diff } }: PayloadAction<{ diff: 1 | -1 }>) => {
      if (diff < 0) {
        // remove ingredient slots
        state.templates.forEach((template, index) => {
          const slots = template.ingredientSlots;
          slots.pop();
          state.templates.set(index, {
            ingredientSlots: slots,
            territories: template.territories,
          });
        });
      } else if (diff > 0) {
        // add ingredient slots
        state.templates.forEach((template, index) => {
          const slots = template.ingredientSlots;
          slots.push(freeIngredientSlot.ingredientId);
          state.templates.set(index, {
            ingredientSlots: slots,
            territories: template.territories,
          });
        });
      }
      state.maxIngredientSlots += diff;
    },
    setSelectedTerritoryDefinition: (
      state,
      {
        payload: { territoryDefinition },
      }: PayloadAction<{
        territoryDefinition: ConceptTerritoryDefinitionOption;
      }>,
    ) => {
      state.selectedTerritoryDefinition = territoryDefinition;
    },
  },
});

export default conceptGenerationV3Slice.reducer;
export const {
  setSelectedIngredients,
  setConceptGenerationConfig,
  setSensoryRemapping,
  addUpdateTemplate,
  resetTemplates,
  deleteTemplate,
  setMaxIngredientSlots,
  setTerritoryDefinitions,
  setTerritoryDefinitionsLoadingState,
  setTerritoryDefinitionsWarningMessage,
  setSelectedTerritoryDefinition,
} = conceptGenerationV3Slice.actions;
