import '../../layout/common.scss';

import ZoomIn from '@mui/icons-material/ZoomIn';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef, GridLoadingOverlay, GridToolbar } from '@mui/x-data-grid';
import orderBy from 'lodash/orderBy';
import startCase from 'lodash/startCase';
import React, { ChangeEvent, FC, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ErrorNotification from '../../components/ErrorNotification';
import Loading from '../../components/Loading';
import TabPanel from '../../components/TabPanel';
import { useDataSources } from '../../context/DataSourcesContext';
import { useLayout } from '../../context/LayoutContext';
import { buildActionUrl, TYPE_INGREDIENT_AROMA_VIZ_PAGE } from '../../shared/url';
import { useAppDispatch, useAppSelector } from '../../store';
import { DataSourceType, DataVersionsType, GenericIngredientType } from '../../types';
import VizContainer from './containers/VizContainer';
import { changeCoreVersion, changeIngredientDbVersion, setError, SLICE_DATASOURCE_NAME } from './store';
import { DataSourceState } from './store/types';
import { GridSelectionModel } from './types';

type Source = { type: DataSourceType; key: keyof DataVersionsType };
const IDX_DATA_SOURCE_MAPPING: { [idx: number]: Source } = {
  0: {
    type: DataSourceType.Core,
    key: 'coreVersions' as keyof DataVersionsType,
  },
  4: {
    type: DataSourceType.IngredientDB,
    key: 'ingredientDbVersions' as keyof DataVersionsType,
  },
};

type MatchProps = {};

export type VersionListProps = {
  versions: string[];
  selected: string;
  changeVersion: (version: string) => void;
  as?: 'autocomplete' | 'list';
  disabled?: boolean;
  inputName?: string;
  label?: string;
  needSortVersions?: boolean;
  required?: boolean;
};

export const VersionList: FC<VersionListProps> = ({
  versions,
  selected,
  changeVersion,
  as,
  disabled,
  label,
  needSortVersions,
  required,
}) => {
  const labelConverted = startCase(label);
  const sortedVersions = useMemo(
    () => (needSortVersions && orderBy(versions, (version: string) => Number(version), ['desc'])) || versions,
    [versions, needSortVersions],
  );

  return (
    <>
      {as === 'autocomplete' && (
        <Autocomplete
          data-id-cypress="dataVersionsOption"
          value={selected}
          options={versions}
          getOptionLabel={(option) => option}
          isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
          fullWidth={true}
          className="common__dataSourceVersionListDropdownWrapper"
          disableClearable={true}
          style={{ height: '40px', minWidth: 150 }}
          onChange={(e, value: string | null) => changeVersion(value || '')}
          disabled={disabled}
          renderInput={(params) => <TextField {...params} label={labelConverted} required={required} />}
        />
      )}

      {as === 'list' && (
        <Paper className="common__configSection">
          <Typography style={{ textAlign: 'center' }} paragraph={true} variant="h5">
            {` ${labelConverted} `}
          </Typography>
          <List component="nav" className="common__dataSourceVersionList">
            {sortedVersions.map((version) => (
              <ListItem
                key={version}
                button={true}
                selected={selected === version}
                onClick={() => changeVersion(version)}
              >
                <ListItemText primary={version} />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
    </>
  );
};

VersionList.defaultProps = {
  as: 'list',
  inputName: 'coreVersion',
  label: 'Versions',
  disabled: false,
  needSortVersions: false,
  required: false,
};

type IngredientTableProps = {
  version: string;
  ingredients: GenericIngredientType[];
  loading: boolean;
  error?: string;
  checkboxSelection: boolean;
  columns: GridColDef[];
  identifier: string;
  showGenerateBarcodeBtn?: boolean;
  initialHeight?: string | number;
};

const IngredientTable: FC<IngredientTableProps> = ({
  version,
  ingredients,
  loading,
  error,
  columns,
  checkboxSelection,
  identifier,
  showGenerateBarcodeBtn,
  initialHeight,
}) => {
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const selectionHandler = useCallback<(model: GridSelectionModel) => void>((model) => setSelectionModel(model), []);

  const rows = useMemo<GenericIngredientType[]>(
    () =>
      (ingredients ?? []).map((ingredient: GenericIngredientType) => {
        const { ingredientId } = ingredient;
        return {
          ...ingredient,
          ingredientId,
          id: ingredient[identifier as keyof GenericIngredientType],
        };
      }),
    [identifier, ingredients],
  );

  return (
    <VizContainer error={error}>
      <div style={{ height: initialHeight, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          components={{
            Toolbar: GridToolbar,
            LoadingOverlay: GridLoadingOverlay,
          }}
          autoPageSize={true}
          checkboxSelection={checkboxSelection}
          disableSelectionOnClick={true}
          selectionModel={selectionModel}
          onSelectionModelChange={selectionHandler}
          className="common__ingredientTableDataGrid"
          loading={loading}
          rowHeight={42}
        />
      </div>
      {showGenerateBarcodeBtn && (
        <Box marginTop={2}>
          <Button
            disabled={selectionModel.length < 1}
            variant="contained"
            color="primary"
            href={`/data/ingredients/${version}/code?ingredientIds=${selectionModel.join(',')}`}
          >
            Generate bar codes
          </Button>
        </Box>
      )}
    </VizContainer>
  );
};

IngredientTable.defaultProps = {
  error: undefined,
  showGenerateBarcodeBtn: false,
  initialHeight: '70vh',
};

const DataSources: React.FC<MatchProps> = () => {
  const { setPageTitle } = useLayout();
  const dataSources = useDataSources();
  const dispatch = useAppDispatch();
  const state = useAppSelector((store) => store[SLICE_DATASOURCE_NAME]);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [dataVersions, setDataVersions] = useState<DataVersionsType | null>(null as DataVersionsType | null);
  const mounted = useRef<boolean>(false);

  const history = useNavigate();

  // action cell renderer
  const renderCoreActionsCell = useCallback<(version: string, id: string) => ReactElement>(
    (version, productPreparationId) => (
      <ZoomIn
        onClick={() =>
          history(
            buildActionUrl(
              {
                version,
                queryParams: `?product_preparation_id=${productPreparationId}`,
              },
              TYPE_INGREDIENT_AROMA_VIZ_PAGE,
            ),
          )
        }
        style={{ cursor: 'pointer' }}
      />
    ),
    [history],
  );

  // version selection handler
  const handleChangeVersion = useCallback(
    (sourceType: DataSourceType, previousVersion?: string) => (version: string) => {
      if (version !== previousVersion) {
        // we only fetch data for two data sources
        if (sourceType === DataSourceType.Core) {
          dispatch(changeCoreVersion({ version, sourceType }));
        } else if (sourceType === DataSourceType.IngredientDB) {
          dispatch(changeIngredientDbVersion({ version, sourceType }));
        }
      }
    },
    [dispatch],
  );

  // Load initial data
  const loadData = useCallback<() => void>(() => setDataVersions(dataSources.versions), [dataSources]);

  // version selection handler
  const preselectInitialVersion = useCallback<
    (idx: number, dataSourceState: DataSourceState, versions?: DataVersionsType) => void
  >(
    (tabIndex, dataSourceState, versions) => {
      const { key, type } = IDX_DATA_SOURCE_MAPPING[tabIndex] || {};
      const stateVersion = dataSourceState[type as keyof typeof state];

      if (!stateVersion && versions?.[key]?.[0]) {
        handleChangeVersion(type, '')(versions?.[key]?.[0] || '');
      }
    },
    [handleChangeVersion],
  );

  // tab change handler
  const handleChangeTab = useCallback<(e: ChangeEvent<{}>, value: number) => void>((event, newValue) => {
    setSelectedTabIndex(newValue);
  }, []);

  useEffect(() => {
    if (mounted.current) return;
    if (!dataSources.loading) {
      mounted.current = true;
      loadData();
    }
  }, [loadData, dataSources.loading]);

  // preselect version when page mounted and when tab opened for first time
  useEffect(
    () => preselectInitialVersion(selectedTabIndex, state, dataVersions || undefined),
    [selectedTabIndex, dataVersions, preselectInitialVersion, state],
  );

  useEffect(() => setPageTitle('Datasources'), [setPageTitle]);

  /*
  const countryQuery = state.recipeVersion
    ? `select count(distinct meta.id) as value, 
  case
    when country = "US" then "USA"
    when country = "MX" then "MEX"
    when country = "FR" then "FRA"
    when country = "CA" then "FRA"
    when country = "AU" then "AUS"
    when country = "DE" then "DEU"
    when country = "JP" then "JPN"
    when country = "AT" then "AUT"
    when country = "AR" then "ARG"
    when country = "GB" then "GBR"
    when country = "BR" then "BRA"
    when country = "IE" then "IRL"
    else country
  end as id
from recipes.recipes_${state.recipeVersion}
where country is not null
group by country`
    : null;
  const [countryData, countryDataLoading, countryDataError] = useFetchQuery(
    countryQuery
  );
  */
  // const countryData = null;
  // const countryDataLoading = false;
  // const countryDataError = 'not supported';
  /*
  const trendsQuery = state.trendsVersion
    ? `
    select avg(score) as score, keyword as ingredient
from trends.google_trends_scores_${state.trendsVersion}
group by keyword
order by score desc
limit 10
  `
    : null;
  const [trendsData, trendsDataLoading, trendsDataError] = useFetchQuery(
    trendsQuery
  );
  */
  // const trendsData = null;
  // const trendsDataLoading = false;
  // const trendsDataError = 'not supported';

  if (dataSources.loading) {
    return <Loading />;
  }

  return (
    <>
      <Typography paragraph={true} variant="h4">
        {' '}
        Datasources
      </Typography>
      <Tabs
        value={selectedTabIndex}
        onChange={handleChangeTab}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab data-id-cypress="datasourcesTabs" label="Core" disabled={state.loading} />
        <Tab data-id-cypress="datasourcesTabs" label="Recipes" disabled={state.loading} />
        <Tab data-id-cypress="datasourcesTabs" label="Social media" disabled={state.loading} />
        <Tab data-id-cypress="datasourcesTabs" label="Trends" disabled={state.loading} />
        <Tab data-id-cypress="datasourcesTabs" label="IngredientDB" disabled={state.loading} />
      </Tabs>
      <TabPanel value={selectedTabIndex} index={0}>
        <Grid container={true} spacing={3} direction="row">
          <Grid item={true}>
            <VersionList
              as="list"
              versions={dataVersions ? dataVersions.coreVersions : []}
              selected={state.coreVersion}
              changeVersion={handleChangeVersion(DataSourceType.Core, state.coreVersion)}
            />
          </Grid>
          <Grid item={true} xs={true}>
            <Paper data-id-cypress="dataSourceTable" className="common__configSection" style={{ height: 'auto' }}>
              {/* <Typography paragraph variant="h5"> */}
              {/*  {' '} */}
              {/*  Info{' '} */}
              {/* </Typography> */}
              {/* @Todo: core info */}
              {selectedTabIndex === 0 && (
                <IngredientTable
                  initialHeight="75vh"
                  identifier="productPreparationId"
                  checkboxSelection={false}
                  columns={[
                    {
                      field: 'productPreparationId',
                      align: 'center',
                      headerAlign: 'center',
                      headerName: 'Product Preparation ID',
                      minWidth: 300,
                    },
                    {
                      field: 'name',
                      headerName: 'Name',
                      align: 'center',
                      headerAlign: 'center',
                      minWidth: 200,
                      flex: 1,
                    },
                    {
                      field: 'actions',
                      headerName: 'Actions',
                      minWidth: 150,
                      align: 'right',
                      headerAlign: 'center',
                      renderCell: (params) =>
                        renderCoreActionsCell(state.coreVersion, params?.row?.productPreparationId),
                    },
                  ]}
                  version={state.coreVersion}
                  ingredients={state.coreIngredients}
                  loading={state.loading}
                />
              )}
            </Paper>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={1}>
        <Grid container={true} spacing={3} direction="row">
          <Grid item={true}>
            <VersionList
              as="list"
              versions={dataVersions ? dataVersions.recipeVersions : []}
              selected={state.recipeVersion}
              changeVersion={handleChangeVersion(DataSourceType.Recipe, state.recipeVersion)}
            />
          </Grid>
          <Grid item={true} container={true} xs={true} direction="row">
            <Paper className="common__configSection">
              <Typography paragraph={true} variant="h5">
                No data available in CFI tool for now
              </Typography>
              {/* <Grid item xs={2}> */}
              {/*  <TextField */}
              {/*    id="standard-basic" */}
              {/*    label="Core version" */}
              {/*    value="20660707" */}
              {/*    className="common__textField" */}
              {/*    margin="normal" */}
              {/*    InputProps={{ */}
              {/*      readOnly: true, */}
              {/*    }} */}
              {/*  /> */}
              {/* </Grid> */}
              {/* <Grid item> */}
              {/*  <Typography paragraph variant="h5"> */}
              {/*    {' '} */}
              {/*    Recipes per country{' '} */}
              {/*  </Typography> */}
              {/*  <VizContainer loading={countryDataLoading} error={countryDataError}> */}
              {/*    <WorldGraph data={countryData} /> */}
              {/*  </VizContainer> */}
              {/* </Grid> */}
            </Paper>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={2}>
        <Grid container={true} spacing={3} direction="row">
          <Grid item={true}>
            <VersionList
              as="list"
              versions={dataVersions ? dataVersions.socialPostVersions : []}
              selected={state.socialPostsVersion}
              changeVersion={handleChangeVersion(DataSourceType.SocialPosts, state.socialPostsVersion)}
            />
          </Grid>
          <Grid item={true} container={true} xs={true} direction="row">
            <Paper className="common__configSection">
              <Typography paragraph={true} variant="h5">
                No data available in CFI tool for now
              </Typography>
              {/*  Todo: social info */}
            </Paper>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={3}>
        <Grid container={true} spacing={3} direction="row">
          <Grid item={true}>
            <VersionList
              as="list"
              versions={dataVersions ? dataVersions.trendsVersions : []}
              selected={state.trendsVersion}
              changeVersion={handleChangeVersion(DataSourceType.Trends, state.trendsVersion)}
            />
          </Grid>
          <Grid item={true} container={true} xs={true} direction="row">
            <Paper className="common__configSection">
              <Typography paragraph={true} variant="h5">
                No data available in CFI tool for now
              </Typography>
              {/* <VizContainer loading={trendsDataLoading} error={trendsDataError}> */}
              {/*  <BarGraph data={trendsData} /> */}
              {/* </VizContainer> */}
            </Paper>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={4}>
        <Grid container={true} spacing={3} direction="row">
          <Grid item={true}>
            <VersionList
              as="list"
              versions={dataVersions?.ingredientDbVersions || []}
              selected={state.ingredientDbVersion}
              changeVersion={handleChangeVersion(DataSourceType.IngredientDB, state.ingredientDbVersion)}
            />
          </Grid>
          <Grid item={true} xs={true}>
            <Paper className="common__configSection" style={{ height: 'auto' }}>
              {/* <Typography paragraph variant="h5"> */}
              {/*  {' '} */}
              {/*  Info{' '} */}
              {/* </Typography> */}
              {/* <Typography paragraph variant="h5"> */}
              {/*  {' '} */}
              {/*  Ingredients{' '} */}
              {/* </Typography> */}
              {selectedTabIndex === 4 && (
                <IngredientTable
                  showGenerateBarcodeBtn={true}
                  identifier="ingredientId"
                  checkboxSelection={true}
                  columns={[
                    { field: 'ingredientId', headerName: 'ID', minWidth: 100 },
                    {
                      field: 'name',
                      headerName: 'Name',
                      minWidth: 200,
                      flex: 1,
                    },
                    {
                      field: 'productPreparationId',
                      headerName: 'ProductPreparationId',
                      minWidth: 250,
                    },
                  ]}
                  version={state.ingredientDbVersion}
                  ingredients={state.ingredients}
                  loading={state.loading}
                />
              )}
            </Paper>
          </Grid>
        </Grid>
      </TabPanel>
      <ErrorNotification error={state.error} handleClose={() => dispatch(setError({ error: null }))} />
    </>
  );
};

export default DataSources;
