import React, { Dispatch } from 'react';

import { ProjectType } from '../../../../types';
import { PhaseType, RunType } from './types';

export type NavigationButtonsStatus = {
  back: boolean;
  next: boolean;
};

export enum FlowActions {
  setLoading = 'setLoading',
  loadProject = 'loadProject',
  loadPhase = 'loadPhase',
  setRuns = 'setRuns',
  setSelectedRun = 'setSelectedRun',
  setNavigationButtonStatusCallback = 'setNavigationButtonStatusCallback',
  setApplyButton = 'setApplyButton',
  setPrimaryNavigationVisible = 'setPrimaryNavigationVisible',
  setPhaseBackUrl = 'setPhaseBakUrl',
  setPhaseNextUrl = 'setPhaseNextUrl',
  setSimulateFail = 'setSimulateFail',
}

export type FlowAction =
  | { type: FlowActions.loadProject; project: ProjectType | null }
  | {
      type: FlowActions.loadPhase;
      phase: PhaseType | null;
      runs?: RunType[];
      selectedRun?: RunType | null;
      backUrl?: string | null;
      nextUrl?: string | null;
      project: ProjectType | null;
    }
  | { type: FlowActions.setLoading; loading: boolean }
  | {
      type: FlowActions.setRuns;
      runs: RunType[];
      selectedRun?: RunType | undefined;
      lastRun?: RunType | null;
    }
  | { type: FlowActions.setSelectedRun; run: RunType | null }
  | { type: FlowActions.setApplyButton; button: any }
  | { type: FlowActions.setPrimaryNavigationVisible; visible: boolean }
  | {
      type: FlowActions.setNavigationButtonStatusCallback;
      callback: (() => NavigationButtonsStatus) | null;
    }
  | { type: FlowActions.setSimulateFail; simulate: boolean }
  | { type: FlowActions.setPhaseBackUrl; backUrl: string | null }
  | { type: FlowActions.setPhaseNextUrl; nextUrl: string | null };

export type ProjectFlowStateType = {
  loading: boolean;
  project: ProjectType | null;
  phase: PhaseType | null;
  runs: RunType[];
  lastRun: RunType | null;
  selectedRun: RunType | null;
  simulateFail: boolean;
  navigationStatus: (() => NavigationButtonsStatus) | null;
  applyButton: any;
  primaryNavVisible: boolean;
  backUrl: string | null;
  nextUrl: string | null;
  outputGenerationByRunId: any | null;
};

type Action = FlowAction;

const initialState: ProjectFlowStateType = {
  project: null,
  phase: null,
  runs: [],
  lastRun: null,
  selectedRun: null,
  loading: true,
  simulateFail: false,
  navigationStatus: null,
  applyButton: null,
  primaryNavVisible: true,
  backUrl: null,
  nextUrl: null,
  outputGenerationByRunId: null,
};

function reducerImplementation(state: ProjectFlowStateType, action: Action) {
  switch (action.type) {
    case FlowActions.setLoading:
      return {
        ...state,
        loading: action.loading,
      };
    case FlowActions.loadProject:
      return {
        ...state,
        project: action.project,
      };
    case FlowActions.loadPhase: {
      const initialLastRun = action.runs?.find((run) => !run.readOnly) || null;

      return {
        ...state,
        phase: action?.phase || null,
        runs: action?.runs || [],
        lastRun: initialLastRun,
        selectedRun: action?.selectedRun || null,
        project: action?.project || null,
        backUrl: action?.backUrl || null,
        nextUrl: action?.nextUrl || null,
      };
    }
    case FlowActions.setRuns: {
      const lastRun =
        typeof action.lastRun === undefined ? action.runs.find((run) => !run.readOnly) || null : action.lastRun || null;
      const selectedRun = action.selectedRun || lastRun;

      return {
        ...state,
        runs: action.runs,
        lastRun: selectedRun,
        selectedRun,
      };
    }
    case FlowActions.setSelectedRun:
      return {
        ...state,
        selectedRun: action.run,
      };
    case FlowActions.setSimulateFail:
      return {
        ...state,
        simulateFail: action.simulate,
      };
    case FlowActions.setApplyButton:
      return {
        ...state,
        applyButton: action.button,
      };
    case FlowActions.setPrimaryNavigationVisible:
      return {
        ...state,
        primaryNavVisible: action.visible,
      };
    case FlowActions.setNavigationButtonStatusCallback:
      return {
        ...state,
        navigationStatus: action.callback,
      };
    case FlowActions.setPhaseBackUrl:
      return {
        ...state,
        backUrl: action.backUrl,
      };
    case FlowActions.setPhaseNextUrl:
      return {
        ...state,
        nextUrl: action.nextUrl,
      };
    default:
      throw new Error();
  }
}

export function reducer(state: ProjectFlowStateType, action: Action): ProjectFlowStateType {
  return reducerImplementation(state, action);
}

export function useProjectFlowReducer(): [ProjectFlowStateType, Dispatch<any>] {
  return React.useReducer(reducer, initialState);
}
