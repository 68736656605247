import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import axios, { AxiosResponse } from 'axios';
import React, { memo, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import Loading from '../../components/Loading';
import { buildActionUrl, TYPE_GET_CONTEXT } from '../../shared/url';
import { ContextType } from '../../types';
import ContextForm from './ContextForm';

interface ContextEditDialogProps {
  handleClose: (update?: boolean) => void;
  contextId: string;
}

const ContextEditDialog: React.FC<ContextEditDialogProps> = ({ handleClose, contextId }) => {
  const [context, setContext] = useState<ContextType | null>(null);
  const [contextLoading, setContextLoading] = useState<boolean>(false);

  useEffect(() => {
    setContextLoading(true);
    const url = buildActionUrl({ contextId }, TYPE_GET_CONTEXT);
    axios
      .get(url)
      .then((res: AxiosResponse) => res.data)
      .then((res) => {
        if (res.ok) {
          setContext(res.context);
          setContextLoading(false);
        }
      });
  }, [contextId]);

  if (!contextId) {
    return <Navigate to={`/contexts`} />;
  }

  return (
    <Dialog fullWidth={true} open={true} onClose={() => handleClose()} maxWidth="md">
      {!contextLoading && context ? (
        <>
          <DialogTitle>Editing context: {context.name}</DialogTitle>
          <DialogContent dividers={true}>
            <ContextForm context={context} handleClose={handleClose} />
          </DialogContent>
        </>
      ) : (
        <Loading />
      )}
    </Dialog>
  );
};

export default memo(ContextEditDialog);
