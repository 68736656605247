import { Add, Archive, Edit } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import axios, { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router';
import { useMatch, useNavigate, useParams } from 'react-router-dom';

import PageTitle from '../../components/PageTitle';
import { useLayout } from '../../context/LayoutContext';
import { DocLink, DocLinkUrl, DocTypeEnum } from '../../features/docs';
import { buildActionUrl, TYPE_POST_CONTEXT_QUERY } from '../../shared/url';
import { ContextType } from '../../types';
import ContextArchiveDialog from './ArchiveContextDialog';
import ContextCreateDialog from './ContextCreateDialog';
import ContextEditDialog from './ContextEditDialog';
import ContextListGrid from './ContextListGrid';
import ContextVersionsList from './ContextVersionsList';

const ContextListPage: React.FC<{
  setOpenArchiveDialog: (context: ContextType) => void;
}> = ({ setOpenArchiveDialog }) => {
  const { setPageTitle } = useLayout();
  const history = useNavigate();
  const [contexts, setContext] = useState<ContextType[]>([]);

  const goToCreate = () => history('/contexts/create', { replace: true });

  useEffect(() => setPageTitle(`Contexts`), [setPageTitle]);

  useEffect(() => {
    const url = buildActionUrl({}, TYPE_POST_CONTEXT_QUERY);
    axios
      .post(url, {
        filterOptions: [
          {
            field: 'CONTEXT_FILTER_FIELD_STATUS',
            operand: 'IN_LIST',
            value: ['CREATED', 'PUBLISHED'],
          },
        ],
        sortOptions: [
          {
            field: 'CONTEXT_SORT_FIELD_CREATED_AT',
            sortDirection: 'DESCENDING',
          },
        ],
      })
      .then((res: AxiosResponse) => res.data)
      .then((result: { contexts?: ContextType[] }) => {
        if (result && result?.contexts) {
          setContext(result?.contexts || []);
        }
      });
  }, []);

  return (
    <>
      <PageTitle
        title="Contexts"
        action={
          <>
            <DocLink link={DocLinkUrl[DocTypeEnum.Contexts]} />
            <Tooltip title="Create context">
              <Button onClick={goToCreate}>
                <Add />
              </Button>
            </Tooltip>
          </>
        }
      />
      <ContextListGrid
        contexts={contexts}
        cardBottomActions={(context) => (
          <Button onClick={() => history(`/contexts/${context.contextId}/versions`)}>Versions</Button>
        )}
        cardTitleAction={(context) => (
          <>
            <Tooltip title="Edit context">
              <IconButton
                onClick={() =>
                  history(`/contexts/edit/${context.contextId}`, {
                    replace: true,
                  })
                }
                aria-label="archive"
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Archive context">
              <IconButton onClick={() => setOpenArchiveDialog(context)} aria-label="archive">
                <Archive />
              </IconButton>
            </Tooltip>
          </>
        )}
        cardActionUrl={(context) => `/contexts/${context.contextId}/versions`}
      />
    </>
  );
};

const ContextList: React.FC = () => {
  const [updateCount, setUpdateCount] = useState(0);
  const [openArchiveDialog, setOpenArchiveDialog] = useState<ContextType | null>(null);
  const history = useNavigate();
  const { contextId = '' } = useParams<{ contextId: string }>();

  const backToList = () => {
    history('/contexts', { replace: true });
    setOpenArchiveDialog(null);
    setUpdateCount(updateCount + 1);
  };

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            !contextId && (
              <ContextListPage setOpenArchiveDialog={setOpenArchiveDialog} key={`contexts-${updateCount}`} />
            )
          }
        >
          {}
        </Route>
        <Route
          path="/:contextId/versions/*"
          element={
            <>
              {
                <ContextVersionsList
                  contextId={useMatch<'contextId', string>('/contexts/:contextId/versions')?.params?.contextId || ''}
                />
              }
            </>
          }
        >
          {}
        </Route>
        <Route path="/create" element={<ContextCreateDialog handleClose={backToList} />}>
          {}
        </Route>
        <Route
          path="/edit/:contextId"
          element={
            <ContextEditDialog
              handleClose={backToList}
              contextId={useMatch<'contextId', string>('/contexts/edit/:contextId')?.params?.contextId || ''}
            />
          }
        >
          {}
        </Route>
      </Routes>
      {openArchiveDialog !== null && (
        <ContextArchiveDialog handleClose={() => backToList()} context={openArchiveDialog} />
      )}
    </>
  );
};

export default ContextList;
