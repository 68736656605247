export const CFI_PROJECT_BOOKMARKS = 'CFI_PROJECT_BOOKMARKS';
export const CFI_PROJECT_PHASE = 'CFI_PROJECT_PHASE';

export interface ProjectBookmark {
  projectId: string;
  phaseId?: string;
  runId?: string | undefined;
  reset?: boolean;
}

export interface ProjectBookmarks {
  [key: string]: ProjectBookmark | null | undefined;
}

export interface PhaseBookmark {
  type: string;
  phaseId: string;
}

/**
 * Save current phase in local storage
 * @param phase
 */
export function setPhaseBookmark(phase: any): void {
  if (phase) {
    localStorage.setItem(CFI_PROJECT_PHASE, JSON.stringify(phase));
  }
}

/**
 *
 * @returns a phase
 */

export function getPhaseBookmark(): PhaseBookmark {
  return JSON.parse(localStorage.getItem(CFI_PROJECT_PHASE) || '{}');
}

/**
 * Save last visited project's phase and run to local storage (add bookmark).
 *
 * @param bookmark
 */
export function setProjectBookmark(bookmark: ProjectBookmark | null): void {
  if (bookmark?.phaseId || bookmark?.reset) {
    const bookmarks = getAllUserBookmarks();
    bookmarks[bookmark.projectId] = !bookmark?.reset ? bookmark : undefined;
    localStorage.setItem(CFI_PROJECT_BOOKMARKS, JSON.stringify(bookmarks));
  }
}

/**
 * Retrieve last visited project's phase and run to local storage (retrieve project's bookmark).
 *
 * @param projectId
 */
export function getProjectBookmark(projectId: string): ProjectBookmark | null {
  const bookmarks = JSON.parse(localStorage.getItem(CFI_PROJECT_BOOKMARKS) || '{}');
  return bookmarks?.[projectId] || null;
}

/**
 * Get all project bookmarks that are stored in browser's local storage.
 */
export function getAllUserBookmarks(): ProjectBookmarks {
  return JSON.parse(localStorage.getItem(CFI_PROJECT_BOOKMARKS) || '{}');
}
