import { ColumnIdentifier, DataManProperties } from '../../../../../components/dataman/types';
import { RenderRemoveContextCell } from './cell-renderer';
import { DefineScopeDataManBuildArg, DefineScopeDatamanRecord } from './types';

function buildStructure(
  args: DefineScopeDataManBuildArg,
  removeContext: any,
  disabled: boolean,
): DataManProperties<DefineScopeDatamanRecord> {
  return {
    list: {
      columns: {
        contextId: { label: 'Context id' },
        context: { label: 'Context' },
        description: { label: 'Description' },
        version: {
          label: 'Version',
        },
        recipesVersion: { label: 'Recipe Version' },
        socialMediaVersion: { label: 'Social Media Version' },
        dishTypeVersion: { label: 'Dish Type Version' },
        recipeCount: { label: 'Recipe Count' },
        delete: {
          label: 'Remove Context',
          renderRowCell: (cellIdentifier: ColumnIdentifier, value, row) =>
            RenderRemoveContextCell(disabled, cellIdentifier, value, row, removeContext),
        },
      },
    },
    records: (args?.records || []).map((context) => ({
      contextId: context.contextId,
      context: context.name,
      description: context.description,
      version: context.contextVersionId,
      recipesVersion: context.recipesVersion,
      socialMediaVersion: context.socialPostsVersion,
      dishTypeVersion: context.dishTypesVersion,
      name: context.name,
      recipeSelections: context.recipeSelections,
      socialPostsSelections: context.socialPostsSelections,
      recipeCount: context.recipeCount || 'N/A',
    })),
  };
}

export default buildStructure;
