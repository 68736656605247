import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';

import ErrorNotification from '../../../../components/ErrorNotification';
import Loading from '../../../../components/Loading';
import { RunType } from '../_utils/types';
import RunSelector from './RunSelector';

interface CreateRunDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (runName: string | null) => void;
  onDuplicate: (runName: string | null, selectedRunId: string) => void;
  runsList: RunType[] | undefined;
  defaultRun: RunType | undefined;
  loading: boolean;
}

const CreateRunDialog: React.FC<CreateRunDialogProps> = ({
  open,
  onClose,
  onSubmit,
  onDuplicate,
  runsList,
  defaultRun,
  loading,
}) => {
  const [newRunName, setNewRunName] = useState<string>('');
  const [radioState, setRadioState] = useState<string | null>('create');
  const [selectedRun, setSelectedRun] = useState<any>(defaultRun);
  const [errorName, setErrorName] = useState<any>(false);

  const closeNewRunDialog = () => {
    setNewRunName('');
    onClose();
  };

  const onChangeHandler = (radioValue: string) => {
    setRadioState(radioValue);
    if (radioValue === 'duplicate') {
      handleRunChange(selectedRun);
    }
  };

  const handleRunChange = (e: any) => {
    // front-end check to avoid duplicate run names inside the phase
    const number = e.runName.match(/- \((\d+)\)(?!.*(\d+))/);
    if (number) {
      const newName = e.runName.replace(
        /- \((\d+)\)(?!.*(\d+))/,
        (match: string, toReplace: string) => `- (${parseInt(toReplace, 10) + 1})`,
      );
      setNewRunName(newName);
    } else {
      setNewRunName(`${e.runName} - (1)`);
    }
    setSelectedRun(e);
  };

  return (
    <Dialog data-id-cypress="createRunDialog" open={open}>
      <DialogTitle>Create or duplicate a new run</DialogTitle>
      <DialogContent>
        {!loading && (
          <>
            <FormControl>
              <RadioGroup onChange={(e) => onChangeHandler(e.target.value)} value={radioState}>
                <FormControlLabel value="create" control={<Radio />} label="Create a new run" />
                <FormControlLabel
                  disabled={runsList?.length === 0}
                  value="duplicate"
                  control={<Radio />}
                  label="Duplicate an existing run"
                />
              </RadioGroup>
            </FormControl>

            {radioState === 'duplicate' && (
              <>
                <Box data-id-cypress="createRunDialogRunSelector" marginBottom={1}>
                  <RunSelector
                    runs={runsList || []}
                    selected={selectedRun || null}
                    handleRunChange={(e) => {
                      handleRunChange(e);
                    }}
                  />
                </Box>
              </>
            )}
            <Box marginTop={1}>
              <Box marginBottom={1}>New run name:</Box>
              {errorName && (
                <ErrorNotification
                  error={'Two runs cannot have the same name'}
                  handleClose={() => setErrorName(false)}
                />
              )}
              <TextField
                data-id-cypress="createRunDialogRunName"
                fullWidth={true}
                variant="outlined"
                placeholder="Run name"
                onChange={(e) => setNewRunName(e.target.value || '')}
                value={newRunName || ''}
              />
            </Box>
          </>
        )}
        {loading && <Loading />}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={closeNewRunDialog} disabled={loading}>
          Cancel
        </Button>
        <Button
          data-id-cypress="createRunDialogCreateButton"
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={() => {
            const names = runsList?.map((run) => run.runName);
            if (names?.includes(newRunName)) {
              setErrorName(true);
            } else {
              setErrorName(false);
              if (radioState === 'create') {
                onSubmit(newRunName);
              } else {
                onDuplicate(newRunName, selectedRun.runId);
              }
              setNewRunName('');
            }
          }}
        >
          {radioState === 'create' ? 'Create' : 'Duplicate'} run
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateRunDialog;
