import './Confirm.scss';

import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';

import { DataManStringOrGenericComponentType } from '../types';

interface ConfirmProps {
  isOpen: boolean;
  onClose?: () => void;
  title?: DataManStringOrGenericComponentType;
  content?: DataManStringOrGenericComponentType;
  onConfirm?: () => void;
  cancelLabel?: string;
  confirmLabel?: string;
  isLoading?: boolean;
}

const Confirm: React.FC<ConfirmProps> = ({
  title,
  isOpen,
  onClose,
  onConfirm,
  cancelLabel = 'Cancel',
  confirmLabel = 'Confirm',
  content,
  isLoading,
}) => {
  const buttonsDisabled = isLoading;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <>{title}</>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <>{content}</>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={buttonsDisabled} onClick={onClose}>
          {cancelLabel}
        </Button>
        <Button disabled={buttonsDisabled} onClick={onConfirm} color="primary" variant="contained" autoFocus={true}>
          {confirmLabel}
          {isLoading && <CircularProgress size={24} className="Confirm__loadProgress" />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Confirm.defaultProps = {
  onClose: undefined,
  title: undefined,
  content: undefined,
  onConfirm: undefined,
  cancelLabel: 'Cancel',
  confirmLabel: 'Confirm',
  isLoading: undefined,
};

export default Confirm;
