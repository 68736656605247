import React, { Dispatch } from 'react';

import { IngredientDBIngredientType, JobStatus, JobType, ProjectStatus, ProjectType } from '../../types';

export type ProjectStateType = {
  configChanged: boolean;
  reloadRequired: boolean;
  stepDone: {
    [stepName: string]: boolean;
  };
  project: ProjectType;
  error: string | null;
};

const initialState: ProjectStateType = {
  configChanged: false,
  stepDone: {},
  reloadRequired: false,
  error: null,
  project: {
    status: ProjectStatus.Unknown,
    ingredientDbVersion: '',
    type: '',
    projectId: '',
    company: '',
    description: '',
    coreVersion: '',
    recipesVersion: '',
    trendsVersion: '',
    sensoryVersion: '',
    languages: [],
    countries: [],
    socialPostVersion: '',
    baseIngredients: [],

    // defaults are being set upon project creation
    // these numbers need to come from the backend
    minimumRecipeCount: 0,
    ingredientPopularityThreshold: 0,
    cfiScoreRangeMinimum: 0,
    cfiScoreRangeMaximum: 0,

    projectBrief: {
      objective: '',
      baseIngredients: [],
      market: [],
      targetAudience: [],
      consumptionMoment: [],
      conceptProfiles: [],
      inspirationCategories: [],
      flavorSpace: [],
      conceptRestrictions: [],
    },
    outputs: {
      output1: {
        jobId: null,
        status: JobStatus.NotFound,
        urls: {
          output: null,
          template: null,
        },
      },
      output2: {
        jobId: null,
        status: JobStatus.NotFound,
        urls: {
          output: null,
          template: null,
        },
      },
      output3: {
        jobId: null,
        status: JobStatus.NotFound,
        urls: {
          output: null,
          template: null,
        },
      },
      provision: {
        jobId: null,
        status: JobStatus.NotFound,
        urls: {
          output: null,
          template: null,
        },
      },
    },
    workflowConfig: [],
  },
};

export type OutputAction =
  | {
      type: 'ProcessStartOutputGeneration';
      outputName: string;
      result: JobType;
    }
  | { type: 'UpdateJobStatus'; outputName: string; jobStatus: JobType }
  | {
      type: 'ProcessAbortedOutputGeneration';
      outputName: string;
      result: JobType;
    };

export type ProjectAction =
  | { type: 'LoadProjectData'; data: ProjectType }
  | {
      type: 'UpdateProjectConfigField';
      name: string;
      value: IngredientDBIngredientType[] | string | string[];
    }
  | {
      type: 'UpdateProjectBriefField';
      name: string;
      value: IngredientDBIngredientType[] | string | string[];
    }
  | { type: 'UpdateProjectData'; data: ProjectType };

export type StepAction = { type: 'StepCompleted'; stepName: string };

export type ErrorAction =
  | { type: 'FetchError'; error: string }
  | { type: 'SubmitError'; error: string }
  | { type: 'DismissError' };

type Action = OutputAction | StepAction | ErrorAction | ProjectAction;

export function reducer(state: ProjectStateType, action: Action): ProjectStateType {
  return reducerImplementation(state, action);
}

function reducerImplementation(state: ProjectStateType, action: Action): ProjectStateType {
  switch (action.type) {
    case 'LoadProjectData':
      return {
        ...state,
        project: { ...state.project, ...action.data },
        configChanged: false,
        reloadRequired: false,
      };
    case 'UpdateProjectData': // note: currently not difference with load impl
      return {
        ...state,
        project: { ...state.project, ...action.data },
        configChanged: false,
        reloadRequired: false,
      };
    case 'UpdateProjectConfigField': {
      const newProject = { ...state.project, [action.name]: action.value };
      return { ...state, project: newProject, configChanged: true };
    }
    case 'UpdateProjectBriefField': {
      const newProject = {
        ...state.project,
        projectBrief: {
          ...state.project.projectBrief,
          [action.name]: action.value,
        },
      };
      return { ...state, project: newProject, configChanged: true };
    }
    case 'ProcessAbortedOutputGeneration': {
      const urls = { output: null, template: null };
      const newProject = {
        ...state.project,
        outputs: {
          ...state.project.outputs,
          [action.outputName]: {
            jobId: null,
            status: JobStatus.Aborted,
            urls,
          },
        },
      };
      return { ...state, project: newProject };
    }
    case 'ProcessStartOutputGeneration': {
      const urls = { output: null, template: null };
      const newOutputs = {
        ...state.project.outputs,
        [action.outputName]: {
          jobId: action.result.jobId,
          status: JobStatus.Created,
          urls,
        },
      };
      const newProject = { ...state.project, outputs: newOutputs };
      return { ...state, project: newProject };
    }
    case 'UpdateJobStatus': {
      const output = state.project.outputs[action.outputName];
      const jobId = action.jobStatus.jobId ? action.jobStatus.jobId : output.jobId;
      const newOutput = {
        ...output,
        jobId,
        status: action.jobStatus.status,
      };
      const newOutputs = {
        ...state.project.outputs,
        [action.outputName]: newOutput,
      };
      const newProject: ProjectType = { ...state.project, outputs: newOutputs };
      const jobFinished = action.jobStatus.status === JobStatus.Ok || action.jobStatus.status === JobStatus.Failed;
      const reloadRequired = action.jobStatus.status !== output.status && jobFinished;
      return { ...state, project: newProject, reloadRequired };
    }
    case 'StepCompleted': {
      const newStepDone = { ...state.stepDone, [action.stepName]: true };
      return { ...state, stepDone: newStepDone };
    }
    case 'FetchError':
      return { ...state, error: action.error };
    case 'SubmitError':
      return { ...state, error: action.error };
    case 'DismissError':
      return { ...state, error: null };
    default:
      throw new Error();
  }
}

export function useProjectReducer(): [ProjectStateType, Dispatch<any>] {
  return React.useReducer(reducer, initialState);
}
