import './BreadCrumbs.scss';

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { capitalize } from '../shared/utils';

interface PathMatchItem {
  pathArray: string[];
  process: (params: any) => string[];
}

const pathMatches: PathMatchItem[] = [
  {
    pathArray: ['projects', ':idProject', 'show'],
    process: (params) => ['projects', params.idProject],
  },
  {
    pathArray: ['projects', ':idProject', 'show', 'concepts'],
    process: (params) => ['projects', params.idProject, 'concepts'],
  },
];

const getRestItemUsingPath = (path: string, items: PathMatchItem[]): any | null => {
  const pathArray = path.split('/');

  for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
    const item = items[itemIndex];
    if (pathArray.length === item.pathArray?.length) {
      let matches = true;
      const params: any = {};
      for (let pathIndex = 0; pathIndex < pathArray.length; pathIndex++) {
        const pathPiece = pathArray[pathIndex];
        if (pathIndex !== 0 && item.pathArray[pathIndex].charAt(0) === ':') {
          params[item.pathArray[pathIndex].slice(1)] = pathPiece;
        } else if (item.pathArray[pathIndex] !== pathPiece) {
          matches = false;
          break;
        }
      }
      if (matches) return { ...item, params };
    }
  }

  return null;
};

const RouteItem: React.FC<{ label: string; url: string; last: boolean }> = ({ label, url, last }) => (
  <>
    {last && <span className="BreadCrumbs__pathItemLast">{label}</span>}
    {!last && (
      <>
        <a href={!url || url === '/' ? '/' : `/${url}`} className="BreadCrumbs__pathItem">
          {label}
        </a>
        <span className="BreadCrumbs__pathSeparator">/</span>
      </>
    )}
  </>
);

const BreadCrumbs: React.FC = () => {
  const location = useLocation();
  const [route, setRoute] = useState('/');

  useEffect(() => {
    setRoute(location.pathname);
  }, [location.pathname]);

  const cleanPath = route.replace(/^\//, '');
  const req = getRestItemUsingPath(cleanPath, pathMatches);
  const routeItems: string[] = req ? req.process(req.params) : cleanPath.split('/');

  return (
    <div>
      <RouteItem label="Home" url="/" last={false} />
      {route !== '/' &&
        routeItems.map((r, index) => (
          <RouteItem
            key={r}
            label={capitalize(r)}
            url={routeItems.slice(0, index + 1).join('/')}
            last={index === routeItems.length - 1}
          />
        ))}
    </div>
  );
};

export default BreadCrumbs;
