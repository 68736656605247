import { Box } from '@mui/material';
import React from 'react';

type FPLogoProps = {
  className?: string;
};

export const FPLogo: React.FC<FPLogoProps> = ({ className }) => (
  <Box className={className}>
    <svg xmlns="http://www.w3.org/2000/svg" width="252.517" height="24" viewBox="0 0 252.517 24">
      <path
        d="M218.149,23.965a11.869,11.869,0,0,1-11.916-12,11.9,11.9,0,1,1,23.8,0,11.84,11.84,0,0,1-11.882,12"
        transform="translate(-176.456)"
        fill="#fff"
        fillRule="evenodd"
      ></path>
      <path
        d="M1476.542,6.4a1.937,1.937,0,0,0-.895-.156h-.632V7.759h.668a2.012,2.012,0,0,0,.7-.093.643.643,0,0,0,.434-.653.637.637,0,0,0-.28-.611m-.8-.658a3.227,3.227,0,0,1,1.146.15,1.037,1.037,0,0,1,.65,1.058.913.913,0,0,1-.408.825,1.605,1.605,0,0,1-.607.2,1.012,1.012,0,0,1,.719.409,1.14,1.14,0,0,1,.229.647V9.34c0,.1,0,.2.011.311a.642.642,0,0,0,.036.217l.026.052h-.692c0-.014-.008-.028-.011-.041s-.007-.029-.011-.046l-.016-.135v-.33a1.017,1.017,0,0,0-.395-.956,1.792,1.792,0,0,0-.823-.135h-.583V9.92h-.739V5.743Zm-2.25-.213a3.16,3.16,0,0,0-.957,2.317,3.2,3.2,0,0,0,.952,2.332,3.292,3.292,0,0,0,4.654,0,3.189,3.189,0,0,0,.958-2.332,3.284,3.284,0,0,0-5.608-2.317m5.029,5.011a3.813,3.813,0,0,1-6.518-2.7,3.8,3.8,0,0,1,3.811-3.793,3.705,3.705,0,0,1,2.707,1.109,3.64,3.64,0,0,1,1.119,2.685,3.68,3.68,0,0,1-1.119,2.7"
        transform="translate(-1259.473 -3.464)"
        fill="#003547"
        fillRule="evenodd"
      ></path>
      <path
        d="M15.261,7.459H4.634v4.96h6.028a2.173,2.173,0,0,1,2.16,2.184,2.144,2.144,0,0,1-2.16,2.151H4.634V24.21A2.3,2.3,0,0,1,2.3,26.5,2.27,2.27,0,0,1,0,24.21V5.413A2.27,2.27,0,0,1,2.3,3.124H15.261a2.174,2.174,0,0,1,2.2,2.15,2.2,2.2,0,0,1-2.2,2.185"
        transform="translate(0 -2.673)"
        fill="#003547"
        fillRule="evenodd"
      ></path>
      <path
        d="M396.586,26.222h-6.167a2.33,2.33,0,0,1-2.335-2.324V5.413a2.3,2.3,0,0,1,2.335-2.289h6.167c7.108,0,11.672,5.064,11.672,11.549s-4.564,11.549-11.672,11.549"
        transform="translate(-332.051 -2.673)"
        fill="#003547"
        fillRule="evenodd"
      ></path>
      <path
        d="M562.889,17.482h-6.307V24.21a2.3,2.3,0,0,1-2.335,2.289,2.27,2.27,0,0,1-2.3-2.289V5.413a2.271,2.271,0,0,1,2.3-2.289h8.641c6.027,0,8.5,3.468,8.5,7.214s-2.474,7.144-8.5,7.144"
        transform="translate(-472.255 -2.673)"
        fill="#003547"
        fillRule="evenodd"
      ></path>
      <path
        d="M697.528,24.649a2.107,2.107,0,0,1-1.951-1.248l-1.916-4.231H683.59L681.674,23.4a2.143,2.143,0,0,1-1.951,1.248,2.2,2.2,0,0,1-2.2-2.185,1.991,1.991,0,0,1,.208-.867l8.92-19.386A2.114,2.114,0,0,1,688.573.962a2.225,2.225,0,0,1,2.021,1.248L699.479,21.6a2.6,2.6,0,0,1,.174.9,2.137,2.137,0,0,1-2.125,2.151"
        transform="translate(-579.704 -0.823)"
        fill="#003547"
        fillRule="evenodd"
      ></path>
      <path
        d="M854.757,24.649a2.329,2.329,0,0,1-2.334-2.323V3.285A2.328,2.328,0,0,1,854.757.962a2.3,2.3,0,0,1,2.3,2.323v19.04a2.3,2.3,0,0,1-2.3,2.323"
        transform="translate(-729.347 -0.823)"
        fill="#003547"
        fillRule="evenodd"
      ></path>
      <path
        d="M931.639,16.1c.664,1.318,3.8,7.074,3.8,8.184a2.241,2.241,0,0,1-4.251.971c-.349-.693-4.111-8.184-4.147-8.358h-6.376V24.21a2.323,2.323,0,0,1-2.334,2.289,2.27,2.27,0,0,1-2.3-2.289V5.413a2.271,2.271,0,0,1,2.3-2.289h8.641c4.6,0,8.468,1.873,8.468,6.936a6.177,6.177,0,0,1-3.8,6.035"
        transform="translate(-783.77 -2.673)"
        fill="#003547"
        fillRule="evenodd"
      ></path>
      <path
        d="M1084.156,24.649a2.328,2.328,0,0,1-2.333-2.323V3.285a2.328,2.328,0,0,1,2.333-2.323,2.3,2.3,0,0,1,2.3,2.323v19.04a2.3,2.3,0,0,1-2.3,2.323"
        transform="translate(-925.626 -0.823)"
        fill="#003547"
        fillRule="evenodd"
      ></path>
      <path
        d="M1166.37,24.649a2,2,0,0,1-1.674-.8L1153.337,9.112V22.5a2.177,2.177,0,0,1-4.354,0V3.147a2.216,2.216,0,0,1,2.264-2.185,2.111,2.111,0,0,1,1.568.8s4.251,5.584,11.394,14.775V3.147A2.195,2.195,0,0,1,1166.37.962a2.225,2.225,0,0,1,2.193,2.185V22.5a2.2,2.2,0,0,1-2.193,2.151"
        transform="translate(-983.088 -0.823)"
        fill="#003547"
        fillRule="evenodd"
      ></path>
      <path
        d="M1330.171,20.186c-2.23,2.74-6.062,4.023-9.512,4.023a11.862,11.862,0,0,1-11.917-11.965,11.892,11.892,0,0,1,11.917-12,11.114,11.114,0,0,1,7.839,2.7,2.227,2.227,0,0,1,.732,1.63,2.1,2.1,0,0,1-2.124,2.081c-1.674,0-2.684-2.15-6.446-2.15-4.391,0-7.283,3.537-7.283,7.734,0,4.231,2.892,7.7,7.283,7.7a7.958,7.958,0,0,0,5.4-1.8l-.034-3.26h-4.356a2.081,2.081,0,1,1,0-4.162h6.691a2.329,2.329,0,0,1,2.334,2.323v5.688a2.037,2.037,0,0,1-.523,1.457"
        transform="translate(-1119.781 -0.209)"
        fill="#003547"
        fillRule="evenodd"
      ></path>
      <path
        d="M113.8,11.966a11.94,11.94,0,0,0,18.583,9.981,11.961,11.961,0,0,1-5.238-9.981,11.9,11.9,0,0,1,5.239-9.957A11.952,11.952,0,0,0,113.8,11.966"
        transform="translate(-97.373 -0.001)"
        fill="#d5cf0c"
        fillRule="evenodd"
      ></path>
      <path
        d="M242.514,21.947a11.922,11.922,0,0,0,18.559-9.981,11.934,11.934,0,0,0-18.559-9.957,11.91,11.91,0,0,1,5.215,9.957,11.966,11.966,0,0,1-5.215,9.981"
        transform="translate(-207.499 -0.001)"
        fill="#268578"
        fillRule="evenodd"
      ></path>
      <path
        d="M211.472,13.908a12.136,12.136,0,0,1,0,19.938,12.105,12.105,0,0,1,0-19.938"
        transform="translate(-176.457 -11.9)"
        fill="#134d62"
        fillRule="evenodd"
      ></path>
      <path
        d="M1580.573,5.78,1572.5,25.835a1.088,1.088,0,0,1-.365.5.888.888,0,0,1-.531.166.907.907,0,0,1-.7-.249,1,1,0,0,1-.266-.614.845.845,0,0,1,.033-.4l8.766-21.45a1,1,0,0,1,1-.664.961.961,0,0,1,.581.183,1.175,1.175,0,0,1,.381.482l8.733,21.35a1.263,1.263,0,0,1,.066.4.876.876,0,0,1-.3.714,1.057,1.057,0,0,1-.7.249.954.954,0,0,1-.581-.183,1.171,1.171,0,0,1-.382-.482l-8.268-20.188Zm-6.143,13.979.83-1.959h10.293l.4,1.959Z"
        transform="translate(-1343.86 -2.673)"
        fill="#003547"
      ></path>
      <path
        d="M1736.54,26.027a1.09,1.09,0,0,1-.349.73,1.012,1.012,0,0,1-.714.3.978.978,0,0,1-.747-.3,1.022,1.022,0,0,1-.282-.73V4.842a.994.994,0,0,1,.3-.73,1.034,1.034,0,0,1,.764-.3,1.011,1.011,0,0,1,.714.3.969.969,0,0,1,.315.73Z"
        transform="translate(-1484.022 -3.262)"
        fill="#003547"
      ></path>
    </svg>
  </Box>
);

FPLogo.defaultProps = {
  className: '',
};
