import { Add, FileCopy, Search } from '@mui/icons-material';
import Button from '@mui/material/Button';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';

import { DataMan } from '../../components/dataman';
import { DataManRecordsType } from '../../components/dataman/types';
import Loading from '../../components/Loading';
import PageTitle from '../../components/PageTitle';
import { useLayout } from '../../context/LayoutContext';
import { DocLink, DocLinkUrl, DocTypeEnum } from '../../features/docs';
import { buildActionUrl, TYPE_CLONE_VERSION, TYPE_EDIT_VERSION, TYPE_GET_CONTEXT_VERSIONS } from '../../shared/url';
import { ContextVersionType } from '../../types';
import ContextVersionsForm from './ContextVersions/ContextVersionsForm';
import { buildStructure, sortContextVersion } from './dataman';
import { ContextVesrionDatamanRecord } from './dataman/types';
import { ContextStatusCreated } from './types';

interface ContextVersionsListProps {
  contextId: string;
}

const ContextVersionsDataMan: React.FC<ContextVersionsListProps> = ({ contextId }) => {
  const { setPageTitle } = useLayout();
  const [loading, setLoading] = useState(false);
  const [contextVersions, setContextVersions] = useState<DataManRecordsType<ContextVesrionDatamanRecord> | null>(null);
  const history = useNavigate();

  const getVersionsPath = buildActionUrl({ contextId }, TYPE_GET_CONTEXT_VERSIONS);

  useEffect(() => {
    setPageTitle(`Context ${contextId} - Versions`);
  }, [setPageTitle, contextId]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(getVersionsPath)
      .then((res) => res.data)
      .then((result: { contextVersions: ContextVersionType[] }) => {
        setContextVersions(
          (result.contextVersions || []).map((cv) => ({
            ...cv,
            status: cv.status || ContextStatusCreated.value,
          })),
        );
        setLoading(false);
      });
  }, [getVersionsPath]);

  const goToCreate = () => history(`/contexts/${contextId}/versions/create`);
  const records = useMemo(() => sortContextVersion(contextVersions), [contextVersions]);
  const config = useMemo(
    () =>
      buildStructure({
        history,
        contextId,
        icons: {
          [TYPE_EDIT_VERSION]: <Search data-id-cypress="inputContextVersionsEditButton" />,
          [TYPE_CLONE_VERSION]: <FileCopy />,
        },
      }),
    [history, contextId],
  );

  if (loading || !contextVersions) return <></>;

  return (
    <>
      <PageTitle
        title="Versions"
        action={
          <>
            <DocLink link={DocLinkUrl[DocTypeEnum.ContextVersions]} />
            <Button onClick={goToCreate}>
              <Add />
            </Button>
          </>
        }
      />
      {loading ? (
        <Loading />
      ) : (
        <DataMan loading={loading} records={records} {...config} key="dataman-context-versions" />
      )}
    </>
  );
};

const CloneEditContextVersion: React.FC<{
  contextId: string;
  mode: 'clone' | 'edit' | 'create';
}> = ({ contextId, mode }) => {
  const { contextVersionId } = useParams<'contextVersionId'>();

  if (!contextId || !contextVersionId) {
    return <Navigate to={`/contexts/${contextId}/versions`} />;
  }

  return <ContextVersionsForm contextId={contextId} contextVersionId={contextVersionId} mode={mode} />;
};

const ContextVersionsList: React.FC<ContextVersionsListProps> = ({ contextId }) => {
  const params = useParams<'contextId'>();
  const context = useMemo<string>(() => contextId || params.contextId || '', [contextId, params.contextId]);

  return (
    <>
      <Routes>
        <Route path="/" element={<ContextVersionsDataMan contextId={context} />}>
          {}
        </Route>
        <Route path={`/create`} element={context && <ContextVersionsForm contextId={context} />}>
          {}
        </Route>
        <Route path={`/clone/*`}>
          <Route path={`:contextVersionId`} element={<CloneEditContextVersion contextId={context} mode="clone" />}>
            {}
          </Route>
        </Route>
        <Route path={`/edit/*`}>
          <Route path={`:contextVersionId`} element={<CloneEditContextVersion contextId={context} mode="edit" />}>
            {}
          </Route>
        </Route>
      </Routes>
    </>
  );
};

export default ContextVersionsList;
