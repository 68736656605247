import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';

import ContextForm from './ContextForm';

interface ContextCreateDialogProps {
  handleClose: (update?: boolean) => void;
}

const ContextCreateDialog: React.FC<ContextCreateDialogProps> = ({ handleClose }) => (
  <Dialog fullWidth={true} open={true} onClose={() => handleClose()} maxWidth="lg">
    <DialogTitle>Create new context</DialogTitle>
    <DialogContent dividers={true}>
      <ContextForm handleClose={handleClose} />
    </DialogContent>
  </Dialog>
);

export default ContextCreateDialog;
