export interface ContextStatus {
  value: string;
  label: string;
}

export const ContextStatusCreated: ContextStatus = {
  value: 'CONTEXT_VERSION_STATUS_CREATED',
  label: 'Created',
};
export const ContextStatusPublished: ContextStatus = {
  value: 'CONTEXT_VERSION_STATUS_PUBLISHED',
  label: 'Published',
};
export const ContextStatusArchived: ContextStatus = {
  value: 'CONTEXT_VERSION_STATUS_ARCHIVED',
  label: 'Archived',
};

export const ContextStatusOptions: ContextStatus[] = [
  ContextStatusCreated,
  ContextStatusPublished,
  ContextStatusArchived,
];
