import Moment from 'moment';

import { DataManRecordsType } from '../../../components/dataman/types';
import { ContextVersionType } from '../../../types';
import { ContextVesrionDatamanRecord } from './types';

function sortContextVersion(
  contextVersions: ContextVersionType[] | null,
): DataManRecordsType<ContextVesrionDatamanRecord> {
  return (
    contextVersions
      ? contextVersions.sort((a, b) => (a.resourceMetadata.changedAt > b.resourceMetadata.changedAt ? -1 : 1))
      : []
  ).map((item) => ({
    ...item,
    changedAt: Moment(item.resourceMetadata.changedAt).format('YYYY-MM-DD HH:mm'),
    changedBy: item.resourceMetadata.changedBy,
  }));
}

export default sortContextVersion;
