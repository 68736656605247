import '../../../../../../layout/correction-table.scss';
import '../../../../../../layout/run-config.scss';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';
import { Alert, Autocomplete, Box, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { DataGrid, DataGridProps, GridColDef, GridToolbar } from '@mui/x-data-grid';
import React, { SyntheticEvent, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { SLICE_DATASOURCE_NAME } from '../../../../../../features/datasources/store';
import {
  addUpdateTemplate,
  deleteTemplate,
  resetTemplates,
  setMaxIngredientSlots,
  setRunError,
  setSelectedTerritoryDefinition,
  SLICE_CONCEPT_GENERATION_V3,
} from '../../../../../../features/project/store';
import {
  ConceptTemplate,
  ConceptTerritoryDefinitionOption,
  emptyIngredientSlot,
  freeIngredientSlot,
} from '../../../../../../features/project/types/concept-generation';
import { getDataGridCellAlignment } from '../../../../../../shared/utils';
import { useAppSelector } from '../../../../../../store';
import { IngredientDBIngredientType } from '../../../../../../types';
import { IngredientDBMap } from '../../../_utils/datasources';
import { RowErrorMessageType } from '../../../_utils/types';
import IngredientSlotEditCell from './IngredientSlotEditCell';
import TerritoriesEditCell from './TerritoriesEditCell';

export interface ConceptTemplateProps {
  runId: string;
  readOnly?: boolean;
}

type TemplateRowType = Record<string, number | null | string[]>;

const RowMessages: { [id: string]: RowErrorMessageType } = {
  ingredientSlotOrder: {
    severity: 'error',
    message: 'Start template with base ingredients, then free ingredients, then empty ingredients.',
  },
  emptyTerritories: {
    severity: 'error',
    message: 'Territories cannot be empty.',
  },
  ingredientNotSelected: {
    severity: 'error',
    message: 'Ingredient is part of selected ingredients.',
  },
  duplicateTemplates: {
    severity: 'warning',
    message: 'Templates can be merged',
  },
  territoryDoesNotExist: {
    severity: 'error',
    message: 'Some of the territories do not exist in the territory definition.',
  },
};

/**
 * Component for configuring concept templates in V3 of the concept generation workflow
 */
const ConceptTemplatesView: React.FC<ConceptTemplateProps> = ({ runId, readOnly }) => {
  const dispatch = useDispatch();
  const {
    templates,
    selectedIngredientIds,
    maxIngredientSlots: noIngredientSlots,
    territoryDefinitions,
    selectedTerritoryDefinition,
  } = useAppSelector((store) => store[SLICE_CONCEPT_GENERATION_V3]);
  const { ingredients: allIngredients } = useAppSelector((store) => store[SLICE_DATASOURCE_NAME]);

  const allowAddSlot = noIngredientSlots < 7;
  const allowRemoveSlot = noIngredientSlots > 2;

  const templatesConfigDisabled = !selectedTerritoryDefinition || readOnly;

  /**
   * Callback for when the territory definition dropdown changes
   * @param event
   * @param newValue
   */
  const onTerritoryDropdownChange = useCallback<
    (event: SyntheticEvent, newValue: ConceptTerritoryDefinitionOption | null) => void
  >(
    (event, newValue) => {
      if (newValue) {
        dispatch(setSelectedTerritoryDefinition({ territoryDefinition: newValue }));
      }
    },
    [dispatch],
  );

  // Territory definition dropdown options
  const territoryDefinitionOptions = useMemo(
    () => territoryDefinitions.map((d) => ({ name: d.name, version: d.version })),
    [territoryDefinitions],
  );

  // All available territories for the selected territory definition
  const availableTerritories = useMemo(() => {
    if (!selectedTerritoryDefinition) return [];
    const foundTerritory = territoryDefinitions.find(
      (t) => t.name === selectedTerritoryDefinition.name && t.version === selectedTerritoryDefinition.version,
    );
    return foundTerritory?.territories || [];
  }, [selectedTerritoryDefinition, territoryDefinitions]);

  // A map with all selected ingredients, used for populating ingredientSlot dropdowns
  // and mapping dropdown option back to ingredients
  const ingredientSlotMap: IngredientDBMap = useMemo(() => {
    const selectedIngredients = allIngredients.filter((i: IngredientDBIngredientType) =>
      selectedIngredientIds.includes(Number(i.ingredientId)),
    );

    // for indicating ingredient slots which are free to fill
    selectedIngredients.push(freeIngredientSlot);

    // for indicating ingredient slots which are empty
    selectedIngredients.push(emptyIngredientSlot);

    return new IngredientDBMap(selectedIngredients, '');
  }, [allIngredients, selectedIngredientIds]);

  // Get message on a row
  const validateRow = useCallback<(row: TemplateRowType) => RowErrorMessageType | null>(
    (row) => {
      const territories = row.territories as string[];
      const slots: number[] = [];
      for (let i = 0; i < noIngredientSlots; i++) {
        slots.push(Number(row[`ingredientSlot_${i}`]));
      }

      // check if ingredient slots are in the right order
      const mappedSlots = slots.map((ingredientId) => {
        switch (ingredientId) {
          case emptyIngredientSlot.ingredientId:
            return 2;
          case freeIngredientSlot.ingredientId:
            return 1;
          default:
            return 0;
        }
      });
      for (let i = 0; i < mappedSlots.length - 1; i++) {
        if (mappedSlots[i] > mappedSlots[i + 1]) return RowMessages.ingredientSlotOrder;
      }

      // check if ingredients are part of the selected ingredients list
      for (let i = 0; i < slots.length; i++) {
        if (!ingredientSlotMap.ingredientMap.has(slots[i])) return RowMessages.ingredientNotSelected;
      }

      // check if territories are empty
      if (territories.length === 0) {
        return RowMessages.emptyTerritories;
      }

      // check if territories exist
      if (territories.some((t) => !availableTerritories.includes(t))) {
        return RowMessages.territoryDoesNotExist;
      }

      // check if duplicate templates exist
      let errorMessage = null;
      templates.forEach((t, index) => {
        if (slots.toString() === t.ingredientSlots.toString() && index !== row.id) {
          errorMessage = RowMessages.duplicateTemplates;
        }
      });

      return errorMessage;
    },
    [templates, noIngredientSlots, ingredientSlotMap, availableTerritories],
  );

  // Set rows for the templates DataGrid
  const templateRows: TemplateRowType[] = useMemo(() => {
    const rows: TemplateRowType[] = [];
    templates.forEach((template, key) => {
      const row: TemplateRowType = {};
      row.id = key;
      template.ingredientSlots.forEach((slot, index) => {
        row[`ingredientSlot_${index}`] = slot;
      });
      row.territories = template.territories;
      rows.push(row);
    });
    return rows;
  }, [templates]);

  /**
   * Callback for when a row is updated in the templates DataGrid
   */
  const processRowUpdate = useCallback<Required<DataGridProps>['processRowUpdate']>(
    (newRow: TemplateRowType) => {
      const templateId = newRow.id as number;
      const territoriesToClone = (newRow.territories || []) as string[];
      const territories = [...territoriesToClone];
      const template: ConceptTemplate = {
        ingredientSlots: [],
        territories: territories.sort(),
      };
      for (let i = 0; i < noIngredientSlots; i++) {
        const ingredientId = Number(newRow[`ingredientSlot_${i}`]);
        template.ingredientSlots.push(ingredientId);
      }
      dispatch(
        addUpdateTemplate({
          templateId,
          template,
        }),
      );
      return newRow;
    },
    [dispatch, noIngredientSlots],
  );

  /**
   * Callback for when a row needs to be deleted
   */
  const onDeleteRow = useCallback<(rowId: number) => void>(
    (rowId) => {
      dispatch(deleteTemplate({ templateId: rowId }));
    },
    [dispatch],
  );

  // Set columns for the templates DataGrid
  const templateTableColumns = useMemo<GridColDef[]>(() => {
    const columns: GridColDef[] = [];
    const alignTextCell = getDataGridCellAlignment('text');
    const alignAnyCell = getDataGridCellAlignment('any');
    columns.push({
      ...alignAnyCell,
      field: 'message',
      headerName: 'Issues',
      editable: false,
      sortable: true,
      filterable: true,
      minWidth: 100,
      renderCell: ({ value }) => (
        <Box component="span">
          {value && (
            <Tooltip title={value?.message}>
              <Alert color={value?.severity} severity={value?.severity} variant="outlined" />
            </Tooltip>
          )}
        </Box>
      ),
      valueGetter: ({ row }) => validateRow(row),
    });
    for (let i = 0; i < noIngredientSlots; i++) {
      columns.push({
        ...alignTextCell,
        field: `ingredientSlot_${i}`,
        headerName: `Ingredient Slot ${i + 1}`,
        editable: !templatesConfigDisabled,
        minWidth: 200,
        renderCell: ({ row }) => {
          const ingredientIdStr = row[`ingredientSlot_${i}`];
          return ingredientSlotMap.dropdownIngredientIdMap.get(Number(ingredientIdStr));
        },
        renderEditCell: ({ row }) => (
          <IngredientSlotEditCell slotNo={i} ingredientSlotMap={ingredientSlotMap} row={row} />
        ),
      });
    }
    columns.push({
      ...alignTextCell,
      field: 'territories',
      headerName: 'Territories',
      editable: !templatesConfigDisabled,
      sortable: true,
      filterable: true,
      minWidth: 400,
      renderCell: ({ row }) => Array.from(row.territories.values()).join(', '),
      renderEditCell: ({ row }) => <TerritoriesEditCell availableTerritories={availableTerritories} row={row} />,
    });
    columns.push({
      field: 'action',
      align: 'center',
      headerAlign: 'center',
      headerName: ' ',
      width: 100,
      renderCell: ({ row }) => (
        <IconButton onClick={() => onDeleteRow(row.id)} disabled={templatesConfigDisabled}>
          <DeleteIcon />
        </IconButton>
      ),
    });
    return columns;
  }, [availableTerritories, ingredientSlotMap, noIngredientSlots, onDeleteRow, validateRow, templatesConfigDisabled]);

  /**
   * Callback for when the user clicks the add ingredient slot button
   */
  const onAddIngredientSlot = useCallback<() => void>(() => {
    dispatch(setMaxIngredientSlots({ diff: 1 }));
  }, [dispatch]);

  /**
   * Callback for when the user clicks the remove ingredient slot button
   */
  const onDeleteIngredientSlot = useCallback<() => void>(() => {
    dispatch(setMaxIngredientSlots({ diff: -1 }));
  }, [dispatch]);

  /**
   * Callback for when the user clicks the add template button
   */
  const onAddTemplateRow = useCallback<() => void>(() => {
    const slots: number[] = [];
    for (let i = 0; i < noIngredientSlots; i++) {
      slots.push(freeIngredientSlot.ingredientId);
    }
    dispatch(
      addUpdateTemplate({
        templateId: undefined,
        template: { ingredientSlots: slots, territories: [] },
      }),
    );
  }, [dispatch, noIngredientSlots]);

  /**
   * Callback for when the user clicks the remove all templates button
   */
  const onDeleteAllTemplates = useCallback<() => void>(() => {
    dispatch(resetTemplates());
  }, [dispatch]);

  /**
   * Effect used to set or unset error in run redux store
   * this state variable is watched by component RunConfigFlowV3
   * and used to enable/disable the Next/Save button
   */
  useEffect(() => {
    // check if template rows exist
    if (templateRows.length === 0) {
      dispatch(setRunError({ runId, error: 'No concept templates' }));
      return;
    }

    // no territory definition selected
    if (!selectedTerritoryDefinition) {
      dispatch(setRunError({ runId, error: 'No territory definition selected' }));
      return;
    }

    // check if rows contain errors
    for (let i = 0; i < templateRows.length; i++) {
      const message = validateRow(templateRows[i]);
      if (message?.severity === 'error') {
        dispatch(setRunError({ runId, error: 'Invalid concept templates' }));
        return;
      }
    }

    // No errors
    dispatch(setRunError({ runId, error: undefined }));
  }, [dispatch, templateRows, runId, validateRow, selectedTerritoryDefinition]);

  /**
   * This callback returns the class name of the row.
   * If there is something wrong with a row, to highlight it.
   */
  const getRowClassName = useCallback<Required<DataGridProps>['getRowClassName']>(
    ({ row }) => {
      const message = validateRow(row);
      switch (message?.severity) {
        case 'error':
          return 'CorrectionTable__errorRow';
        case 'warning':
          return 'CorrectionTable__warningRow';
        default:
          return '';
      }
    },
    [validateRow],
  );

  const allowedKeys = [
    'Enter',
    'Backspace',
    'Delete',
    'Escape',
    'ArrowDown',
    'ArrowUp',
    'ArrowLeft',
    'ArrowRight',
    'Tab',
  ];

  return (
    <>
      <Box className="RunConfig__compositionTemplateContainer">
        <Box className="RunConfig__stepTitle" sx={{ flexDirection: 'column' }}>
          <Typography>Create concept templates by first selecting a flavor territory definition.</Typography>
          <Typography>Then choose the maximum amount of ingredients.</Typography>
          <Typography>
            Finally fill in the ingredient slots and select the flavor territories for each template.
          </Typography>
        </Box>
        <Box className="RunConfig__territoryDefinitionDropdown">
          <Autocomplete
            id="territory-definition-dropdown"
            data-id-cypress="territoryDefinitionDropdown"
            options={territoryDefinitionOptions}
            getOptionLabel={(option) => `${option.name} - version ${option.version}`}
            filterOptions={() => territoryDefinitionOptions}
            value={territoryDefinitionOptions.find(
              (d) => d.name === selectedTerritoryDefinition?.name && d.version === selectedTerritoryDefinition?.version,
            )}
            onChange={onTerritoryDropdownChange}
            renderInput={(params) => <TextField {...params} label="Territory Definition" variant="outlined" />}
            disableClearable={true}
            fullWidth={true}
          />
        </Box>
        <Box className="RunConfig__ingredientsNumberBlock">
          <Typography>Number of ingredients</Typography>
          <Box>
            <IconButton onClick={onDeleteIngredientSlot} disabled={!allowRemoveSlot || templatesConfigDisabled}>
              <RemoveIcon />
            </IconButton>
            <Box>
              <Typography variant="h4">{noIngredientSlots}</Typography>
            </Box>
            <IconButton onClick={onAddIngredientSlot} disabled={!allowAddSlot || templatesConfigDisabled}>
              <AddIcon />
            </IconButton>
          </Box>
        </Box>

        <DataGrid
          style={{ minHeight: 500, height: '100%' }}
          onCellKeyDown={(value, event) => {
            if (!allowedKeys.includes(event.key)) event.stopPropagation();
          }}
          className="CorrectionTable__grid"
          columns={templateTableColumns}
          rows={templateRows}
          autoHeight={false}
          headerHeight={76}
          rowHeight={80}
          processRowUpdate={processRowUpdate}
          experimentalFeatures={{ newEditingApi: true }}
          components={{ Toolbar: GridToolbar }}
          getRowClassName={getRowClassName}
        />
        <Box display="flex" flexDirection="row" justifyContent="center">
          <Box className="RunConfig__actionBlock" marginRight={4}>
            <IconButton onClick={onAddTemplateRow} style={{ borderRadius: 0 }} disabled={templatesConfigDisabled}>
              <AddIcon />
              <Typography>Add composition template</Typography>
            </IconButton>
          </Box>
          <Box className="RunConfig__actionBlock" marginRight={4}>
            <IconButton onClick={onDeleteAllTemplates} style={{ borderRadius: 0 }} disabled={templatesConfigDisabled}>
              <DeleteIcon />
              <Typography>Remove all composition templates</Typography>
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

ConceptTemplatesView.defaultProps = {
  readOnly: false,
};

export default ConceptTemplatesView;
