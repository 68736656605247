import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import difference from 'lodash/difference';
import React, { ChangeEvent, MouseEvent, useCallback, useMemo, useState } from 'react';

import TagList from './TagList';

type SelectIngredientDialogProps = {
  open: boolean;
  onClose: () => void;
  items: string[];
  checked: string[];
  multiple: boolean;
  handleChangeSelection: (selected: string[]) => void;
  dialogTitle?: string;
};

const SelectIngredientDialog: React.FC<SelectIngredientDialogProps> = ({
  multiple,
  open,
  onClose,
  items,
  checked,
  handleChangeSelection,
  dialogTitle,
}) => {
  const [searchFilter, setSearchFilter] = useState<string>('');

  // boost selected ingredients to top and filter list
  const filteredItems = useMemo<string[]>(
    () =>
      [...checked.filter((i) => i), ...difference(items, checked)].filter((value) =>
        searchFilter ? value.toLowerCase().includes(searchFilter.toLowerCase()) : true,
      ),
    [searchFilter, checked, items],
  );

  const handleToggle = useCallback(
    (value: string) => (event: MouseEvent) => {
      event.preventDefault();
      const currentIndex = checked.indexOf(value);
      const newChecked = multiple ? [...checked] : [];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      handleChangeSelection(newChecked);
      if (!multiple) {
        onClose();
      }
    },
    [checked, handleChangeSelection, multiple, onClose],
  );

  const handleSearchChange = useCallback<(event: ChangeEvent<HTMLInputElement>) => void>((event) => {
    event.preventDefault();
    setSearchFilter(event.target.value);
  }, []);

  return (
    <Dialog data-id-cypress="selectIngredientDialog" open={open} onClose={onClose}>
      <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <TagList
          name="ingredient selection"
          items={filteredItems}
          checked={checked}
          handleToggle={handleToggle}
          onSearchChange={handleSearchChange}
          value={searchFilter}
        />
      </DialogContent>
      <DialogActions>
        <Button data-id-cypress="selectIngredientDialogDone" onClick={onClose} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SelectIngredientDialog.defaultProps = { dialogTitle: 'Select base products' };

export const useSelectIngredientDialog = (
  multiple: boolean,
  availableIngredients: string[],
  currentIngredients: string[],
  handleChangeSelection: (values: string[]) => void,
  dialogTitle?: string,
): { dialog: JSX.Element; openDialog: () => void } => {
  const [open, setOpen] = React.useState(false);
  const openDialog = () => setOpen(true);
  const dialog = (
    <SelectIngredientDialog
      data-id-cypress="selectIngredientDialog"
      open={open}
      onClose={() => setOpen(false)}
      items={availableIngredients}
      checked={currentIngredients}
      multiple={multiple}
      handleChangeSelection={handleChangeSelection}
      dialogTitle={dialogTitle}
    />
  );
  return { dialog, openDialog };
};

export default SelectIngredientDialog;
