import '../layout/common.scss';

import Cancel from '@mui/icons-material/Cancel';
import Check from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import axios, { AxiosResponse } from 'axios';
import _ from 'lodash';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getDataSheetUrl } from '../admin/projects/ProjectFlow/_utils/datasources';
import { JobStatus, RunType } from '../admin/projects/ProjectFlow/_utils/types';
import {
  buildActionUrl,
  TYPE_CANCEL_JOB,
  TYPE_INPUT_PREPARATION,
  TYPE_LOAD_RUNS,
  TYPE_OUTPUT_GENERATION,
  TYPE_VIEW_JOB_STATUS,
} from '../shared/url';
import { capitalize, isJobIsInProgress, isProduction } from '../shared/utils';
import { JobExecutor, ProjectType } from '../types';
import ExternalLink from './ExternalLink';
import SectionTitle from './SectionTitle';

export interface JobHandlerRef {
  startNewJob: (project?: ProjectType, run?: RunType) => boolean;
}

export enum JobTypes {
  input = 'input',
  output = 'output',
}

interface JobHandlerProps {
  run?: RunType | null;
  project: ProjectType | null;
  simulateFail?: boolean;
  updateView: () => void;
  updateRun?: (run: RunType) => void;
  isDisabled?: boolean | ((run: RunType, project: ProjectType | null) => boolean);
  type: JobTypes;
  actionName?: string;
  title?: string;
  setRef?: (reference: JobHandlerRef) => void;
  selectedRunId?: string;
  isTableJob?: boolean;
  refreshInterval?: number | undefined;
  inProgressText?: string | undefined;
  successText?: string | undefined;
  jobSheetResourceKey?: string;
  externalLinkLabel?: string;
  executor: JobExecutor | null;
  showSheetLinks?: boolean;
  leftActions?: ReactNode | null;
  waitForInputId?: boolean;
  errorMessage?: string;
}

const JobHandler: React.FC<JobHandlerProps> = ({
  title,
  run,
  project,
  simulateFail,
  updateView,
  updateRun,
  type,
  isDisabled,
  setRef,
  externalLinkLabel,
  selectedRunId,
  inProgressText,
  successText,
  refreshInterval = 4500,
  isTableJob = false,
  actionName = 'generation',
  jobSheetResourceKey = '',
  executor = JobExecutor.default,
  leftActions,
  showSheetLinks,
  waitForInputId,
  errorMessage,
}) => {
  const isProductionMode = isProduction();
  const history = useNavigate();
  const updateTimer = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  const stateRef = React.useRef<any>(null);
  const isDataLoading = React.useRef<boolean>(false);
  const isInput = type === JobTypes.input;
  const outputType = isInput ? 'input_preparation' : 'output_generation';
  const [jobStatus, setJobStatus] = useState<string>(run?.outputs?.[outputType]?.status || '');
  const isOk = jobStatus === JobStatus.ok;
  const [newJobOutputUrl, setNewJobOutputUrl] = useState<string>(
    isOk ? getDataSheetUrl(outputType, jobSheetResourceKey, run || null) : '',
  );

  const [jobId, setJobId] = useState<string | null | undefined>(
    (isInput ? run?.outputs?.input_preparation?.jobId : run?.outputs?.output_generation?.jobId) || null,
  );
  const selectedRunProgress = jobStatus || run?.outputs?.[outputType]?.status;
  const isJobRunning =
    selectedRunProgress !== undefined && selectedRunProgress !== null && selectedRunProgress !== JobStatus.aborted;
  const generateDisabled =
    !run || (isDisabled && typeof isDisabled === 'function' ? isDisabled(run, project) : isDisabled);

  const isJobInProgress = isJobIsInProgress(selectedRunProgress as JobStatus);
  const isManualJob = executor === JobExecutor.manual;

  const hasJobSuccess = selectedRunProgress === JobStatus.ok;
  const hasJobFailed = selectedRunProgress === JobStatus.failed;

  const inProgressLabel = inProgressText || (isTableJob ? 'Preparing ' : `Generating ${title?.toLowerCase()}`);
  const cancelSelectedJob = async () => {
    const url = buildActionUrl(
      {
        projectId: project?.projectId,
        jobId: jobId || '',
      },
      TYPE_CANCEL_JOB,
    );
    const cancelResponse: { status?: JobStatus } = await axios.post(url);

    if (cancelResponse?.status) {
      setJobStatus(cancelResponse.status as JobStatus);
    }

    updateView();
  };

  const submitNewJob = () => {
    submitJob(project?.projectId, run?.phaseId, run?.runId || selectedRunId, executor).then((r) => r);
  };

  const submitJob = async (projectId?: string, phaseId?: string, runId?: string, jobExecutor?: JobExecutor | null) => {
    isDataLoading.current = true;
    const urlParams = { projectId, phaseId, runId };
    const urlType = isInput ? TYPE_INPUT_PREPARATION : TYPE_OUTPUT_GENERATION;
    const url = buildActionUrl(urlParams, urlType);
    const job: {
      run?: RunType;
    } = await axios
      .post(url, {
        simulate_failure: simulateFail === true,
        executor: jobExecutor,
      })
      .then((res: AxiosResponse) => res.data);

    if (job?.run?.outputs?.[outputType]?.status) {
      setJobStatus(job.run.outputs[outputType].status as JobStatus);
    }

    if (job?.run?.outputs?.[outputType].jobId && job?.run?.outputs?.[outputType].jobId !== jobId) {
      setJobId(job.run.outputs[outputType].jobId);
    }

    if (job?.run) {
      updateView();
    }

    isDataLoading.current = false;
  };

  const goToJob = () => {
    if (jobId) {
      history(`/projects/${project?.projectId}/jobs/${jobId}`);
    }
  };

  const getSheetLinkData = (): { url: string; title: string }[] => {
    const links = [];

    if (jobSheetResourceKey) {
      links.push({
        url: newJobOutputUrl,
        title: externalLinkLabel || _.startCase(jobSheetResourceKey),
      });
    } else {
      const { resources } = run?.outputs?.[outputType]?.jobOutput || {};

      if (resources) {
        Object.keys(resources).forEach((key) => {
          if (resources[key]?.sheets) {
            const url = getDataSheetUrl(outputType, key, run || null);
            if (url) {
              links.push({ url, title: _.startCase(key) });
            }
          }
        });
      }
    }

    return links;
  };

  const checkSelectedRun = useCallback(async () => {
    const state: { selectedRun: RunType } = { selectedRun: stateRef.current };

    if (state.selectedRun && !isDataLoading.current) {
      const currentJobId = isInput
        ? state.selectedRun?.outputs?.input_preparation?.jobId
        : state.selectedRun?.outputs?.output_generation?.jobId;

      if (currentJobId) {
        isDataLoading.current = true;
        const url = buildActionUrl(
          {
            projectId: state?.selectedRun?.projectId,
            jobId: currentJobId,
          },
          TYPE_VIEW_JOB_STATUS,
        );
        const currentJobStatus = await axios.get(url).then((res: AxiosResponse) => res.data);
        const isOkStatus = currentJobStatus?.status === JobStatus.ok;
        const isFailedStatus = currentJobStatus?.status === JobStatus.failed;

        if (isOkStatus || isFailedStatus) {
          const loadRunUrl = buildActionUrl(
            {
              projectId: state?.selectedRun?.projectId,
              phaseId: state?.selectedRun?.phaseId,
              runId: state?.selectedRun?.runId,
            },
            TYPE_LOAD_RUNS,
          );
          const runResponse: { run?: RunType } = await axios.get(loadRunUrl).then((res: AxiosResponse) => res.data);
          let newJobStatus = currentJobStatus?.status;
          let updatedJobOutputUrl = '';

          if (runResponse?.run) {
            newJobStatus =
              (isInput
                ? runResponse.run?.outputs?.input_preparation?.status
                : runResponse.run?.outputs?.output_generation?.status) || '';
            currentJobStatus.status = newJobStatus;

            if (newJobStatus === JobStatus.ok) {
              updatedJobOutputUrl = getDataSheetUrl(outputType, jobSheetResourceKey, runResponse?.run || null);
            }
          }

          setJobStatus(newJobStatus);
          setNewJobOutputUrl(updatedJobOutputUrl);
          updateTimer.current = null;
          isDataLoading.current = false;

          if (runResponse?.run) {
            updateRun?.(runResponse.run);
          }

          if ([JobStatus.ok, JobStatus.failed, JobStatus.aborted].includes(currentJobStatus?.status)) {
            return;
          }
        }

        if (currentJobStatus) {
          setJobStatus(currentJobStatus.status);
        }
      }
    }

    isDataLoading.current = false;
    updateTimer.current = setTimeout(() => {
      checkSelectedRun().then((r) => r);
    }, refreshInterval);
  }, [updateRun, isInput, refreshInterval, jobSheetResourceKey, outputType]);

  const refObject: JobHandlerRef = {
    startNewJob: (jobProject?: ProjectType, jobRun?: RunType) => {
      if (!jobRun || !jobProject || isInput) return false;
      submitJob(jobProject?.projectId, jobRun?.phaseId, jobRun?.runId).then((r) => r);
      return true;
    },
  };

  useEffect(() => {
    if (setRef) {
      setRef(refObject);
    }
    // eslint-disable-next-line
  }, [setRef]);

  useEffect(() => {
    stateRef.current = run;
  }, [run]);

  useEffect(() => {
    if (run) {
      run.outputs[outputType].status = selectedRunProgress as JobStatus;
      if (selectedRunProgress !== JobStatus.ok) {
        updateRun?.(run);
      }
    }
  }, [selectedRunProgress, run, updateRun, outputType]);

  // when component is updating check status
  useEffect(() => {
    if (isJobInProgress && !updateTimer.current) {
      updateTimer.current = setTimeout(checkSelectedRun, refreshInterval);
    }

    return () => {
      if (updateTimer.current) {
        clearTimeout(updateTimer.current);
      }
    };
  }, [isJobInProgress, updateTimer, checkSelectedRun, refreshInterval]);

  return (
    <Box mt={isTableJob ? 0 : 6} display={isTableJob ? 'flex' : 'block'}>
      {!isTableJob && <SectionTitle variant="body1" title={`${title} ${capitalize(type)}`} useCommonStyles={true} />}
      {isManualJob && (
        <Box display="flex" mb={2}>
          <Typography>This job requires manual actions.</Typography>
        </Box>
      )}
      <Box display="flex">
        {!isJobRunning && !isInput && (
          <Box mr={2}>
            <Button
              data-id-cypress="jobHandlerGenerateButton"
              color="primary"
              variant="contained"
              onClick={submitNewJob}
              disabled={generateDisabled || waitForInputId}
            >
              Generate
            </Button>
          </Box>
        )}
        {!isJobRunning && !isInput && errorMessage && (generateDisabled || waitForInputId) && (
          <Typography color={'red'}>{errorMessage}</Typography>
        )}
        {leftActions}
        {isJobRunning && (
          <>
            <Box mr={2}>
              <Box>
                <Box display="flex" alignItems="center" justifyContent="center">
                  {isJobInProgress && inProgressLabel}
                  {!isTableJob && hasJobSuccess && (
                    <>
                      <Box mr={4}>{successText || `${title} ready`}</Box>
                      <Check />
                    </>
                  )}
                  {hasJobFailed && (
                    <>
                      <Box mr={4}>
                        {title} {actionName} failed
                      </Box>
                      <Cancel />
                    </>
                  )}
                </Box>
              </Box>
            </Box>
            {isJobInProgress && (
              <>
                <Box mr={2} width={50} textAlign="right">
                  <CircularProgress size={24} />
                </Box>

                <Box mr={2}>
                  <Button variant="contained" color="secondary" size="small" onClick={cancelSelectedJob}>
                    Cancel
                  </Button>
                </Box>
              </>
            )}
            {!isJobInProgress && !waitForInputId && (
              <Box mr={2}>
                <Button
                  data-id-cypress="jobHandlerRegenerateButton"
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={isInput || generateDisabled}
                  onClick={submitNewJob}
                >
                  Regenerate
                </Button>
              </Box>
            )}

            <Box mr={2}>
              <Button
                data-id-cypress="jobHandlerJobButton"
                data-info-cypress={jobId}
                variant="contained"
                color="secondary"
                size="small"
                onClick={goToJob}
              >
                Job
              </Button>
            </Box>
            <Box mr={2}>
              {!isTableJob && !isProductionMode && (
                <Typography data-id-cypress="jobHandlerStatus">{selectedRunProgress}</Typography>
              )}
            </Box>
          </>
        )}
      </Box>
      {!isTableJob &&
        isJobRunning &&
        hasJobSuccess &&
        showSheetLinks &&
        getSheetLinkData().map((linkData) => <ExternalLink key={linkData.title} {...linkData} />)}
    </Box>
  );
};

JobHandler.defaultProps = {
  run: undefined,
  simulateFail: false,
  updateRun: undefined,
  isDisabled: undefined,
  actionName: 'generation',
  title: undefined,
  setRef: undefined,
  selectedRunId: undefined,
  isTableJob: false,
  refreshInterval: 4500,
  inProgressText: undefined,
  successText: undefined,
  jobSheetResourceKey: '',
  externalLinkLabel: undefined,
  leftActions: null,
  showSheetLinks: true,
  waitForInputId: false,
  errorMessage: '',
};

export default JobHandler;
