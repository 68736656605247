import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DataGrid, GridAlignment, GridColDef, GridRenderCellParams, GridRowData } from '@mui/x-data-grid';
import React, { useMemo, useState } from 'react';

import {
  Composition,
  CompositionConfig,
  IngredientClass,
  IngredientType,
} from '../../../../../features/project/types/concept-generation';
import { BaseClasses, generateCompositionTemplates as generate } from '../../../../../features/project/utils';

const SLOT_PREFIX = 'slot';

type SetupProjectDialogProps = {
  slots: number;
  categories: IngredientClass[];
  callback: (templates: CompositionConfig[]) => void;
  open?: boolean;
  setOpenDialog: (open: boolean) => void;
};

const CompositionGenerationDialog: React.FC<SetupProjectDialogProps> = ({
  callback,
  categories,
  open,
  setOpenDialog,
  slots,
}) => {
  const emptyValue = Array<IngredientClass[]>(slots).fill([]);
  const [pageSize, setPageSize] = useState<number>(50);
  const [input, setInput] = useState<typeof emptyValue>(emptyValue);
  const [disabledSlot, setDisabledSlot] = useState<string[]>([]);

  const isClassChecked = (i: number, value: string): boolean => {
    if (input && input[i] && input[i].length > 0) {
      return input[i].includes(value as IngredientClass);
    }
    return false;
  };

  const columns = emptyValue?.map(
    (item, i) =>
      ({
        field: `${SLOT_PREFIX}${i + 1}`,
        align: 'center' as GridAlignment,
        headerAlign: 'center' as GridAlignment,
        headerName: `Ingredient slot ${i + 1}`,
        valueOptions: categories,
        minWidth: 212,

        renderCell: ({ value, field }: GridRenderCellParams) => (
          <Box display="flex" style={{ width: '100%' }}>
            <Checkbox
              disabled={disabledSlot.includes(field) && String(value) !== input[i][0]}
              onChange={({ target: { checked: check } }) => {
                setInput(({ [i]: cur = [], ...last }) => ({
                  ...last,
                  [i]: [
                    ...(!BaseClasses.includes(value) ? cur.filter((c) => c !== value) : []),
                    (check && value) as IngredientClass,
                  ].filter(Boolean),
                }));

                if (BaseClasses.includes(value)) {
                  setDisabledSlot((v = []) => [...v.filter((o) => !!o && o !== field), (check && field) || '']);
                }
              }}
              checked={isClassChecked(i, value)}
            />
            <Box>{value}</Box>
          </Box>
        ),
      }) as GridColDef,
  );

  const rows = useMemo<GridRowData[]>(
    () =>
      categories.map((category, id) => ({
        id,
        ...Object.fromEntries(
          Array(slots)
            .fill(SLOT_PREFIX)
            ?.map((p, i) => [`${p}${i + 1}`, categories[id]]),
        ),
      })),
    [categories, slots],
  );

  return (
    <Dialog open={!!open} fullWidth={true} maxWidth="lg">
      <DialogTitle id="form-dialog-title">Generate Composition Templates</DialogTitle>
      <DialogContent>
        <Box style={{ height: 600, width: '100%' }}>
          <DataGrid
            pageSize={pageSize}
            onPageSizeChange={(page) => setPageSize(page)}
            rows={rows}
            columns={columns}
            disableSelectionOnClick={true}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialog(false)} color="secondary">
          Back
        </Button>
        <Button
          onClick={() => {
            const [first, ...others] = Object.values(input);
            const templates: Composition[] = [...generate(first as Composition, ...(others as Composition[]))];

            callback(
              templates.map<CompositionConfig>((template) => {
                const catIdx = template.findIndex((i) => i && !i.toLowerCase().includes('base'));
                return {
                  slots: template,
                  category: template[catIdx] || IngredientType.other,
                };
              }),
            );
          }}
          color="primary"
          variant="contained"
        >
          Generate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CompositionGenerationDialog.defaultProps = { open: false };

export default CompositionGenerationDialog;
