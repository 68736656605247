import Public from '@mui/icons-material/Public';
import ZoomIn from '@mui/icons-material/ZoomIn';
import Box from '@mui/material/Box';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DataMan } from '../../../components/dataman';
import { DataManDataHandlerOptions, DataManRecordsType, LoadRecordsResult } from '../../../components/dataman/types';
import PageTitle from '../../../components/PageTitle';
import { useLayout } from '../../../context/LayoutContext';
import { TYPE_PROJECT_SHOW, TYPE_SHOW_PROJECT_JOB } from '../../../shared/url';
import { buildStructure } from './dataman/config';
import { loadJobRecords } from './dataman/load-records';
import { JobListDataManRecord } from './dataman/types';

const JobList: React.FC = () => {
  const { setPageTitle } = useLayout();
  const history = useNavigate();
  // state
  const [jobs, setJobs] = React.useState<DataManRecordsType<JobListDataManRecord>>([]);
  const [nextId, setNextId] = useState<string | null>(null);
  const [loadMoreEnabled, setLoadMoreEnabled] = useState<boolean>(true);

  // fetch data
  const dataHandler = useCallback(
    async (options: DataManDataHandlerOptions): Promise<void> => {
      const data: LoadRecordsResult<JobListDataManRecord> | null = await loadJobRecords(
        nextId,
        options.sort,
        !options.loadMore,
      );

      if (data) {
        setJobs([...jobs, ...(data?.records || [])]);
        setLoadMoreEnabled(!!data?.nextId);
        setNextId(data?.nextId || null);
      }
    },
    [nextId, jobs],
  );

  // build data man config
  const dataManConfig = useMemo(
    () =>
      buildStructure({
        dataHandler,
        records: jobs,
        history,
        loadMoreEnabled,
        icons: {
          [TYPE_SHOW_PROJECT_JOB]: <ZoomIn data-id-cypress="editJobButton" />,
          [TYPE_PROJECT_SHOW]: <Public />,
        },
      }),
    [history, dataHandler, jobs, loadMoreEnabled],
  );

  const mounted = React.useRef(false);
  useEffect(() => {
    if (mounted.current) return;

    setPageTitle('Jobs');

    mounted.current = true;
  }, [setPageTitle]);

  return (
    <Box>
      <PageTitle title="Jobs" data-id-cypress="jobList" />
      <DataMan {...dataManConfig} records={jobs} />
    </Box>
  );
};

export default JobList;
