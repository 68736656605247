import '../layout/card.scss';

import { Box, Card, CardActions, CardMedia } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Link } from 'react-router-dom';
import uuid from 'react-uuid';

import { ContextType } from '../types';

interface ContextCardProps {
  context: ContextType;
  cardTitleAction?: (context: ContextType) => React.ReactElement;
  cardActionUrl?: string | ((context: ContextType) => string);
  cardBottomActions?: (context: ContextType) => React.ReactElement;
  cardAction?: (context: ContextType) => () => void;
  disabled?: boolean;
  disabledMessage?: React.ReactElement;
  searchString?: string | undefined;
}

const ContextCard: React.FC<ContextCardProps> = ({
  context,
  cardTitleAction,
  cardActionUrl,
  cardBottomActions,
  cardAction,
  disabled,
  disabledMessage,
  searchString,
}) => {
  const CHARACTER_LIMIT_NUMBER = 60;
  let actionUrl = '';

  if (cardActionUrl) {
    actionUrl = typeof cardActionUrl === 'string' ? cardActionUrl : cardActionUrl(context);
  }

  const getHighlightedText = (text: string | undefined, highlight: string | undefined) => {
    if (text && highlight) {
      // Split on highlight term and include term into parts, ignore case
      const parts = text?.split(new RegExp(`(${highlight})`, 'gi'));
      if (parts[0].length > CHARACTER_LIMIT_NUMBER) {
        const cutString = parts[0].substring(parts[0].length - 15);
        const firstSpace = cutString.indexOf(' ');
        parts[0] = `... ${cutString.substring(firstSpace)}`;
      }

      return (
        <span>
          {parts?.map((part) => (
            <span key={uuid()} className={part.toLowerCase() === highlight.toLowerCase() ? 'card__highlighted' : ''}>
              {part}
            </span>
          ))}
        </span>
      );
    }
    return text;
  };

  return (
    <Card>
      <Box position={'relative'} data-id-cypress="contextCard">
        <CardHeader
          classes={{ content: 'card__headerContent' }}
          title={
            <Typography
              data-id-cypress="cardTitle"
              variant="h6"
              component="h4"
              noWrap={true}
              gutterBottom={true}
              className="card__title"
            >
              {getHighlightedText(context.contextId, searchString) || '<no name>'}
              {context.contextVersionId && (
                <Typography className="card__version">Version: {context.contextVersionId}</Typography>
              )}
            </Typography>
          }
          avatar={<Avatar> {(context.name ? context.name[0] : 'o').toUpperCase()} </Avatar>}
          action={cardTitleAction ? cardTitleAction(context) : null}
        />
        <CardActionArea
          onClick={cardAction ? cardAction(context) : () => null}
          component={cardActionUrl ? Link : 'div'}
          to={actionUrl}
        >
          <CardContent>
            <CardMedia className="card__media">
              <Box className="card__image">
                <img
                  src={context.imageUrl || '/card-no-image.svg'}
                  alt="Context"
                  style={{ width: context.imageUrl ? '100%' : 37 }}
                />
              </Box>
            </CardMedia>
            <Typography
              paragraph={true}
              align="left"
              variant="body1"
              noWrap={true}
              color="textSecondary"
              className="card__description"
            >
              {getHighlightedText(context.description, searchString) || '<no description>'}
            </Typography>
          </CardContent>
        </CardActionArea>
        {cardBottomActions && <CardActions>{cardBottomActions(context)}</CardActions>}

        {disabled && (
          <Box
            position={'absolute'}
            width={'100%'}
            height={'100%'}
            top={0}
            left={0}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 20,
              zIndex: 10,
              background: 'rgba(255, 255, 255, 0.8)',
            }}
          >
            {disabledMessage}
          </Box>
        )}
      </Box>
    </Card>
  );
};

ContextCard.defaultProps = {
  cardTitleAction: undefined,
  cardActionUrl: undefined,
  cardBottomActions: undefined,
  cardAction: undefined,
  disabled: false,
  disabledMessage: undefined,
  searchString: '',
};

export default ContextCard;
