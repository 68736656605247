import React, { PropsWithChildren, useCallback, useMemo } from 'react';

const drawerOpenWidth = 240;
const drawerClosedWith = 58;

export enum LayoutActions {
  TOGGLE_SIDEBAR = 'toggleSidebar',
  SET_TITLE = 'setTitle',
}

export interface LayoutContextState {
  isSidebarOpened: boolean;
  drawerWidth: number;
  pageTitle: string;
}

export type LayoutAction =
  | {
      type: LayoutActions.TOGGLE_SIDEBAR;
      open: boolean;
    }
  | { type: LayoutActions.SET_TITLE; title: string };

interface LayoutProviderContext {
  layout: LayoutContextState;
  toggleSidebar: (open?: boolean) => void;
  setPageTitle: (title: string) => void;
}

const LayoutInitialState: LayoutContextState = {
  isSidebarOpened: true,
  drawerWidth: drawerOpenWidth,
  pageTitle: '',
};

type Actions = LayoutAction;

const LayoutContext = React.createContext<LayoutProviderContext | undefined>(undefined);

const LayoutReducer = (state: LayoutContextState, action: Actions) => {
  switch (action.type) {
    case LayoutActions.TOGGLE_SIDEBAR:
      return {
        ...state,
        isSidebarOpened: action.open,
        drawerWidth: action.open ? drawerOpenWidth : drawerClosedWith,
      };
    case LayoutActions.SET_TITLE:
      return {
        ...state,
        pageTitle: action.title,
      };
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
};

const LayoutProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const [state, dispatch] = React.useReducer(LayoutReducer, LayoutInitialState);
  const toggleSidebar = useCallback(
    (open?: boolean) => {
      dispatch({
        type: LayoutActions.TOGGLE_SIDEBAR,
        open: open === undefined ? !state.isSidebarOpened : open,
      });
    },
    [state.isSidebarOpened],
  );
  const setPageTitle = useCallback((title: string) => {
    dispatch({ type: LayoutActions.SET_TITLE, title });
  }, []);

  const contextValue = useMemo(
    () => ({
      layout: state,
      toggleSidebar,
      setPageTitle,
    }),
    [state, toggleSidebar, setPageTitle],
  );

  return <LayoutContext.Provider value={contextValue}>{children}</LayoutContext.Provider>;
};

const useLayout = (): LayoutProviderContext => {
  const context = React.useContext(LayoutContext);
  if (context === undefined) {
    throw new Error('useLayoutState must be used within a LayoutProvider');
  }
  return context;
};

export { LayoutProvider, useLayout };
