import '../layout/common.scss';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import React, { ChangeEvent, memo, MouseEvent } from 'react';
import { FixedSizeList as List } from 'react-window';

import { SocialTagErrorItem } from '../types';
import WarningIcon from './WarningIcon';

type RowProps = {
  style: any;
  value: string;
  last: boolean;
  handleToggle: (name: string, _category: string) => (event: MouseEvent) => void;
  checked: boolean;
  category: string;
  disabled: boolean;
  error: boolean;
};

const Row: React.FC<RowProps> = ({ style, last, value, handleToggle, category, disabled, error, checked }) => {
  const labelId = `transfer-list-item-${value}-label`;
  return (
    <ListItem
      key={value}
      style={style}
      role="listitem"
      button={true}
      onClick={handleToggle(value, category)}
      className={last ? 'common__lastTagIList' : ''}
    >
      {!disabled && (
        <ListItemIcon>
          <Box display="flex" alignItems="center">
            {error && <WarningIcon />}
            <Checkbox
              checked={checked}
              tabIndex={-1}
              disableRipple={true}
              inputProps={{ 'aria-labelledby': labelId }}
              disabled={disabled}
            />
          </Box>
        </ListItemIcon>
      )}
      <ListItemText id={labelId} primary={value} />
    </ListItem>
  );
};

function isRowPropsEqual(p: RowProps, n: RowProps): boolean {
  return (
    p.value === n.value &&
    p.disabled === n.disabled &&
    p.checked === n.checked &&
    p.category === n.category &&
    p.error === n.error
  );
}

const RowMemoized = memo(Row, isRowPropsEqual);

export type TagListCategory = {
  category: string;
  items: string[];
};

type TagListProps = {
  name: string;
  items: TagListCategory[];
  checked: { [key: string]: string[] };
  handleToggle: (name: string, _category: string) => (event: MouseEvent) => void;
  onSearchChange: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  errors?: SocialTagErrorItem[] | null;
};

const MultiTagList: React.FC<TagListProps> = ({
  name,
  items,
  checked,
  handleToggle,
  onSearchChange,
  disabled,
  errors,
}) => (
  <Paper className="common__noScrollbarPaper">
    <div style={{ width: 300 }}>
      <TextField
        id="standard-search"
        label={`Search ${name}`}
        type="search"
        className="common__textField"
        margin="normal"
        onChange={onSearchChange}
      />
    </div>
    <Box className="common__scrollableTransferList">
      {items.map((category) => {
        const catErrors = errors?.find((error) => error.category === category.category) || null;
        const categoryChecked = checked[category.category] || null;
        const itemsLength = category.items.length;
        const height = 60 * itemsLength > 600 ? 600 : 60 * itemsLength;

        return (
          <div key={category.category}>
            <Box key={`${category.category}_heading`} className="common__categoryGroup">
              {category.category}
            </Box>
            <List height={height} width={316} key={`${category.category}_list`} itemCount={itemsLength} itemSize={60}>
              {({ index, style }) => (
                <RowMemoized
                  last={itemsLength - 1 === index}
                  key={index + category.items[index]}
                  disabled={disabled || false}
                  value={category.items[index]}
                  style={style}
                  handleToggle={disabled ? () => () => null : handleToggle}
                  checked={categoryChecked && categoryChecked.indexOf(category.items[index]) !== -1}
                  error={catErrors !== null && catErrors.tags.includes(category.items[index])}
                  category={category.category}
                />
              )}
            </List>
          </div>
        );
      })}
    </Box>
  </Paper>
);

function areEqual(p: TagListProps, n: TagListProps) {
  return (
    p.name === n.name &&
    JSON.stringify(p.items) === JSON.stringify(n.items) &&
    JSON.stringify(p.checked) === JSON.stringify(n.checked) &&
    p.disabled === n.disabled &&
    p.errors === n.errors
  );
}

MultiTagList.defaultProps = {
  disabled: undefined,
  errors: undefined,
};

export default memo(MultiTagList, areEqual);
