import { Add, ArrowDownward, ArrowUpward, Remove } from '@mui/icons-material';
import { Button } from '@mui/material';
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getUrlFiltersAndSort, setUrlFilters, setUrlSort, UrlFiltersAndSortType } from '../../shared/utils';
import TableList from './List/TableList';
import SelectOptionsListFilter from './ListFilters/SelectOptionsListFilter';
import TextListFilter from './ListFilters/TextListFilter';
import {
  ColumnIdentifier,
  DataManAppliedFilter,
  DataManDataHandlerOptions,
  DataManFilterDialogProps,
  DataManFilters,
  DataManListSortDirection,
  DataManListSortOptions,
  DataManObject,
  DataManProperties,
  DataManRecord,
  DataManRecordsType,
} from './types';

export const DataManFilterDialog: React.FC<DataManFilterDialogProps> = ({ ...props }) => {
  const [filters, setFilters] = useState<DataManAppliedFilter[]>(
    props.filters?.length ? props.filters : [{ value: null }],
  );

  const addFilter = (afterIndex: number) => {
    const newFilters = [...filters];
    newFilters.splice(afterIndex + 1, 0, { value: null });
    setFilters(newFilters);
  };

  const removeFilter = (filterIndex: number) => {
    const newFilters = [...filters];
    newFilters.splice(filterIndex, 1);
    setFilters(newFilters);
  };

  const applyFilter = () => {
    props.handleChangeFilter(props.columnIdentifier, filters);
  };

  const clearFilters = () => {
    props.handleChangeFilter(props.columnIdentifier, []);
    setFilters([{ value: null }]);
  };

  const handleFilterChange = (filterIndex: number, newFilter: DataManAppliedFilter) => {
    const newFilters = [...filters];
    newFilters[filterIndex] = newFilter;
    setFilters(newFilters);
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          padding: 10,
          flexDirection: 'column',
        }}
      >
        {filters.map((filter, index: number) => (
          <div style={{ display: 'flex' }} key={filter.value}>
            {props.column.renderAs === 'list' ? (
              <SelectOptionsListFilter
                {...props}
                handleChangeFilter={handleFilterChange}
                filterIndex={index}
                value={filter}
              />
            ) : (
              <TextListFilter {...props} handleChangeFilter={handleFilterChange} filterIndex={index} value={filter} />
            )}
            {props.column.allowFilterList && (
              <div>
                <Button onClick={() => addFilter(index)}>
                  <Add />
                </Button>
                <Button onClick={() => removeFilter(index)} style={{ visibility: index ? 'visible' : 'hidden' }}>
                  <Remove />
                </Button>
              </div>
            )}
          </div>
        ))}
      </div>

      <div style={{ background: '#efefef', padding: 10, textAlign: 'right' }}>
        {props.filters && props.filters.length > 0 && (
          <Button color="secondary" onClick={clearFilters}>
            Clear
          </Button>
        )}
        <Button color="primary" onClick={applyFilter}>
          Apply
        </Button>
      </div>
    </div>
  );
};

export const DataManSortIndicator: React.FC<{
  direction: DataManListSortDirection;
}> = ({ direction }) => {
  if (direction === 'ASC') return <ArrowDownward />;

  return <ArrowUpward />;
};

export const DataManListNoRecords: React.FC = () => (
  <div style={{ padding: 20, textAlign: 'center' }}>No records in table</div>
);

const DataMan: React.FC<PropsWithChildren<DataManProperties<DataManRecord>>> = ({ records, ...props }) => {
  const config = props;
  config.children = undefined;
  const history = useNavigate();
  const location = useLocation();
  const useDataHandler = !!config.dataHandler;

  const startUrlFilters: UrlFiltersAndSortType = getUrlFiltersAndSort(location);

  const [dataInitialized, setDataInitialized] = useState<boolean>(false);
  const [rows, setRows] = useState<DataManRecordsType<DataManRecord>>(records || []);
  const [sortFields, setSortFields] = useState<DataManListSortOptions>(
    config.useUrlFilterAndSort ? startUrlFilters.sort || config.list.sort || [] : config.list.sort || [],
  );
  const [dataFilters, setDataFilters] = useState<{
    [key: string]: DataManAppliedFilter[];
  }>(config.useUrlFilterAndSort ? startUrlFilters.filters || {} : config.startFilters || {});
  const [isLoading, setLoading] = useState<boolean>(false);

  const filterStaticRecords = (
    _records: DataManRecordsType<DataManRecord>,
    _filters: {
      [key: string]: DataManAppliedFilter[];
    },
    _sort: DataManListSortOptions,
  ) => {
    let newRecords = _records || [];
    Object.keys(_filters).forEach((field) => {
      _filters[field].forEach((filter) => {
        newRecords = newRecords.filter((record) => String(record[field]).indexOf(filter.value) !== -1);
      });
    });

    _sort.forEach((field) => {
      newRecords = newRecords.sort((a, b) => {
        if (a[field.field] > b[field.field]) {
          return field.direction === 'ASC' ? 1 : -1;
        }

        return field.direction === 'ASC' ? -1 : 1;
      });
    });

    return newRecords;
  };

  const refreshData = useCallback(
    async (options: DataManDataHandlerOptions) => {
      if (!config.dataHandler) return;
      setLoading(true);
      try {
        await config.dataHandler(options || {});
      } finally {
        setLoading(false);
      }
    },
    [config],
  );

  const handleChangeFilter = (columnId: ColumnIdentifier, filters: DataManAppliedFilter[]) => {
    const newFilters = { ...dataFilters, [columnId]: filters };
    setDataFilters(newFilters);

    if (config.useUrlFilterAndSort) {
      const filtersToUrl: any = {};

      Object.keys(newFilters).forEach((fKey) => {
        const filtersByKey = newFilters[fKey].filter((f) => f.value !== undefined && f.value !== null);

        filtersToUrl[fKey] = filtersByKey.length > 0 ? filtersByKey : null;
      });

      setUrlFilters(location, filtersToUrl);
    }

    if (useDataHandler) {
      refreshData({ filters: newFilters, sort: sortFields });
    }
  };

  const handleChangeSort = (column: ColumnIdentifier, direction: DataManListSortDirection, append: boolean) => {
    let fields: DataManListSortOptions = sortFields && append ? sortFields : [];
    fields = fields.filter((item) => item.field !== column);
    fields.push({ field: column, direction });
    setSortFields(fields);

    if (config.useUrlFilterAndSort) {
      setUrlSort(location, history, fields);
    }

    if (useDataHandler) {
      refreshData({ filters: dataFilters, sort: fields });
    }
  };

  const handleLoadMore = async (filters: DataManFilters, sort: DataManListSortOptions) => {
    if (config.list.useLoadMore) {
      await refreshData({ filters, sort, loadMore: true });
    }

    if (config.list.onLoadMore) config.list.onLoadMore();
  };

  // Init when data needs to be fetched with a datahandler
  useEffect(() => {
    if (useDataHandler && !dataInitialized) {
      refreshData({ filters: dataFilters, sort: sortFields }).then(() => null);
      setDataInitialized(true);
    }
  }, [useDataHandler, dataFilters, sortFields, refreshData, dataInitialized]);

  // Refresh rows when records/filters/sorting changes
  useEffect(() => {
    const initRecords = [...(records || [])];
    if (useDataHandler) {
      setRows(initRecords);
    } else {
      setRows(filterStaticRecords(initRecords, dataFilters, sortFields));
    }
  }, [useDataHandler, dataFilters, sortFields, records]);

  const dataManObject: DataManObject = {
    sortColumn: handleChangeSort,
    applyColumnFilters: handleChangeFilter,
    loadMore: handleLoadMore,
    filters: dataFilters,
    sort: sortFields,
  };

  return (
    <TableList
      dataMan={dataManObject}
      config={config}
      dataFilters={dataFilters}
      sortFields={sortFields}
      isLoading={isLoading}
      rows={rows}
    />
  );
};

export default DataMan;
