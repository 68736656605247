import '../../../../../../layout/correction-table.scss';
import '../../../../../../layout/run-config.scss';

import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import { Alert, Box, Button, Tooltip, Typography } from '@mui/material';
import { DataGridProps } from '@mui/x-data-grid';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { SLICE_DATASOURCE_NAME } from '../../../../../../features/datasources/store';
import { SLICE_CONCEPT_GENERATION_COMMON } from '../../../../../../features/project/store/concept-generation-reducer';
import {
  setSensoryRemapping,
  SLICE_CONCEPT_GENERATION_V3,
} from '../../../../../../features/project/store/concept-generation-reducer-v3';
import { SensoryRemapping } from '../../../../../../features/project/types/concept-generation';
import { lookupReferenceIngredient } from '../../../../../../features/project/utils';
import { getDataGridCellAlignment, timestampToLocalDateSting } from '../../../../../../shared/utils';
import { useAppSelector } from '../../../../../../store';
import { IngredientDBIngredientType, IngredientDBIngredientTypeWithSensoryRemapping } from '../../../../../../types';
import CorrectedIngredientAutoComplete from '../../../_components/CorrectedIngredientAutoComplete';
import IngredientTable from '../../../_components/IngredientTable';
import { IngredientDBMap } from '../../../_utils/datasources';
import { RowErrorMessageType } from '../../../_utils/types';

const rowInfoOrWarningMessage = (
  infoMessage: string | undefined,
  foundSensory: boolean,
): RowErrorMessageType | undefined => {
  if (foundSensory) {
    return infoMessage ? { severity: 'info', message: infoMessage } : undefined;
  }
  let message = 'Neither ingredient nor reference has sensory information.';
  if (infoMessage) {
    message += ` - ${infoMessage}`;
  }
  return { message, severity: 'warning' };
};

interface SensoryRemappingRow {
  ingredientId: number;
  name: string;
  correctedIngredientId: number | undefined;
  project: string | undefined;
  createdAt: number | undefined;
  correctionMessage: string | undefined;
}

interface SensoryRemappingProps {
  selectedIngredientIds: number[];
  sensoryRemapping: SensoryRemapping;
  isReadOnly: boolean;
}

const SensoryRemappingStep: FC<SensoryRemappingProps> = ({ selectedIngredientIds, sensoryRemapping, isReadOnly }) => {
  const dispatch = useDispatch();

  const {
    ingredientDbVersion,
    ingredients: allIngredients,
    sensoryIngredients,
    sensoryIngredientsLoading,
    ingredientsLoading,
  } = useAppSelector((store) => store[SLICE_DATASOURCE_NAME]);
  const { initialIngredients } = useAppSelector((store) => store[SLICE_CONCEPT_GENERATION_COMMON]);
  const { sensoryRemapping: sensoryRemappingState } = useAppSelector((store) => store[SLICE_CONCEPT_GENERATION_V3]);
  const [selectedIngredientsWithCorrections, setSelectedIngredientsWithCorrections] = useState(
    [] as IngredientDBIngredientTypeWithSensoryRemapping[],
  );
  const dataSourcesLoading = sensoryIngredientsLoading || ingredientsLoading;

  // Memo that creates a map of all ingredients to have speedy search
  const ingredientDBMap = useMemo(
    () => new IngredientDBMap(allIngredients, ingredientDbVersion),
    [allIngredients, ingredientDbVersion],
  );

  // Memo that creates a map with all ingredients having sensory information
  // we only need these ingredients in our ingredient correction dropdown
  const sensoryIngredientsMap = useMemo(() => {
    const allSensoryIngredientIds = sensoryIngredients.map((s) => s.ingredientId);
    const allSensoryIngredients = allIngredients.filter((ingredient: IngredientDBIngredientType) =>
      allSensoryIngredientIds.includes(Number(ingredient.ingredientId)),
    );
    return new IngredientDBMap(allSensoryIngredients, ingredientDbVersion);
  }, [allIngredients, sensoryIngredients, ingredientDbVersion]);

  /**
   * This callback adds or removes sensory remapping from the redux store
   * @param ingredientId
   * @param correctedIngredientId
   */
  const updateSensoryRemapping = useCallback(
    (ingredientId: number, correctedIngredientId: number | undefined) => {
      dispatch(
        setSensoryRemapping({
          ingredientId,
          sensoryIngredientId: correctedIngredientId,
        }),
      );
    },
    [dispatch],
  );

  // Effect that gets all ingredients for the SensoryRemappingTable and adds the sensory remapping + correction message
  useEffect(() => {
    const selectedIngredients = allIngredients.filter((ingredient: IngredientDBIngredientType) =>
      selectedIngredientIds.includes(Number(ingredient.ingredientId)),
    );
    const rows = selectedIngredients.map(
      (ingredient: IngredientDBIngredientType): IngredientDBIngredientTypeWithSensoryRemapping => {
        const ingredientId = Number(ingredient.ingredientId);
        const storedCorrectedIngredientId = sensoryRemapping.get(ingredientId);
        let correctedIngredientId: number | undefined;
        let createdAt: number | undefined;
        let project: string | undefined;
        let infoMessage: string | undefined;
        let correctable = true;
        const sensoryIngredient = sensoryIngredientsMap.searchIngredientById(ingredientId);
        if (sensoryIngredient) {
          // if there is sensory information, we will never remap the ingredient
          correctable = false;
          if (storedCorrectedIngredientId) {
            infoMessage = `Sensory information available: remapping to ${storedCorrectedIngredientId} discarded.`;
          }
        } else {
          const remappingIngredient = initialIngredients.find(
            (initialIngredient) => initialIngredient.ingredient_id === ingredientId,
          );
          if (!remappingIngredient?.sensory_remap_to) {
            // keep stored value, no default remapping available
            correctedIngredientId = storedCorrectedIngredientId;
          } else if (
            !storedCorrectedIngredientId ||
            storedCorrectedIngredientId === remappingIngredient.sensory_remap_to
          ) {
            // no stored remapping, or it is the same as the default one: use the default remapping of previous projects
            correctedIngredientId = remappingIngredient.sensory_remap_to;
            createdAt = remappingIngredient.sensory_remap_created_at;
            project = remappingIngredient.sensory_remap_project_id || '';
          } else {
            // there is a stored remapping, but it is different from the default one: keep the value and show a warning
            correctedIngredientId = storedCorrectedIngredientId;
            const createdAtStr = timestampToLocalDateSting(remappingIngredient.sensory_remap_created_at);
            infoMessage =
              `Remapping of ${createdAtStr} - ${remappingIngredient.sensory_remap_project_id}` +
              ` - mapping to ${remappingIngredient.sensory_remap_to} NOT USED!`;
          }
        }
        if (correctedIngredientId !== storedCorrectedIngredientId) {
          updateSensoryRemapping(ingredientId, correctedIngredientId);
        }
        return {
          ingredientId,
          name: ingredient.name,
          correctedIngredientId,
          createdAt,
          project,
          correctionMessage: infoMessage,
          correctable,
        };
      },
    );
    setSelectedIngredientsWithCorrections(rows);
  }, [
    selectedIngredientIds,
    sensoryIngredientsMap,
    sensoryRemapping,
    allIngredients,
    initialIngredients,
    updateSensoryRemapping,
  ]);

  /**
   * This callback checks if the ingredient has sensory information.
   * @param ingredientId
   * @returns boolean
   */
  const hasSensoryInformation = useCallback(
    (ingredientId: number | undefined) => {
      if (ingredientId) {
        return !!sensoryIngredientsMap.searchIngredientById(ingredientId);
      }
      return false;
    },
    [sensoryIngredientsMap],
  );

  /**
   * This callback sets the error message for a row.
   * It checks if the ingredient or the reference ingredient has sensory information.
   * If an info message is already be added to the row, it will be included.
   * @param ingredientId
   * @returns RowErrorMessageType | undefined
   */
  const getRowErrorMessage = useCallback(
    (ingredientRow: SensoryRemappingRow) => {
      const { ingredientId, correctionMessage } = ingredientRow;
      const ingredient = ingredientDBMap.searchIngredientById(ingredientId);
      const remappedIngredient = sensoryRemappingState.get(ingredientId);
      const foundSensory =
        hasSensoryInformation(ingredientId) ||
        hasSensoryInformation(Number(ingredient?.referenceIngredientId)) ||
        hasSensoryInformation(remappedIngredient);
      return rowInfoOrWarningMessage(correctionMessage, foundSensory);
    },
    [ingredientDBMap, hasSensoryInformation, sensoryRemappingState],
  );

  /**
   * This callback is triggered when a cell of the IngredientTable is updated.
   * @param: newRow
   * @returns: SensoryRemappingRow
   */
  const processRowUpdate = useCallback<Required<DataGridProps>['processRowUpdate']>(
    (newRow: SensoryRemappingRow) => {
      updateSensoryRemapping(newRow.ingredientId, newRow.correctedIngredientId);
      return newRow;
    },
    [updateSensoryRemapping],
  );

  const alignTextCell = getDataGridCellAlignment('text');
  const alignAnyCell = getDataGridCellAlignment('any');
  const alignNumericCell = getDataGridCellAlignment('numeric');

  /**
   * This callback returns the class name of the row.
   * If there is something wrong with a row, to highlight it.
   */
  const getRowClassName = useCallback<Required<DataGridProps>['getRowClassName']>(
    ({ row }) => {
      const message = getRowErrorMessage(row);
      switch (message?.severity) {
        case 'error':
          return 'CorrectionTable__errorRow';
        case 'warning':
          return 'CorrectionTable__warningRow';
        case 'info':
          return 'CorrectionTable__infoRow';
        default:
          return '';
      }
    },
    [getRowErrorMessage],
  );

  return (
    <Box className="RunConfig__stepContainer">
      <Box className="RunConfig__stepTitle">
        <Typography>
          Every ingredient needs sensory information if we want to use it for liking. We do not have sensory information
          for all our ingredients. Map ingredients without sensory to ingredients with sensory information.
        </Typography>
      </Box>
      <IngredientTable
        identifier="ingredientId"
        loading={dataSourcesLoading}
        ingredients={selectedIngredientsWithCorrections}
        checkboxSelection={false}
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{ newEditingApi: true }}
        isReadOnly={isReadOnly}
        columns={[
          {
            ...alignAnyCell,
            field: 'message',
            headerName: 'Issues',
            cellClassName: 'message',
            hideSortIcons: true,
            filterable: true,
            filterOperators: [
              {
                label: 'Hide Empty',
                value: 'hide',
                getApplyFilterFn: ({ columnField, operatorValue }) => {
                  if (!columnField || operatorValue !== 'hide') {
                    return null;
                  }

                  return ({ value }) => !!value?.message;
                },
              },
            ],
            valueGetter: ({ row }) => getRowErrorMessage(row),
            renderCell: ({ value }) => (
              <Box component="span">
                {value && (
                  <Tooltip title={value?.message}>
                    <Alert color={value?.severity} severity={value?.severity} variant="outlined" />
                  </Tooltip>
                )}
              </Box>
            ),
          },
          {
            ...alignNumericCell,
            field: 'ingredientId',
            minWidth: 150,
            headerName: 'Ingredient ID',
            resizable: false,
          },
          {
            ...alignTextCell,
            field: 'name',
            minWidth: 200,
            headerName: 'Ingredient Name',
            valueGetter: ({ row }) => ingredientDBMap.searchIngredientById(row.ingredientId)?.name,
          },
          {
            ...alignNumericCell,
            field: 'refIngrId',
            minWidth: 200,
            headerName: 'Ref Ingredient ID',
            valueGetter: ({ row }) => lookupReferenceIngredient(row.ingredientId, ingredientDBMap)?.ingredientId,
          },
          {
            ...alignTextCell,
            field: 'refIngrName',
            minWidth: 200,
            headerName: 'Ref Ingredient Name',
            valueGetter: ({ row }) => lookupReferenceIngredient(row.ingredientId, ingredientDBMap)?.name,
          },
          {
            ...alignNumericCell,
            field: 'correctedIngredientId',
            minWidth: 200,
            headerName: 'Corrected ID',
            editable: !isReadOnly,
            renderEditCell: (params) => (
              <>
                {params.row.correctable && (
                  <CorrectedIngredientAutoComplete
                    ingredientMap={sensoryIngredientsMap}
                    correctedIdKey="correctedIngredientId"
                    cellParams={params}
                  />
                )}
                {!params.row.correctable && <Typography>Remapping not allowed</Typography>}
              </>
            ),
            renderCell: (params) => {
              if (params.value) {
                return (
                  <>
                    <p>{params.value}</p>
                    <Button
                      data-id-cypress="removeCorrectedIdButton"
                      onClick={() => {
                        updateSensoryRemapping(params.row.ingredientId, undefined);
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                  </>
                );
              }
              return '';
            },
            renderHeader: () => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip title="Edit Corrected ID of Lemma Mapping">
                  <Box display="flex" alignItems="center" component="span">
                    <Typography>Corrected Id</Typography>
                    {!isReadOnly && (
                      <CreateIcon
                        className="editableHeaderIcon"
                        sx={{
                          transform: 'rotate(-18deg)',
                          fontSize: '1.8rem',
                          color: 'warning.main',
                        }}
                        color="inherit"
                        fontSize="small"
                        titleAccess="Edit Corrected ID"
                      />
                    )}
                  </Box>
                </Tooltip>
              </div>
            ),
          },
          {
            ...alignTextCell,
            field: 'createdAt',
            headerName: 'Creation Time',
            minWidth: 200,
            cellClassName: 'creation_time',
            renderCell: (params) => <p>{params.value && timestampToLocalDateSting(params.value)}</p>,
          },
          {
            ...alignTextCell,
            field: 'project',
            headerName: 'Project',
            minWidth: 200,
            cellClassName: 'project',
          },
          {
            ...alignTextCell,
            field: 'correctedIngrName',
            minWidth: 200,
            headerName: 'Corrected Name',
            valueGetter: ({ row }) => ingredientDBMap.searchIngredientById(row.correctedIngredientId)?.name,
          },
          {
            ...alignNumericCell,
            field: 'correctedRefIngrId',
            minWidth: 200,
            headerName: 'Corrected Ref. ID',
            valueGetter: ({ row }) =>
              lookupReferenceIngredient(row.correctedIngredientId, ingredientDBMap)?.ingredientId,
          },
          {
            ...alignTextCell,
            field: 'correctedRefIngrName',
            minWidth: 200,
            headerName: 'Corrected Ref. Name',
            valueGetter: ({ row }) => lookupReferenceIngredient(row.correctedIngredientId, ingredientDBMap)?.name,
          },
        ]}
        headerHeight={76}
        autoHeight={false}
        rowHeight={80}
        className="CorrectionTable__grid"
        getRowClassName={getRowClassName}
      />
    </Box>
  );
};

export default SensoryRemappingStep;
