import React from 'react';

import { useFetchWithAuth } from '../../../auth';
import { buildActionUrl, TYPE_VIEW_JOB_STATUS } from '../../../shared/url';
import { JobStatus, JobType } from '../../../types';
import { useJobNotification } from '../components/JobNotification';

type WatchJobHookType = {
  jobNotification: JobType | null;
  handleCloseJobNotification: (event?: React.SyntheticEvent | Event, reason?: string) => void;
};

const useWatchJob = (
  projectId: string,
  outputName: string,
  jobId: string | null,
  initialJobStatus: JobStatus,
  updateJobStatus: (outputName: string, jobStatus: JobType) => void,
  pollInterval = 5000,
): WatchJobHookType => {
  const { jobNotification, handleJobNotification, handleCloseJobNotification } = useJobNotification();
  const fetchWithAuth = useFetchWithAuth();
  const finished =
    initialJobStatus === JobStatus.Failed ||
    initialJobStatus === JobStatus.Ok ||
    initialJobStatus === JobStatus.Aborted;
  // load Job status
  const loadJobData = React.useCallback(() => {
    if (jobId) {
      const jobStatusUrl = buildActionUrl({ projectId, jobId }, TYPE_VIEW_JOB_STATUS);
      fetchWithAuth(jobStatusUrl, {}).then(async ({ error, data: jobInfo }) => {
        if (!error) {
          const jobStatus: JobType = { ...jobInfo, jobId };
          updateJobStatus(outputName, jobInfo);
          if (jobInfo.status === 'JOB_STATUS_FAILED') {
            handleJobNotification(jobStatus); // job info does not include job id
          } else {
            handleJobNotification(null);
          }
        } else {
          console.log('Error: Fetching job status failed', error);
        }
      });
    } else {
      handleJobNotification(null);
    }
  }, [projectId, jobId, outputName, fetchWithAuth, handleJobNotification, updateJobStatus]);

  const timer = React.useRef<any>(); // fixme
  React.useEffect(() => {
    if (!finished && jobId) {
      loadJobData();
      timer.current = setInterval(() => loadJobData(), pollInterval);
    } else if (timer.current !== null) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    return () => {
      if (timer.current !== null) {
        clearTimeout(timer.current);
        timer.current = null;
      }
    };
  }, [finished, jobId, loadJobData, pollInterval]);
  return { jobNotification, handleCloseJobNotification };
};

export default useWatchJob;
