import axios, { AxiosResponse } from 'axios';

import { AromaType } from '../../../admin/lab-dataviz';
import {
  buildActionUrl,
  TYPE_ADD_NEW_AROMA_PROFILE_VARIANT,
  TYPE_AROMA_DATA_BY_PRODUCT_PREP_ID,
  TYPE_LIST_AROMA_PROFILES,
  TYPE_UPDATE_AROMA_PROFILE_VARIANT,
} from '../../../shared/url';
import { boostNotEmptyRows } from '../conversion';
import {
  AromaDataType,
  AromaDataTypeFields,
  AromaRecord,
  AromaWheelResponse,
  DotGraphFormRecord,
  IngredientProfileRecord,
} from '../types';
import { getAvailableDataTypes } from '../utils';
import { AromaTableProfile, AromaVariantsResponse, SetDotGraphRows, SetIngredientAromaVizRows } from './types';

export type IngredientWithAromaTypes = Omit<DotGraphFormRecord, 'aromaRecords'> & {
  aromaRecords: (Omit<AromaRecord, 'type'> & { type: AromaType })[];
};

export async function fetchIngredientAromaVizRows(
  version: string,
  productPreparationId: number,
  profileName?: string,
  dataType?: AromaDataTypeFields,
): Promise<SetIngredientAromaVizRows> {
  const url = buildActionUrl({ version, productPreparationId, profileName }, TYPE_AROMA_DATA_BY_PRODUCT_PREP_ID);
  const { profile: { data } = {} } = await axios.get(url).then((res: AxiosResponse) => res.data as AromaWheelResponse);

  const dataTypes = dataType
    ? [dataType as AromaDataType]
    : getAvailableDataTypes<IngredientProfileRecord>(data?.records || []);

  return {
    aromaVizRows: convertAromaRecordsToTableRows(productPreparationId, data?.records || [], dataTypes[0]),
    type: dataTypes[0],
  };
}

export function convertAromaRecordsToTableRows(
  productPreparationId: number,
  records: IngredientProfileRecord[],
  dataType: AromaDataTypeFields,
): AromaRecord[] {
  return boostNotEmptyRows(
    records.map((row, id) => ({
      ...row,
      originalFd: row?.fd?.original,
      fd: typeof row?.fd?.override === undefined ? row?.fd?.original : row?.fd?.override,
      originalOav: row?.oav?.original,
      oav: typeof row?.oav?.override === undefined ? row?.oav?.original : row?.oav?.override,
      productPreparationId,
      id,
    })),
    dataType,
  );
}

export async function fetchIngredientDotGraphRows(
  version: string,
  ingredient: DotGraphFormRecord,
  profileName?: string,
): Promise<SetDotGraphRows> {
  const iRow = {
    ...ingredient,
    aromaTypes: [],
    aromaTypeToMolecules: {},
  } as IngredientWithAromaTypes;

  if (!iRow.aromaRecords && iRow.id) {
    const { aromaVizRows } = await fetchIngredientAromaVizRows(version, iRow.id, profileName);
    iRow.aromaRecords = (aromaVizRows || []) as (Omit<AromaRecord, 'type'> & {
      type: AromaType;
    })[];
  }

  iRow.aromaRecords?.forEach(({ type, molecules: mols = [] }) => {
    if (!iRow.aromaTypes.includes(type)) {
      iRow.aromaTypes.push(type);
    }

    iRow.aromaTypeToMolecules[type] = [...(iRow.aromaTypeToMolecules[type] || []), ...mols.map((m) => m.moleculeId)];
  });

  return {
    dotGraphMainIngredient: iRow?.rowPosition === -1 ? iRow : null,
    addDotGraphOtherIngredient: iRow?.rowPosition > -1 ? iRow : undefined,
  };
}

export async function postAromaVariant(
  version: string,
  productPreparationId: number,
  profile: AromaTableProfile,
  update = false,
): Promise<AromaTableProfile> {
  const type = update ? TYPE_UPDATE_AROMA_PROFILE_VARIANT : TYPE_ADD_NEW_AROMA_PROFILE_VARIANT;
  const records = (profile?.data?.records || []).map(({ typeId, descriptorId, originalFd, fd, originalOav, oav }) => ({
    typeId,
    descriptorId,
    fd: fd ? { originalOverriddenValue: Number(originalFd), override: Number(fd) } : undefined,
    oav: oav
      ? {
          originalOverriddenValue: Number(originalOav),
          override: Number(oav),
        }
      : undefined,
  }));
  const url = buildActionUrl({ version, productPreparationId, profileName: profile?.name }, type);
  return axios.post(url, {
    coreVersion: version,
    productPreparationId,
    variantName: update ? profile?.name : undefined,
    overrides: update ? records : undefined,
    profile: update ? undefined : { ...profile, data: { ...(profile.data || {}), records } },
  });
}

export async function getAromaVariants(version: string, productPreparationId: number): Promise<AromaVariantsResponse> {
  const url = buildActionUrl({ version, productPreparationId }, TYPE_LIST_AROMA_PROFILES);
  return axios.get(url).then((res) => res.data);
}
