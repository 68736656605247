import { CategoryType, SocialTagErrorItem } from '../../../types';

export interface SocialPostTagsWithCategory {
  category: string;
  posts: { tag: string }[];
}

export interface SocialPostTagsSorted extends SocialPostTagsWithCategory {
  items: string[];
}

/**
 * Get array of category names from social post categories array.
 *
 * @param socialPostsCategories
 */
export function getCategoryNames(socialPostsCategories: { [name: string]: CategoryType }): string[] {
  return Object.entries(socialPostsCategories).map(([name]) => name);
}

/**
 * Prepare available tags for category and resort them.
 *
 * @param tags
 * @param socialErrors
 */
export function prepareAvailableTags(
  tags: SocialPostTagsWithCategory[],
  socialErrors: SocialTagErrorItem[] | null,
): SocialPostTagsSorted[] {
  return tags.map((checked) => {
    const categoryTags = checked.posts.map((i) => i.tag);
    const catErrors = socialErrors?.find((error) => error.category === checked.category) || null;

    return { ...checked, items: boostErrorTagsToTop(categoryTags, catErrors) };
  });
}

/**
 * Resort category tags and boost tags with errors to top of list.
 *
 * @param categoryTags
 * @param catErrors
 */
export function boostErrorTagsToTop(categoryTags: string[], catErrors: SocialTagErrorItem | null): string[] {
  const bottom: string[] = [];
  const top: string[] = [];

  if (catErrors !== null) {
    categoryTags.forEach((item) => {
      if (catErrors.tags.includes(item)) {
        top.push(item);
        return;
      }

      bottom.push(item);
    });

    return [...top, ...bottom];
  }

  return categoryTags;
}
