import Box from '@mui/material/Box';
import React from 'react';

import { DataMan } from '../../../../../components/dataman';
import { ColumnIdentifier, DataManBuildArg } from '../../../../../components/dataman/types';
import { ProjectType } from '../../../../../types';
import { RunType } from '../../_utils/types';
import {
  RenderChangedAtCell,
  RenderChangedByCell,
  RenderGenerateConceptInputCell,
  RenderGenerateConceptOutputCell,
  RenderNameCell,
} from '../_utils/cell-renderer';
import { ConceptGenerationDatamanRecord } from '../_utils/types';

export interface ConceptGenerationRunTableArgs extends DataManBuildArg<ConceptGenerationDatamanRecord> {
  records: ConceptGenerationDatamanRecord[];
  loading: boolean;
  updateView: () => Promise<void>;
  updateRun: (run: RunType) => void;
  runs: RunType[] | undefined;
  simulateFail: boolean;
  project: ProjectType | null;
  isPhaseReadOnly: boolean;
}

const ConceptGenerationRunTable: React.FC<ConceptGenerationRunTableArgs> = ({
  records,
  loading,
  updateView,
  updateRun,
  simulateFail,
  project,
  runs = [],
  isPhaseReadOnly = false,
}) => {
  const list = {
    columns: {
      name: {
        label: 'Name',
        width: '100px',
        renderRowCell: (cellIdentifier: ColumnIdentifier, value: string) => RenderNameCell(cellIdentifier, value),
      },
      startedAt: {
        label: 'Started at',
        width: '100px',
        renderRowCell: (cellIdentifier: ColumnIdentifier, value: string) => RenderChangedAtCell(cellIdentifier, value),
      },
      startedBy: {
        label: 'Started by',
        width: '150px',
        renderRowCell: (cellIdentifier: ColumnIdentifier, value: string) => RenderChangedByCell(cellIdentifier, value),
      },
      ingredients: {
        label: 'Concepts generation input',
        width: '300px',
        renderRowCell: (cellIdentifier: ColumnIdentifier, value: any, row: ConceptGenerationDatamanRecord) =>
          RenderGenerateConceptInputCell(
            cellIdentifier,
            row,
            runs,
            updateView,
            simulateFail,
            updateRun,
            project,
            isPhaseReadOnly,
            true,
          ),
      },
      concepts: {
        label: 'Concepts generation output',
        width: '500px',
        renderRowCell: (cellIdentifier: ColumnIdentifier, value: any, row: ConceptGenerationDatamanRecord) =>
          RenderGenerateConceptOutputCell(
            cellIdentifier,
            row,
            runs,
            updateView,
            simulateFail,
            updateRun,
            project,
            isPhaseReadOnly,
          ),
      },
    },
  };

  return (
    <Box data-id-cypress="ConceptGenerationRunTable" mt={6}>
      <DataMan loading={loading} records={records} list={list} key={`data-tables-${JSON.stringify(records)}`} />
    </Box>
  );
};

export default ConceptGenerationRunTable;
