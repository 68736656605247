import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ShowConceptGenWorkflow } from '../../../admin/projects/ProjectFlow/ConceptGeneration/_utils/types';
import { BackendLoadingStatus } from '../types/common';
import {
  ConceptGenerationWFStepsV2,
  ConceptGenerationWFStepsV3,
  IngredientType,
  SelectedIngredient,
} from '../types/concept-generation';

export interface ConceptGenerationWorkflowCommonState {
  // to help with screens and navigation
  activeStep: ConceptGenerationWFStepsV2 | ConceptGenerationWFStepsV3;
  showWorkflow: ShowConceptGenWorkflow;
  ingredientsLoadingStatus: BackendLoadingStatus;

  // actual data
  inputTable: string | null;
  initialIngredients: SelectedIngredient[];
}

export const defaultConceptGenerationWFCommonState: ConceptGenerationWorkflowCommonState = {
  activeStep: ConceptGenerationWFStepsV2.SelectIngredients,
  showWorkflow: ShowConceptGenWorkflow.hide,
  ingredientsLoadingStatus: BackendLoadingStatus.Initial,
  inputTable: null,
  initialIngredients: [],
};

export const SLICE_CONCEPT_GENERATION_COMMON = 'concept_generation_common';
export const conceptGenerationCommonSlice = createSlice({
  name: SLICE_CONCEPT_GENERATION_COMMON,
  initialState: defaultConceptGenerationWFCommonState,
  reducers: {
    resetConceptGenerationConfig: (state) => {
      state.showWorkflow = ShowConceptGenWorkflow.hide;
      state.activeStep = ConceptGenerationWFStepsV2.SelectIngredients;
      state.ingredientsLoadingStatus = BackendLoadingStatus.Initial;
    },
    setIngredientsLoadingStatus: (state, action: PayloadAction<BackendLoadingStatus>) => {
      state.ingredientsLoadingStatus = action.payload;
    },
    showConfigurationWorkflow: (state, action: PayloadAction<ShowConceptGenWorkflow>) => {
      state.showWorkflow = action.payload;
    },
    setActiveStep: (state, action: PayloadAction<ConceptGenerationWFStepsV2>) => {
      state.activeStep = action.payload;
    },
    setInitialIngredients: (
      state,
      {
        payload: { ingredients, inputTable },
      }: PayloadAction<{
        ingredients: SelectedIngredient[];
        inputTable: string;
      }>,
    ) => {
      ingredients.map((ingredient) => {
        ingredient.class = ingredient.class || IngredientType.other;
        return ingredient;
      });
      state.initialIngredients = ingredients;
      state.inputTable = inputTable;
    },
  },
});

export default conceptGenerationCommonSlice.reducer;
export const {
  setInitialIngredients,
  setIngredientsLoadingStatus,
  resetConceptGenerationConfig,
  setActiveStep,
  showConfigurationWorkflow,
} = conceptGenerationCommonSlice.actions;
