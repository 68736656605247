import { NavigateNext, Search, SearchOff, Warning } from '@mui/icons-material';
import { Button, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC, useCallback, useEffect, useState } from 'react';

import { DishType } from '../features/concepts/types';

interface SearchTreeSelectProps {
  nodeList: any[];
  onSelectionChange: any;
  fieldLabel?: string;
  disabled?: boolean;
  preSelected?: any[];
}

const SearchTreeSelect: FC<SearchTreeSelectProps> = ({
  nodeList,
  onSelectionChange,
  fieldLabel,
  disabled,
  preSelected,
}) => {
  const [searchString, setSearchString] = useState<string>('');
  const [nodesToDisplay, setNodesToDisplay] = useState<any[]>([]);
  const [selectedDishTypes, setSelectedDishTypes] = useState<DishType[]>(() => []);
  const [collapsedDishTypes, setCollapsedDishTypes] = useState<DishType[]>(() => []);
  const [showAllNodes, setShowAllNodes] = useState<boolean>(false);
  const [displayOnlySelectedNodes, setDisplayOnlySelectedNodes] = useState<boolean>(true);
  const [displaySearchedNodes, setDisplaySearchedNodes] = useState<boolean>(false);

  // Filter to return the leaf nodes in a given array of DishType
  const deepFilter = useCallback(
    (nodes: DishType[], callback: any) =>
      nodes
        ?.map((node: DishType) => {
          if (callback(node)) return node;
          const subTypes: any = deepFilter(node.subTypes || [], callback);
          return subTypes.length && { ...node, subTypes };
        })
        .filter(Boolean),
    [],
  );

  const findNodeInList = useCallback((node: any, id: string): DishType | undefined => {
    if (node.dishTypeId === id) return node;
    if (node.subTypes) {
      return node.subTypes.reduce((result: any, n: any) => result || findNodeInList(n, id), undefined);
    }
    return undefined;
  }, []);

  // A method that checks if the node is checked, and if so, adds it to the selectedDishTypes array, but if not, removes it
  const checkNodeState = useCallback(
    (node: DishType) => {
      const id = selectedDishTypes.findIndex((n) => n.dishTypeId === node.dishTypeId);
      if (node.checked) {
        if (id === -1) {
          selectedDishTypes.push(node);
          setSelectedDishTypes([...selectedDishTypes]);
        }
      } else if (id !== -1) {
        selectedDishTypes.splice(id, 1);
        setSelectedDishTypes([...selectedDishTypes]);
      }
    },
    [selectedDishTypes],
  );

  // Method used to check all node state and put the checked and/or indeterminate state on the nodes
  const checkAllNodesState = useCallback(
    (nodesToCheck: DishType[]) => {
      nodesToCheck.forEach((displayNode: DishType) => {
        if (displayNode.subTypes) {
          // Go see the leaf node first
          checkAllNodesState(displayNode.subTypes);
          // Check states if subTypes are checked or indeterminate
          const subtypesChecked = [];
          const subtypesIndeterminate = [];

          displayNode.subTypes.forEach((subnode: DishType) => {
            if (subnode.checked) {
              subtypesChecked.push(subnode.dishTypeId);
            }
            if (subnode.indeterminate) {
              subtypesIndeterminate.push(subnode.dishTypeId);
            }
          });
          if (subtypesChecked.length > 0) {
            if (subtypesChecked.length < displayNode.subTypes.length) {
              // If some are checked and some are not
              displayNode.checked = false;
              displayNode.indeterminate = true;
            } else {
              // If all are checked
              displayNode.checked = true;
              displayNode.indeterminate = false;
            }
          } else if (subtypesIndeterminate.length > 0) {
            // If some are indeterminate
            displayNode.checked = false;
            displayNode.indeterminate = true;
          } else {
            // If none are checked or indeterminate
            displayNode.checked = false;
            displayNode.indeterminate = false;
          }
        }
        checkNodeState(displayNode);
      });
    },
    [checkNodeState],
  );

  const search = useCallback(() => {
    setDisplaySearchedNodes(true);
    setShowAllNodes(false);
    setDisplayOnlySelectedNodes(false);
    checkAllNodesState(nodeList);

    const filteredNodes = deepFilter(
      nodeList,
      (node: DishType) => node?.label?.toLowerCase().includes(searchString.toLowerCase()),
    );
    setNodesToDisplay(filteredNodes);
  }, [nodeList, searchString, deepFilter, checkAllNodesState]);

  const checkSelectedNodesInList = useCallback((nodes: DishType[], comparisonArray: DishType[]) => {
    nodes?.forEach((node) => {
      const id = comparisonArray.findIndex((compareNode) => compareNode.dishTypeId === node.dishTypeId);
      if (!node.subTypes) {
        if (id !== -1) {
          node.checked = true;
        } else {
          node.checked = false;
        }
      } else {
        checkSelectedNodesInList(node.subTypes, comparisonArray);
      }
    });
  }, []);

  // Will handle the checking of leaf nodes or nodes with subTypess
  const handleNodeCheck = useCallback(
    (node: DishType, checkValue: boolean) => {
      // node.checked = checkValue;
      // handle leaf node
      if (!node.subTypes) {
        const id = selectedDishTypes.findIndex((searchNode) => searchNode.dishTypeId === node.dishTypeId);
        if (checkValue) {
          if (id === -1) {
            selectedDishTypes.push(node);
            setSelectedDishTypes([...selectedDishTypes]);
          }
        } else if (id !== -1) {
          selectedDishTypes.splice(id, 1);
          setSelectedDishTypes([...selectedDishTypes]);
        }
        checkSelectedNodesInList(nodeList, selectedDishTypes);
      } else {
        // handles nodes with child nodes
        const subTypesChecked = [];
        const compareNode = findNodeInList(nodeList[0], node.dishTypeId);

        compareNode?.subTypes?.forEach((childNode) => {
          // handle the leaf node first
          handleNodeCheck(childNode, checkValue);
          if (checkValue) {
            subTypesChecked.push(childNode.dishTypeId);
          }
        });
        // if Some subTypes are checked
        const id = selectedDishTypes.findIndex((searchNode) => searchNode.dishTypeId === node.dishTypeId);
        if (compareNode?.subTypes) {
          if (subTypesChecked.length > 0 && subTypesChecked.length < compareNode.subTypes.length) {
            if (id === -1) {
              selectedDishTypes.push(node);
              setSelectedDishTypes([...selectedDishTypes]);
            }
          }
        }
      }
      checkAllNodesState(nodeList);
      if (displaySearchedNodes) {
        search();
      }
    },
    [
      checkAllNodesState,
      nodeList,
      checkSelectedNodesInList,
      findNodeInList,
      selectedDishTypes,
      displaySearchedNodes,
      search,
    ],
  );

  const checkCollapsedNodesInList = useCallback((nodes: DishType[], comparisonArray: DishType[]) => {
    nodes?.forEach((node) => {
      const id = comparisonArray.findIndex((compareNode) => compareNode.dishTypeId === node.dishTypeId);
      if (!node.subTypes) {
        if (id !== -1) {
          node.collapsed = true;
        } else {
          node.collapsed = false;
        }
      } else {
        checkCollapsedNodesInList(node.subTypes, comparisonArray);
      }
    });
  }, []);

  const collapseNode = useCallback(
    (node: DishType) => {
      node.collapsed = !node.collapsed;
      if (node.collapsed) {
        collapsedDishTypes.push(node);
        setCollapsedDishTypes([...collapsedDishTypes]);
      } else {
        const id = collapsedDishTypes.findIndex((searchNode) => searchNode.dishTypeId === node.dishTypeId);
        collapsedDishTypes.splice(id, 1);
        setCollapsedDishTypes([...collapsedDishTypes]);
      }
      setNodesToDisplay([...nodesToDisplay]);
    },
    [nodesToDisplay, collapsedDishTypes],
  );

  const showOnlyCheckedNodes = useCallback(() => {
    setShowAllNodes(false);
    setDisplaySearchedNodes(false);
    setDisplayOnlySelectedNodes(true);
    checkAllNodesState(nodeList);
    const checkedNodes = deepFilter(nodeList, (node: DishType) => node.checked);
    setNodesToDisplay(checkedNodes);
  }, [deepFilter, nodeList, checkAllNodesState]);

  useEffect(() => {
    onSelectionChange(selectedDishTypes);
  }, [selectedDishTypes, onSelectionChange]);

  useEffect(() => {
    checkCollapsedNodesInList(nodeList, collapsedDishTypes);
  }, [collapsedDishTypes, checkCollapsedNodesInList, nodeList]);

  useEffect(() => {
    if (preSelected && preSelected?.length > 0 && displayOnlySelectedNodes) {
      if (selectedDishTypes.length === 0) {
        setSelectedDishTypes(preSelected);
      }
      checkSelectedNodesInList(nodeList, selectedDishTypes);
      showOnlyCheckedNodes();
    }
  }, [
    preSelected,
    checkSelectedNodesInList,
    selectedDishTypes,
    showOnlyCheckedNodes,
    displayOnlySelectedNodes,
    nodeList,
  ]);

  const recursiveTreeRender = (nodes: any[]) =>
    nodes.map((node: any) => (
      <Box key={`${node.dishTypeId}_el`} data-id-cypress="dishTypeNode">
        <Box display={'flex'} key={`${node.dishTypeId}_container`}>
          {node.subTypes && (
            <Box
              key={`${node.dishTypeId}_arrow`}
              marginTop={1}
              marginRight={0.5}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                collapseNode(node);
              }}
            >
              <NavigateNext
                key={`${node.dishTypeId}_arrow_icon`}
                style={{
                  transition: 'transform 0.2s ease-in-out',
                }}
                transform={!node.collapsed ? 'rotate(90)' : ''}
              ></NavigateNext>
            </Box>
          )}
          <FormControlLabel
            label={node.label}
            key={`${node.dishTypeId}_label`}
            control={
              <Checkbox
                checked={node.checked || false}
                disabled={disabled}
                indeterminate={node.indeterminate || false}
                value={node.label}
                key={`${node.dishTypeId}_checkbox`}
                onChange={(value) => handleNodeCheck(node, value.target.checked)}
              ></Checkbox>
            }
          />
        </Box>
        {node.subTypes && !node.collapsed && (
          <Box borderLeft={'1px dashed grey'} marginLeft={1.5} paddingLeft={4.5} key={`${node.dishTypeId}_border`}>
            {recursiveTreeRender(node.subTypes)}
          </Box>
        )}
      </Box>
    ));

  return (
    <>
      <Box display={'flex'}>
        <TextField
          data-id-cypress="dishTypeSelector"
          disabled={disabled}
          fullWidth={true}
          variant="outlined"
          label={fieldLabel || ''}
          onChange={(e) => setSearchString(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              search();
            }
          }}
        ></TextField>
        <Button data-id-cypress="dishTypeNodeSearchButton" disabled={disabled} variant="contained" onClick={search}>
          <Search></Search>
        </Button>
      </Box>
      <Box padding={2}>
        <Box display={'flex'} justifyContent={'space-between'} marginBottom={1}>
          <Box display={'flex'}>
            <Box marginRight={1}>
              <Button variant={displayOnlySelectedNodes ? 'contained' : 'text'} onClick={showOnlyCheckedNodes}>
                <Typography>Show only selected dish types</Typography>
              </Button>
            </Box>
            <Box marginRight={1}>
              <Button
                variant={showAllNodes ? 'contained' : 'text'}
                onClick={() => {
                  setShowAllNodes(true);
                  setDisplayOnlySelectedNodes(false);
                  setDisplaySearchedNodes(false);
                  checkAllNodesState(nodeList);
                  setNodesToDisplay(nodeList);
                }}
              >
                <Typography>Show all nodes</Typography>
              </Button>
            </Box>
            <Button
              disabled={searchString.length <= 3}
              variant={displaySearchedNodes ? 'contained' : 'text'}
              onClick={() => {
                search();
              }}
            >
              <Typography>Show searched dish types</Typography>
            </Button>
          </Box>
        </Box>
        <Box maxHeight={500} style={{ overflowY: 'scroll' }}>
          {nodesToDisplay?.length > 0 && recursiveTreeRender(nodesToDisplay)}
          {nodesToDisplay?.length === 0 && (
            <>
              <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <SearchOff fontSize={'large'}></SearchOff>
                <Typography>Nothing matches your search :(</Typography>
              </Box>
              {searchString.length <= 3 && (
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'} color={'orange'}>
                  <Warning fontSize={'large'}></Warning>
                  <Typography>The search prompt needs to be at least 3 characters</Typography>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

SearchTreeSelect.defaultProps = {
  fieldLabel: '',
  disabled: false,
  preSelected: [],
};

export default SearchTreeSelect;
