import { combineReducers, configureStore, Middleware } from '@reduxjs/toolkit';
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';

import { aromaReducer, SLICE_AROMA_NAME } from '../features/aroma/store';
import { dataSourcesReducer, SLICE_DATASOURCE_NAME } from '../features/datasources/store';
import {
  conceptGenerationReducer,
  conceptGenerationReducerV2,
  conceptGenerationReducerV3,
  metricsCalculationReducer,
  projectWorkflowReducer,
  SLICE_CONCEPT_GENERATION,
  SLICE_CONCEPT_GENERATION_V2,
  SLICE_CONCEPT_GENERATION_V3,
  SLICE_METRICS_CALCULATION,
  SLICE_PROJECT_WORKFLOW,
} from '../features/project/store';
import rootSaga from './root-saga';

const rootReducer = combineReducers({
  [SLICE_DATASOURCE_NAME]: dataSourcesReducer,
  [SLICE_AROMA_NAME]: aromaReducer,
  [SLICE_PROJECT_WORKFLOW]: projectWorkflowReducer,
  [SLICE_METRICS_CALCULATION]: metricsCalculationReducer,
  [SLICE_CONCEPT_GENERATION]: conceptGenerationReducer,
  [SLICE_CONCEPT_GENERATION_V2]: conceptGenerationReducerV2,
  [SLICE_CONCEPT_GENERATION_V3]: conceptGenerationReducerV3,
});

const sagaMiddleware: SagaMiddleware<object> = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false, serializableCheck: false }).prepend(sagaMiddleware as Middleware),
});

sagaMiddleware.run(rootSaga);

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
