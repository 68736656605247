import { DataManProperties } from '../../../../components/dataman/types';
import { SORT_DIRECTION_DESC } from '../../../../shared/filter-sort-query';
import { TYPE_PROJECT_SHOW, TYPE_SHOW_PROJECT_JOB } from '../../../../shared/url';
import { renderRowCell } from './cell-renderer';
import { JobListDataManBuildArg, JobListDataManRecord } from './types';

export function buildStructure(args: JobListDataManBuildArg): DataManProperties<JobListDataManRecord> {
  const { icons, history, dataHandler, records, loadMoreEnabled } = args;

  return {
    dataHandler,
    records,
    list: {
      sort: [{ field: 'createdAt', direction: SORT_DIRECTION_DESC }],
      columns: {
        projectId: {
          label: 'Project id',
        },
        jobId: {
          label: 'Job id',
          renderRowCell,
        },
        workflowStepId: {
          label: 'Step',
          renderRowCell,
        },
        jobType: {
          label: 'Job type',
          renderRowCell,
        },
        createdAt: {
          label: 'Created',
          renderRowCell,
        },
        status: {
          label: 'Status',
          renderRowCell,
        },
        startedByUser: {
          label: 'Started by',
          renderRowCell,
        },
      },
      actions: [
        {
          buttonIcon: icons[TYPE_SHOW_PROJECT_JOB],
          tooltip: 'Show job',
          handleAction: (action, record) => {
            history?.(`/projects/${record.projectId}/jobs/${record.jobId}`);
          },
        },
        {
          buttonIcon: icons[TYPE_PROJECT_SHOW],
          tooltip: 'Show project workflow step',
          handleAction: (action, record) => {
            let url;
            if (record.phaseId && record.runId) {
              url = `/projects/${record.projectId}/phases/${record.phaseId}/runs/${record.runId}`;
            } else {
              url = `/projects/${record.projectId}/show`;
            }
            if (history) {
              history(url);
            }
          },
        },
      ],
      useLoadMore: true,
      loadMoreEnabled,
    },
  };
}
