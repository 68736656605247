// concept generation config types
import { GridRowId, GridRowModel } from '@mui/x-data-grid';
import { FC, ReactNode } from 'react';

export enum InputGenerationType {
  Sheets = 'SHEETS',
  Wizard = 'WIZARD',
}

export interface GenericInputConfig {
  // snake_case for compatibility with backend
  input_type: InputGenerationType;
  version?: number;
  lemmas?: any[];
  is_complete?: boolean; // in a Request: indicates if the lemmas are complete after this call
  has_more_lemmas?: boolean; // in a response: indicates if there are more lemmas to be retrieved
}

export interface GenericInput {
  inputId?: string;
  config?: GenericInputConfig;
  offset?: string;
  limit?: number;
}

export interface GenericInputList {
  genericInputs?: GenericInput[];
}

export interface GenericInputRequest {
  genericPhase: {
    createGenericInput?: GenericInput;
    updateGenericInput?: GenericInput;
    deleteGenericInput?: GenericInput;
    // for list operation, always use {} as input
    listGenericInput?: object;
    getGenericInput?: GenericInput;
  };
}

export interface GenericInputResponse {
  genericPhase: {
    createGenericInput?: { genericInput: GenericInput };
    updateGenericInput?: { genericInput: GenericInput };
    deleteGenericInput?: { genericInput: GenericInput };
    listGenericInput?: GenericInputList;
    getGenericInput?: { genericInput: GenericInput };
  };
}

export interface RunIdPayload {
  runId?: string;
  table?: string;
  newInputId?: string;
  newConfig?: any;
}

export interface RunGenericInputState extends GenericInput {
  readOnly: boolean;
  loading: boolean;
  error?: string;
}

export type RunConfigurationFlowState = {
  runId: string | undefined;
  loading: boolean;
  error: string | ReactNode | FC | null;
  runs: { [id: string]: RunGenericInputState };
};

// TODO: improve this type
export type MUIDataGridRow<I extends GridRowId, M = any> = GridRowModel &
  Record<string, M> & {
    id?: I;
  };
// TODO: improve this type
export type GenericDataGridRow = MUIDataGridRow<number, number | string | null>;

export enum BackendLoadingStatus {
  Initial = 'INITIAL',
  Loading = 'LOADING',
  Loaded = 'LOADED',
}
