import './ContextVersionsForm.scss';

import { Backdrop, Box, Button, CircularProgress, Paper, Tab, Tabs, Typography } from '@mui/material';
import axios, { AxiosResponse } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ErrorNotification from '../../../components/ErrorNotification';
import Loading from '../../../components/Loading';
import PageTitle from '../../../components/PageTitle';
import SearchTreeSelect from '../../../components/SearchTreeSelect';
import WarningIcon from '../../../components/WarningIcon';
import { useDataSources } from '../../../context/DataSourcesContext';
import { useLayout } from '../../../context/LayoutContext';
import { DishType } from '../../../features/concepts/types';
import { DocLink, DocLinkUrl, DocTypeEnum } from '../../../features/docs';
import { buildActionUrl, TYPE_CLONE_VERSION, TYPE_GET_CONTEXT_VERSION } from '../../../shared/url';
import { ContextVersionType } from '../../../types';
import { ContextStateType, LoadingActions, useContextReducer } from '../reducer';
import {
  getNewSocialError,
  isArchiveDisabled,
  isEditingDisabled,
  isLoading,
  isNoTagOrFiltersSelected,
  isNotInListSelected,
  isPublishDisabled,
  isSaveDisabled,
} from '../utils/flags';
import {
  archiveOrPublishVersion,
  checkSocialTags,
  LoadDataArguments,
  loadDishTypes,
  loadRecipeFilters,
  loadSelectedSocialPostsTagsCategories,
  loadSocialPostsCategories,
  setLoadingStatus,
  updateData,
} from '../utils/source';
import ArchiveContextVersionDialog from './ArchiveContextVersionDialog';
import MetaDataPanel from './Form/MetaDataPanel';
import TabPanel from './Form/TabPanel';
import PublishContextVersionDialog from './PublishContextVersionDialog';
import RecipeTagSelection from './RecipeTagSelection';
import SocialTagSelection from './SocialTagSelection';

interface ContextVersionTypeWithId extends ContextVersionType {
  id: string;
}

const ContextVersionsForm: React.FC<{
  contextId: string;
  contextVersionId?: string;
  mode?: 'clone' | 'edit' | 'create';
}> = ({ contextId, contextVersionId, mode = 'create' }) => {
  const history = useNavigate();
  const dataSources = useDataSources();

  const { setPageTitle } = useLayout();

  const [state, dispatch] = useContextReducer();

  const isEditing = mode === 'edit';
  const isCloning = mode === 'clone';

  const loading = isLoading(state);
  const disableSave = isSaveDisabled(state, isCloning);
  const disableArchive = isArchiveDisabled(state);
  const disablePublish = isPublishDisabled(state);
  const disableEditing = isEditingDisabled(state);
  const disableBack = state.isSaving;

  const cloningPageTitle = isCloning
    ? `Cloning context version ${contextVersionId} in ${contextId}`
    : `Create context version for ${contextId}`;
  const pageTitle = isEditing ? `Version ${contextVersionId}` : cloningPageTitle;
  const layoutTitle =
    isEditing || isCloning
      ? `Context ${contextId} - Version ${contextVersionId}`
      : `Create context version for ${contextId}`;
  const cloneBtnLabel = isCloning ? 'Clone' : 'Create';
  const saveBtnLabel = isEditing ? 'Save' : cloneBtnLabel;
  const [dishTypeList, setDishTypeList] = useState<DishType[]>([]);
  const [preSelectedDishTypes, setPreSelectedDishTypes] = useState<DishType[]>([]);
  const { recipeVersions, socialPostVersions, dishTypesVersions } = dataSources.versions;
  const [isLegacyInputContext, setIsLegacyInputContext] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [error, setError] = useState<boolean>(false);

  /* Callbacks */
  const initialize = React.useCallback(async () => {
    // Load versions
    let contextVersion: ContextVersionType | null = null;
    let selectedRecipeVersion: string | null = null;
    let selectedSocialVersion: string | null = null;
    let selectedDishTypesVersion: string | null = null;

    /**
     * We've a context version id.
     * Which means this is an editing or cloning form
     * We have to load the context data
     */
    if (contextVersionId) {
      const url = buildActionUrl({ contextId, contextVersionId }, TYPE_GET_CONTEXT_VERSION);
      const loadedContextVersion: {
        contextVersion: ContextVersionTypeWithId;
      } = await axios.get(url).then((res: AxiosResponse) => res.data);

      contextVersion = loadedContextVersion?.contextVersion;

      selectedRecipeVersion = contextVersion?.recipesVersion;
      selectedSocialVersion = contextVersion?.socialPostsVersion;
      selectedDishTypesVersion = contextVersion?.dishTypesVersion || null;

      if (!dishTypesVersions.includes(selectedDishTypesVersion as string)) selectedDishTypesVersion = null;

      if (selectedRecipeVersion || selectedSocialVersion) {
        setIsLegacyInputContext(true);
      }

      setPreSelectedDishTypes(contextVersion?.dishTypes);
    }

    /* If social or recipe version is not selected, select the highest ones */

    if (!selectedRecipeVersion) {
      selectedRecipeVersion = String(Math.max(...recipeVersions.map((rv) => Number(rv))));
    }

    if (!selectedSocialVersion) {
      selectedSocialVersion = String(Math.max(...socialPostVersions.map((sv) => Number(sv))));
    }

    if (!selectedDishTypesVersion) {
      selectedDishTypesVersion = String(Math.max(...dishTypesVersions.map((dtv) => Number(dtv))));
    }

    if (dataSources.loading) return;

    dispatch({
      type: 'loadState',
      data: {
        // Context version data if loaded
        ...contextVersion,

        // Recipe and Social versions
        recipeVersions,
        socialPostVersions,

        // Dishtype versions
        dishTypesVersions,

        // Recipe and Social selected items
        recipeSelections: contextVersion ? contextVersion.recipes || [] : [],
        socialPostsSelections: contextVersion ? contextVersion.socialPosts || [] : [],

        // Recipe and Social selected versions
        socialPostVersion: selectedSocialVersion || '',
        recipesVersion: selectedRecipeVersion || '',
        dishTypesVersion: selectedDishTypesVersion || '',

        // Saving a complete server version to match changes and get metadata
        contextVersion,
      } as ContextStateType,
    });

    if (contextVersion) {
      /* Set form initial data */
      dispatch({
        type: 'loadContextVersion',
        data: contextVersion,
      });
    }

    /* Set initial recipe version */
    dispatch({
      type: 'setRecipesVersion',
      data: { recipesVersion: selectedRecipeVersion },
    });

    /* Set initial social post version */
    dispatch({
      type: 'setSocialPostVersion',
      data: { socialPostVersion: selectedSocialVersion },
    });

    // Set initial dishtype version
    dispatch({
      type: 'setDishTypesVersion',
      data: { dishTypesVersion: selectedDishTypesVersion },
    });
  }, [
    contextId,
    contextVersionId,
    dispatch,
    recipeVersions,
    socialPostVersions,
    dishTypesVersions,
    dataSources.loading,
  ]);

  /* Effects */
  /* prepare argument for all data loading functions */
  const dataLoadArg: LoadDataArguments = { dispatch, state };
  useEffect(() => {
    dataLoadArg.state = state;
    // eslint-disable-next-line
  }, [state]);

  const runInitialize = useCallback(() => {
    setLoadingStatus(dispatch, LoadingActions.init, true);
    initialize().then(() => setLoadingStatus(dispatch, LoadingActions.init, false));
  }, [dispatch, initialize]);

  /* Initialize */
  useEffect(() => {
    runInitialize();
  }, [runInitialize]);

  useEffect(() => {
    setPageTitle(layoutTitle);
  }, [setPageTitle, layoutTitle]);

  /* Effect on Recipe version changed */
  useEffect(() => {
    loadRecipeFilters(dataLoadArg);
    // eslint-disable-next-line
  }, [state.recipesVersion]);

  /* Effect on Social Post category name changed */
  useEffect(() => {
    loadSelectedSocialPostsTagsCategories(dataLoadArg);
    // eslint-disable-next-line
  }, [state.selectSocialPostsCategoryName]);

  /* Effect on Dish Type version changed */
  useEffect(() => {
    loadDishTypes(dataLoadArg);
    // eslint-disable-next-line
  }, [state.dishTypesVersion]);

  useEffect(() => {
    setDishTypeList(state.dishTypesList);
    // eslint-disable-next-line
  }, [state.dishTypesList]);

  /* Effect on social media version changed */
  useEffect(() => {
    // initial load of categories for specified post version
    loadSocialPostsCategories(dataLoadArg);

    // check tags for categories
    checkSocialTags(dataLoadArg).then((r) => r);
    // eslint-disable-next-line
  }, [state.socialPostVersion]);

  /**
   * Effect on recipe tag add/remove.
   * Check for missing tags in recipe version
   */
  useEffect(() => {
    if (!state.recipesVersion) return;
    const withoutTagOrFiltersSelected = isNoTagOrFiltersSelected(state.recipeSelections, state.recipeFilters);

    if (withoutTagOrFiltersSelected) {
      dispatch({ type: 'setRecipesError', error: false });
      return;
    }

    if (state.recipeSelections && state.recipeSelections.length) {
      const notInList = isNotInListSelected(state.recipeSelections, state.recipeFilters);
      dispatch({
        type: 'setRecipesError',
        error: notInList !== undefined && notInList !== null,
      });
    }
  }, [state.recipesVersion, state.recipeFilters, state.recipeSelections, dispatch]);

  /**
   * Effect on social tag changes ( add/removing ).
   * This method is different from the one that checks on version change.
   * This only checks if any tags that contained an error have been removed.
   */
  useEffect(() => {
    if (state.socialErrors === null) return;

    const newSocialError = getNewSocialError(state.socialErrors, state.socialPostsSelections);

    if (state.socialErrors.length !== newSocialError?.length) {
      dispatch({
        type: 'setSocialErrors',
        errors: newSocialError?.length ? newSocialError : null,
      });
    }
  }, [state.socialPostsSelections, state.socialErrors, dispatch]);

  /* UI Actions */
  const handleChangeTab = (event: React.ChangeEvent<{}>, selectedTab: number) => {
    dispatch({ type: 'setSelectedTab', selectedTab });
  };

  const goBack = () => history(`/contexts/${contextId}/versions`, { replace: true });

  const createOrSave = () => {
    const callback = (url: string | undefined) => {
      if (isEditing) {
        runInitialize();
        return;
      }

      history(String(url), { replace: true });
    };

    updateData({
      ...dataLoadArg,
      callback,
      isEditing,
      contextId,
      contextVersionId,
    });
  };

  const onCloneVersion = () => {
    const url = buildActionUrl({ contextId, contextVersionId }, TYPE_CLONE_VERSION);

    axios
      .post(url, {})
      .then((res: AxiosResponse) => {
        history(`/contexts/${contextId}/versions/edit/${res.data.contextVersion.contextVersionId}`);
      })
      .catch((err) => {
        setErrorMessage(err.message);
        setError(true);
      });
  };

  const onPublishArchiveVersion = (isPublish: boolean): void => {
    archiveOrPublishVersion({
      ...dataLoadArg,
      callback: goBack,
      contextId,
      contextVersionId,
      dispatch,
      isPublish,
    });
  };

  const onDishTypeSelect = useCallback(
    (selection: DishType[]) => {
      dispatch({ type: 'setDishTypesSelection', data: selection });
    },
    [dispatch],
  );

  if (dataSources.loading) {
    return <Loading />;
  }

  return (
    <>
      <Box className="contextVersionsForm__formWrapper">
        <Backdrop className="contextVersionsForm__backdrop" open={loading}>
          <CircularProgress size={24} className="contextVersionsForm__buttonProgress" />
        </Backdrop>
        <PageTitle title={pageTitle} action={<DocLink link={DocLinkUrl[DocTypeEnum.EditCreateContextVersion]} />} />
        <Paper>
          {isLegacyInputContext && (
            <Tabs onChange={handleChangeTab} value={state.selectedTab}>
              <Tab label="Metadata" />
              <Tab label={<Box display="flex">{state.recipeError && <WarningIcon />} Recipes</Box>} />
              <Tab label={<Box display="flex">{state.socialErrors && <WarningIcon />} Social Media</Box>} />
            </Tabs>
          )}

          <TabPanel value={state.selectedTab} index={0}>
            <MetaDataPanel
              description={state.description}
              contextVersionStatus={state.contextVersion?.status}
              recipesVersion={state.recipesVersion}
              recipeVersions={state.recipeVersions}
              socialPostVersion={state.socialPostVersion}
              socialPostVersions={state.socialPostVersions}
              dishTypesVersion={state.dishTypesVersion}
              dishTypesVersions={state.dishTypesVersions}
              isLegacy={isLegacyInputContext}
              changedBy={state?.contextVersion?.resourceMetadata?.changedBy}
              changedAt={state?.contextVersion?.resourceMetadata?.changedAt}
              dispatch={dispatch}
              disabled={disableEditing}
            />
          </TabPanel>
          {isLegacyInputContext && (
            <>
              <TabPanel value={state.selectedTab} index={1}>
                <RecipeTagSelection
                  recipeSelections={state.recipeSelections}
                  recipeFilters={state.recipeFilters}
                  dispatch={dispatch}
                  disabled={disableEditing}
                />
              </TabPanel>

              <TabPanel value={state.selectedTab} index={2}>
                <SocialTagSelection
                  dispatch={dispatch}
                  socialErrors={state.socialErrors}
                  socialPostsSelections={state.socialPostsSelections}
                  selectedSocialPostsCategoryName={state.selectedSocialPostsCategoryName}
                  socialPostsCategories={state.socialPostsCategories}
                  disabled={disableEditing}
                />
              </TabPanel>
            </>
          )}
        </Paper>
        <Paper className="contextVersionsForm__footerActionsBar">
          <Button color="secondary" onClick={goBack} disabled={disableBack}>
            Back
          </Button>
          <Button
            onClick={isCloning ? onCloneVersion : createOrSave}
            disabled={disableSave}
            color={isEditing ? 'secondary' : 'primary'}
            variant="contained"
          >
            {saveBtnLabel}
            {state.isSaving && state.loadingTarget === 'SAVE' && (
              <CircularProgress size={24} className="contextVersionsForm__circularProgress" />
            )}
          </Button>
          {isEditing && (
            <Button
              onClick={() =>
                dispatch({
                  type: 'setOpenArchiveDialog',
                  openArchiveDialog: true,
                })
              }
              disabled={disableArchive}
              color="secondary"
              variant="contained"
            >
              Archive
              {state.isSaving && state.loadingTarget === 'ARCHIVE' && (
                <CircularProgress size={24} className="contextVersionsForm__circularProgress" />
              )}
            </Button>
          )}
          {isEditing && (
            <Button
              onClick={() =>
                dispatch({
                  type: 'setOpenPublishDialog',
                  openPublishDialog: true,
                })
              }
              disabled={disablePublish}
              color="primary"
              variant="contained"
            >
              Publish
              {state.isSaving && state.loadingTarget === 'PUBLISH' && (
                <CircularProgress size={24} className="contextVersionsForm__circularProgress" />
              )}
            </Button>
          )}
        </Paper>
        {state.openArchiveDialog && (
          <ArchiveContextVersionDialog
            handleClose={() =>
              dispatch({
                type: 'setOpenArchiveDialog',
                openArchiveDialog: false,
              })
            }
            contextVersion={state}
            archiveVersion={() => onPublishArchiveVersion(false)}
          />
        )}
        {state.openPublishDialog && (
          <PublishContextVersionDialog
            handleClose={() =>
              dispatch({
                type: 'setOpenPublishDialog',
                openPublishDialog: false,
              })
            }
            contextVersion={state}
            publishVersion={() => onPublishArchiveVersion(true)}
          />
        )}
        {!isLegacyInputContext && (
          <Box marginTop={5} marginBottom={6} className="contextVersionsForm__formWrapper">
            <Paper>
              <Box padding={3.2}>
                <Typography marginBottom={2}>Context input Dishtype</Typography>
                <SearchTreeSelect
                  nodeList={dishTypeList}
                  onSelectionChange={onDishTypeSelect}
                  fieldLabel={'Dishtype Selection'}
                  disabled={disableEditing}
                  preSelected={preSelectedDishTypes}
                ></SearchTreeSelect>
              </Box>
            </Paper>
          </Box>
        )}
      </Box>
      {error && <ErrorNotification error={errorMessage} handleClose={() => dispatch(setError(false))} />}
    </>
  );
};

ContextVersionsForm.defaultProps = {
  contextVersionId: undefined,
  mode: 'create',
};

export default ContextVersionsForm;
