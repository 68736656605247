import axios, { AxiosResponse } from 'axios';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';

import { buildActionUrl, TYPE_DATASOURCES_INGREDIENTS_BY_VERSIONS, TYPE_GET_INGREDIENT_DB } from '../../../shared/url';
import {
  DataSourceType,
  DBIngredientFields,
  GenericIngredientFields,
  GenericIngredientType,
  SensoryIngredientType,
  SortDirections,
} from '../../../types';
import { IngredientResponse } from '../store/types';
import { fetchBQTable } from './bigquery';

const DEFAULT_REQUEST_LIMIT = 1000;

/* eslint-disable no-await-in-loop */
export async function fetchIngredients<T extends GenericIngredientType>(
  version: string,
  sourceType: DataSourceType,
): Promise<IngredientResponse<T>> {
  const result = { ingredients: [] } as IngredientResponse<T>;
  let startAtId = null;

  if (sourceType === DataSourceType.IngredientDB) {
    do {
      const url = buildActionUrl({ version }, TYPE_GET_INGREDIENT_DB);
      const response = (await axios
        // ingredientDB
        .get(url, { params: { limit: DEFAULT_REQUEST_LIMIT, startAtId } })
        .then((res: AxiosResponse) => res.data)) as IngredientResponse<T>;
      result.ingredients = [...(result.ingredients || []), ...(response.ingredients || [])];
      startAtId = response.nextId;
    } while (startAtId && startAtId > 0);
  } else if (sourceType === DataSourceType.Core) {
    // core
    const url = buildActionUrl({ version }, TYPE_DATASOURCES_INGREDIENTS_BY_VERSIONS);
    const response = (await axios.get(url).then((res: AxiosResponse) => res.data)) as IngredientResponse<T>;
    result.ingredients = [...(result.ingredients || []), ...(response.ingredients || [])];
  }

  // ingredients sorted in alphabetical order by name
  return {
    ...(result || {}),
    ingredients: orderBy(
      uniqBy(
        result?.ingredients || [],
        sourceType === DataSourceType.IngredientDB
          ? DBIngredientFields.ingredientId
          : DBIngredientFields.productPreparationId,
      ),
      GenericIngredientFields.name,
      ['asc'],
    ),
  };
}

export async function fetchIngredientsSensory(version: string): Promise<IngredientResponse<SensoryIngredientType>> {
  const rawResponse = await fetchBQTable(
    'sensory',
    ['ingredient_ID', 'item_status'],
    {
      column: 'ingredient_id',
      direction: SortDirections.Ascending,
    },
    {
      column: 'item_status',
      operatorEquals: {
        value: 'FINISHED',
      },
    },
    undefined, // projecId not used here
    'core', // datasetId
    version, // source table version
  );

  const response = {
    ingredients: [],
  } as IngredientResponse<SensoryIngredientType>;

  if (rawResponse.data) {
    const ingredients = rawResponse.data.map((item: any) => ({
      ingredientId: item[0],
    }));
    response.ingredients = [...ingredients];
  }
  return response;
}
