import './AromaViz.scss';

import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import ErrorNotification from '../../components/ErrorNotification';
import Loading from '../../components/Loading';
import { useSelectIngredientDialog } from '../../components/SelectIngredientDialog';
import { useDataSources } from '../../context/DataSourcesContext';
import { imPatchIngredientJSON, toIngredient } from '../../features/aroma/conversion';
import { setError, setMainIngredient, SLICE_AROMA_NAME } from '../../features/aroma/store';
import { addAromaProfileVariant, loadAromaProfileVariants, loadDotGraphRow } from '../../features/aroma/store/reducer';
import { AromaDataType, AromaRecord, ConvertedIngredient } from '../../features/aroma/types';
import { getAvailableDataTypes } from '../../features/aroma/utils';
import { VersionList } from '../../features/datasources/DataSources';
import { changeCoreVersion, SLICE_DATASOURCE_NAME } from '../../features/datasources/store';
import { getIngredientsString, ingredientToString } from '../../shared/utils';
import { useAppDispatch, useAppSelector } from '../../store';
import { CoreIngredientType, DataSourceType, IngredientDBIngredientType } from '../../types';
import DotGraph from '../dotgraph/DotGraph';
import { ExportButton, mockData, scripts, VisualizationWrapperComponent } from '../lab-dataviz';
import AromaTable from './AromaTable';
import CreateNewVariantDialog from './Variant/CreateNewVariantDialog';
import DataTypeSelector from './Variant/DataTypeSelector';
import ProfileSelector from './Variant/ProfileSelector';

const QUERY_PARAM_PRODUCT_PREPARATION_ID = 'product_preparation_id';
const EXCLUDED_AROMA_WHEEL_TYPES = [
  scripts.ViewType.CustomIngredientViewV1,
  scripts.ViewType.CustomIngredientViewV2,
  scripts.ViewType.CustomTaxonomyView,
];

const { ViewType, patchTaxonomyJSON } = scripts;
const taxonomy = patchTaxonomyJSON(mockData.taxonomy);

function calculateHeight(offset: number, sidePanelClassName = '') {
  let y = offset;

  if (sidePanelClassName) {
    const sidePanel = document.getElementsByClassName(sidePanelClassName)[0];
    y = Number(sidePanel?.firstElementChild?.clientHeight);
  }

  return window.innerHeight - y;
}

const exportSVG: typeof scripts.Renderer.prototype.exportSVG = (querySelector: any) =>
  (document?.querySelectorAll(querySelector)[0] as SVGElement) || '';

const AromaViz: FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useNavigate();
  const dataSources = useDataSources();
  const {
    aromaVizRows: rows,
    dataType,
    loading,
    loadingAromaDotGraph,
    loadingAromaViz,
    mainIngredient,
    error: errorMessage,
    aromaProfileVariants,
    currentProfileVariant,
  } = useAppSelector((store: any) => store[SLICE_AROMA_NAME]);
  const { coreIngredients, coreIngredientsLoading: dataSourceLoading } = useAppSelector(
    (store: any) => store[SLICE_DATASOURCE_NAME],
  );
  const { version } = useParams<{ version: string }>();
  const { productPreparationId = undefined, name = '', ...restMainIngredient } = mainIngredient || {};
  const queryParamId = Number(new URLSearchParams(location.search).get(QUERY_PARAM_PRODUCT_PREPARATION_ID));
  const coreVersion = useMemo(
    () => [...(dataSources?.versions?.coreVersions || [])].sort((a, b) => parseInt(b, 10) - parseInt(a, 10))[0],
    [dataSources?.versions?.coreVersions],
  );
  const versionToApply = version || coreVersion;
  const [ingredient, setIngredient] = useState<ConvertedIngredient | null>(null);
  const [aromaWheelHeight, setAromaWheelHeight] = useState<number>(0);
  const [aromaWheelWidth, setAromaWheelWidth] = useState<number>(0);
  const [type, setType] = useState<scripts.ViewType | null>(scripts.ViewType.IngredientViewV2);
  const [openCreateVariant, setOpenCreateVariant] = useState<boolean>(false);
  const [editedRows, setEditedRows] = useState<AromaRecord[]>([]);

  // retrieve main ingredient name id etc
  const mainIngredientData = useMemo<CoreIngredientType>(
    () => coreIngredients.filter((i: any) => Number(i.productPreparationId) === Number(queryParamId))[0] || {},
    [coreIngredients, queryParamId],
  );

  // retrieve available data types from ingredient aroma records
  const availableDataTypes = useMemo<AromaDataType[]>(() => getAvailableDataTypes<AromaRecord>(rows), [rows]);

  // prepare ingredients selector
  const coreIngredientsStr = useMemo(
    () => getIngredientsString<CoreIngredientType>(coreIngredients, 'productPreparationId'),
    [coreIngredients],
  );

  // callback to select base ingredient
  const handleChangeBaseProduct = useCallback(
    (values: string[]) => {
      const ingredients = values.map((value) => coreIngredients[coreIngredientsStr.indexOf(value)]);
      const { productPreparationId: id } = ingredients[0] || {};
      history({
        pathname: `/data/aroma/${versionToApply}`,
        search: id ? `?${QUERY_PARAM_PRODUCT_PREPARATION_ID}=${id}` : '',
      });
    },
    [coreIngredients, coreIngredientsStr, versionToApply, history],
  );

  const { dialog, openDialog } = useSelectIngredientDialog(
    false,
    coreIngredientsStr,
    [
      productPreparationId
        ? ingredientToString<Partial<IngredientDBIngredientType>>('productPreparationId')({
            productPreparationId,
            name,
            ...restMainIngredient,
          })
        : '',
    ].filter(String),
    handleChangeBaseProduct,
    'Select ingredient',
  );

  // callback save edited records to aroma profile
  const handleUpdateAromaProfile = useCallback(
    (records: AromaRecord[]) => {
      if (!currentProfileVariant?.name || currentProfileVariant?.name?.toLowerCase() === 'default') {
        setOpenCreateVariant(true);
        setEditedRows(records);
        return;
      }

      if (productPreparationId && version && records.length) {
        // Should distribute the data after this call
        dispatch(
          addAromaProfileVariant({
            version,
            productPreparationId,
            profile: { ...currentProfileVariant, data: { records } },
            update: true,
            rows,
          }),
        );
      }
    },
    [currentProfileVariant, dispatch, productPreparationId, version, rows],
  );

  // callback to change aroma wheel visualization type
  const handleChange = useCallback((event: SelectChangeEvent) => {
    setType(event.target.value as scripts.ViewType);
  }, []);

  // callback to change core version
  const handleChangeCoreVersion = useCallback(
    (value: string) => {
      history({
        pathname: `/data/aroma/${value}`,
        search: queryParamId ? `?${QUERY_PARAM_PRODUCT_PREPARATION_ID}=${queryParamId}` : '',
      });
    },
    [history, queryParamId],
  );

  // set product preparation id to store and clean up aroma data on unmount
  useEffect(() => {
    dispatch(setMainIngredient(mainIngredientData));
  }, [dispatch, mainIngredientData]);

  // when no version provided in url - add the latest core version
  useEffect(() => {
    if (!version) {
      history(`/data/aroma/${coreVersion}`);
    }
  }, [coreVersion, history, version]);

  // when no version provided in url - add the latest core version
  useEffect(() => {
    setAromaWheelHeight(calculateHeight(0) - 0.5);
    setAromaWheelWidth(window.innerWidth - 369.5);

    setTimeout(() => {
      setAromaWheelHeight(calculateHeight(0));
      setAromaWheelWidth(window.innerWidth - 370);
    });

    window.addEventListener('resize', () => {
      setAromaWheelHeight(calculateHeight(0));
      setAromaWheelWidth(window.innerWidth - 370);
    });

    return () =>
      window.removeEventListener('resize', () => {
        setAromaWheelHeight(calculateHeight(0));
        setAromaWheelWidth(window.innerWidth - 370);
      });
  }, [type, ingredient, rows]);

  // apply version
  useEffect(() => {
    dispatch(
      changeCoreVersion({
        version: versionToApply,
        sourceType: DataSourceType.Core,
      }),
    );
    // eslint-disable-next-line
  }, [versionToApply]);

  // automatically redraw aroma wheel when rows or id changes
  useEffect(() => {
    setIngredient(null);
    if (rows.length && productPreparationId && dataType) {
      setIngredient(imPatchIngredientJSON(toIngredient(productPreparationId, rows, dataType)));
    }
  }, [rows, productPreparationId, dispatch, dataType]);

  // apply version
  useEffect(() => {
    if (productPreparationId) {
      dispatch(
        loadDotGraphRow({
          version: versionToApply,
          ingredient: {
            id: productPreparationId,
            name,
            rowPosition: -1,
            aromaRecords: rows,
            aromaTypes: [],
            aromaTypeToMolecules: {},
          },
        }),
      );
    }
  }, [dispatch, name, productPreparationId, rows, versionToApply]);

  // trigger loading of aroma profile variants
  useEffect(() => {
    if (version && queryParamId) {
      dispatch(
        loadAromaProfileVariants({
          productPreparationId: queryParamId,
          version,
        }),
      );
    }
    // eslint-disable-next-line
  }, [dispatch, queryParamId, version]);

  const loadingVariant = loading || loadingAromaViz || loadingAromaDotGraph;

  if (dataSources.loading) {
    return <Loading />;
  }

  return (
    <>
      <Paper className="aromaViz__ingredientAnalysis">
        <Typography paragraph={true} variant="h5">
          Ingredient analysis
        </Typography>
        <Box className="aromaViz__block" style={{ padding: 16 }}>
          <Box display="flex" alignItems="center" sx={{ flexShrink: 1 }}>
            {loadingVariant && (
              <div className="aromaViz__linearProgressRoot">
                <LinearProgress variant="query" className="aromaViz__linearProgress" />
              </div>
            )}
            <Grid container={true} justifyItems="baseline" flexDirection="row" rowSpacing={1} spacing={{ xs: 2 }}>
              <Grid item={true} xs={5} sm={3} md={3} lg={2} xl={2} className="aromaViz__versionSelector">
                <VersionList
                  changeVersion={handleChangeCoreVersion}
                  versions={dataSources.versions.coreVersions}
                  selected={versionToApply}
                  as="autocomplete"
                  required={true}
                  disabled={loadingVariant || dataSourceLoading}
                  label={DataSourceType.Core}
                  inputName={DataSourceType.Core}
                />
              </Grid>
              <Grid item={true} xs={7} sm={8} md={8} lg="auto">
                <FormControl className="aromaViz__container" key="form-control" required={true}>
                  <InputLabel id="ingredient-select-label">Select ingredient</InputLabel>
                  <Select
                    data-id-cypress="aromaDataVizSelectIngredientInput"
                    fullWidth={true}
                    labelId="ingredient-select-label"
                    displayEmpty={true}
                    placeholder="Select ingredient"
                    style={{ height: '40px', minWidth: 200, maxWidth: 300 }}
                    required={true}
                    disabled={loadingVariant}
                    input={<Input id="select-baseProducts-input" />}
                    open={false}
                    onOpen={openDialog}
                    value={productPreparationId || ''}
                  >
                    {productPreparationId && <option value={productPreparationId || ''}>{name}</option>}
                  </Select>
                  {dialog}
                </FormControl>
              </Grid>
              <Grid item={true} xs={5} sm={3} md={3} lg={2} xl={2}>
                <ProfileSelector
                  availableProfileVariants={aromaProfileVariants}
                  dispatch={dispatch}
                  disableSelection={!productPreparationId || !version || loadingVariant}
                  selectedProfile={currentProfileVariant}
                  productPreparationId={productPreparationId}
                  version={version}
                  containerClassName=""
                />
              </Grid>
              <Grid
                item={true}
                xs={5}
                sm={3}
                md={3}
                lg={1}
                xl={1}
                style={{ margin: '26px 4px', minWidth: 300, maxWidth: 300 }}
              >
                {!!currentProfileVariant?.description && (
                  <Typography component="div" paragraph={false}>
                    {currentProfileVariant.description}
                  </Typography>
                )}
              </Grid>
              <Grid item={true} xs={5} sm={3} md={3} lg={3} xl={2} style={{ margin: '16px 4px' }}>
                <CreateNewVariantDialog
                  disabled={!version || !productPreparationId || loadingVariant}
                  setOpen={setOpenCreateVariant}
                  open={openCreateVariant}
                  dispatch={dispatch}
                  productPreparationId={productPreparationId}
                  version={version || ''}
                  editedRows={editedRows}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>
      <Paper className="aromaViz__aromaProfile">
        <Typography paragraph={true} variant="h5">
          Aroma profile
        </Typography>
        <Box className="aromaViz__block" style={{ padding: 16 }}>
          <Box display="flex" alignItems="center">
            {loadingVariant && (
              <div className="aromaViz__linearProgressRoot">
                <LinearProgress variant="query" className="aromaViz__linearProgress" />
              </div>
            )}
            <FormControl className="aromaViz__container" key="form-control">
              <InputLabel key="type-selector-label" id="select-type">
                Select type:
              </InputLabel>
              <Select
                key="type-selector"
                labelId="select-type"
                id="aroma-select-type"
                input={<Input id="aroma-select-type" />}
                value={type || ''}
                label="Select type"
                onChange={handleChange}
                disabled={loadingVariant}
              >
                {Object.values(ViewType)
                  .filter((t) => !EXCLUDED_AROMA_WHEEL_TYPES.includes(t))
                  .map((visualizationType) => (
                    <MenuItem key={`menu-item-${visualizationType}`} value={visualizationType as string}>
                      {visualizationType as string}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <DataTypeSelector
              availableDataTypes={availableDataTypes}
              containerClassName="aromaViz__container"
              dataType={dataType}
              loading={loadingVariant}
              dispatch={dispatch}
            />
            <div className="aromaViz__container" style={{ maxWidth: 150 }}>
              <ExportButton
                fileName={`${type}_visualisation.svg`}
                type="svg"
                renderer={{
                  exportSVG: exportSVG.bind(this, ['.aromaViz__aromaWheelSvgWrapper svg']),
                }}
              >
                <Button
                  variant="contained"
                  type="button"
                  color="primary"
                  disabled={!ingredient || loadingVariant}
                  fullWidth={true}
                >
                  <Box display="flex" alignItems="center">
                    <Typography>Export to SVG</Typography>
                  </Box>
                </Button>
              </ExportButton>
            </div>
          </Box>
          {type && ingredient && Object.keys(ingredient)?.length > 0 ? (
            <Box
              style={{
                minWidth: aromaWheelWidth,
                minHeight: String((aromaWheelWidth > aromaWheelHeight ? aromaWheelHeight : aromaWheelWidth) * 0.9),
              }}
            >
              <VisualizationWrapperComponent
                loading={loadingVariant}
                key={type}
                ingredient={ingredient}
                taxonomy={taxonomy}
                canvasClassName={'canvas aromaViz__canvas'}
                canvasPanelClassName={'canvas-panel aromaViz__canvasPanel'}
                svgWrapperClassName="aromaViz__aromaWheelSvgWrapper"
                type={type as scripts.ViewType}
                height={String((aromaWheelWidth > aromaWheelHeight ? aromaWheelHeight : aromaWheelWidth) * 0.9)}
                width={String(aromaWheelWidth)}
                useSvg={true}
              />
            </Box>
          ) : (
            !loadingVariant && <h3 data-id-cypress="aromaVizDataErrorMessage"> Not enough data </h3>
          )}
        </Box>
      </Paper>
      {!!productPreparationId && !!version && (
        <Paper className="aromaViz__aromaData">
          <Typography paragraph={true} variant="h5">
            Aroma data
          </Typography>
          <AromaTable
            productPreparationId={productPreparationId}
            version={version}
            dispatch={dispatch}
            rows={rows}
            tableClassName="aromaViz__table"
            tableWrapperStyle={{ height: '40vh', width: '100%' }}
            saveEditedRows={handleUpdateAromaProfile}
            dataType={dataType}
          />
        </Paper>
      )}
      <Paper className="aromaViz__aromaComparison">
        <Typography paragraph={true} variant="h5">
          Aroma comparison
        </Typography>
        <Box className="aromaViz__block">
          <DotGraph
            coreIngredients={coreIngredients}
            coreIngredientsStr={coreIngredientsStr}
            loading={loadingVariant || dataSourceLoading}
            version={versionToApply}
          />
        </Box>
      </Paper>
      <ErrorNotification error={errorMessage} handleClose={() => dispatch(setError({ error: null }))} />
    </>
  );
};

export default AromaViz;
