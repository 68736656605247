import { Avatar, Button, Menu, MenuItem } from '@mui/material';
import { User } from 'firebase/auth';
import * as React from 'react';

import { logout } from '../auth';

interface UserMenuProps {
  user: User;
}

const UserMenu: React.FC<UserMenuProps> = ({ user }) => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const UserMenuContent = (
    <Menu
      anchorEl={menuAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id="user-profile-menu"
      keepMounted={true}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={logout}>Logout</MenuItem>
    </Menu>
  );

  return (
    <>
      <Button color="inherit" onClick={handleProfileMenuOpen}>
        <Avatar alt={user?.displayName || ''} src={user?.photoURL || ''} />
      </Button>
      {UserMenuContent}
    </>
  );
};

export default UserMenu;
