import '../../../../../../layout/run-config.scss';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { GridRowId } from '@mui/x-data-grid';
import difference from 'lodash/difference';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { AnyAction, Dispatch } from 'redux';

import { setError, setSelectedIngredientsV2 } from '../../../../../../features/project/store';
import {
  IngredientClass,
  IngredientType,
  SelectedIngredient,
} from '../../../../../../features/project/types/concept-generation';
import { getDuplicatedBaseIngredientOverride } from '../../../../../../features/project/utils';
import IngredientTable from '../../../_components/IngredientTable';
import IngredientClassSelector, { ClassChangeHandler } from '../IngredientClassSelector';

interface OverrideIngredientClassProps {
  loading: boolean;
  dispatch: Dispatch<AnyAction>;
  ingredients: SelectedIngredient[];
  ingredientClasses: IngredientClass[];
  restrictBaseDuplicates?: boolean;
}

const { other } = IngredientType;
type ClassChangeCallback = (id: GridRowId, rows: SelectedIngredient[]) => ClassChangeHandler<IngredientClass>;

const ERR_MSG_BASE_DUPLICATED = 'Each Base ingredient (Base1, Base2...Base5) can be mapped only to one ingredient.';

const OverrideIngredientClass: FC<OverrideIngredientClassProps> = ({
  loading,
  dispatch,
  ingredientClasses,
  ingredients,
  restrictBaseDuplicates = false,
}) => {
  const [errorIds, setErrorIds] = useState<number[]>([]);

  const onClassChangeHandler = useCallback<ClassChangeCallback>(
    (id, rows) =>
      ({ target: { value } }) => {
        const idx = rows.findIndex((i) => i.ingredient_id === Number(id));
        const updatedRows = [...rows];
        updatedRows[idx] = { ...updatedRows[idx], class: value };
        dispatch(setSelectedIngredientsV2({ items: updatedRows }));
      },
    [dispatch],
  );

  useEffect(() => {
    if (restrictBaseDuplicates) {
      setErrorIds((prevIds) => {
        const ids = getDuplicatedBaseIngredientOverride(ingredients);
        dispatch(setError(ids.length ? ERR_MSG_BASE_DUPLICATED : null));
        return difference(prevIds, ids)?.length ? ids : prevIds;
      });
    }
  }, [dispatch, ingredients, restrictBaseDuplicates]);

  useEffect(() => {
    dispatch(setSelectedIngredientsV2({ items: ingredients }));
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <Box className="RunConfig__stepContainer">
      <Box className="RunConfig__stepTitle">
        <Typography className="RunConfig__stepTitle">Select ingredients to be included in the concepts</Typography>
      </Box>
      <IngredientTable
        identifier="ingredient_id"
        checkboxSelection={false}
        columns={[
          {
            field: 'ingredient_id',
            align: 'center',
            headerAlign: 'center',
            headerName: 'Ingredient ID',
            minWidth: 200,
          },
          {
            field: 'ingredient_name',
            headerName: 'Ingredient name',
            align: 'center',
            headerAlign: 'center',
            minWidth: 200,
            flex: 1,
          },
          {
            field: 'class',
            headerName: 'Ingredient class',
            minWidth: 250,
            align: 'right',
            headerAlign: 'center',
            editable: true,
            sortable: true,
            renderCell: ({ id, row: { class: val } }) => (
              <IngredientClassSelector
                value={val || other}
                options={ingredientClasses}
                onChangeHandler={onClassChangeHandler(Number(id), ingredients)}
                isInvalid={errorIds.includes(Number(id))}
              />
            ),
          },
        ]}
        ingredients={ingredients}
        initialSorting={[{ field: 'class', sort: 'desc' }]}
        loading={loading}
        height="100%"
      />
    </Box>
  );
};

OverrideIngredientClass.defaultProps = {
  restrictBaseDuplicates: false,
};

export default memo(OverrideIngredientClass);
