import { DataManProperties } from '../../../components/dataman/types';
import { TYPE_CLONE_VERSION, TYPE_EDIT_VERSION } from '../../../shared/url';
import { ContextStatusOptions } from '../types';
import { renderChangedAtCell, renderChangedByCell, renderContextStatusCell } from './row-cell-renderer';
import { ContextVersionDataManBuildArg, ContextVesrionDatamanRecord } from './types';

/**
 * Build Contexts table dataman config.
 * @param args
 */
function buildStructure(args: ContextVersionDataManBuildArg): DataManProperties<ContextVesrionDatamanRecord> {
  const { history, contextId, icons } = args;

  return {
    useUrlFilterAndSort: true,
    list: {
      lineClick: (record) => {
        history?.(`/contexts/${contextId}/versions/edit/${record.contextVersionId}`);
      },
      columns: {
        contextVersionId: {
          label: 'Id',
          sortable: true,
          filterable: true,
        },
        description: {
          label: 'Description',
          sortable: true,
          filterable: true,
        },
        status: {
          label: 'Status',
          renderRowCell: renderContextStatusCell,
          sortable: true,
          filterable: true,
          renderAs: 'list',
          filterOptions: {
            options: ContextStatusOptions,
            inputLabel: 'Status',
          },
        },
        recipesVersion: {
          label: 'Recipes version',
          sortable: true,
          filterable: true,
        },
        socialPostsVersion: {
          label: 'Social media version',
          sortable: true,
          filterable: true,
        },
        changedBy: {
          label: 'Changed By',
          renderRowCell: renderChangedByCell,
          sortable: true,
          filterable: true,
        },
        changedAt: {
          sortable: true,
          filterable: true,
          label: 'Changed At',
          renderRowCell: renderChangedAtCell,
        },
      },
      actions: [
        {
          buttonIcon: icons[TYPE_EDIT_VERSION],
          tooltip: 'Edit version',
          handleAction: (action, record: any) => {
            history?.(`/contexts/${record.contextId}/versions/edit/${record.contextVersionId}`);
          },
        },
        {
          buttonIcon: icons[TYPE_CLONE_VERSION],
          tooltip: 'Clone version',
          handleAction: (action, record: any) => {
            history?.(`/contexts/${record.contextId}/versions/clone/${record.contextVersionId}`);
          },
        },
      ],
    },
  };
}

export default buildStructure;
