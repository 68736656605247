import { VisibilityOff, VisibilityOutlined } from '@mui/icons-material';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import { Alert, Button, Checkbox } from '@mui/material';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { GridColDef, GridEditRowsModel } from '@mui/x-data-grid';
import zipObject from 'lodash/zipObject';
import React from 'react';

import { LemmaTableRow, LemmaTableRowKeys } from '../../../../../features/project/types/metrics-calculation';
import { lookupReferenceIngredient } from '../../../../../features/project/utils';
import { getDataGridCellAlignment, timestampToLocalDateSting } from '../../../../../shared/utils';
import { DBIngredientFields } from '../../../../../types';
import CorrectedIngredientAutoComplete from '../../_components/CorrectedIngredientAutoComplete';
import { IngredientDBMap } from '../../_utils/datasources';
import { RowErrorMessages } from './RowErrorMessages';

export const {
  correctedIdKey,
  correctedRefIdKey,
  correctedRefNameKey,
  correctedNameKey,
  idKey,
  nameKey,
  lemmaKey,
  refIdKey,
  refNameKey,
  isRelevantKey,
  messageKey,
  creationTimeKey,
  projectKey,
  hideLemmasKey,
} = LemmaTableRowKeys;

export const PRINTABLE_KEY_PREFIXES = ['Key', 'Digit', 'Minus', 'Equal', 'Slash', 'Period', 'Comma', 'Numpad'];
export const CLEAR_CELL_KEY_CODES = ['Backspace', 'Delete'];

export function prepareGridEditRow(cols: string[], values?: any[]): GridEditRowsModel {
  return zipObject(cols, values?.map((value) => value || null) || new Array(cols.length).fill(values));
}

export function isPressedKeyPrintableChar(charCode = ''): boolean {
  return !!charCode && !!PRINTABLE_KEY_PREFIXES.filter((charCodePrefix) => charCode?.startsWith(charCodePrefix)).length;
}

export function isRemoveKey(charCode = ''): boolean {
  return !!charCode && CLEAR_CELL_KEY_CODES.includes(charCode);
}

export enum CellActions {
  Edit = 'Edit',
  Reset = 'Reset',
  View = 'View',
}

const { aromaProductPreparationId: aromaPrepIdKey } = DBIngredientFields;

// set selected ingredients and modify relevant_ingredient field
export const getRowErrorMessage = (row: LemmaTableRow, map: IngredientDBMap): RowErrorMessages | undefined => {
  const { [correctedIdKey]: correctedId, [idKey]: id } = row;
  const idUsed = correctedId || id;
  if (!idUsed) {
    return RowErrorMessages.noIdOrCorrectedId;
  }

  const { ingredientMap: idMap } = map;
  const referenceId = Number(lookupReferenceIngredient(Number(idUsed), map)?.ingredientId);
  const aromaPrepId = idMap.get(idUsed)?.[aromaPrepIdKey];
  const referenceAromaPrepId = referenceId && idMap.get(referenceId)?.[aromaPrepIdKey];
  if (!aromaPrepId && !referenceAromaPrepId) {
    return correctedId ? RowErrorMessages.noReferenceCorrectedMapping : RowErrorMessages.noReferenceMapping;
  }

  return undefined;
};

/**
 * Util function to prepare data grid lemma columns configuration.
 */
export function prepareLemmaTableColumns(
  map: IngredientDBMap,
  onHideLemma: any,
  onRemoveCorrectedId: any,
  isReadOnly?: boolean,
): GridColDef[] {
  const alignTextCell = getDataGridCellAlignment('text');
  const alignNumericCell = getDataGridCellAlignment('numeric');
  const hideSortIcons = true;

  return [
    {
      field: isRelevantKey,
      headerName: 'Is Relevant',
      type: 'singleSelect',
      hide: true,
      disableColumnMenu: true,
      filterable: true,
      valueOptions: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
      ],
      cellClassName: isRelevantKey,
    },
    {
      ...alignNumericCell,
      minWidth: 50,
      field: hideLemmasKey,
      headerName: 'Hide',
      cellClassName: hideLemmasKey,
      renderCell: ({ row }) => (
        <Box display={'flex'} justifyContent={'center'}>
          <Checkbox
            data-id-cypress="hideLemmaButton"
            icon={<VisibilityOutlined></VisibilityOutlined>}
            checkedIcon={<VisibilityOff color={'disabled'}></VisibilityOff>}
            checked={row.hide_lemma}
            onChange={(e) => {
              onHideLemma(e.target.checked, row);
            }}
          />
        </Box>
      ),
    },
    {
      ...alignTextCell,
      field: messageKey,
      headerName: 'Issues',
      cellClassName: messageKey,
      hideSortIcons,
      filterable: true,
      filterOperators: [
        {
          label: 'Hide Empty',
          value: 'hide',
          getApplyFilterFn: ({ columnField, operatorValue }) => {
            if (!columnField || operatorValue !== 'hide') {
              return null;
            }

            return ({ value }) => !!value?.toString()?.trim();
          },
        },
      ],
      valueGetter: ({ row }) => getRowErrorMessage(row as LemmaTableRow, map) || '',
      renderCell: ({ value }) => (
        <Box alignItems="center" component="span">
          {value?.toString()?.trim() && (
            <Tooltip title={value}>
              <Alert
                color={value.toString() === RowErrorMessages.noIdOrCorrectedId ? 'error' : 'warning'}
                severity={value.toString() === RowErrorMessages.noIdOrCorrectedId ? 'error' : 'warning'}
                variant="outlined"
              />
            </Tooltip>
          )}
        </Box>
      ),
    },
    {
      ...alignTextCell,
      field: lemmaKey,
      minWidth: 200,
      headerName: 'Lemma',
      hideSortIcons,
    },
    {
      ...alignNumericCell,
      field: idKey,
      minWidth: 150,
      headerName: 'Ingredient ID',
      resizable: false,
      hideSortIcons,
    },
    {
      ...alignTextCell,
      field: nameKey,
      minWidth: 200,
      headerName: 'Ingredient Name',
      hideSortIcons,
      valueGetter: ({ row }) => map.searchIngredientById(Number(row.ingredient_id))?.name,
    },
    {
      ...alignNumericCell,
      field: refIdKey,
      minWidth: 150,
      headerName: 'Reference ID',
      resizable: false,
      hideSortIcons,
      valueGetter: ({ row }) => lookupReferenceIngredient(Number(row.ingredient_id), map)?.ingredientId,
    },
    {
      ...alignTextCell,
      field: refNameKey,
      minWidth: 220,
      headerName: 'Reference Name',
      hideSortIcons,
      valueGetter: ({ row }) => lookupReferenceIngredient(Number(row.ingredient_id), map)?.name,
    },
    {
      ...alignNumericCell,
      field: correctedIdKey,
      minWidth: 200,
      headerName: 'Corrected ID',
      editable: !isReadOnly,
      cellClassName: correctedIdKey,
      resizable: false,
      hideSortIcons,
      renderEditCell: (params: any) => (
        <CorrectedIngredientAutoComplete ingredientMap={map} cellParams={params} correctedIdKey={correctedIdKey} />
      ),
      renderCell: (params: any) => {
        if (params.value) {
          return (
            <>
              <p>{params.value}</p>
              <Button
                data-id-cypress="removeCorrectedIdButton"
                onClick={() => {
                  params.api.setEditCellValue({ id: params.id, field: correctedIdKey, value: null });
                  onRemoveCorrectedId(params.row);
                }}
              >
                <DeleteIcon />
              </Button>
            </>
          );
        }
        return '';
      },
      renderHeader: () => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title="Edit Corrected ID of Lemma Mapping">
            <Box display="flex" alignItems="center" component="span">
              <Typography>Corrected Id</Typography>
              {!isReadOnly && (
                <CreateIcon
                  className="editableHeaderIcon"
                  sx={{
                    transform: 'rotate(-18deg)',
                    fontSize: '1.8rem',
                    color: 'warning.main',
                  }}
                  color="inherit"
                  fontSize="small"
                  titleAccess="Edit Corrected ID"
                />
              )}
            </Box>
          </Tooltip>
        </div>
      ),
    },
    {
      ...alignTextCell,
      field: correctedNameKey,
      minWidth: 220,
      headerName: 'Corrected Name',
      hideSortIcons,
      valueGetter: ({ row }) => map.searchIngredientById(Number(row.corrected_ingredient_id))?.name,
    },
    {
      ...alignNumericCell,
      field: correctedRefIdKey,
      minWidth: 150,
      headerName: 'Corrected Ref. ID',
      hideSortIcons,
      valueGetter: ({ row }) => lookupReferenceIngredient(Number(row.corrected_ingredient_id), map)?.ingredientId,
    },
    {
      ...alignTextCell,
      field: correctedRefNameKey,
      minWidth: 250,
      headerName: 'Corrected Ref. Name',
      hideSortIcons,
      valueGetter: ({ row }) => lookupReferenceIngredient(Number(row.corrected_ingredient_id), map)?.name,
    },
    {
      ...alignTextCell,
      field: creationTimeKey,
      headerName: 'Creation Time',
      minWidth: 250,
      cellClassName: creationTimeKey,
      renderCell: (params) => <p>{params.value && timestampToLocalDateSting(params.value)}</p>,
    },
    {
      ...alignTextCell,
      field: projectKey,
      headerName: 'Project',
      minWidth: 250,
      cellClassName: projectKey,
    },
  ];
}
