import axios from 'axios';

import { buildActionUrl, TYPE_BQ_CONNECTOR } from '../../../shared/url';
import { SortDirections } from '../../../types';

const BQ_CONNECTOR_URL = buildActionUrl({}, TYPE_BQ_CONNECTOR);
const PAGE_SIZE = 1000;

export type BqIngredientResponse = ReturnType<
  (...args: any) => {
    rows: (number | string | null)[][];
    totalRows: number;
    message?: string;
    code?: number | string;
  }
>;

interface OrderByClause {
  column: string;
  direction: SortDirections;
}

interface EqualsOperator {
  value: string | number | boolean;
}

interface WhereClause {
  column: string;
  operatorEquals: EqualsOperator;
}

interface BQTableRequest {
  query: {
    columns: string[];
    order_by: OrderByClause;
    where?: WhereClause;
  };
  limit: number;
  offset: number;
  data_source_table?: {
    table_name: string;
    dataset: string;
    version: string;
  };
  project_table?: {
    table_name: string;
    project_id: string;
  };
}

/* eslint-disable no-await-in-loop */
export async function fetchBQTable(
  tableName: string,
  columns: string[],
  orderBy: OrderByClause,
  where: WhereClause | undefined = undefined,
  projectId: string | undefined = undefined,
  dataset: string | undefined = undefined,
  version: string | undefined = undefined,
): Promise<{ data: any }> {
  let result = { rows: [], totalRows: 0 } as BqIngredientResponse;
  const body: BQTableRequest = {
    query: {
      columns,
      order_by: orderBy,
      where,
    },
    offset: 0,
    limit: PAGE_SIZE,
  };

  if (dataset && version) {
    body.data_source_table = {
      table_name: tableName,
      dataset,
      version,
    };
  } else if (projectId) {
    body.project_table = {
      table_name: tableName,
      project_id: projectId,
    };
  } else {
    throw new Error('Either projectId or dataset and version must be provided');
  }

  do {
    body.offset = result.rows.length;

    const response: BqIngredientResponse & {} = await axios.post(BQ_CONNECTOR_URL, body).then((res) => res.data);

    if (response.code && response.message) {
      throw new Error(response.message);
    }
    result = {
      rows: [...result.rows, ...(response?.rows || [])],
      totalRows: Number(response?.totalRows),
    };
  } while (result?.rows?.length < result.totalRows);

  return { data: result.rows };
}
