import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import React from 'react';

const Loading: React.FC<{
  message?: string;
  useOverlay?: boolean;
  size?: string;
  marginLeft?: number;
}> = ({ message, useOverlay, size, marginLeft }) => {
  const loader = (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" className="loader__div">
      <Box>
        <CircularProgress size={size} style={{ marginLeft }} />
      </Box>
      <Box>
        <Typography variant="body1"> {message || 'Loading...'} </Typography>
      </Box>
    </Box>
  );

  if (useOverlay === true) {
    return (
      <Box
        position="absolute"
        left="0"
        right="0"
        top="0"
        bottom="0"
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{ backgroundColor: 'rgba(0,0,0,.2)' }}
      >
        {loader}
      </Box>
    );
  }

  return loader;
};

Loading.defaultProps = {
  message: undefined,
  useOverlay: undefined,
  size: undefined,
  marginLeft: 0,
};

export default Loading;
