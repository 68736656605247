import '../../../layout/project-flow.scss';

import { Box, Button, FormControlLabel, Paper, Step, StepLabel, Stepper, Switch } from '@mui/material';
import React, { FC, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Loading from '../../../components/Loading';
import PageTitle from '../../../components/PageTitle';
import { useLayout } from '../../../context/LayoutContext';
import { isProduction } from '../../../shared/utils';
import { WorkflowConfig } from '../../../types';
import { ProjectBookmark, setPhaseBookmark, setProjectBookmark } from '../bookmark';
import { steps } from '.';
import { getBackUrl, getNextUrl, loadFlowStepData, proceedToNextPhase, StepDataResponse } from './_utils/datasources';
import { FlowActions, NavigationButtonsStatus, useProjectFlowReducer } from './_utils/reducer';
import { ProjectPhaseType } from './_utils/types';

const ProjectFlow: FC<{}> = () => {
  const isProductionMode = isProduction();
  const [state, dispatch] = useProjectFlowReducer();
  const { setPageTitle } = useLayout();
  const { loading, navigationStatus, phase, project, selectedRun, backUrl, nextUrl } = state;
  const history = useNavigate();

  const [isPhaseReadOnly, setIsPhaseReadOnly] = useState<boolean>(phase?.readOnly === true);
  const [stepTitle, setStepTitle] = useState<string>('');
  const [stepDescription, setStepDescription] = useState<string>('');
  const {
    phaseId = '',
    runId = '',
    idProject: projectId = '',
  } = useParams<{ idProject: string; phaseId: string; runId: string }>();

  const getWorkflowStepOrderId = useCallback(
    (workflowStepId: string | undefined): number => {
      const workflowConfig = project?.workflowConfig || [];
      for (let i = 0; i < workflowConfig.length; i++) {
        if (workflowConfig[i].workflowStepId === workflowStepId) {
          return i;
        }
      }
      return 0;
    },
    [project?.workflowConfig],
  );

  // set initial values for back/next url
  const navButtonsStatus: NavigationButtonsStatus | null = (navigationStatus && navigationStatus()) || null;
  const prevIsDisabled = loading || !backUrl || !navButtonsStatus?.back;
  const nextIsDisabled = loading || !navButtonsStatus?.next;
  const configButtonIsDisabled = !state.phase || loading;
  // process step change
  const changeStep = useCallback<(url: string) => void>(
    (url) => {
      if (url) {
        dispatch({ type: FlowActions.setLoading, loading: true });
        dispatch({ type: FlowActions.loadPhase, phase: null, project: null });
        history(url);
      }
    },
    [history, dispatch],
  );

  // callback to initialize step data
  const initializeStepData = useCallback(
    (
      routeProjectId: string,
      routePhaseId?: string | undefined | null,
      routeRunId?: string | undefined | null,
    ): void => {
      dispatch({ type: FlowActions.setLoading, loading: true });
      dispatch({ type: FlowActions.loadProject, project: null });

      loadFlowStepData(routeProjectId, routePhaseId, routeRunId).then((result: StepDataResponse) => {
        if (result?.project) {
          const { firstPhaseId, workflowConfig } = result.project;
          const { dependsOnPhases, dependsOnRuns, type, readOnly, workflowStepId } = result?.phase || {};
          const { dependingPhaseRuns } = result;

          if (type && result?.phase?.phaseId) {
            workflowConfig.forEach((workflow) => {
              if (workflow) {
                workflow.type = workflow.type || ProjectPhaseType.GenericPhase;

                if (workflow?.workflowStepId === workflowStepId) {
                  setStepDescription(workflow?.description || '');
                  setStepTitle(workflow?.title || '');
                }
              }
            });

            setIsPhaseReadOnly(readOnly === true);
          }

          const isPhaseId = result?.phase?.phaseId;
          setPhaseBookmark(result?.phase);

          const bookmark: ProjectBookmark = {
            projectId: result.project.projectId,
            phaseId: isPhaseId ? result?.phase?.phaseId : undefined,
            runId: isPhaseId ? result?.selectedRun?.runId : undefined,
          };

          setProjectBookmark(bookmark);
          const phaseType = type || ProjectPhaseType.ProjectConfiguration;
          // Get the next phase from the last depending phase of the selected run
          // Except for concept generation, get the next phase from the last depending phase,
          // because in concept generation we're not able to select a single run.
          const nextPhaseId =
            phaseType === ProjectPhaseType.ConceptGeneration
              ? result?.phase?.dependingPhases?.pop()
              : result?.selectedRun?.dependingPhases?.pop();
          // Get the last run id from all depending runs
          const nextRunId = dependingPhaseRuns?.pop()?.runId;
          const newBackUrl = getBackUrl(result.project.projectId, phaseType, dependsOnPhases?.[0], dependsOnRuns?.[0]);
          const newNextUrl = getNextUrl(result.project.projectId, firstPhaseId, phaseType, nextPhaseId, nextRunId);

          dispatch({
            type: FlowActions.loadPhase,
            phase: result?.phase || null,
            runs: result?.runs || [],
            selectedRun: result?.selectedRun || null,
            project: result.project,
            backUrl: newBackUrl,
            nextUrl: newNextUrl,
          });
        }

        dispatch({ type: FlowActions.setLoading, loading: false });
      });
    },
    [dispatch, setIsPhaseReadOnly],
  );

  // initialize phase, project, runs when route is reached
  useEffect(() => {
    if (projectId) {
      initializeStepData(projectId, phaseId, runId);
    }
  }, [projectId, phaseId, runId, initializeStepData]);

  // reset bookmark to project config if we are going to another page from configure project
  useLayoutEffect(
    () => () => setProjectBookmark(projectId && !phaseId ? { projectId, reset: true } : null),
    [projectId, phaseId],
  );

  useEffect(() => {
    setPageTitle(`Project ${projectId}`);

    return () => {
      setPageTitle('');
    };
  }, [setPageTitle, projectId]);
  const StepComponent = steps[phase?.type || ProjectPhaseType.ProjectConfiguration].component;

  return (
    <>
      <PageTitle
        title={`Project ${projectId}`}
        /* 
          This is here for debug purposes on frontend side
          it allows us to simulate a job failure.
        */
        action={
          !isProductionMode ? (
            <FormControlLabel
              disabled={isPhaseReadOnly || !!state?.selectedRun?.readOnly}
              control={<Switch />}
              label="Simulate job fail"
              onChange={(e, checked) =>
                dispatch({
                  type: FlowActions.setSimulateFail,
                  simulate: checked,
                })
              }
            />
          ) : null
        }
      />
      <Box className="ProjectFlow__root">
        {project?.workflowConfig && (
          <Stepper
            activeStep={getWorkflowStepOrderId(state.phase?.workflowStepId)}
            className="ProjectFlow__stepper"
            alternativeLabel={true}
          >
            {project.workflowConfig.map((workflowItem: WorkflowConfig) => (
              <Step key={workflowItem.title}>
                <StepLabel>{workflowItem.title}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
        <Box>
          {!project ? (
            <Loading />
          ) : (
            <StepComponent
              stepTitle={stepTitle || ''}
              currentStep={getWorkflowStepOrderId(state.phase?.workflowStepId)}
              stepDescription={stepDescription || ''}
              key={`selected-step-${state.phase?.workflowStepId}`}
              phaseId={phaseId || undefined}
              runId={runId || undefined}
              selectedRun={state?.selectedRun || undefined}
              runs={state?.runs || undefined}
              simulateFail={state?.simulateFail || false}
              project={project || null}
              dispatch={dispatch}
              isPhaseReadOnly={isPhaseReadOnly}
              phase={phase}
            />
          )}
        </Box>
        <Paper className="ProjectFlow__footerActionsBar">
          {state.primaryNavVisible && state.phase?.type !== ProjectPhaseType.ProjectConfiguration && (
            <Button
              data-id-cypress="projectFlowConfig"
              disabled={configButtonIsDisabled}
              onClick={() => changeStep((projectId && getBackUrl(projectId)) || '')}
              className="ProjectFlow__backButton"
            >
              Config
            </Button>
          )}
          {state.primaryNavVisible && (
            <Button
              data-id-cypress="projectFlowBack"
              disabled={prevIsDisabled}
              onClick={() => backUrl && changeStep(backUrl)}
              className="ProjectFlow__backButton"
            >
              Back
            </Button>
          )}
          {state.applyButton}
          {state.primaryNavVisible && (
            <Button
              data-id-cypress="projectFlowNext"
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch({ type: FlowActions.setLoading, loading: true });
                proceedToNextPhase(nextUrl, phase?.type, selectedRun).then((newUrl) => {
                  changeStep(newUrl || '');
                  if (!newUrl) {
                    dispatch({
                      type: FlowActions.setLoading,
                      loading: false,
                    });
                  }
                });
              }}
              disabled={nextIsDisabled}
            >
              Next
            </Button>
          )}
        </Paper>
      </Box>
      <Box mt={10} />
    </>
  );
};

export default ProjectFlow;
