import { PayloadAction } from '@reduxjs/toolkit';
import { compress, decompress } from 'lzutf8';
import { call, ForkEffectDescriptor, put, SimpleEffect, takeLatest } from 'redux-saga/effects';

import { CoreIngredientType, DataSourceType, GenericIngredientType, IngredientDBIngredientType } from '../../../types';
import { fetchIngredients, fetchIngredientsSensory } from '../query/ingredients';
import {
  changeCoreVersion,
  changeIngredientDbVersion,
  changeSensoryVersion,
  setError,
  setIngredients,
  setIngredientsLoading,
  setLoading,
} from './reducer';
import { ChangeVersionPayload, IngredientResponse } from './types';

const LOCAL_KEY_DATASOURCE = 'CFI_PROJECT_KEY_DATASOURCE';
const LOCAL_KEY_DATASOURCE_STORAGE_VERSION = `${LOCAL_KEY_DATASOURCE}_VERSION`;
const LOCAL_STORAGE_NEW_VERSION = 'v1';

type SessionIngredientByVersion = {
  [key: string]: undefined | null | GenericIngredientType[];
};

type sourceLoaderMapType = { [key: string]: (version: string) => Promise<any> };

const sourceLoaderMap: sourceLoaderMapType = {
  [DataSourceType.Core]: (version) => fetchIngredients<CoreIngredientType>(version, DataSourceType.Core),
  [DataSourceType.IngredientDB]: (version) =>
    fetchIngredients<IngredientDBIngredientType>(version, DataSourceType.IngredientDB),
  [DataSourceType.Sensory]: (version) => fetchIngredientsSensory(version),
};

// Workers
function* loadVersionDataWorker(action: PayloadAction<ChangeVersionPayload>) {
  const { version, sourceType } = action?.payload || {};
  const cacheKey = `${LOCAL_KEY_DATASOURCE}_${sourceType}`;
  let storedIngredients: SessionIngredientByVersion = {};
  yield put({ type: setLoading.type, payload: { loading: true } });
  yield put({
    type: setIngredients.type,
    payload: { ingredients: [] },
    sourceType,
  });

  if (Object.keys(sourceLoaderMap).includes(sourceType)) {
    clearIngredientStorage(LOCAL_STORAGE_NEW_VERSION);

    if (localStorage) {
      try {
        // decompressing of compressed data in local storage
        storedIngredients = JSON.parse(
          decompress(localStorage.getItem(cacheKey), {
            inputEncoding: 'StorageBinaryString',
            outputEncoding: 'String',
          }) || '{}',
        ) as SessionIngredientByVersion;
      } catch (e: any) {
        storedIngredients = {} as SessionIngredientByVersion;
      }
    }

    try {
      if (!storedIngredients[version] && version) {
        yield put({
          type: setIngredientsLoading.type,
          payload: { loading: true, sourceType },
        });
        const response: ReturnType<(...args: any) => IngredientResponse> = yield call(
          sourceLoaderMap[sourceType],
          version,
        );

        storedIngredients[version] = response.ingredients || [];

        // compressing data before saving to local storage to reduce used space
        storedIngredients = yield call(saveToStorage, cacheKey, storedIngredients, version);
      }

      yield put({
        type: setIngredients.type,
        payload: { ingredients: storedIngredients[version] },
        sourceType,
      });
    } catch (e: any) {
      yield put({ type: setError.type, payload: { error: e?.toString() } });
    }
  }

  yield put({
    type: setIngredientsLoading.type,
    payload: { loading: false, sourceType },
  });
  yield put({ type: setLoading.type, payload: { loading: false } });
}

// Watchers
export function* versionWatcher(): Generator<SimpleEffect<'FORK', ForkEffectDescriptor<never>>, void, unknown> {
  // TODO: refactor here and give every watcher its own worker
  yield takeLatest(changeCoreVersion.type, loadVersionDataWorker);
  yield takeLatest(changeIngredientDbVersion.type, loadVersionDataWorker);
  yield takeLatest(changeSensoryVersion.type, loadVersionDataWorker);
}

function saveToStorage(key: string, cache: SessionIngredientByVersion, version: string): SessionIngredientByVersion {
  let data = cache;

  if (localStorage) {
    try {
      localStorage.setItem(
        key,
        compress(JSON.stringify(data), {
          outputEncoding: 'StorageBinaryString',
        }),
      );
    } catch (e: any) {
      Object.keys(localStorage).forEach((storageKey) => {
        if (storageKey.indexOf(LOCAL_KEY_DATASOURCE) !== -1) {
          localStorage.removeItem(storageKey);
        }
      });

      data = { [version]: cache[version] };
      localStorage.setItem(
        key,
        compress(JSON.stringify(data), {
          outputEncoding: 'StorageBinaryString',
        }),
      );
    }
  }

  return data;
}

function clearIngredientStorage(storageVersion: string) {
  if (localStorage) {
    const currentStorageVersion = localStorage.getItem(LOCAL_KEY_DATASOURCE_STORAGE_VERSION);

    if (currentStorageVersion !== storageVersion) {
      Object.keys(localStorage).forEach((storageKey) => {
        if (storageKey.indexOf(LOCAL_KEY_DATASOURCE) !== -1) {
          localStorage.removeItem(storageKey);
        }
      });

      localStorage.setItem(LOCAL_KEY_DATASOURCE_STORAGE_VERSION, storageVersion);
    }
  }
}
