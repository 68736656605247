import TextField from '@mui/material/TextField';
import React from 'react';

import { DataManFilterComponentProps } from '../types';

const TextListFilter: React.FC<DataManFilterComponentProps> = ({
  options,
  value,
  handleChangeFilter,
  filterIndex,
  column,
}) => {
  const opts = options || {};

  return (
    <div style={{ padding: 2 }}>
      <TextField
        variant="outlined"
        size="small"
        label={opts.inputLabel || column.label}
        onChange={(e) => handleChangeFilter(filterIndex, { value: e.target.value || '' })}
        value={value.value || ''}
      />
    </div>
  );
};

export default TextListFilter;
