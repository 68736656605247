import './DotGraph.scss';

import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React, { FC, useCallback } from 'react';

import { loadDotGraphRow, setDotGraphRows, SLICE_AROMA_NAME } from '../../features/aroma/store/reducer';
import { DotGraphFormRecord } from '../../features/aroma/types';
import { useAppDispatch, useAppSelector } from '../../store';
import { CoreIngredientType } from '../../types';
import { DotGraphViz } from '../lab-dataviz';
import DotGraphForm from './DotGraphForm';

interface DotGraphProps {
  coreIngredients: CoreIngredientType[];
  coreIngredientsStr: string[];
  loading: boolean;
  version: string;
}

const DotGraph: FC<DotGraphProps> = ({ coreIngredients, coreIngredientsStr, loading, version }) => {
  const dispatch = useAppDispatch();
  const { dotGraphMainIngredient, dotGraphOtherIngredients } = useAppSelector((store) => store[SLICE_AROMA_NAME]);

  // prepare data for DotGraph visualization
  const updateDotGraphRows = useCallback(
    (data: { update?: DotGraphFormRecord[]; add?: DotGraphFormRecord }) => {
      const { update, add: ingredient } = data;
      dispatch?.(loadDotGraphRow({ ingredient, version }));
      dispatch(
        setDotGraphRows({
          dotGraphMainIngredient: null,
          dotGraphOtherIngredients: update,
        }),
      );
    },
    [dispatch, version],
  );

  return (
    <>
      <Grid container={true} style={{ margin: 15, minHeight: 50 }}>
        {(!!dotGraphMainIngredient?.id || !!dotGraphOtherIngredients.length) && coreIngredients.length > 0 && (
          <DotGraphViz
            forceDownload={false}
            exportBtnClassName="MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButtonBase-root css-4m3ehn-MuiButtonBase-root-MuiButton-root"
            main={dotGraphMainIngredient}
            other={dotGraphOtherIngredients}
            showAromaNames={true}
            showDownloadButton={true}
            showIngredientTitle={true}
            svgWidth="1550"
            wrapperClassName="DotGraph__dotGraphWrapper"
            wrapperWidth="100%"
          />
        )}
        {loading && (
          <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" width="100%">
            <CircularProgress />
          </Box>
        )}
      </Grid>
      <DotGraphForm
        coreIngredients={coreIngredients}
        coreIngredientsStr={coreIngredientsStr}
        dotGraphOtherIngredients={dotGraphOtherIngredients}
        loading={loading}
        version={version}
        updateFormRows={updateDotGraphRows}
      />
    </>
  );
};

export default DotGraph;
