import '../../../layout/common.scss';

import { SearchOff, Warning } from '@mui/icons-material';
import { Box, Button, Checkbox, CircularProgress, DialogContent, InputLabel, Paper, Switch } from '@mui/material';
import Typography from '@mui/material/Typography';
import axios, { AxiosResponse } from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import ContextListGrid from '../../../admin/contexts/ContextListGrid';
import { FlowActions } from '../../../admin/projects/ProjectFlow/_utils/reducer';
import { RunType } from '../../../admin/projects/ProjectFlow/_utils/types';
import Loading from '../../../components/Loading';
import SectionTitle from '../../../components/SectionTitle';
import { buildActionUrl, TYPE_PHASE_RUN_INPUT, TYPE_POST_SELECTABLE_CONTEXTS_VERSION } from '../../../shared/url';
import { ContextType, ProjectType } from '../../../types';
import { DocLink, DocLinkUrl, DocTypeEnum } from '../../docs';
import FlowButtons from './FlowButtons';
import RemoveContextFromProjectDialog from './RemoveContextFromProjectDialog';

interface GlobalContextsProps {
  projectId: string;
  project: ProjectType;
  dispatch: any;
  phaseId: string;
  run: RunType;
  existingProjectContexts: ContextType[];
  onClose: () => void;
}

interface ContextsDialogProps {
  projectId: string;
  onContextAdded: (contexts: ContextType[]) => void;
  hiddenDialogContexts?: string[];
  existingProjectContexts?: ContextType[];
}

const ContextsDialog: React.FC<ContextsDialogProps> = ({
  projectId,
  onContextAdded,
  hiddenDialogContexts,
  existingProjectContexts,
}) => {
  const [selectedContexts, setSelectedContexts] = useState<ContextType[]>([]);
  const [contexts, setContext] = useState<ContextType[]>([]);
  const [displayedContexts, setDisplayedContexts] = useState<ContextType[]>([]);
  const [contextLoading, setContextLoading] = useState<boolean>(true);
  const [showOnlyLastVersions, setShowOnlyLastVersions] = useState<boolean>(true);
  const [savedSelection, setSavedSelection] = useState<ContextType[]>([]);

  const getSelectedContextIndex = useCallback(
    (context: ContextType): number =>
      selectedContexts.findIndex(
        (c) => context.contextId === c.contextId && context.contextVersionId === c.contextVersionId,
      ),
    [selectedContexts],
  );

  const isContextAvailable = useCallback(
    (context: ContextType): boolean => {
      let available = true;
      if (selectedContexts.find((c) => c.contextId === context.contextId)) {
        if (
          selectedContexts.find(
            (c) => c.contextId === context.contextId && c.contextVersionId === context.contextVersionId,
          )
        ) {
          available = true;
        } else {
          available = false;
        }
      }

      return available;
    },
    [selectedContexts],
  );

  const toggleSelectedContext = useCallback(
    (context: ContextType): void => {
      if (isContextAvailable(context)) {
        const selContexts: ContextType[] = [...selectedContexts];
        const selectedContext = getSelectedContextIndex(context);
        if (selectedContext > -1) {
          selContexts.splice(selectedContext, 1);
        } else {
          selContexts.push(context);
        }
        onContextAdded(selContexts);
        setSelectedContexts(selContexts);
      }
    },
    [getSelectedContextIndex, isContextAvailable, onContextAdded, selectedContexts],
  );

  const fetchContexts = useCallback(async () => {
    setContextLoading(true);
    const url = buildActionUrl({ projectId }, TYPE_POST_SELECTABLE_CONTEXTS_VERSION);
    await axios
      .post(url)
      .then((res: AxiosResponse) => res.data)
      .then((result: { contextsWithVersions: ContextType[] }) => {
        if (result && result.contextsWithVersions) {
          const filteredContexts: any = [];

          result.contextsWithVersions.forEach((context: any) => {
            if (context.contextVersions) {
              context.contextVersions.forEach((version: ContextType) => {
                const id = existingProjectContexts?.findIndex((c) => c.contextId === version.contextId);
                if (id === -1) {
                  filteredContexts.push(version);
                }
              });
            }
          });

          setContext(
            filteredContexts.filter((context: any) =>
              hiddenDialogContexts ? hiddenDialogContexts.indexOf(context.contextId || '') === -1 : true,
            ),
          );
        }
        setContextLoading(false);
      });
  }, [projectId, hiddenDialogContexts, existingProjectContexts]);

  const filterLastVersions = useCallback(() => {
    const filteredContexts: any[] = [];
    if (showOnlyLastVersions) {
      contexts.forEach((context: any) => {
        const id = filteredContexts.findIndex((c: any) => c.contextId === context.contextId);
        if (id > -1) {
          filteredContexts[id] =
            context.contextVersionId > filteredContexts[id].contextVersionId ? context : filteredContexts[id];
        } else {
          filteredContexts.push(context);
        }
      });
      setDisplayedContexts([...filteredContexts]);
    } else {
      setDisplayedContexts([...contexts]);
    }
  }, [contexts, showOnlyLastVersions]);

  const getAvailableContextsNumbers = useCallback(() => {
    let available = 0;
    displayedContexts.forEach((context) => {
      if (isContextAvailable(context)) available += 1;
    });
    return available;
  }, [displayedContexts, isContextAvailable]);

  const onSelectAllVisibleClick = useCallback(
    (event: any) => {
      if (event.target.checked) {
        setSavedSelection([...selectedContexts]);
        displayedContexts.forEach((context) => {
          const id = getSelectedContextIndex(context);
          if (id === -1 && isContextAvailable(context)) {
            selectedContexts.push(context);
            setSelectedContexts([...selectedContexts]);
          }
        });
      } else {
        displayedContexts.forEach((context) => {
          const id = getSelectedContextIndex(context);
          if (id > -1 && savedSelection.findIndex((c) => c.contextId === context.contextId) === -1) {
            selectedContexts.splice(id, 1);
            setSelectedContexts([...selectedContexts]);
          }
        });
      }
      onContextAdded(selectedContexts);
    },
    [displayedContexts, selectedContexts, savedSelection, getSelectedContextIndex, isContextAvailable, onContextAdded],
  );

  useEffect(() => {
    fetchContexts();
  }, [fetchContexts]);

  useEffect(() => {
    filterLastVersions();
  }, [contexts, showOnlyLastVersions, filterLastVersions]);

  return (
    <Box data-id-cypress="phaseGlobalContextsDialog" marginTop={1}>
      <Paper>
        <DialogContent key={projectId} dividers={true}>
          {contextLoading ? (
            <Box display={'flex'} justifyContent={'center'}>
              <Box display={'flex'} alignItems={'center'}>
                <CircularProgress />
                <Typography marginLeft={1}>Loading...</Typography>
              </Box>
            </Box>
          ) : (
            <ContextListGrid
              contexts={displayedContexts}
              selectedContexts={selectedContexts}
              headerActions={
                <Box display={'flex'} alignItems={'center'} width={'100%'} justifyContent={'flex-end'} marginTop={1}>
                  <InputLabel>
                    <Switch
                      checked={selectedContexts.length >= getAvailableContextsNumbers()}
                      onClick={onSelectAllVisibleClick}
                    ></Switch>
                    Select all visible concepts
                  </InputLabel>
                  <InputLabel>
                    <Switch
                      checked={showOnlyLastVersions}
                      onChange={(value) => setShowOnlyLastVersions(value.target.checked)}
                    ></Switch>
                    Show only last versions
                  </InputLabel>
                </Box>
              }
              disabledCardMessage={
                <Box display={'flex'} justifyContent={'center'} marginTop={2}>
                  <Warning></Warning>
                  You already selected another version of this context
                </Box>
              }
              cardTitleAction={(context) => (
                <Checkbox
                  data-id-cypress="phaseGlobalContextsDialogCheckbox"
                  onClick={() => toggleSelectedContext(context)}
                  checked={getSelectedContextIndex(context) > -1}
                  disabled={!isContextAvailable(context)}
                />
              )}
              cardAction={(context) => () => toggleSelectedContext(context)}
            />
          )}
          {contexts.length === 0 && !contextLoading && (
            <Box display={'flex'} justifyContent={'center'} marginTop={2}>
              <Typography display={'flex'} alignItems={'center'}>
                <SearchOff></SearchOff>
                There is no context to display
              </Typography>
            </Box>
          )}
        </DialogContent>
      </Paper>
    </Box>
  );
};

ContextsDialog.defaultProps = {
  hiddenDialogContexts: undefined,
  existingProjectContexts: undefined,
};

type ContextVersionsList = {
  [key: string]: string | null | undefined;
};

const PhaseGlobalContexts: React.FC<GlobalContextsProps> = ({
  projectId,
  project,
  dispatch,
  phaseId,
  existingProjectContexts,
  run,
  onClose,
}) => {
  // const canEditProject = isProjectEditable(project);
  const [savingLoading, setSavingLoading] = useState<boolean>(false);
  const [loadingContexts, setLoadingContexts] = useState<boolean>(false);
  const [contextToRemove, setContextToRemove] = useState<string>('');

  const [newSelectedContexts, setNewSelectedContexts] = useState<ContextType[]>([]);

  const [hiddenDialogContexts, setHiddenDialogContexts] = useState<string[]>([]);

  const refChangedContextVersions = useRef<ContextVersionsList>({});

  const getProjectSelectedContexts = useCallback(async () => {
    setLoadingContexts(true);
    const url = buildActionUrl({ projectId }, TYPE_POST_SELECTABLE_CONTEXTS_VERSION);
    const { contextsWithVersions } = await axios.post(url).then((res) => res.data);
    const filteredContexts: ContextType[] = [];

    if (contextsWithVersions) {
      contextsWithVersions.forEach((selContextV: any) => {
        if (selContextV?.contextVersions) {
          selContextV.contextVersions.forEach((contextVersion: ContextType) => {
            if (contextVersion?.contextVersionId) {
              contextVersion.name = `${contextVersion.contextId} - ${contextVersion.contextVersionId}`;
              filteredContexts.push(contextVersion);
            }
          });
        }
        if (existingProjectContexts.length > 0) {
          existingProjectContexts.forEach((context: any) => {
            if (context.contextId === selContextV.context.contextId) {
              hiddenDialogContexts.push(selContextV?.context.contextId);
            }
          });
        }
      });
      setHiddenDialogContexts([...hiddenDialogContexts]);
    }
    setLoadingContexts(false);
    // eslint-disable-next-line
  }, [phaseId, projectId, run.runId]);

  const selectNewContexts = (contexts: ContextType[]) => {
    setNewSelectedContexts([...contexts]);
  };

  const submitContexts = useCallback(async () => {
    setSavingLoading(true);

    // For all new contexts
    const newContextPromises = [];
    for (let i = 0; i < newSelectedContexts.length; i++) {
      const context: ContextType = newSelectedContexts[i];
      const url = buildActionUrl(
        {
          projectId,
          phaseId,
          runId: run.runId,
        },
        TYPE_PHASE_RUN_INPUT,
      );
      try {
        newContextPromises.push(
          axios.post(url, {
            scopeDefinition: {
              createContextSelection: {
                contextSelection: {
                  contextId: context.contextId,
                  contextVersionId: context.contextVersionId,
                },
                calculateRecipeCount: true,
              },
            },
          }),
        );
      } catch (error) {
        console.log('Error: Failed to create context', error);
      }
    }

    await Promise.all(newContextPromises);

    setSavingLoading(false);
    refChangedContextVersions.current = {};
    setNewSelectedContexts([]);
    onClose();
  }, [projectId, phaseId, run.runId, newSelectedContexts, onClose]);

  const versionsPassCheck = useRef(false);

  const removeContextFromProject = async (contextId: string) => {
    setSavingLoading(true);
    const url = buildActionUrl(
      {
        projectId,
        phaseId,
        runId: run.runId,
      },
      TYPE_PHASE_RUN_INPUT,
    );
    try {
      await axios.post(url, {
        scopeDefinition: {
          deleteContextSelection: {
            contextId,
          },
        },
      });
      await getProjectSelectedContexts();
    } catch (error) {
      // error log
    }

    setSavingLoading(false);
  };

  useEffect(() => {
    dispatch({ type: FlowActions.setPrimaryNavigationVisible, visible: false });
    dispatch({
      type: FlowActions.setApplyButton,
      button: (
        <>
          <Button
            style={{ marginRight: 10 }}
            variant="contained"
            color="secondary"
            disabled={savingLoading}
            onClick={onClose}
          >
            Back to define scope
          </Button>
          <Button
            data-id-cypress="phaseGlobalContextsApplyButton"
            disabled={newSelectedContexts.length === 0 || savingLoading}
            variant="contained"
            color="primary"
            onClick={submitContexts}
            style={{ marginRight: 10 }}
          >
            Apply
            {savingLoading && <Loading size="1rem" marginLeft={10} message=" " />}
          </Button>
        </>
      ),
    });
  }, [submitContexts, versionsPassCheck, savingLoading, onClose, dispatch, newSelectedContexts]);

  return (
    <>
      {loadingContexts ? (
        <Loading />
      ) : (
        <>
          <div style={{ opacity: savingLoading ? 0.5 : 1 }}>
            <SectionTitle
              title="Project Contexts"
              action={
                <>
                  <DocLink link={DocLinkUrl[DocTypeEnum.CreateCloneProjectDialog]} />
                </>
              }
              useCommonStyles={true}
            />
            <Box>
              <Typography variant="body1" align="left">
                Define the contexts to select relevant content for the project.
              </Typography>
            </Box>
            {/* Contexts selected from dialog */}
          </div>
          <>
            <FlowButtons
              activeStep={1}
              project={project}
              dispatch={dispatch}
              backDisabled={savingLoading}
              nextDisabled={savingLoading}
              actionButton={
                <Button
                  disabled={!versionsPassCheck || savingLoading}
                  variant="contained"
                  color="primary"
                  onClick={submitContexts}
                >
                  Apply
                  {savingLoading && <Loading size="1rem" marginLeft={10} message=" " />}
                </Button>
              }
            />
            <ContextsDialog
              hiddenDialogContexts={hiddenDialogContexts}
              projectId={projectId}
              onContextAdded={selectNewContexts}
              existingProjectContexts={existingProjectContexts}
            />
            {contextToRemove && (
              <RemoveContextFromProjectDialog
                handleClose={() => setContextToRemove('')}
                contextId={contextToRemove}
                projectId={projectId}
                removeContext={() => {
                  removeContextFromProject(contextToRemove).then(() => null);
                  setContextToRemove('');
                }}
              />
            )}
          </>
        </>
      )}
    </>
  );
};

export default PhaseGlobalContexts;
