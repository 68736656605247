import '../../../layout/data-man.scss';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import React, {
  CSSProperties,
  FC,
  isValidElement,
  memo,
  MouseEvent,
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

import Confirm from '../components/Confirm';
import { DataManFilterDialog, DataManListNoRecords, DataManSortIndicator } from '../dataman';
import {
  ColumnIdentifier,
  DataManAppliedFilter,
  DataManColumn,
  DataManConfig,
  DataManListSortDirection,
  DataManListSortOptions,
  DataManObject,
  DataManRowActionsCellProps,
  DataManRowActionsCellPropsWithAction,
  DataManRowCellWrapperProps,
  HeadCellRenderProps,
  RowCellRenderProps,
} from '../types';
import { getIconFromProp, getSortableOptions } from '../utils';

interface DataManColumnCellWrapperProps {
  column: DataManColumn;
  columnIdentifier: ColumnIdentifier;
  sort: DataManListSortOptions;
  handleChangeSort: (column: ColumnIdentifier, direction: DataManListSortDirection, append: boolean) => void;
  handleChangeFilter: (column: ColumnIdentifier, filters: DataManAppliedFilter[]) => void;
  filters?: DataManAppliedFilter[] | null;
}

interface DataManListProps {
  isLoading: boolean;
  config: DataManConfig;
  dataMan: DataManObject;
  sortFields: DataManListSortOptions;
  dataFilters: { [key: string]: DataManAppliedFilter[] };
  rows: any[];
}

export const DataManColumnRender: FC<HeadCellRenderProps> = ({ column }) => <>{column.label}</>;

const dataManAutoHeightFallbackPosition = 250;
const dataManAutoHeightBottomMargin = 20;

const DataManRowActionButton: FC<DataManRowActionsCellPropsWithAction> = ({ dataMan, record, action }) => {
  const disabled = false;
  const {
    buttonIcon,
    buttonLabel,
    confirmAction,
    confirmMessage,
    confirmTitle,
    disabled: isActionDisabled,
    handleAction,
    handleConfirm,
    tooltip,
  } = action;
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleDialogClose = useCallback(() => setShowConfirm(false), [setShowConfirm]);
  const handleConfirmCallback = useCallback(async () => {
    if (handleConfirm) {
      setIsLoading(true);
      const result = await handleConfirm(action, record, dataMan);

      if (result !== false) {
        setShowConfirm(false);
      }

      setIsLoading(false);
    }
  }, [action, handleConfirm, record, dataMan, setIsLoading, setShowConfirm]);
  const ActionComponent = useMemo(
    () => (
      <Button
        disabled={isActionDisabled}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (confirmAction) {
            setShowConfirm(true);
          }

          if (!confirmAction && handleAction) {
            handleAction(action, record, dataMan);
          }
        }}
      >
        {buttonIcon && getIconFromProp(buttonIcon)}
        {buttonLabel}
      </Button>
    ),
    [action, buttonIcon, buttonLabel, confirmAction, isActionDisabled, handleAction, dataMan, record],
  );

  return (
    <>
      {tooltip ? <Tooltip title={tooltip}>{ActionComponent}</Tooltip> : ActionComponent}
      {!disabled && (
        <Confirm
          isOpen={showConfirm}
          title={confirmTitle instanceof Function ? confirmTitle(action, record, dataMan) : confirmTitle}
          content={confirmMessage instanceof Function ? confirmMessage(action, record, dataMan) : confirmMessage}
          onConfirm={handleConfirmCallback}
          onClose={handleDialogClose}
          confirmLabel="Delete"
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export const DataManRowActionsCell: FC<DataManRowActionsCellProps> = ({ actions, dataMan, record }) => (
  <>
    {actions.map((action) => (
      <DataManRowActionButton
        key={action.buttonLabel || action.tooltip}
        actions={actions}
        action={action}
        dataMan={dataMan}
        record={record}
      />
    ))}
  </>
);

export const DataManColumnCellWrapper: FC<PropsWithChildren<DataManColumnCellWrapperProps>> = ({
  children,
  column,
  sort,
  handleChangeSort,
  handleChangeFilter,
  columnIdentifier,
  filters,
}) => {
  const { sortable, width, filterable, filterOptions } = column;
  const style: CSSProperties = {};
  const sortOptions = getSortableOptions(sort, columnIdentifier);
  const { field: sortField, direction: sortDirection } = sortOptions || {};
  const wrapperClasses = ['DataMan__columnCellWrapper'];
  const [filterAnchorEl, setFilterAnchorEl] = useState<HTMLDivElement | null>(null);
  const filterOpen = useMemo(() => Boolean(filterAnchorEl), [filterAnchorEl]);
  const filterAnchorId = useMemo(() => (filterOpen ? 'simple-popover' : undefined), [filterOpen]);

  // callbacks definition
  const handleFilterClose = useCallback(() => {
    setFilterAnchorEl(null);
  }, [setFilterAnchorEl]);
  const handleFilterClick = useCallback(
    (event: MouseEvent<HTMLDivElement>): void => {
      event.stopPropagation();
      event.preventDefault();
      setFilterAnchorEl(event.currentTarget);
    },
    [setFilterAnchorEl],
  );
  const sortColumns = useCallback(
    (e: any): void => {
      if (sortable) {
        const newDirection = sortDirection === 'ASC' ? 'DESC' : 'ASC';
        handleChangeSort(
          sortField || columnIdentifier,
          sortDirection ? newDirection : 'ASC',
          sortField ? true : e.altKey,
        );
      }
    },
    [sortable, sortField, sortDirection, columnIdentifier, handleChangeSort],
  );
  const applyFilterAndCloseDialog = useCallback(
    (columnId: ColumnIdentifier, appliedFilters: DataManAppliedFilter[]): void => {
      handleChangeFilter(columnId, appliedFilters);
      handleFilterClose();
    },
    [handleChangeFilter, handleFilterClose],
  );

  if (width !== undefined && width !== null) style.width = width;
  if (sortable) wrapperClasses.push('DataMan__columnCellSortable');
  if (filterable) wrapperClasses.push('DataMan__columnCellFilterable');
  if (filterable && filters?.length) wrapperClasses.push('DataMan__columnFilterActive');

  // @TODO-eslint check if mouse down works as onclick
  return (
    <TableCell tabIndex={-1} className={wrapperClasses.join(' ')} style={style}>
      <div
        aria-hidden="true"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onMouseDown={sortColumns}
      >
        <div style={{ flex: 1, padding: '20px 8px' }}>{children}</div>
        <div
          style={{
            visibility: sortable && sortField ? 'visible' : 'hidden',
            padding: '20px 2px',
          }}
        >
          {sortable && <DataManSortIndicator direction={sortDirection || 'ASC'} />}
        </div>
        {filterable && (
          <div
            style={{
              position: 'relative',
              pointerEvents: !filterOptions?.options?.length ? 'none' : 'all',
            }}
          >
            <div
              aria-hidden="true"
              className="DataMan__columnFilterButton"
              aria-describedby={filterAnchorId}
              onMouseDown={handleFilterClick}
            >
              <img src="/filter-icon.svg" alt="Filters" />
            </div>
          </div>
        )}
      </div>
      {filterable && (
        <Popover
          id={filterAnchorId}
          open={filterOpen}
          anchorEl={filterAnchorEl}
          onClose={handleFilterClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <DataManFilterDialog
            filters={filters}
            handleChangeFilter={applyFilterAndCloseDialog}
            options={filterOptions}
            column={column}
            columnIdentifier={columnIdentifier}
          />
        </Popover>
      )}
    </TableCell>
  );
};

DataManColumnCellWrapper.defaultProps = {
  filters: undefined,
};

export const DataManRowCellWrapper: FC<PropsWithChildren<DataManRowCellWrapperProps>> = ({ children, colSpan = 1 }) => (
  <TableCell colSpan={colSpan} className="DataMan__rowCellWrapper">
    {' '}
    {children}{' '}
  </TableCell>
);

export const DataManRowActionsCellWrapper: FC<PropsWithChildren<any>> = ({ children }) => (
  <TableCell className="DataMan__rowActionCellWrapper"> {children} </TableCell>
);

const TableList: FC<DataManListProps> = ({ isLoading, config, sortFields, dataFilters, dataMan, rows }) => {
  const { list } = config;
  const { columns } = list;
  const columnIds = useMemo(() => Object.keys(columns), [columns]);
  const actionsColumnIdentifier = 'actions';
  const actionsColumn: DataManColumn = { label: '' };
  const showActions = useMemo(() => list.actions && list.actions.length > 0, [list.actions]);
  const domRef = useRef<HTMLDivElement>(null);
  const domRefY = domRef?.current?.getBoundingClientRect()?.y
    ? domRef.current.getBoundingClientRect().y + dataManAutoHeightBottomMargin
    : dataManAutoHeightFallbackPosition;
  const containerY = String(domRef.current ? domRefY : dataManAutoHeightFallbackPosition);

  return (
    <div ref={domRef}>
      <Box position="relative">
        <TableContainer
          component={Paper}
          style={{ maxHeight: `calc(100vh - ${containerY}px)` }}
          className="DataMan__tableContainer"
        >
          <Table stickyHeader={true}>
            <TableHead>
              <TableRow>
                {columnIds.map((columnName) => {
                  const column: DataManColumn = columns[columnName];
                  const { renderHeadCell } = column;
                  const dataManProps: HeadCellRenderProps = {
                    config,
                    column,
                    columnIdentifier: columnName,
                  };
                  const dataManColumnCellWrapperProps: DataManColumnCellWrapperProps = {
                    column,
                    columnIdentifier: columnName,
                    sort: sortFields,
                    handleChangeSort: dataMan.sortColumn,
                    handleChangeFilter: dataMan.applyColumnFilters,
                    filters: dataFilters[columnName] ? dataFilters[columnName] : null,
                  };

                  if (renderHeadCell) {
                    const renderComponent = renderHeadCell(columnName, config);

                    if (isValidElement(renderComponent)) {
                      return (
                        <DataManColumnCellWrapper key={columnName} {...dataManColumnCellWrapperProps}>
                          {renderComponent}
                        </DataManColumnCellWrapper>
                      );
                    }
                    if (typeof renderComponent === 'function') {
                      return (
                        <DataManColumnCellWrapper key={columnName} {...dataManColumnCellWrapperProps}>
                          {' '}
                          {renderComponent(dataManProps)}
                        </DataManColumnCellWrapper>
                      );
                    }
                  }

                  return (
                    <DataManColumnCellWrapper key={columnName} {...dataManColumnCellWrapperProps}>
                      <DataManColumnRender {...dataManProps} />
                    </DataManColumnCellWrapper>
                  );
                })}
                {showActions && (
                  <DataManColumnCellWrapper
                    {...{
                      column: actionsColumn,
                      config,
                      sort: [],
                      handleChangeFilter: () => null,
                      handleChangeSort: () => null,
                      columnIdentifier: actionsColumnIdentifier,
                    }}
                  >
                    <DataManColumnRender
                      {...{
                        config,
                        column: actionsColumn,
                        columnIdentifier: actionsColumnIdentifier,
                      }}
                    />
                  </DataManColumnCellWrapper>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 && (
                <>
                  {rows.map((row, idx) => (
                    <TableRow
                      key={row?.name || idx}
                      className={list.lineClick ? 'DataMan__clickableTableRow' : ''}
                      onClick={() => {
                        list?.lineClick?.(row, dataMan);
                      }}
                    >
                      {columnIds.map((columnName) => {
                        const col = columns[columnName];
                        const { getColSpan, isColumnHidden, renderRowCell } = col;
                        const colSpan = getColSpan?.(columnName, col, row);
                        const isColHidden = isColumnHidden?.(columnName, col, row) || false;
                        const dataManProps: RowCellRenderProps = {
                          value: row[columnName],
                          row,
                          columnIdentifier: columnName,
                          column: col,
                          config,
                        };

                        if (isColHidden) return <></>;

                        if (renderRowCell) {
                          const renderComponent = renderRowCell(columnName, row[columnName], row, config);

                          if (isValidElement(renderComponent)) {
                            return (
                              <DataManRowCellWrapper colSpan={colSpan} key={`row-cell-renderer-1-${columnName}`}>
                                {renderComponent}
                              </DataManRowCellWrapper>
                            );
                          }
                          if (typeof renderComponent === 'function') {
                            return (
                              <DataManRowCellWrapper colSpan={colSpan} key={`row-cell-renderer-2-${columnName}`}>
                                {' '}
                                {renderComponent(dataManProps)}
                              </DataManRowCellWrapper>
                            );
                          }
                        }

                        return (
                          <DataManRowCellWrapper key={`row-cell-renderer-default- ${columnName}`} colSpan={colSpan}>
                            {row[columnName] && <>{String(row[columnName])}</>}
                          </DataManRowCellWrapper>
                        );
                      })}

                      {showActions && (
                        <DataManRowActionsCellWrapper>
                          <DataManRowActionsCell record={row} dataMan={dataMan} actions={list.actions || []} />
                        </DataManRowActionsCellWrapper>
                      )}
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>

          {rows.length < 1 && <DataManListNoRecords />}

          {list.listFooter}

          {list.useLoadMore && (
            <Box style={{ padding: 20, textAlign: 'center' }}>
              <Button
                disabled={isLoading || !list.loadMoreEnabled}
                onClick={() => {
                  dataMan.loadMore(dataFilters, sortFields);
                }}
              >
                Load more
              </Button>
            </Box>
          )}
        </TableContainer>

        {(isLoading || config.loading) && (
          <div className="DataMan__tableLoading">
            <CircularProgress size={64} className="DataMan__loadProgress" />
          </div>
        )}
      </Box>
    </div>
  );
};

function areEqual(p: DataManListProps, n: DataManListProps): boolean {
  return (
    p.isLoading === n.isLoading &&
    p.config?.loading === n.config?.loading &&
    JSON.stringify(p.config.list.columns) === JSON.stringify(n.config.list.columns) &&
    JSON.stringify(p.sortFields) === JSON.stringify(n.sortFields) &&
    JSON.stringify(p.dataFilters) === JSON.stringify(n.dataFilters) &&
    JSON.stringify(p.rows) === JSON.stringify(n.rows) &&
    JSON.stringify(p.dataMan) === JSON.stringify(n.dataMan)
  );
}

export default memo(TableList, areEqual);
