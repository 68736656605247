import '../../../../layout/project-flow.scss';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';

import SectionTitle from '../../../../components/SectionTitle';
import { buildActionUrl, TYPE_UPDATE_PHASE } from '../../../../shared/url';
import { getAllRuns } from '../_utils/datasources';
import { JobStatus, PhaseType, RunType } from '../_utils/types';

export type PreviousRunSelectorProps = {
  phase: PhaseType | null;
  projectId: string | undefined;
  isButtonDisabled: boolean;
  isEditMode: boolean;
  toggleEditMode: (toggle: boolean) => void;
};

const PreviousRunSelector: React.FC<PreviousRunSelectorProps> = ({
  phase,
  projectId,
  isButtonDisabled,
  isEditMode,
  toggleEditMode,
}) => {
  const [parentPhaseRuns, setParentPhaseRuns] = useState<RunType[] | null>([]);
  const [runCheckboxes, setRunCheckboxes] = React.useState<{
    [x: string]: boolean;
  }>({});
  const [checkedRunList, setCheckedRunList] = React.useState<string[]>(phase?.dependsOnRuns || []);
  const parentPhaseId = phase?.dependsOnPhases?.[0];

  const loadRunsParentPhase = useCallback(async () => {
    if (parentPhaseId) {
      const runs = await getAllRuns(projectId, parentPhaseId);
      const runNames = runs?.map((item) => item.runName);

      if (runs) {
        const preparedCheckboxes = runNames?.reduce(
          (obj, item, index) => ({
            ...obj,
            [item]: !!phase?.dependsOnRuns?.includes(runs[index].runId),
          }),
          {},
        );

        setRunCheckboxes(preparedCheckboxes || {});
      }

      const generatedRuns: RunType[] = [];
      runs?.forEach((run) => {
        const outputStatusOk = run?.outputs?.output_generation?.status === JobStatus.ok;

        if (outputStatusOk) {
          generatedRuns.push(run);
        }
      });

      setParentPhaseRuns(generatedRuns);
    }
  }, [phase?.dependsOnRuns, projectId, parentPhaseId]);

  useEffect(() => {
    loadRunsParentPhase().then((r) => r);
  }, [loadRunsParentPhase]);

  const handleCheckboxes = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { checked } = event.target;
    setRunCheckboxes({ ...runCheckboxes, [event.target.name]: checked });

    if (parentPhaseRuns) {
      const checkedRunId = parentPhaseRuns.filter((item) => item.runName === event.target.name)[0]?.runId;

      setCheckedRunList(
        checked ? [...checkedRunList, checkedRunId] : checkedRunList.filter((id) => id !== checkedRunId),
      );
    }
  };

  const handleSaveCheckedRuns = useCallback(
    async (projectIdentifier: string, phaseObject?: PhaseType): Promise<void> => {
      if (phaseObject) {
        const { phaseId } = phaseObject;
        const url = buildActionUrl({ projectId: projectIdentifier, phaseId }, TYPE_UPDATE_PHASE);
        await axios.post(url, {
          ...phaseObject,
          dependsOnRuns: checkedRunList,
        });

        toggleEditMode(false);
      }
    },
    [toggleEditMode, checkedRunList],
  );

  return (
    <>
      <Box display="flex" flexDirection="row" className="ProjectFlow__stepTitleWrapper">
        <SectionTitle title="Previous phase runs" variant="body1" />
      </Box>
      <Box data-id-cypress="previousRunSelector" display="flex" flexDirection="row" marginBottom={2}>
        <FormControl component="fieldset">
          <Box display="flex" marginBottom={2}>
            <Typography>
              All runs of the previous phase in the workflow are listed below. Select the runs this step should depend
              on. <br />
              Note that this setting applies to the complete phase. Thus, it impacts all runs of this phase.
            </Typography>
          </Box>
          <Box display="flex">
            <FormGroup className="ProjectFlow__formGroup">
              {parentPhaseRuns?.map((field) => (
                <FormControlLabel
                  key={field.runName}
                  label={field.runName}
                  control={
                    <Checkbox
                      disabled={isButtonDisabled || !isEditMode}
                      key={field.runName}
                      checked={runCheckboxes[field.runName]}
                      onChange={handleCheckboxes}
                      name={field.runName}
                    />
                  }
                />
              ))}
            </FormGroup>
          </Box>
          <Box>
            <Button
              key="save-depends-on-runs"
              variant="outlined"
              className="ProjectFlow__button"
              disabled={isButtonDisabled || !isEditMode}
              onClick={() => handleSaveCheckedRuns(projectId || '', phase || undefined)}
            >
              Save
            </Button>
            <Button
              key="edit-depends-on-runs"
              variant="outlined"
              className="ProjectFlow__button"
              disabled={isButtonDisabled || isEditMode}
              onClick={() => toggleEditMode(true)}
            >
              Edit
            </Button>
          </Box>
        </FormControl>
      </Box>
    </>
  );
};

export default PreviousRunSelector;
