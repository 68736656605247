import { ProjectPhaseType } from './admin/projects/ProjectFlow/_utils/types';
import { DishType } from './features/concepts/types';

export type ThemeName = 'light' | 'dark';

export type OutputUrls = {
  output: string | null;
  template: string | null;
  extra?: string;
};

export type OutputType = {
  urls: OutputUrls | null;
  jobId: string | null;
  status: JobStatus;
};

export enum OutputName {
  Output1 = 'output1',
  Output2 = 'output2',
  Output3 = 'output3',
}

export enum JobStatus {
  NotFound = 'JOB_STATUS_NOT_FOUND',
  Created = 'JOB_STATUS_CREATED',
  InProgress = 'JOB_STATUS_IN_PROGRESS',
  Ok = 'JOB_STATUS_OK',
  Failed = 'JOB_STATUS_FAILED',
  Aborted = 'JOB_STATUS_ABORTED',
}

export enum ProjectStatus {
  Unknown = 'PROJECT_STATUS_UNKNOWN',
  Created = 'PROJECT_STATUS_CREATED',
  Configured = 'PROJECT_STATUS_CONFIGURED',
  Provisioning = 'PROJECT_STATUS_PROVISIONING',
  Provisioned = 'PROJECT_STATUS_PROVISIONED',
  Locked = 'PROJECT_STATUS_LOCKED',
  Archiving = 'PROJECT_STATUS_ARCHIVING',
  Archived = 'PROJECT_STATUS_ARCHIVED',
  Restoring = 'PROJECT_STATUS_RESTORING',
  Restored = 'PROJECT_STATUS_RESTORED',
  Removing = 'PROJECT_STATUS_REMOVING',
  Removed = 'PROJECT_STATUS_REMOVED',
  ArchiveFailed = 'PROJECT_STATUS_ARCHIVE_FAILED',
  RestoreFailed = 'PROJECT_STATUS_RESTORE_FAILED',
  RemoveFailed = 'PROJECT_STATUS_REMOVE_FAILED',
}

export type ProjectTypeBrief = {
  objective: '' | null;
  baseIngredients: [] | null;
  market: [] | null;
  targetAudience: [] | null;
  consumptionMoment: [] | null;
  conceptProfiles: [] | null;
  inspirationCategories: [] | null;
  flavorSpace: [] | null;
  conceptRestrictions: [] | null;
};

export type ProjectType = {
  status: ProjectStatus;
  type: string;
  projectId: string;
  company: string;
  description: string;
  coreVersion: string;
  recipesVersion: string;
  sensoryVersion: string;
  socialPostVersion: string;
  trendsVersion: string;
  languages: string[];
  countries: string[];
  outputs: { [outputName: string]: OutputType };
  baseIngredients: IngredientDBIngredientType[];
  minimumRecipeCount: number;
  ingredientPopularityThreshold: number;
  cfiScoreRangeMinimum: number;
  cfiScoreRangeMaximum: number;
  projectBrief: ProjectTypeBrief;
  resourceMetadata?: { createdAt?: string; createdBy?: string };
  firstPhaseId?: string;
  workflowConfig: WorkflowConfig[];
  ingredientDbVersion: string;
  outputGenerationByRunId?: any;
};

export type WorkflowConfig = {
  title: string;
  description: string;
  options?: {
    select_previous_runs?: boolean;
    input_preparation?: boolean;
  };
  type?: ProjectPhaseType;
  workflowStepId?: string | undefined;
};

export type ContextType = {
  contextId?: string;
  description?: string;
  name: string;
  resourceMetadata?: {
    changedAt: string;
    changedBy: string;
    createdAt: string;
    createdBy: string;
  };
  imageUrl?: string;
  recipeSelections: RecipeSelectionType[];
  socialPostsSelections: SocialPostSelectionType[];
  contextVersionId?: string;
  contextVersions?: ContextVersionType[];
  version?: string;
};

export type CategoryType = {
  name: string;
  tags: string[];
};

export type SocialPostSelectionType = {
  category: string;
  posts: { tag: string }[];
};

export type RecipeSelectionType = {
  dishType: string;
  regionType: string;
  tasteType: string;
};

export type RecipeFiltersType = {
  dishTypes: string[];
  regionTypes: string[];
  tasteTypes: string[];
};

export enum JobExecutor {
  default = 'JOB_EXECUTOR_DEFAULT',
  manual = 'JOB_EXECUTOR_MANUAL',
}

export type JobType = {
  workflowStepId: string;
  phaseId: string;
  runId: string;
  externalJobId?: string;
  jobType: string;
  jobId: string;
  status: JobStatus;
  errorMessage: string | null;
  createdAt: Date;
  startedAt: Date | null;
  finishedAt?: Date | null;
  startedByUser: string | null;
  projectId?: string;
  output?: {
    resources?: {
      [key: string]: Resource;
    };
    values?: {
      [key: string]: any;
    };
  };
  executor: JobExecutor;
};

export interface Sheet {
  sheetType: string;
  sheetUrl: string | any;
}

export interface Table {
  tableName: string;
  tableType: string;
}

export type Resource = {
  sheets?: {
    sheets?: Sheet[];
  };
  tables?: {
    tables?: Table[];
  };
};

export enum GenericIngredientFields {
  name = 'name',
}

export enum DBIngredientFields {
  ingredientId = 'ingredientId',
  productPreparationId = 'productPreparationId',
  imageUrl = 'imageUrl',
  referenceIngredientId = 'referenceIngredientId',
  aromaProductPreparationId = 'aromaProductPreparationId',
}

export type GenericIngredientType = Record<string, any>;

export interface CoreIngredientType extends GenericIngredientType {
  name: string;
  productPreparationId?: number;
}

export interface IngredientDBIngredientType extends GenericIngredientType {
  ingredientId: number;
  name: string;
  productPreparationId?: number;
  imageUrl?: string;
  referenceIngredientId?: number;
  aromaProductPreparationId?: number;
}
export interface IngredientDBIngredientTypeWithSensoryRemapping extends IngredientDBIngredientType {
  createdAt?: number;
  project?: string;
  correctionMessage?: string;
}
export interface SensoryIngredientType extends GenericIngredientType {
  ingredientId: number;
}

export type DataVersionsType = {
  coreVersions: string[];
  recipeVersions: string[];
  socialPostVersions: string[];
  trendsVersions: string[];
  availableCountries: string[];
  ingredientDbVersions: string[];
  sensoryVersions: string[];
  dishTypesVersions: string[];
};

export enum DataSourceType {
  Core = 'coreVersion',
  SocialPosts = 'socialPostVersion',
  Recipe = 'recipesVersion',
  Trends = 'trendsVersion',
  IngredientDB = 'ingredientDbVersion',
  Sensory = 'sensoryVersion',
}

/// /////////////////////////////////////////////
// Annotations
//

export type LineExampleType = {
  original: string;
  translated: string | null;
};

type LemmaMappingInfoType = {
  ingredient: string;
  preparation: string | null;
  cut: string | null;
  mappingScore: number;
  occurences: number;
  mappedProduct: IngredientDBIngredientType | null;
  countryOfOrigin: string;
  examples: LineExampleType[];
};

export enum LemmaMappingStatus {
  NoInput = 'LEMMA_MAPPING_STATUS_NO_INPUT',
  Valid = 'LEMMA_MAPPING_STATUS_VALID',
  Invalid = 'LEMMA_MAPPING_STATUS_INVALID',
  Remapped = 'LEMMA_MAPPING_STATUS_REMAPPED',
}

export type LemmaMappingSelectionType = {
  status: LemmaMappingStatus;
  remappedProduct: IngredientDBIngredientType | null;
  issue: string;
};

// lemma mapping
export type IngredientMappingAnnotationInputType = {
  kind: 'ingredient_mapping';
  item: AnnotationItemType;
  info: LemmaMappingInfoType;
  selection: LemmaMappingSelectionType;
};

// multiple choice
export type MultipleChoiceAnnotationInputType = {
  item: AnnotationItemType;
  choices: AnnotationChoiceType[];
  selectedChoice: string | null;
};

// global
export type AnnotationInputType = {
  multipleChoice: MultipleChoiceAnnotationInputType | null;
  ingredientMapping: IngredientMappingAnnotationInputType | null;
};

export type AnnotationType = {
  name: string;
  kind: string;
  description: string;
  coreVersion: string;
  sourceVersion: string;
  createdAt: string;
  inputIndex: Map<string, number>;
};

export type AnnotationInputPageType = {
  inputs: AnnotationInputType[];
  nextPageId: number | null;
};

export type AnnotationItemType = {
  id: string;
  text: string;
};

export type AnnotationChoiceType = {
  id: string;
  text: string;
  url: string | null;
};

export type AnnotatedInputType = {
  itemText: string;
  multipleChoice: { selectedId: string } | null;
  ingredientMapping: LemmaMappingSelectionType | null;
  comment: string;
};

/// /////////////////////////////////////////////
// Concepts
//
export type ConceptMetricType = {
  name: string;
  value: number;
};

export type ConceptIngredientType = {
  name: string;
  imageUrl?: string;
  metrics?: ConceptMetricType[];
  contextAssociativity: number;
  foodpairingMatch: number;
  ingredientAssociativity: number;
  ingredientComboPopularity: number;
  ingredientPopularity: number;
  trend: number;
};

export type ConceptType = {
  name: string;
  description?: string;
  cfiScore?: number;
  ingredients: ConceptIngredientType[];
  likingScore?: number;
  appealScore?: number;
};

export enum FilterOperands {
  Contains = 'CONTAINS',
  Equals = 'EQUALS',
  InList = 'IN_LIST',
}

export enum SortDirections {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export interface QueryFilter {
  operand: FilterOperands.Equals | FilterOperands.InList | FilterOperands.Contains;
  value: string[];
  field: string;
}

export interface ConceptQueryFilter extends QueryFilter {
  field: ConceptFilterFields;
}

export enum ConceptFilterFields {
  ProjectId = 'PROJECT_CONCEPT_FILTER_FIELD_PROJECT_ID',
  Company = 'PROJECT_CONCEPT_FILTER_FIELD_COMPANY',
  Name = 'PROJECT_CONCEPT_FILTER_FIELD_CONCEPT_NAME',
  Ingredients = 'PROJECT_CONCEPT_FILTER_FIELD_INGREDIENTS',
}

export enum ConceptSortFields {
  ProjectId = 'PROJECT_CONCEPT_SORT_FIELD_PROJECT_ID',
  Company = 'PROJECT_CONCEPT_SORT_FIELD_COMPANY',
  Name = 'PROJECT_CONCEPT_SORT_FIELD_CONCEPT_NAME',
}

export enum JobSortFields {
  createdAt = 'QUERY_PROJECT_JOBS_SORT_FIELD_CREATED_AT',
}

export interface QuerySort<T> {
  field: T;
  sortDirection: SortDirections;
}

export type ConceptQueryFilters = ConceptQueryFilter[];

export interface ConceptQueryBody {
  startAtId?: string | null | undefined;
  filterOptions?: ConceptQueryFilters;
  sortOptions?: QuerySort<ConceptSortFields>[];
}

export interface JobQueryBody {
  startAtId?: string | null | undefined;
  sortOptions?: QuerySort<JobSortFields>[];
}

/// /////////////////////////////////////////////
// Layout
//

export interface ContextVersionType {
  contextId: string;
  contextVersionId: string;
  description: string;
  recipes: any[];
  recipesVersion: string;
  resourceMetadata: {
    changedAt: string;
    changedBy: string;
    createdAt: string;
    createdBy: string;
  };
  socialPosts: any[];
  dishTypes: DishType[];
  socialPostsVersion: string;
  dishTypesVersion?: string;
  status: string;
}

export interface SocialTagErrorItem {
  category: string;
  tags: string[];
}

export type CompanyType = {
  company: string;
};

export type ProjectCompanyType = {
  companyId: string;
  name: string;
  resourceMetadata: {
    changedAt: string;
    changedBy: string;
    createdAt: string;
    createdBy: string;
  };
};
