import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import flatten from 'lodash/flatten';
import mergeWith from 'lodash/mergeWith';
import pick from 'lodash/pick';

import {
  getGenericInput,
  IngredientDBMap,
  updateGenericInput,
} from '../../admin/projects/ProjectFlow/_utils/datasources';
import { buildActionUrl, TYPE_CONCEPT_TERRITORY_DEFINITIONS } from '../../shared/url';
import {
  IngredientDBIngredientType,
  JobStatus,
  OutputType,
  OutputUrls,
  ProjectType,
  SortDirections,
} from '../../types';
import { fetchBQTable } from '../datasources/query/bigquery';
import {
  initRun,
  setError,
  setIngredientsLoadingStatus,
  setInitialIngredients,
  setInitialLemmas,
  setLemmaLoadingStatus,
  setLemmas,
  setRunError,
  setRunGenericInput,
  setRunLoading,
  setTerritoryDefinitions,
  setTerritoryDefinitionsLoadingState,
} from './store';
import { setTerritoryDefinitionsWarningMessage } from './store/concept-generation-reducer-v3';
import { BackendLoadingStatus, GenericDataGridRow, GenericInputConfig, InputGenerationType } from './types/common';
import {
  APIConceptTerritoryDefinitionResponse,
  Composition,
  CompositionConfig,
  ConceptGenerationConfigResponse,
  ConceptTerritoryDefinition,
  IngredientClass,
  IngredientType,
  MetricWeights,
  SelectedIngredient,
  SingleMetricIngredientQuery,
} from './types/concept-generation';
import {
  LemmaQueryIngredient,
  LemmaTableRow,
  LemmaTableRowKeys,
  MetricsCalculationConfigResponse,
} from './types/metrics-calculation';

const { other, ...bases } = IngredientType;

const {
  lemmaKey,
  idKey,
  nameKey,
  refIdKey,
  refNameKey,
  correctedIdKey,
  correctedNameKey,
  isRelevantKey,
  correctedRefIdKey,
  correctedRefNameKey,
  projectKey,
  creationTimeKey,
  hideLemmasKey,
  messageKey,
} = LemmaTableRowKeys;

export const getJobStatus = (output: OutputType): { inProgress: boolean; success: boolean; outputUrls: OutputUrls } => {
  const outputStatus = output ? (output.status as JobStatus) : JobStatus.NotFound;
  const inProgress = outputStatus === JobStatus.InProgress || outputStatus === JobStatus.Created;
  const success = outputStatus === JobStatus.Ok;
  const outputUrls = (output ? output.urls : null) || {
    output: '',
    template: '',
  };

  return { inProgress, success, outputUrls };
};

export const isProjectEditable = (project: ProjectType): boolean =>
  project.status !== 'PROJECT_STATUS_LOCKED' && project.status !== 'PROJECT_STATUS_PROVISIONING';

export function prepareIngredientsToTable(
  data: (string | null | number | boolean)[][],
  keys = [
    'ingredient_id',
    'ingredient_name',
    'class',
    'is_base_ingredient',
    'sensory_remap_to',
    'sensory_remap_created_at',
    'sensory_remap_project_id',
  ],
): SelectedIngredient[] {
  /* eslint-disable */ // @ts-ignore
  return data.map((i) => prepareIngredient(Object.fromEntries(keys.map((field, index) => [field, i[index]])), true));
}

export function prepareLemmaTableRow(data: any[], fromBQ = false): LemmaTableRow[] {
  if (fromBQ) {
    return data.map((l) => {
      return {
        [lemmaKey]: l[0],
        [idKey]: l[1],
        [nameKey]: l[2],
        [refIdKey]: l[3],
        [refNameKey]: l[4],
        [correctedIdKey]: l[5],
        [correctedNameKey]: l[6],
        [isRelevantKey]: Boolean(l[7] || false),
        [hideLemmasKey]: Boolean(l[8] || false),
        [creationTimeKey]: l[9],
        [projectKey]: l[10],
        [correctedRefIdKey]: null,
        [correctedRefNameKey]: null,
        [messageKey]: null,
      };
    });
  } else {
    return data.map((l) => {
      return {
        ...l,
      };
    });
  }
}

/**
 * Validate that ANY ingredients come after BASE.
 *
 * @param rest
 */
export function getInvalidRowAnySlots(rest: { [key: string | number]: any }): string[] {
  return Object.keys(rest)
    .filter((k) => k !== 'id')
    .reduce((result, currentField, currentIndex, initialArray) => {
      if (currentField !== 'id' && rest[currentField]?.toLowerCase()?.includes('base')) {
        result = initialArray.filter(
          (key, idx) => key !== 'id' && !rest[key]?.toLowerCase()?.includes('base') && idx < currentIndex,
        );
      }

      return result;
    }, [] as string[]);
}

/**
 * Get list of classes from ingredients.
 *
 * @param items
 * @param appendBase
 */
export function getAvailableClasses(items: SelectedIngredient[] = [], appendBase = false): IngredientClass[] {
  return Array.from(new Set([...Object.values(appendBase ? bases : {}), ...items.map((i) => i.class)]));
}

/**
 * Check if weights of generic input config valid.
 *
 * @param weights
 */
export function isWeightConfigValid(weights: MetricWeights): boolean {
  return Object.values(weights).filter((value) => value !== 0).length !== 0;
}

/**
 * Check if weights of generic input config valid.
 *
 * @param ingredient
 * @param withBase
 */
export function prepareIngredient(ingredient: SelectedIngredient, withBase = false): SelectedIngredient {
  const { base1, other } = IngredientType;
  const { class: iClass = other, is_base_ingredient = false, ...fields } = <SelectedIngredient>ingredient;
  return { ...fields, class: withBase && is_base_ingredient ? base1 : iClass, is_base_ingredient };
}

/**
 * Generate composition templates based on provided ingredient classes grouped by slots (max 5 slots).
 *
 * @param slot
 * @param otherSlots
 */
export function* generateCompositionTemplates(
  slot: Composition,
  ...otherSlots: Composition[]
): Generator<Composition, void, any> {
  const [first, ...other] = otherSlots;
  const remainder = !!otherSlots?.length ? generateCompositionTemplates(first, ...other) : [[]];
  for (const r1 of remainder) {
    for (const s1 of slot) {
      yield [s1, ...r1.filter(Boolean)] as Required<Composition>;
    }
  }
}

export const BaseClasses = Object.values(bases);

/**
 * Get ids of rows where base class is duplcated in override class table.
 *
 * @param ingredients
 */
export function getDuplicatedBaseIngredientOverride(ingredients: SelectedIngredient[]): number[] {
  const [first, ...rest] = ingredients.map(({ class: iClass, ingredient_id }) => ({ [iClass]: [ingredient_id] }));
  const duplicatedIds = flatten(
    Object.values(
      pick(
        mergeWith(first, ...rest, (dest: number[], src: number[]) => {
          if (Array.isArray(dest)) {
            return dest.concat(src);
          }
          return [dest, ...(Array.isArray(src) ? src : [src])]?.filter(Boolean);
        }),
        BaseClasses,
      ),
    ).filter((i) => i?.length > 1),
  );

  return duplicatedIds?.filter(Boolean);
}

/**
 * Async function that updates the lemmas via the API and then sets the result in the redux store.
 * During the operation, it also sets the loading state to true and resets the error state.
 * @param dispatch: redux dispatch
 * @param runId
 * @param phaseId
 * @param projectId
 * @param inputId
 * @param lemmas
 */
export async function updateLemmas(
  dispatch: Dispatch<AnyAction>,
  runId: string,
  phaseId: string,
  projectId: string,
  inputId: string | undefined,
  lemmas: LemmaTableRow[],
  inputType: InputGenerationType = InputGenerationType.Wizard,
) {
  dispatch(setLemmaLoadingStatus(BackendLoadingStatus.Loading));
  dispatch(setError(null));
  try {
    const genericInputParams = {
      inputId,
      config: {
        input_type: inputType,
        lemmas,
        version: undefined,
      },
    };
    const result = (await updateGenericInput(
      projectId,
      phaseId,
      runId,
      genericInputParams,
      inputId,
    )) as MetricsCalculationConfigResponse;

    if (result?.config?.lemmas) {
      const lemmas = prepareLemmaTableRow(result.config.lemmas);
      dispatch(setLemmas({ lemmas }));
    }

    if (result.inputId) {
      dispatch(setRunGenericInput({ inputId: result.inputId, config: result.config, runId }));
    }
  } catch (e: any) {
    dispatch(setError(e.toString()));
  } finally {
    dispatch(setLemmaLoadingStatus(BackendLoadingStatus.Loaded));
  }
}

/**
 * Async function which gets lemmas from the API and from BQ (if required) and sets them in the redux store.
 * During the operation, it also sets the loading state to true and resets the error state.
 * @param dispatch
 * @param runId
 * @param phaseId
 * @param projectId
 * @param inputTable: BQ table name
 * @param loadInitialLemmas: if true, also load the initial lemmas from BQ
 */
export async function getLemmas(
  dispatch: Dispatch<AnyAction>,
  runId: string,
  phaseId: string,
  projectId: string,
  inputTable: string,
  loadInitialLemmas: boolean = false,
  readOnly: boolean,
) {
  dispatch(setLemmaLoadingStatus(BackendLoadingStatus.Loading));

  dispatch(setError(null));
  try {
    const apiLemmaPromise = getGenericInput(projectId, phaseId, runId);
    // first set the initialLemmas if required
    if (loadInitialLemmas) {
      const bqColumns = Object.values(LemmaQueryIngredient);
      const bqLemmaPromise = fetchBQTable(
        inputTable,
        bqColumns,
        {
          column: LemmaQueryIngredient.ingredient,
          direction: SortDirections.Ascending,
        },
        undefined,
        projectId,
      );

      const { data: bqLemmas } = await bqLemmaPromise;
      const initialLemmas = prepareLemmaTableRow(bqLemmas, true);
      dispatch(setInitialLemmas({ lemmas: initialLemmas, inputTable }));
      dispatch(setLemmas({ lemmas: initialLemmas }));
    }

    // then set the lemmas (as the lemmas are enriched with data from the initial lemmas)
    const result = (await apiLemmaPromise) as MetricsCalculationConfigResponse;

    // if lemmas have been saved before, alse set lemmas
    if (result?.config?.lemmas) {
      const lemmas = prepareLemmaTableRow(result.config.lemmas);
      dispatch(setLemmas({ lemmas }));
    }

    // if the inputId has been saved before, also set inputId
    if (result?.inputId) {
      dispatch(setRunGenericInput({ inputId: result.inputId, config: result.config, runId, readOnly }));
    }
  } catch (e: any) {
    dispatch(setError(e.toString()));
  } finally {
    dispatch(setLemmaLoadingStatus(BackendLoadingStatus.Loaded));
  }
}

/**
 * This function is used to fetch ingredients from a metrics calculation output
 * and to then to set them in the redux store.
 * @param: dispatch
 * @param: projectId
 * @param: inputTable: BQ table name
 */
export async function getSingleMetricIngredients(dispatch: Dispatch<AnyAction>, projectId: string, inputTable: string) {
  dispatch(setIngredientsLoadingStatus(BackendLoadingStatus.Loading));
  dispatch(setError(null));
  try {
    const bqColumns = Object.values(SingleMetricIngredientQuery);
    const bqIngredientsPromise = fetchBQTable(
      inputTable,
      bqColumns,
      {
        column: SingleMetricIngredientQuery.ingredient_name,
        direction: SortDirections.Ascending,
      },
      undefined,
      projectId,
    );

    const { data: bqIngredients } = await bqIngredientsPromise;
    const initialIngredients = prepareIngredientsToTable(bqIngredients);
    dispatch(setInitialIngredients({ ingredients: initialIngredients, inputTable }));
  } catch (e: any) {
    dispatch(setError(e.toString()));
  } finally {
    dispatch(setIngredientsLoadingStatus(BackendLoadingStatus.Loaded));
  }
}

/**
 * This function is used to fetch the concept generation config from the backend
 * and to then to set them in the redux store.
 * @param: dispatch
 * @param: runId
 * @param: phaseId
 * @param: projectId
 */
export async function getConceptGenerationConfig(
  dispatch: Dispatch<AnyAction>,
  runId: string,
  phaseId: string,
  projectId: string,
  readOnly: boolean,
) {
  dispatch(initRun({ runId }));

  try {
    const apiConfigPromise = getGenericInput(projectId, phaseId, runId);

    // set the redux state based on the response
    const result = (await apiConfigPromise) as ConceptGenerationConfigResponse;

    // if the inputId has been saved before, also set inputId
    if (result?.inputId) {
      dispatch(setRunGenericInput({ inputId: result.inputId, config: result.config, runId, readOnly }));
    }
  } catch (e: any) {
    dispatch(setRunError({ runId, error: e.toString() }));
  } finally {
    dispatch(setRunLoading({ runId, loading: false }));
  }
}

/**
 * This function is used to update concept generation config in the backend
 * @param: dispatch
 * @param: runId
 * @param: phaseId
 * @param: projectId
 * @param: config
 */
export async function updateConceptGenerationConfig(
  dispatch: Dispatch<AnyAction>,
  runId: string,
  phaseId: string,
  projectId: string,
  inputId: string | undefined,
  config: GenericInputConfig,
  readOnly: boolean,
): Promise<boolean> {
  let success = false;

  dispatch(setError(null));
  dispatch(setRunLoading({ runId, loading: true }));

  try {
    const payload = {
      inputId,
      config,
    };
    const apiConfigPromise = updateGenericInput(projectId, phaseId, runId, payload, inputId);
    const result = (await apiConfigPromise) as ConceptGenerationConfigResponse;

    // If the inputId has been saved before, it needs to be set.
    if (result?.inputId) {
      dispatch(setRunGenericInput({ inputId: result.inputId, config: result.config, runId, readOnly }));
    }

    // If we got here, all operations succeeded
    success = true;
  } catch (e: any) {
    dispatch(setError(e.toString()));
  } finally {
    dispatch(setRunLoading({ runId, loading: false }));
  }

  return success;
}

export function tableRowToCompositions(rows: GenericDataGridRow[]): CompositionConfig[] {
  return rows.map(({ category = other, id = '', ...rest }) => ({
    slots: Object.values(rest).map((v) => v || other) as Composition,
    category,
    [id]: undefined,
  }));
}

/**
 * Convert compositions to ui table rows.
 *
 * @param compositions
 * @param slots
 */
export function convertCompositionToTableRows(compositions: CompositionConfig[]): GenericDataGridRow[] {
  return compositions.map(
    ({ slots, category }, id) =>
      ({
        id,
        ...Object.fromEntries(
          [...slots, category].map((v, i, array) => [
            i < array.length - 1 ? `ingredient${i + 1}` : 'category',
            v || other,
          ]),
        ),
      }) as GenericDataGridRow,
  );
}

/**
 * Get a list territory definitions
 * @param dispatch
 * @param fetch
 * @param projectId
 */
export async function getTerritoryDefinitions(dispatch: Dispatch<AnyAction>, projectId: string): Promise<void> {
  dispatch(setError(null));
  dispatch(setTerritoryDefinitionsLoadingState(BackendLoadingStatus.Loading));
  const url = buildActionUrl({ projectId }, TYPE_CONCEPT_TERRITORY_DEFINITIONS);
  try {
    const result = (await axios
      .get(url)
      .then((res: AxiosResponse) => res.data)) as APIConceptTerritoryDefinitionResponse;

    if (result?.definitions) {
      const territoryDefinitions: ConceptTerritoryDefinition[] = result.definitions.map((d) => ({
        name: d.name,
        version: d.version,
        territories: d.definition.territories.map((t) => t.name),
      }));
      // This also sets the backend loading state to Loaded
      dispatch(setTerritoryDefinitions(territoryDefinitions));
      dispatch(setTerritoryDefinitionsWarningMessage(''));
    } else {
      dispatch(setTerritoryDefinitionsWarningMessage('No territory definitions found'));
    }
  } catch (e: any) {
    dispatch(setTerritoryDefinitionsLoadingState(BackendLoadingStatus.Initial));
    throw new Error(e.toString());
  }
}

/**
 * This function finds the reference for a given ingredientId
 * @param ingredientId
 * @param ingredientDBMap
 * @returns IngredientDBIngredientType | undefined
 */
export function lookupReferenceIngredient(
  ingredientId: number,
  ingredientDBMap: IngredientDBMap,
): IngredientDBIngredientType | undefined {
  const ingredient = ingredientDBMap.searchIngredientById(ingredientId);
  if (ingredient?.referenceIngredientId) {
    return ingredientDBMap.searchIngredientById(Number(ingredient.referenceIngredientId));
  }
  return undefined;
}
