import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Moment from 'moment';
import React, { memo } from 'react';

import { VersionList } from '../../../../features/datasources/DataSources';
import { MetadataAction, StateAction } from '../../reducer';

interface MetaDataPaneProps {
  description: string;
  contextVersionStatus: string | undefined;
  recipesVersion: string;
  recipeVersions: string[];
  socialPostVersion: string;
  socialPostVersions: string[];
  dishTypesVersion: string;
  dishTypesVersions: string[];
  isLegacy?: boolean;
  changedBy: string | undefined;
  changedAt: string | undefined;
  dispatch: (action: MetadataAction | StateAction) => void;
  disabled: boolean;
}

const MetaDataPanel: React.FC<MetaDataPaneProps> = ({
  description,
  contextVersionStatus,
  recipesVersion,
  recipeVersions,
  socialPostVersion,
  socialPostVersions,
  dishTypesVersion,
  dishTypesVersions,
  isLegacy,
  changedBy,
  changedAt,
  dispatch,
  disabled,
}) => {
  const setDescription = (newDescription: string): void => {
    dispatch({ type: 'setMetadataDescription', description: newDescription });
  };

  const setSocialPostsVersion = (newSocialPostVersion: string) => {
    dispatch({
      type: 'setSocialPostVersion',
      data: { socialPostVersion: newSocialPostVersion },
    });
  };

  const setRecipesVersion = (newRecipesVersion: string) => {
    dispatch({
      type: 'setRecipesVersion',
      data: { recipesVersion: newRecipesVersion },
    });
  };

  const setDishTypesVersion = (newDishTypeVersion: string) => {
    dispatch({
      type: 'setDishTypesVersion',
      data: { dishTypesVersion: newDishTypeVersion },
    });
  };

  return (
    <>
      <Grid container={true} item={true} direction="row" spacing={4} mt={0}>
        <Grid item={true} md={4}>
          <TextField
            name="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            label="Description"
            variant="outlined"
            fullWidth={true}
            disabled={disabled}
          />
        </Grid>
        <Grid item={true} md={5} />
        <Grid item={true} md={3}>
          <>
            Status <br />{' '}
            {String(contextVersionStatus || 'CONTEXT_VERSION_STATUS_CREATED').replace('CONTEXT_VERSION_STATUS_', '')}
          </>
        </Grid>
      </Grid>
      <Grid container={true} item={true} direction="row" spacing={4} mt={0}>
        <Grid item={true} md={2}>
          {!isLegacy && (
            <VersionList
              changeVersion={setDishTypesVersion}
              versions={dishTypesVersions}
              selected={dishTypesVersion}
              as="autocomplete"
              required={true}
              disabled={disabled}
              label="Dishtype version"
            />
          )}
          {isLegacy && (
            <VersionList
              changeVersion={setRecipesVersion}
              versions={recipeVersions}
              selected={recipesVersion}
              as="autocomplete"
              required={true}
              disabled={disabled}
              label="Recipe version"
            />
          )}
        </Grid>
        <Grid item={true} md={2}>
          {isLegacy && (
            <VersionList
              changeVersion={setSocialPostsVersion}
              versions={socialPostVersions}
              selected={socialPostVersion}
              as="autocomplete"
              required={true}
              disabled={disabled}
              label="Social media version"
            />
          )}
        </Grid>
        <Grid item={true} md={2}>
          {' '}
        </Grid>
        <Grid item={true} md={3}>
          {changedBy && (
            <>
              {' '}
              Changed By <br /> {changedBy}
            </>
          )}
        </Grid>
        <Grid item={true} md={3}>
          {changedAt && (
            <>
              {' '}
              Changed At <br /> {Moment(changedAt).format('YYYY-MM-DD HH:mm')}
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

MetaDataPanel.defaultProps = {
  isLegacy: false,
};

export default memo(MetaDataPanel);
