import './SubMenu.scss';

import { ExpandLess } from '@mui/icons-material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { FC, PropsWithChildren, ReactElement } from 'react';

interface Props {
  dense: boolean;
  handleToggle: () => void;
  icon?: ReactElement;
  isOpen: boolean;
  name: string;
  sidebarIsOpen: boolean;
}

const SubMenu: FC<PropsWithChildren<Props>> = ({
  handleToggle,
  sidebarIsOpen,
  isOpen,
  name,
  icon,
  children,
  dense,
}) => {
  const header = (
    <MenuItem dense={dense} onClick={handleToggle}>
      <ListItemIcon className="SubMenu__icon">{icon}</ListItemIcon>
      <Typography className="SubMenu__typography" variant="inherit" color="textSecondary" style={{ flexGrow: 1 }}>
        {name}
      </Typography>
      <div>{!isOpen ? <ExpandMore /> : <ExpandLess />}</div>
    </MenuItem>
  );

  return (
    <>
      {sidebarIsOpen || isOpen ? (
        header
      ) : (
        <Tooltip title={name} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit={true}>
        <List
          dense={dense}
          component="div"
          disablePadding={true}
          className={sidebarIsOpen ? 'SubMenu__sidebarIsOpen' : 'SubMenu__sidebarIsClosed'}
        >
          {children}
        </List>
      </Collapse>
    </>
  );
};

SubMenu.defaultProps = {
  icon: undefined,
};

export default SubMenu;
