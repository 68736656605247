import '../../../../layout/project-flow.scss';

import { WarningOutlined } from '@mui/icons-material';
import Add from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import JobHandler, { JobTypes } from '../../../../components/JobHandler';
import SectionTitle from '../../../../components/SectionTitle';
import { DocLink, DocLinkUrl, DocTypeEnum } from '../../../../features/docs';
import { GenericInput, InputGenerationType } from '../../../../features/project/types/common';
import { isJobIsInProgress } from '../../../../shared/utils';
import { JobExecutor } from '../../../../types';
import CreateRunDialog from '../_components/CreateRunDialog';
import PreviousRunSelector from '../_components/PreviousRunSelector';
import RunSelector from '../_components/RunSelector';
import { createGenericInput, createRun, duplicateRun, getAllRuns, getGenericInput } from '../_utils/datasources';
import { FlowActions } from '../_utils/reducer';
import { JobStatus, ProjectFlowStepProps, ResourceKeys, RunType, RunTypeWithInputId } from '../_utils/types';

type EditMode = { parentRuns: boolean; params: boolean };

const ModelPreparation: React.FC<ProjectFlowStepProps> = ({
  project,
  currentStep,
  selectedRun,
  runs,
  simulateFail,
  phaseId,
  dispatch,
  isPhaseReadOnly,
  stepTitle,
  stepDescription,
  phase,
}) => {
  const history = useNavigate();
  const projectId = project?.projectId;
  const selectedRunId = selectedRun?.runId;
  const [loading, setLoading] = useState<boolean>(false);
  const [runNameDialogOpen, setRunNameDialogOpen] = useState<boolean>(false);
  const [inputId, setInputId] = useState<string>('');
  const [runLoader, setRunLoader] = useState<boolean>(false);
  const outputStatus = selectedRun?.outputs?.output_generation?.status;
  const inputStatus = selectedRun?.outputs?.input_preparation?.status;
  const outputIsInProgress = outputStatus ? isJobIsInProgress(outputStatus) : false;
  const inputIsInProgress = inputStatus ? isJobIsInProgress(inputStatus) : false;
  const isButtonDisabled =
    outputIsInProgress ||
    inputIsInProgress ||
    !(projectId && phaseId) ||
    loading ||
    !!selectedRun?.readOnly ||
    isPhaseReadOnly;
  const [editMode, setEditMode] = useState<EditMode>({
    parentRuns: false,
    params: false,
  });
  const numOfSteps = (project?.workflowConfig?.length || 0) - 1;

  const updateRun = useCallback<(run: RunType) => void>(
    (run) => dispatch({ type: FlowActions.setSelectedRun, run }),
    [dispatch],
  );

  // callback to update generic phase's runs data
  const refreshView = useCallback(async () => {
    setLoading(true);
    const newRuns = await getAllRuns(projectId, phaseId);
    dispatch({ type: FlowActions.setRuns, runs: newRuns || [], selectedRun });
    setLoading(false);
    // eslint-disable-next-line
  }, [dispatch, phaseId, projectId, setLoading]);

  // callback to change current run
  const handleRunChange = useCallback(
    (run?: RunType | null): void => {
      setRunLoader(false);
      if (run) {
        history(`/projects/${run.projectId}/phases/${run.phaseId}/runs/${run.runId}`);
      }
    },
    [history],
  );

  // callback to handle new run creation
  const createNewRun = useCallback(
    (runName?: string) => {
      setRunLoader(true);
      setLoading(true);
      createRun(projectId, phaseId, runName || '').then((result: RunTypeWithInputId | null) => {
        handleRunChange(result?.run);
      });
      setRunNameDialogOpen(false);
    },
    [projectId, phaseId, handleRunChange, setLoading, setRunNameDialogOpen],
  );

  // callback to handle duplication of an existing run
  const createDuplicateRun = useCallback(
    (sourceRunId: string, runName?: string) => {
      setRunLoader(true);
      setLoading(true);
      duplicateRun(projectId, phaseId, sourceRunId, runName || '').then((result: RunTypeWithInputId | null) =>
        handleRunChange(result?.run),
      );
      setRunNameDialogOpen(false);
    },
    [projectId, phaseId, handleRunChange, setLoading, setRunNameDialogOpen],
  );

  // callback to update generic input data in component's local state
  const updateLocalInputData = useCallback(
    (genericInput: GenericInput): void => {
      if (genericInput.inputId) setInputId(genericInput.inputId);
      setLoading(false);
    },
    [setInputId, setLoading],
  );

  const processGenericInputResponse = useCallback(
    (response: GenericInput | undefined) => {
      if (response) {
        updateLocalInputData(response);
      }
    },
    [updateLocalInputData],
  );

  /**
   * This callback creates a config when none exists yet
   */
  const initGenericInput = useCallback(() => {
    if (projectId && phaseId && selectedRunId) {
      createGenericInput(projectId, phaseId, selectedRunId, {
        config: { input_type: InputGenerationType.Wizard },
      }).then((response) => {
        processGenericInputResponse(response);
      });
    }
  }, [projectId, phaseId, selectedRunId, processGenericInputResponse]);

  /**
   * This effect should only be called once upon loading a new run
   * When a new run is selected, the inputApiUrl is updated and the effect is triggered
   * - only trigger this effect when the inputApiUrl is set
   * - and when inputId is not set (meaning no config has been loaded)
   */
  useEffect(() => {
    if (projectId && phaseId && selectedRunId && !inputId) {
      setLoading(true);
      getGenericInput(projectId, phaseId, selectedRunId).then((response) => {
        if (response) {
          updateLocalInputData(response);
        } else {
          initGenericInput();
        }
      });
    }
  }, [projectId, phaseId, selectedRunId, inputId, setLoading, initGenericInput, updateLocalInputData]);

  useEffect(() => {
    dispatch({
      type: FlowActions.setNavigationButtonStatusCallback,
      callback: () => ({
        next: outputStatus === JobStatus.ok && (currentStep || 0) < numOfSteps,
        back: true,
      }),
    });
  }, [dispatch, currentStep, outputStatus, numOfSteps]);

  useEffect(() => {
    if (runs?.length === 0) {
      setRunNameDialogOpen(true);
    }
  }, [runs]);

  const isJobGenerationDisabled = () =>
    phase?.dependsOnRuns.length === 0 ||
    editMode.parentRuns ||
    editMode.params ||
    isPhaseReadOnly ||
    !!selectedRun?.readOnly;

  return (
    <Box position="relative" data-id-cypress="modelPreparation">
      <Box display="flex" flexDirection="row" className="ProjectFlow__stepTitleWrapper">
        <SectionTitle
          title={stepTitle}
          action={
            <>
              <DocLink link={DocLinkUrl[DocTypeEnum.ModelPreparationStep]} />
              <Tooltip title="Create or duplicate a run">
                <Button
                  disabled={isPhaseReadOnly || !phase?.dependsOnRuns?.length}
                  onClick={() => setRunNameDialogOpen(true)}
                  data-id-cypress="addNewRunButton"
                >
                  <Add />
                </Button>
              </Tooltip>
            </>
          }
        />
        <RunSelector runs={runs || []} selected={selectedRun || null} handleRunChange={handleRunChange} />
        {isPhaseReadOnly && (
          <Box marginLeft={1} display={'flex'} alignItems={'center'}>
            <Typography
              className="WithWarning"
              color={'error'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <WarningOutlined />
              This phase is read-only
            </Typography>
          </Box>
        )}
      </Box>

      <Box display="flex" flexDirection="row" marginBottom={2}>
        <Box>
          <Typography>{stepDescription}</Typography>
        </Box>
      </Box>

      <PreviousRunSelector
        phase={phase}
        projectId={projectId}
        isButtonDisabled={isButtonDisabled}
        isEditMode={editMode.parentRuns || false}
        toggleEditMode={(toggle: boolean) => setEditMode((mode) => ({ ...mode, parentRuns: toggle }))}
      />

      {!loading && (
        <JobHandler
          project={project}
          title={stepTitle}
          updateRun={updateRun}
          selectedRunId={selectedRun?.runId}
          updateView={refreshView}
          type={JobTypes.output}
          run={runs?.find((run) => run.runId === selectedRun?.runId)}
          inProgressText="Generating output"
          simulateFail={simulateFail}
          jobSheetResourceKey={ResourceKeys.MODEL_PREPARATION_CONCEPT_SELECTION_TEMPLATE}
          externalLinkLabel="Concept selection template"
          isDisabled={isJobGenerationDisabled}
          key={`output-handler-${selectedRun?.runId}`}
          executor={JobExecutor.default}
        />
      )}

      <CreateRunDialog
        open={runNameDialogOpen}
        onClose={() => setRunNameDialogOpen(false)}
        onSubmit={(newRunName) => createNewRun(newRunName || '')}
        onDuplicate={(newRunName, sourceRunId) => createDuplicateRun(sourceRunId, newRunName || '')}
        runsList={runs}
        defaultRun={selectedRun}
        loading={runLoader}
      />
    </Box>
  );
};

export default memo(ModelPreparation);
