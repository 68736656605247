import '../layout/common.scss';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import React, { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { FixedSizeList } from 'react-window';

type DataProps = {
  items: string[];
  checked: string[];
  handleToggle: (name: string) => (event: MouseEvent) => void;
};

const Row: React.FC<{ index: number; style: any; data: DataProps }> = ({ index, style, data }) => {
  const { items, checked, handleToggle } = data;
  const value = items[index];
  const labelId = `transfer-list-item-${value}-label`;
  return (
    <ListItem
      data-id-cypress="ingredientListItem"
      key={value}
      style={style}
      role="listitem"
      button={true}
      onClick={handleToggle(value)}
    >
      <ListItemIcon>
        <Checkbox
          checked={checked.indexOf(value) !== -1}
          tabIndex={-1}
          disableRipple={true}
          inputProps={{ 'aria-labelledby': labelId }}
        />
      </ListItemIcon>
      <ListItemText data-id-cypress="ingredientListItemText" id={labelId} primary={value} />
    </ListItem>
  );
};

type TagListProps = {
  name: string;
  items: string[];
  checked: string[];
  handleToggle: (name: string) => (event: MouseEvent) => void;
  onSearchChange: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  value?: string;
};

const TagList: React.FC<TagListProps> = ({ name, items, checked, handleToggle, onSearchChange, disabled, value }) => {
  const box = React.useRef<HTMLInputElement>(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (box.current) {
      setWidth(box.current.getBoundingClientRect().width);
      setHeight(box.current.getBoundingClientRect().height);
    }
  }, [box]);

  return (
    <Paper className="common__noScrollbarPaper">
      <TextField
        style={{ width: '98%' }}
        id="standard-search"
        label={`Search ${name}`}
        type="search"
        className="common__textField"
        margin="normal"
        onChange={onSearchChange}
        disabled={!!disabled}
        value={value}
      />
      <Box className="common__transferList" ref={box}>
        <FixedSizeList
          height={height}
          width={width}
          itemData={{ items, checked, handleToggle }}
          itemSize={46}
          itemCount={items.length}
        >
          {Row}
        </FixedSizeList>
      </Box>
    </Paper>
  );
};

TagList.defaultProps = {
  disabled: undefined,
  value: '',
};

export default TagList;
