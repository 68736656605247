import axios, { AxiosResponse } from 'axios';

import {
  buildActionUrl,
  TYPE_CREATE_CONTEXT_VERSION,
  TYPE_DATASOURCES_DISHTYPE_VERSION,
  TYPE_DATASOURCES_SOCIAL_CATEGORIES,
  TYPE_DATASOURCES_SOCIAL_CATEGORY,
  TYPE_GET_CONTEXT_VERSION,
  TYPE_GET_CONTEXT_VERSION_PUBLISH_ARCHIVE,
  TYPE_RECIPE_FILTER,
} from '../../../shared/url';
import { CategoryType, RecipeFiltersType, SocialPostSelectionType, SocialTagErrorItem } from '../../../types';
import { ContextActionDispatcher, ContextStateType, LoadingActions, SocialActions } from '../reducer';

export type LoadDataArguments = {
  state: ContextStateType;
  dispatch: ContextActionDispatcher;
};

export type UpdateContextArg = Omit<LoadDataArguments, 'get'> & {
  contextId: string | undefined;
  contextVersionId: string | undefined;
  isEditing: boolean;
  callback: (url: string | undefined) => void;
};

export type ArchivePublishArg = Omit<UpdateContextArg, 'isEditing'> & {
  isPublish: boolean;
};

/**
 * If social media tags have already been selected - check for missing tags in this version.
 *
 * @param args
 */
export async function checkSocialTags(args: LoadDataArguments): Promise<void> {
  const { state, dispatch } = args;

  if (state?.socialPostsSelections.length) {
    const socialErrors: SocialTagErrorItem[] = [];
    const { socialPostsSelections, socialPostVersion } = state;
    setLoadingStatus(dispatch, LoadingActions.socialTagsCheck, true);

    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < socialPostsSelections.length; i++) {
      const category: SocialPostSelectionType = socialPostsSelections[i];
      const url = buildActionUrl(
        {
          version: socialPostVersion,
          category: category.category,
        },
        TYPE_DATASOURCES_SOCIAL_CATEGORY,
      );
      const cat = await axios.get(url).then((res) => res.data);

      const foundIssues = category.posts.map((p) => p.tag).filter((t) => !cat.category.tags.includes(t));

      if (foundIssues && foundIssues.length > 0) {
        socialErrors.push({
          category: category.category,
          tags: foundIssues,
        });
      }
    }
    /* eslint-enable no-await-in-loop */

    if (socialErrors.length > 0) {
      dispatch({
        type: 'setSocialErrors',
        errors: socialErrors?.length > 0 ? socialErrors : null,
      });
    }

    setLoadingStatus(dispatch, LoadingActions.socialTagsCheck, false);
  }
}

/**
 * Load social post category names for specified social post version and dispatch respective action.
 *
 * @param args
 */
export function loadSocialPostsCategories(args: LoadDataArguments): void {
  const { state, dispatch } = args;
  const { socialPostVersion } = state;

  if (!socialPostVersion) return;

  const url = buildActionUrl(
    {
      version: socialPostVersion,
    },
    TYPE_DATASOURCES_SOCIAL_CATEGORIES,
  );
  setLoadingStatus(dispatch, LoadingActions.socialCategory, true);

  axios
    .get(url)
    .then((res: AxiosResponse) => res.data)
    .then((result: { categoryNames: string[] }) => {
      dispatch({
        type: 'loadInitialSocialPostsCategories',
        items: result?.categoryNames?.map((name: string) => ({ name, tags: [] })) || [],
      });
    })
    .finally(() => {
      setLoadingStatus(dispatch, LoadingActions.socialCategory, false);
    });
}

/**
 * Load dish types list
 *
 * @params args
 */

export function loadDishTypes(args: LoadDataArguments): void {
  const { state, dispatch } = args;
  const { dishTypesVersion, dishTypesVersions } = state;

  if (!dishTypesVersion || !dishTypesVersions.includes(dishTypesVersion)) return;

  const url = buildActionUrl(
    {
      version: dishTypesVersion,
    },
    TYPE_DATASOURCES_DISHTYPE_VERSION,
  );
  setLoadingStatus(dispatch, LoadingActions.dishTypesList, true);
  axios
    .get(url)
    .then((res: AxiosResponse) => res.data)
    .then((result) => {
      dispatch({
        type: 'loadDishTypesList',
        data: result.dishTypes,
      });
    })
    .finally(() => setLoadingStatus(dispatch, LoadingActions.dishTypesList, false));
}

/**
 * Load social post categories and dispatch respective action.
 *
 * @param args
 */
export function loadSelectedSocialPostsTagsCategories(args: LoadDataArguments): void {
  const { state, dispatch } = args;
  const { socialPostVersion, selectSocialPostsCategoryName } = state;

  if (!selectSocialPostsCategoryName) return;

  const url = buildActionUrl(
    {
      version: socialPostVersion,
      category: selectSocialPostsCategoryName,
    },
    TYPE_DATASOURCES_SOCIAL_CATEGORIES,
  );
  setLoadingStatus(dispatch, LoadingActions.socialCategoryTags, true);

  axios
    .get(url)
    .then((res: AxiosResponse) => res.data)
    .then((result: { category: CategoryType }) => {
      dispatch({
        type: SocialActions.load,
        name: selectSocialPostsCategoryName || '',
        category: result?.category,
      });
    })
    .finally(() => setLoadingStatus(dispatch, LoadingActions.socialCategoryTags, false));
}

/**
 * Load recipe filters and trigger respective action.
 *
 * @param args
 */
export function loadRecipeFilters(args: LoadDataArguments): void {
  const { state, dispatch } = args;
  const { recipesVersion } = state;

  if (!recipesVersion) return;
  const url = buildActionUrl(
    {
      version: recipesVersion,
    },
    TYPE_RECIPE_FILTER,
  );
  setLoadingStatus(dispatch, LoadingActions.recipeFilters, true);

  axios
    .get(url)
    .then((res: AxiosResponse) => res.data)
    .then((result: RecipeFiltersType) => {
      dispatch({ type: 'loadRecipeFilters', data: result });
    })
    .finally(() => setLoadingStatus(dispatch, LoadingActions.recipeFilters, false));
}

/**
 * Update or edit data.
 *
 * @param updateArgs
 */
export function updateData(updateArgs: UpdateContextArg): void {
  const { contextId, contextVersionId, dispatch, state, callback, isEditing } = updateArgs;
  const url = isEditing
    ? buildActionUrl({ contextId, contextVersionId, isEditing }, TYPE_GET_CONTEXT_VERSION)
    : buildActionUrl({ contextId }, TYPE_CREATE_CONTEXT_VERSION);

  dispatch({ type: 'setSaving', isSaving: false });
  dispatch({ type: 'setLoadingTarget', loadingTarget: 'SAVE' });

  // SAVE DISHTYPES TOO

  axios
    .post(url, {
      description: state.description,
      recipesVersion: state.recipesVersion,
      socialPostsVersion: state.socialPostVersion,
      recipes: state.recipeSelections,
      socialPosts: state.socialPostsSelections,
      dishTypesVersion: state.dishTypesVersion,
      dishTypes: state.dishTypesSelection,
    })
    .then((res: AxiosResponse) => res.data)
    .then((result?: { contextVersion: { contextVersionId: string } }) => {
      const redirectUrl = !isEditing
        ? `/contexts/${contextId}/versions/edit/${result?.contextVersion?.contextVersionId}`
        : undefined;

      callback(redirectUrl);
    })
    .finally(() => dispatch({ type: 'setSaving', isSaving: false }));
}

/**
 * Archive or publish context version.
 *
 * @param archiveArg
 */
export function archiveOrPublishVersion(archiveArg: ArchivePublishArg): void {
  const { contextId, contextVersionId, callback, dispatch, isPublish } = archiveArg;

  if (!contextVersionId) {
    return;
  }

  const url = buildActionUrl({ contextId, contextVersionId, isPublish }, TYPE_GET_CONTEXT_VERSION_PUBLISH_ARCHIVE);

  dispatch({ type: 'setSaving', isSaving: false });
  dispatch({
    type: 'setLoadingTarget',
    loadingTarget: isPublish ? 'PUBLISH' : 'ARCHIVE',
  });

  axios
    .post(url, {})
    .then(() => {
      callback(undefined);
    })
    .finally(() => dispatch({ type: 'setSaving', isSaving: false }));
}

/**
 * Wrapper on dispatch to manipulate status of loader.
 *
 * @param type
 * @param status
 * @param dispatch
 */
export function setLoadingStatus(dispatch: ContextActionDispatcher, type: LoadingActions, status: boolean): void {
  dispatch({ type, loading: status });
}
