import '../../../../layout/project-flow.scss';

import { Add, WarningOutlined } from '@mui/icons-material';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import React, { ChangeEvent, FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import JobHandler, { JobHandlerRef, JobTypes } from '../../../../components/JobHandler';
import Loading from '../../../../components/Loading';
import SectionTitle from '../../../../components/SectionTitle';
import { DocLink, DocLinkUrl, DocTypeEnum } from '../../../../features/docs';
import {
  resetLemmas,
  setInputType,
  setLemmaCorrectedInfos,
  setLemmaRelevant,
  setLemmaVisibility,
  SLICE_METRICS_CALCULATION as RUN_STATE,
  SLICE_PROJECT_WORKFLOW as PROJECT_STATE,
} from '../../../../features/project/store';
import { BackendLoadingStatus, InputGenerationType } from '../../../../features/project/types/common';
import { LemmaTableRow } from '../../../../features/project/types/metrics-calculation';
import { getLemmas, updateLemmas } from '../../../../features/project/utils';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { JobExecutor } from '../../../../types';
import CreateRunDialog from '../_components/CreateRunDialog';
import InputTypeSelector from '../_components/InputTypeSelector';
import RunSelector from '../_components/RunSelector';
import { createRun, duplicateRun, getAllRuns } from '../_utils/datasources';
import { FlowActions } from '../_utils/reducer';
import { JobStatus, ProjectFlowStepProps, ResourceKeys, RunType } from '../_utils/types';
import LemmaMappingTable from './ConfigureMetricsCalculationRun/LemmaMappingTable';

const MetricsCalculation: FC<ProjectFlowStepProps> = ({
  phaseId: propsPhaseId = '',
  selectedRun,
  runs,
  project,
  simulateFail,
  dispatch,
  isPhaseReadOnly,
  stepDescription,
  stepTitle,
}) => {
  const navigateTo = useNavigate();
  const reduxDispatch = useAppDispatch();
  const { runs: allPhaseRuns, loading: isRunLoading } = useAppSelector((store) => store[PROJECT_STATE]);
  const {
    lemmas,
    initialLemmas,
    inputTable: loadedTable,
    lemmaLoadingStatus,
  } = useAppSelector((store) => store[RUN_STATE]);
  const { runId = '' } = selectedRun || {};
  const inputType = allPhaseRuns[runId]?.config?.input_type || InputGenerationType.Wizard;
  const run = useMemo(() => runs?.find((item) => item.runId === runId) || undefined, [runs, runId]);
  const inputId = allPhaseRuns?.[runId]?.inputId || undefined;

  const [inputTable, setInputTable] = useState<string>('');
  const [createRunDialogOpen, setCreateRunDialogOpen] = useState<boolean>(false);
  const [runLoader, setRunLoader] = useState<boolean>(false);

  const inputJobHandler = React.useRef<JobHandlerRef | null>(null);
  const inputStatus = selectedRun?.outputs?.input_preparation?.status;
  const outputStatus = selectedRun?.outputs?.output_generation?.status;
  const { projectId = '', ingredientDbVersion = '' } = project || {};
  const loadBqLemmas = loadedTable ? !(inputTable === loadedTable) : true;
  const areLemmasLoading =
    lemmaLoadingStatus === BackendLoadingStatus.Loading || lemmaLoadingStatus === BackendLoadingStatus.Initial;

  /** ***************************************************
   * Project workflow related useCallback and useEffects
   ****************************************************** */

  /**
   * This callback makes the browser navigate to a given run
   * @param r: the run to navigate to
   * */
  const onRunChange = useCallback<(r?: RunType | null) => void>(
    (r) => {
      setRunLoader(false);
      if (r) {
        navigateTo(`/projects/${r.projectId}/phases/${r.phaseId}/runs/${r.runId}`);
      }
    },
    [navigateTo],
  );

  /**
   * This callback creates a new run, given the run name
   * @param runName: the name of the run to create
   * @returns a promise that resolves when the run is created
   */
  const onCreateRun = useCallback<(runName: string | null) => Promise<void | null>>(
    async (runName) => {
      setRunLoader(true);
      const result = await createRun(projectId, propsPhaseId, runName || '');
      onRunChange(result?.run);
      setCreateRunDialogOpen(false);
    },
    [projectId, propsPhaseId, onRunChange],
  );

  /**
   * This callback creates a new run by duplicating an existing one.
   * @param sourceRunId: the id of the run to duplicate
   * @param runName: the name of the run to create
   * @returns a promise that resolves when the run is created
   * */
  const onDuplicateRun = useCallback<(sourceRunId: string, runName: string | null) => Promise<void | null>>(
    async (sourceRunId, runName) => {
      setRunLoader(true);
      const result = await duplicateRun(projectId, propsPhaseId, sourceRunId, runName || '');
      onRunChange(result?.run);
      setCreateRunDialogOpen(false);
    },
    [projectId, propsPhaseId, onRunChange],
  );

  /**
   * This callback reloads all runs of a phase and keeps the currently selected run selected.
   * @returns a promise that resolves when the runs are loaded
   */
  const onRefreshRuns = useCallback<() => Promise<void>>(async () => {
    const allRuns = (await getAllRuns(projectId, propsPhaseId)) || [];
    dispatch({ type: FlowActions.setRuns, runs: allRuns, selectedRun });
  }, [dispatch, propsPhaseId, projectId, selectedRun]);

  /**
   * This callback updates the selected run the redux store
   * @param r: the run to select
   */
  const onSelectRun = useCallback<(r: RunType | null) => void>(
    (r) => {
      dispatch({ type: FlowActions.setSelectedRun, run: r });
    },
    [dispatch],
  );

  /**
   * This effect disables the apply button from the project flow footer bar for this workflow step
   */
  useEffect(() => dispatch({ type: FlowActions.setApplyButton, button: null }), [dispatch]);

  /**
   * This effect opens the create/duplicate run dialog if no runId is present
   * Variable runId is empty when no selected run is present
   * This normally only occurs when the user has not created a run yet, because in all other cases
   * the selectedRun is passed from the previous step in the workflow.
   */
  useEffect(() => {
    if (!runId) {
      setCreateRunDialogOpen(true);
    }
  }, [runId]);

  /**
   * This effect is triggered when the run has changed.
   * Set default inputType to Wizard for the selected run
   * */
  useEffect(() => {
    if (runId) {
      reduxDispatch(setInputType({ inputType: InputGenerationType.Wizard, runId }));
      reduxDispatch(resetLemmas());
    }
  }, [reduxDispatch, runId]);

  /**
   * This effect enables and disables the project workflow navigation buttons
   * in the redux store based on the status of the output generation job of the selected run.
   */
  useEffect(() => {
    dispatch({
      type: FlowActions.setNavigationButtonStatusCallback,
      callback: () => ({ next: outputStatus === JobStatus.ok, back: true }),
    });
  }, [inputStatus, outputStatus, dispatch]);

  /** ***************************************************
   * Run configuration related useCallback and useEffects
   ****************************************************** */
  /**
   * This callback sets the lemma visibility in the redux store
   */
  const onHideLemma = useCallback(
    (newValue: boolean, row: LemmaTableRow) => {
      if (row.lemma) {
        reduxDispatch(
          setLemmaVisibility({
            lemmaId: row.lemma,
            hideLemma: newValue,
          }),
        );
      }
    },
    [reduxDispatch],
  );

  const onRemoveCorrectedId = useCallback(
    (row: LemmaTableRow) => {
      if (row.lemma) {
        reduxDispatch(
          setLemmaCorrectedInfos({
            runId: selectedRun?.runId || '',
            lemma: row.lemma,
            corrected_ingredient_id: null,
            corrected_ingredient_name: null,
            corrected_reference_ingredient_id: null,
            corrected_reference_ingredient_name: null,
          }),
        );
      }
    },
    [reduxDispatch, selectedRun?.runId],
  );

  /**
   * This callback sets the lemma relevance in the redux store
   * */
  const onRowSelect = useCallback(
    (ids: string[]) => {
      reduxDispatch(setLemmaRelevant({ selectedIds: ids }));
    },
    [reduxDispatch],
  );

  /**
   * This effect is triggered when the project object changes.
   * It sets the input table name in the redux store.
   */
  useEffect(() => {
    const resources = project?.outputGenerationByRunId.jobOutput?.resources;
    const table = resources?.scope_definition_ingredients?.tables?.tables?.[0]?.tableName || '';

    if (table) {
      setInputTable(table);
    }
  }, [project]);

  /**
   * This effect loads the lemmas from the API and from the BQ table (if required)
   */
  useEffect(() => {
    if (lemmaLoadingStatus === BackendLoadingStatus.Initial) {
      if (selectedRun && inputTable) {
        getLemmas(
          reduxDispatch,
          selectedRun.runId,
          selectedRun.phaseId,
          selectedRun.projectId,
          inputTable,
          loadBqLemmas,
          selectedRun.readOnly,
        );
      }
    }
  }, [reduxDispatch, selectedRun, inputTable, loadBqLemmas, lemmaLoadingStatus]);

  /**
   * Deprecated: This callback handles a change in the input type (WIZARD / SHEETS)
   */
  const onInputTypeChanged = useCallback<(e: ChangeEvent<{ value: unknown }>) => void>(
    (e) => {
      const newInputType = e?.target?.value as InputGenerationType;
      reduxDispatch(setInputType({ inputType: newInputType, runId: run?.runId || '' }));

      // when input type changed - send request to update it in run generic input
      // when changing input types, we need not provide the lemmas
      if (run && inputId && !run?.readOnly && !isRunLoading) {
        updateLemmas(reduxDispatch, run.runId, run.phaseId, run.projectId, inputId, [], newInputType);
      }
    },
    [reduxDispatch, run, inputId, isRunLoading],
  );

  /** ****************
   *  UI Components
   * **************** */

  if (!propsPhaseId) return <Loading />;

  return (
    <Box position="relative">
      <Box display="flex" flexDirection="row" className="ProjectFlow__stepTitleWrapper">
        <SectionTitle
          title={stepTitle || ''}
          action={
            <>
              <DocLink link={DocLinkUrl[DocTypeEnum.MetricsCalculationStep]} />
              <Tooltip title="Create or duplicate a run">
                <>
                  <Button disabled={isPhaseReadOnly} onClick={() => setCreateRunDialogOpen(true)}>
                    <Add />
                  </Button>
                </>
              </Tooltip>
            </>
          }
        />
        <RunSelector runs={runs || []} selected={selectedRun || null} handleRunChange={onRunChange} />
        {isPhaseReadOnly && (
          <Box marginLeft={1} display={'flex'} alignItems={'center'}>
            <Typography
              className="WithWarning"
              color={'error'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <WarningOutlined />
              This phase is read-only
            </Typography>
          </Box>
        )}
        {selectedRun?.readOnly && (
          <Box marginLeft={1} display={'flex'} alignItems={'center'}>
            <Typography
              className="WithWarning"
              color={'error'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <WarningOutlined />
              This run is read-only
            </Typography>
          </Box>
        )}
      </Box>
      <Box display="flex" flexDirection="row" marginTop={1}>
        <Box>
          <Typography>{stepDescription || ''}</Typography>
        </Box>
      </Box>

      {/* Metrics input */}
      <Box display={inputType === InputGenerationType.Wizard ? 'none' : 'box'} alignItems="center">
        <JobHandler
          leftActions={
            <InputTypeSelector
              disabled={isRunLoading || !inputTable || isPhaseReadOnly}
              dropdownClassName="ProjectFlow__menuItem"
              onChangeHandler={onInputTypeChanged}
              value={inputType as InputGenerationType | undefined}
            />
          }
          showSheetLinks={inputType === InputGenerationType.Sheets}
          title="Metrics"
          updateView={onRefreshRuns}
          updateRun={onSelectRun}
          selectedRunId={selectedRun?.runId}
          project={project}
          type={JobTypes.input}
          simulateFail={simulateFail}
          setRef={(ref) => {
            inputJobHandler.current = ref;
          }}
          key={`input-handler-${selectedRun?.runId}`}
          inProgressText="Preparing input"
          successText="Input ready"
          jobSheetResourceKey={ResourceKeys.SCOPE_DEFINITION_INGREDIENT_MAPPING}
          externalLinkLabel="Ingredients"
          isDisabled={isPhaseReadOnly || !!selectedRun?.readOnly}
          executor={JobExecutor.default}
        />
      </Box>
      {inputType === InputGenerationType.Wizard && (
        <Box mt={2} width="100%">
          {selectedRun?.runId && ingredientDbVersion && (
            <LemmaMappingTable
              projectId={selectedRun.projectId}
              phaseId={selectedRun.phaseId}
              runId={selectedRun.runId}
              inputId={inputId}
              lemmas={lemmas.length ? lemmas : initialLemmas}
              loading={areLemmasLoading}
              version={ingredientDbVersion}
              onHideLemma={onHideLemma}
              onRemoveCorrectedId={onRemoveCorrectedId}
              onRowSelect={onRowSelect}
              isPhaseReadOnly={isPhaseReadOnly}
              isRunReadOnly={selectedRun.readOnly}
            />
          )}
        </Box>
      )}
      {/* Metrics output */}
      <JobHandler
        title="Metrics"
        updateView={onRefreshRuns}
        updateRun={onSelectRun}
        run={run}
        selectedRunId={selectedRun?.runId}
        project={project}
        type={JobTypes.output}
        simulateFail={simulateFail}
        isDisabled={isPhaseReadOnly || !!selectedRun?.readOnly}
        key={`output-handler-${selectedRun?.runId}`}
        inProgressText="Calculating metrics"
        successText="Metrics ready"
        externalLinkLabel="Ingredient Metrics"
        jobSheetResourceKey={ResourceKeys.METRICS_CALCULATION_INGREDIENT_LABELS}
        executor={JobExecutor.default}
        waitForInputId={!inputId}
        errorMessage="You need to save the mapping before being able to generate the output"
      />

      <CreateRunDialog
        open={createRunDialogOpen}
        onClose={() => setCreateRunDialogOpen(false)}
        onSubmit={onCreateRun}
        onDuplicate={(newRunName, sourceRunId) => onDuplicateRun(sourceRunId, newRunName)}
        runsList={runs}
        defaultRun={selectedRun}
        loading={runLoader}
      />
    </Box>
  );
};

export default memo(MetricsCalculation);
