import './WarningIcon.scss';

import { WarningOutlined } from '@mui/icons-material';
import React, { PropsWithChildren } from 'react';

const WarningIcon: React.FC<PropsWithChildren<any>> = (props) => {
  const iconProps = { ...props, children: undefined };

  return <WarningOutlined className="warningIcon" {...iconProps} />;
};

export default WarningIcon;
