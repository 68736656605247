import { FirebaseApp, initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup, signOut, User } from 'firebase/auth';
import React from 'react';

import firebaseConfig from './firebase/config';

export const useDB = (): null => null;

type UserContext = {
  user: User | null;
  initialising: boolean;
  config: any;
};

export type FetchWithAuth = (
  url: string,
  options: any,
) => Promise<{
  error: string | null;
  status: number;
  data: any | null;
}>;

export const userContext = React.createContext({
  user: null,
  initialising: false,
  config: null,
} as UserContext);

export const useSession = (): User | null => {
  const { user } = React.useContext(userContext);
  return user;
};

export interface FirebaseConfigAndApp {
  config: any | null;
  app: FirebaseApp | null;
}

export const useFirebaseApp = (): FirebaseConfigAndApp => {
  const [appState, setAppState] = React.useState<FirebaseConfigAndApp>({
    config: null,
    app: null,
  });

  React.useEffect(() => {
    if (!appState.app) {
      // use magic urls for production builds
      if (process.env.REACT_APP_API_HOST) {
        fetch('/__/firebase/init.json').then(async (response) => {
          if (response.status === 200) {
            const config = await response.json();
            const initializedApp = initializeApp(config);
            setAppState({ config, app: initializedApp });
          }
        });
      } else {
        const config = firebaseConfig;
        console.warn('Warning: using dev config for firebase');
        const initializedApp = initializeApp(config);
        setAppState({ config, app: initializedApp });
      }
    }
  }, [appState.app]);

  return appState;
};

export const useFetchWithAuth = (): FetchWithAuth => {
  const user = useSession();
  const fetchWithAuth = async (url: string, options: any) => {
    const fullUrl = (process.env.REACT_APP_API_HOST ? process.env.REACT_APP_API_HOST : '') + url;
    let newOptions = options;
    if (user !== null) {
      const realToken = await user.getIdToken();
      const newHeaders = {
        ...(options.headers || {}),
        Authorization: `Bearer ${realToken}`,
      };
      newOptions = { ...options, headers: newHeaders };
      if (process.env.REACT_APP_API_HOST) {
        newOptions = { ...newOptions, mode: 'cors' };
      }
    }
    const response = await fetch(fullUrl, newOptions);
    if (response.ok) {
      const data = await response.json();
      return { error: null, status: response.status, data };
    }
    const { headers } = response;
    const grpcMessage = headers.get('grpc-message');
    const error = grpcMessage || `response failed with status ${response.status}`;
    return { error, status: response.status, data: null };
  };
  return React.useCallback(fetchWithAuth, [user]);
};

type UserState = {
  initialising: boolean;
  user: User | null;
  config: any | null;
};

type UserAction = { type: 'LoadUser'; user: User | null } | { type: 'Initialized'; config: any };

const reducer = (state: UserState, action: UserAction): UserState => {
  switch (action.type) {
    case 'LoadUser':
      return { ...state, user: action.user };
    case 'Initialized':
      return { ...state, initialising: false, config: action.config };
    default:
      return { ...state };
  }
};

export const useAuth = (): UserState => {
  const [state, dispatch] = React.useReducer(reducer, {
    initialising: true,
    user: null,
    config: null,
  });
  const { app, config } = useFirebaseApp();

  React.useEffect(() => {
    if (app) {
      const auth = getAuth(app);
      dispatch({ type: 'Initialized', config });

      const unsubscribe = onAuthStateChanged(auth, (user: User | null) => {
        dispatch({ type: 'LoadUser', user });
      });

      return () => unsubscribe();
    }
    return undefined;
  }, [app, config]);

  return state;
};

const provider = new GoogleAuthProvider();

export const loginWithGoogle = async (): Promise<void> => {
  const auth = getAuth();
  try {
    await signInWithPopup(auth, provider);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const logout = (): void => {
  const auth = getAuth();
  signOut(auth);
};
