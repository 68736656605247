import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';

import { addAromaProfileVariant } from '../../../features/aroma/store/reducer';
import { AromaTableProfile } from '../../../features/aroma/store/types';
import { AromaRecord } from '../../../features/aroma/types';

type CreateNewVariantDialogProps = {
  version?: string;
  disabled?: boolean;
  dispatch: Dispatch;
  productPreparationId: number | undefined;
  open?: boolean;
  setOpen: (open: boolean) => void;
  editedRows: AromaRecord[];
};

const defaultProps = { version: '', open: false, disabled: false };

const EMPTY_VARIANT = { name: '', description: '' };

const CreateNewVariantDialog: FC<CreateNewVariantDialogProps & typeof defaultProps> = ({
  disabled,
  dispatch,
  productPreparationId,
  version,
  open,
  setOpen,
  editedRows,
}) => {
  const [{ name, description }, setProfile] = useState<AromaTableProfile>(EMPTY_VARIANT);
  const isActionDisabled = !name || !description || !version || !productPreparationId;
  const history = useNavigate();

  // handle dialog close and reset inputs data
  const onDialogClose = useCallback(() => {
    setOpen(false);
    setProfile(EMPTY_VARIANT);
  }, [setOpen]);

  // handle chane name and description of profile to add
  const onInputChange = useCallback((e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name: field, value } = (e?.target || {}) as HTMLInputElement;
    setProfile((profile) => (field ? { ...profile, [field]: value } : profile));
  }, []);

  // dispatch add new aroma profile action
  const addNewAromaProfileVariant = useCallback(() => {
    if (version && productPreparationId && name && description) {
      dispatch(
        addAromaProfileVariant({
          version,
          productPreparationId,
          profile: { name, description, data: { records: editedRows } },
        }),
      );
    }
    history(`./${version}?product_preparation_id=${productPreparationId}&variant=${name}`);
    onDialogClose();
  }, [version, dispatch, description, name, productPreparationId, onDialogClose, editedRows, history]);

  return (
    <>
      <div>
        <Button
          fullWidth={true}
          variant="contained"
          type="button"
          color="primary"
          onClick={() => setOpen(true)}
          disabled={disabled}
        >
          <AddIcon />
          <Box display="flex" alignItems="center">
            <Typography>New</Typography>
          </Box>
        </Button>
      </div>

      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="new-variant-dialog-title">
        <DialogTitle id="new-variant-dialog-title">Add New Variant</DialogTitle>
        <DialogContent>
          <TextField
            data-id-cypress="addNewVariantName"
            required={true}
            value={name}
            onChange={onInputChange}
            autoFocus={true}
            name="name"
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth={true}
          />
          <TextField
            data-id-cypress="addNewVariantDescription"
            required={true}
            value={description}
            onChange={onInputChange}
            margin="dense"
            name="description"
            id="description"
            label="Description"
            type="text"
            fullWidth={true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            data-id-cypress="addNewVariantButton"
            onClick={addNewAromaProfileVariant}
            color="primary"
            disabled={isActionDisabled}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

CreateNewVariantDialog.defaultProps = defaultProps;

export default CreateNewVariantDialog;
