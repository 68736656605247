import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import React, { FC, PropsWithChildren } from 'react';

type GraphProps = {
  error?: string;
  loading?: boolean;
  height?: string | number;
};

const VizContainer: FC<PropsWithChildren<GraphProps>> = ({ error, height, loading, children }) => {
  const content = loading ? <CircularProgress variant="indeterminate" /> : children;

  return (
    <Box minWidth="100%" minHeight={height}>
      {error && <Typography variant="h4"> Error: {error} </Typography>}
      {!error && content}
    </Box>
  );
};

VizContainer.defaultProps = {
  error: undefined,
  loading: false,
  height: '300',
};

export default VizContainer;
