import './RunSelector.scss';

import { Autocomplete, FormControl, TextField } from '@mui/material';
import React from 'react';

import { RunType } from '../_utils/types';

export interface RunSelectorProps {
  runs: RunType[];
  selected: RunType | null;
  handleRunChange: (run: RunType | null) => void;
}

const RunSelector: React.FC<RunSelectorProps> = ({ runs, selected, handleRunChange }) => {
  const selectRuns: { label: string; value: string }[] = runs.map((run) => ({
    label: run.runName,
    value: run.runId,
  }));

  const defaultRun = runs.find((run) => !run.readOnly) || runs[0] || null;
  let value = null;
  if (selected) {
    value = { value: selected.runId, label: selected.runName };
  } else if (defaultRun) {
    value = { value: defaultRun.runId, label: defaultRun.runName };
  }

  return (
    <FormControl variant="outlined" size="small">
      <Autocomplete
        data-id-cypress="runSelector"
        id="runs-combo-box"
        options={selectRuns}
        getOptionLabel={(option: any) => option.label}
        style={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Select run" variant="outlined" />}
        isOptionEqualToValue={(option, val) => option.value === val.value}
        value={value}
        onChange={(e, val) =>
          val?.value ? handleRunChange(runs.find((run) => run.runId === val.value) || null) : null
        }
      />
    </FormControl>
  );
};

export default RunSelector;
