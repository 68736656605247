import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BackendLoadingStatus } from '../types/common';
import { LemmaTableRow, LemmaTableRowKeys } from '../types/metrics-calculation';

const { correctedIdKey, correctedNameKey, correctedRefIdKey, correctedRefNameKey, hideLemmasKey } = LemmaTableRowKeys;

export interface MetricsCalculationState {
  lemmas: LemmaTableRow[];
  initialLemmas: LemmaTableRow[];
  inputTable?: string | null;
  lemmaLoadingStatus: BackendLoadingStatus;
}

const initMetricCalculationState: MetricsCalculationState = {
  lemmas: [],
  initialLemmas: [],
  inputTable: null,
  lemmaLoadingStatus: BackendLoadingStatus.Initial,
};

function findLemmaIndex(lemmas: LemmaTableRow[], lemma: string): number {
  return lemmas.findIndex((l: LemmaTableRow) => l.lemma === lemma);
}

export const SLICE_METRICS_CALCULATION = 'metrics_calculation';
export const metricsCalculationSlice = createSlice({
  name: SLICE_METRICS_CALCULATION,
  initialState: initMetricCalculationState,
  reducers: {
    setLemmaLoadingStatus: (state, action: PayloadAction<BackendLoadingStatus>) => {
      state.lemmaLoadingStatus = action.payload;
    },
    resetLemmas: (state) => {
      state.lemmas = [];
      // triggers reload of the lemmas
      state.lemmaLoadingStatus = BackendLoadingStatus.Initial;
    },
    setLemmas: (state, action: PayloadAction<{ lemmas: LemmaTableRow[] }>) => {
      let { lemmas } = action.payload;
      // we need to copy the initialLemmas from the state
      // the state works with Proxy objects
      // by using ... notations to access the values behind
      const initialLemmas = [...state.initialLemmas].map((l) => ({ ...l }));
      // enrich lemmas with initialLemmas
      lemmas = lemmas.map((l) => {
        const initialLemmaIndex = findLemmaIndex(initialLemmas, l.lemma);
        if (initialLemmaIndex >= 0) {
          return {
            ...l,
            project: initialLemmas[initialLemmaIndex].project,
            creation_time: initialLemmas[initialLemmaIndex].creation_time,
          };
        }
        return l;
      });
      state.lemmas = lemmas;
    },
    setLemmaVisibility: (state, action: PayloadAction<{ lemmaId: string; hideLemma: boolean }>) => {
      const index = findLemmaIndex(state.lemmas, action.payload.lemmaId);
      if (index > -1) {
        state.lemmas[index][hideLemmasKey] = action.payload.hideLemma;
      }
    },
    setLemmaRelevant: (state, action: PayloadAction<{ selectedIds: string[] }>) => {
      if (state.lemmas) {
        state.lemmas.forEach((item: any) => {
          item.relevant_ingredient = action.payload.selectedIds.includes(item.lemma);
        });
      }
    },
    setLemmaCorrectedInfos: (
      state,
      action: PayloadAction<{
        runId: string;
        lemma: string;
        corrected_ingredient_id: number | null;
        corrected_ingredient_name: string | null;
        corrected_reference_ingredient_id: number | null;
        corrected_reference_ingredient_name: string | null;
      }>,
    ) => {
      const index = state.lemmas.findIndex((l: any) => l.lemma === action.payload.lemma);
      if (index > -1) {
        state.lemmas[index][correctedIdKey] = action.payload.corrected_ingredient_id;
        state.lemmas[index][correctedNameKey] = action.payload.corrected_ingredient_name;
        state.lemmas[index][correctedRefIdKey] = action.payload.corrected_reference_ingredient_id;
        state.lemmas[index][correctedRefNameKey] = action.payload.corrected_reference_ingredient_name;
      }
    },
    setInitialLemmas: (
      state,
      action: PayloadAction<{
        lemmas: LemmaTableRow[];
        inputTable: string;
      }>,
    ) => {
      const { lemmas } = action.payload;
      // filter out empty lemmas if any occur
      lemmas.filter((l) => !!l.lemma);
      state.initialLemmas = action.payload.lemmas;
      state.inputTable = action.payload.inputTable;
    },
  },
});

export default metricsCalculationSlice.reducer;
export const {
  setLemmaLoadingStatus,
  setLemmas,
  resetLemmas,
  setLemmaRelevant,
  setLemmaCorrectedInfos,
  setLemmaVisibility,
  setInitialLemmas,
} = metricsCalculationSlice.actions;
