import '../../../../../layout/correction-table.scss';

import { Error, VisibilityOutlined } from '@mui/icons-material';
import { Box, Button, Paper, Typography } from '@mui/material';
import { DataGridProps, GridColDef } from '@mui/x-data-grid';
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';

import { changeIngredientDbVersion, SLICE_DATASOURCE_NAME } from '../../../../../features/datasources/store';
import { setLemmaCorrectedInfos } from '../../../../../features/project/store';
import { LemmaTableRow, LemmaTableRowKeys } from '../../../../../features/project/types/metrics-calculation';
import { updateLemmas } from '../../../../../features/project/utils';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { DataSourceType } from '../../../../../types';
import IngredientTable from '../../_components/IngredientTable';
import { IngredientDBMap } from '../../_utils/datasources';
import { RowErrorMessages } from './RowErrorMessages';
import { getRowErrorMessage, prepareLemmaTableColumns } from './utils';

const { isRelevantKey, lemmaKey } = LemmaTableRowKeys;

interface LemmaMappingTableProps {
  version: string;
  inputId: string | undefined;
  lemmas: LemmaTableRow[];
  loading: boolean;
  runId: string;
  phaseId: string;
  projectId: string;
  onHideLemma: any;
  onRemoveCorrectedId: any;
  onRowSelect: any;
  isPhaseReadOnly?: boolean;
  isRunReadOnly?: boolean;
}

const LemmaMappingTable: FC<LemmaMappingTableProps> = ({
  version,
  inputId,
  lemmas,
  loading,
  runId,
  phaseId,
  projectId,
  onHideLemma,
  onRemoveCorrectedId,
  onRowSelect,
  isPhaseReadOnly,
  isRunReadOnly,
}) => {
  const dispatch = useAppDispatch();

  const [idMap, setIdMap] = useState<IngredientDBMap>(new IngredientDBMap([], ''));
  const [rows, setRows] = useState<LemmaTableRow[]>([]);
  const [displayHiddenLemmas, setDisplayHiddenLemmas] = useState<boolean>(false);
  const [lemmaErrorList, setLemmaErrorList] = useState<any[]>([]);
  const [showOnlyErrorRows, setShowOnlyErrorRows] = useState<boolean>(false);
  const { ingredients, ingredientsLoading } = useAppSelector((store) => store[SLICE_DATASOURCE_NAME]);

  const onEditCorrectionIngredients = useCallback(
    (
      lemma: string,
      ingredientId: number | null,
      ingredientName: string | null,
      ingredientRefId: number | null,
      ingredientRefName: string | null,
    ) => {
      dispatch(
        setLemmaCorrectedInfos({
          runId,
          lemma,
          corrected_ingredient_id: ingredientId,
          corrected_ingredient_name: ingredientName,
          corrected_reference_ingredient_id: ingredientRefId,
          corrected_reference_ingredient_name: ingredientRefName,
        }),
      );
    },
    [dispatch, runId],
  );

  // prepare data grid columns
  const columns = useMemo<GridColDef[]>(
    () =>
      (idMap.dropdownOptions.length &&
        prepareLemmaTableColumns(idMap, onHideLemma, onRemoveCorrectedId, isPhaseReadOnly || isRunReadOnly)) ||
      [],
    [idMap, onHideLemma, onRemoveCorrectedId, isPhaseReadOnly, isRunReadOnly],
  );

  // get initially checked row ids
  const selectedIds = useMemo<string[]>(
    () => lemmas.filter((l) => l[isRelevantKey]).map((l) => String(l[lemmaKey] || '')),
    [lemmas],
  );

  // get initially checked row ids
  useEffect(() => {
    if (version && !ingredientsLoading && ingredients.length) {
      setIdMap(new IngredientDBMap(ingredients, version));
    }
  }, [version, ingredients, ingredientsLoading]);

  // set selected ingredients and modify relevant_ingredient field
  const setSelection = useCallback((ids: string[]) => onRowSelect(ids), [onRowSelect]);

  // set selected ingredients and modify relevant_ingredient field
  const getRowClassName = useCallback<Required<DataGridProps>['getRowClassName']>(
    ({ row }) => {
      const message = getRowErrorMessage(row, idMap);
      switch (message) {
        case RowErrorMessages.noIdOrCorrectedId:
          return 'CorrectionTable__errorRow';
        case RowErrorMessages.noReferenceMapping:
          return 'CorrectionTable__warningRow';
        case RowErrorMessages.noReferenceCorrectedMapping:
          return 'CorrectionTable__warningRow';
        default:
          return '';
      }
    },
    [idMap],
  );

  /**
   * This callback is trigger when the user clicks the save mapping button
   * It will update the lemmas with the new mapping using the API
   */
  const onSaveLemmaMapping = useCallback<() => void>(async () => {
    await updateLemmas(dispatch, runId, phaseId, projectId, inputId, lemmas);
  }, [dispatch, runId, phaseId, projectId, inputId, lemmas]);

  const processRowUpdate = useCallback<Required<DataGridProps>['processRowUpdate']>(
    (newRow: LemmaTableRow) => {
      // Id of a row cannot be empty cannot be empty
      if (newRow.lemma === null) return newRow;
      if (newRow?.corrected_ingredient_id !== null) {
        const ingredient = idMap.searchIngredientById(newRow?.corrected_ingredient_id);

        const ref = ingredient?.referenceIngredientId
          ? idMap.searchIngredientById(Number(ingredient?.referenceIngredientId))
          : null;

        onEditCorrectionIngredients(
          newRow.lemma,
          Number(ingredient?.ingredientId) || null,
          ingredient?.name || null,
          Number(ref?.ingredientId) || null,
          ref?.name || null,
        );
      } else {
        onEditCorrectionIngredients(newRow.lemma, null, null, null, null);
      }
      setRows((prevRows) => prevRows.map((r) => (r[lemmaKey] === newRow?.[lemmaKey] ? newRow : r)));
      return newRow;
    },
    [onEditCorrectionIngredients, idMap],
  );

  // Will find any missing ID error within the lemmas
  useEffect(() => {
    if (!showOnlyErrorRows) {
      setLemmaErrorList([]);
      const tempLemmaErrorList: any[] = [];

      lemmas.forEach((item) => {
        if (item.relevant_ingredient === true && item.ingredient_id === null && item.corrected_ingredient_id === null) {
          tempLemmaErrorList.push(item);
        }
      });

      if (tempLemmaErrorList.length > 0) {
        setLemmaErrorList(tempLemmaErrorList);
      }
    }
  }, [lemmas, showOnlyErrorRows]);

  // when ingredient version is changing - load ingredients
  useEffect(() => {
    dispatch(
      changeIngredientDbVersion({
        version,
        sourceType: DataSourceType.IngredientDB,
      }),
    );
  }, [version, dispatch]);

  const handleDisplayHiddenLemmas = useCallback((value: boolean) => {
    setDisplayHiddenLemmas(value);
  }, []);

  // update local rows when redux rows changed
  useEffect(() => {
    if (displayHiddenLemmas) {
      setRows(lemmas);
      onRowSelect(selectedIds);
    } else if (showOnlyErrorRows) {
      setRows(lemmaErrorList);
      onRowSelect(selectedIds);
    } else {
      const temp = lemmas.filter((f) => f.hide_lemma === false || !f.hide_lemma);
      setRows(temp);
      onRowSelect(selectedIds);
    }
  }, [lemmas, displayHiddenLemmas, onRowSelect, selectedIds, lemmaErrorList, showOnlyErrorRows]);

  const errorAndFilter = (
    <Box display="flex" justifyContent={'flex-start'} flex={1} alignItems={'center'}>
      <Box padding={1} display={'flex'} flexDirection={'column'}>
        {lemmaErrorList.length > 0 && (
          <Typography
            data-id-cypress="lemmaMappingTableLink"
            display={'flex'}
            alignItems={'center'}
            fontSize={14}
            style={{
              color: 'blue',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => {
              setShowOnlyErrorRows(!showOnlyErrorRows);
              setDisplayHiddenLemmas(false);
            }}
          >
            <Error color={'error'}></Error>
            {showOnlyErrorRows
              ? 'You are seeing the lemmas with error only, click here to show everything'
              : 'Some lemmas have errors, click here to show them'}
          </Typography>
        )}
        {!showOnlyErrorRows && (
          <Typography
            data-id-cypress="lemmaMappingTableDisplayHiddenLemmas"
            display={'flex'}
            alignItems={'center'}
            fontSize={14}
            style={{
              color: 'blue',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (!showOnlyErrorRows) {
                handleDisplayHiddenLemmas(!displayHiddenLemmas);
                setDisplayHiddenLemmas(!displayHiddenLemmas);
              }
            }}
          >
            <VisibilityOutlined></VisibilityOutlined>
            {!displayHiddenLemmas
              ? 'Some lemmas are hidden, click here to show all lemmas'
              : 'All the lemmas are displayed, show only the ones that need attention'}
          </Typography>
        )}
      </Box>
    </Box>
  );

  return (
    <>
      <Box display="flex" justifyContent={'flex-end'} alignItems={'center'}>
        {errorAndFilter}
      </Box>
      <Paper>
        <IngredientTable
          actions={
            <Box display="flex" justifyContent="end" p={2}>
              <Button
                disabled={loading || lemmaErrorList.length > 0 || isPhaseReadOnly || isRunReadOnly}
                onClick={onSaveLemmaMapping}
                color="secondary"
                variant="contained"
                data-id-cypress="saveMappingButton"
              >
                Save Mapping
              </Button>
            </Box>
          }
          checkboxSelection={true}
          disableSelectionOnClick={true}
          className="CorrectionTable__grid"
          headerHeight={76}
          autoHeight={false}
          setSelectedIngredients={setSelection}
          initialSelection={selectedIds}
          identifier={lemmaKey}
          columns={columns}
          ingredients={rows || []}
          loading={ingredientsLoading || loading}
          height={900}
          getRowClassName={getRowClassName}
          rowHeight={80}
          showColumnRightBorder={true}
          hideFooterSelectedRowCount={true}
          processRowUpdate={processRowUpdate}
          columnVisibilityModel={{ [isRelevantKey]: false }}
          experimentalFeatures={{ newEditingApi: true }}
          isReadOnly={isPhaseReadOnly || isRunReadOnly}
        />
      </Paper>
      {errorAndFilter}
    </>
  );
};

LemmaMappingTable.defaultProps = {
  isPhaseReadOnly: false,
  isRunReadOnly: false,
};

export default memo<FC<LemmaMappingTableProps>>(LemmaMappingTable) as typeof LemmaMappingTable;
