import './App.scss';

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { ErrorOutline } from '@mui/icons-material';
import { Box, Button, CssBaseline, Dialog, Typography } from '@mui/material';
import axios, { AxiosRequestHeaders } from 'axios';
import axiosRetry from 'axios-retry';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Provider as ReduxProvider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import uuid from 'react-uuid';

import { loginWithGoogle, useAuth, userContext } from './auth';
import ErrorNotification from './components/ErrorNotification';
import { DataSourcesProvider } from './context/DataSourcesContext';
import { LayoutProvider } from './context/LayoutContext';
import Layout from './layout/Layout';
import { FPLogo } from './Logo';
import routes from './routes';
import { isProduction } from './shared/utils';
import { store } from './store';

interface AppProps {
  onUnmount: () => void;
}

const LoginView: React.FC = () => (
  <Box className={'Login__box'}>
    <FPLogo className={'Login__box__logo'} />
    <Button
      className={'Login__box__button'}
      variant="contained"
      onClick={loginWithGoogle}
      color="inherit"
      data-id-cypress="googleLogin"
    >
      Login via Google
    </Button>
  </Box>
);

const App = ({ onUnmount }: AppProps): JSX.Element => {
  const { initialising, config, user } = useAuth();
  const [error, setError] = useState<string | null>('');
  const userContextValue = useMemo(() => ({ initialising, user, config }), [initialising, user, config]);
  const [nonce, setNonce] = useState<string>('');
  const cache = createCache({ key: 'cfi', nonce, prepend: true });
  const [displayErrorPopup, setDisplayErrorPopup] = useState(false);
  const maxRetry = 3;
  const exceptionPostCall = ['/v1/projects/query', '/v1/jobs/query', '/v1/contexts/query'];
  const exceptionGetCall: RegExp[] = [/\/v1\/datasources\/core\/\d{8}\/ingredient\/\d+\/aroma$/];
  const retryErrorCode: number[] = [401, 500, 502, 503, 509, 524];
  const [errorArray, setErrorArray] = useState<any[]>([]);

  console.log(user);

  useEffect(() => onUnmount, [onUnmount]);

  useEffect(() => setNonce(uuid().toString()), []); // set the nonce only once

  useEffect(() => {
    const env = isProduction() ? 'production' : 'development';
    document.body.classList.add(`theme--${env}`);
  }, []);

  const retryCalls = useCallback(
    (err: any, count: number) => {
      setDisplayErrorPopup(true);
      const index = errorArray.findIndex((e) => e.error?.config?.url === err.config?.url);
      if (index === -1) {
        errorArray.push({ error: err, retryCount: count });
      } else {
        errorArray[index].retryCount = count;
      }
      setErrorArray([...errorArray]);
    },
    [errorArray],
  );

  axiosRetry(axios, {
    retries: maxRetry,
    retryDelay: axiosRetry.exponentialDelay,
    onRetry(count: number, err: any) {
      retryCalls(err, count);
    },
    retryCondition(err: any) {
      if (!retryErrorCode.includes(err.response?.status)) return false;
      if (err.config?.method === 'get') {
        const urlOk = exceptionGetCall.findIndex((url) => url.test(err.config?.url));
        return urlOk !== -1;
      }
      return exceptionPostCall.includes(err.config?.url);
    },
  });

  axios.defaults.baseURL = process.env.REACT_APP_API_HOST;

  axios.interceptors.request.use(
    async (configParam: any) => {
      const newConfig = { ...configParam };
      if (!newConfig.headers) newConfig.headers = {} as AxiosRequestHeaders;
      if (user !== null) {
        const token = await user.getIdToken();
        newConfig.headers.Authorization = `Bearer ${token}`;
      }
      return newConfig;
    },
    (err: any) => Promise.reject(err),
  );

  axios.interceptors.response.use(async (response) => {
    const index = errorArray.findIndex((e) => e.error.config?.url === response.config.url);
    if (index !== -1) {
      errorArray.splice(index, 1);
      setErrorArray([...errorArray]);
    }
    if (errorArray.length === 0) {
      setDisplayErrorPopup(false);
    }
    return response;
  });

  const element = useRoutes(routes);

  // probably we can remove the CssBaseline
  return (
    <>
      <CacheProvider value={cache}>
        <Helmet>
          <title>Foodpairing CFI</title>
          <meta
            httpEquiv="Content-Security-Policy"
            content={`
              default-src 'self' https://cdn.firebase.com https://*.firebaseio.com;
              script-src
                'self'
                https://cdn.firebase.com https://*.firebaseio.com https://apis.google.com https://accounts.google.com/gsi/client;
              img-src 'self' https://*.googleusercontent.com;
              child-src 'self' https://*.firebaseapp.com https://*.googleusercontent.com;
              style-src 'self' 'unsafe-inline' ${nonce};
              frame-src 'self' https://*.firebaseapp.com https://*.google.com https://*.googleapis.com https://accounts.google.com/gsi/;
              connect-src
                'self'
                https://accounts.google.com/gsi/
                https://*.googleapis.com
                https://cfi-api.internal.staging.foodpairing.com
                https://cfi-api.internal.foodpairing.com;
            `}
          />
        </Helmet>
        <CssBaseline />
        <userContext.Provider value={userContextValue}>
          <LayoutProvider>
            {initialising ? (
              <div>Loading...</div>
            ) : (
              <div>
                {user !== null || process.env.REACT_APP_ENV === 'testing' ? (
                  <ReduxProvider store={store}>
                    <DataSourcesProvider>
                      <Box>
                        <Layout user={user}>{element}</Layout>
                      </Box>
                    </DataSourcesProvider>
                    <Dialog open={displayErrorPopup}>
                      <Box className="App__error_background"></Box>
                      <Box className="App__error_popup">
                        <Box className="App__error_popup__header">
                          <Button className="App__error_popup__close" onClick={() => setDisplayErrorPopup(false)}>
                            X
                          </Button>
                        </Box>
                        <Typography className="App__error_popup__title">
                          <ErrorOutline color="error"></ErrorOutline>Oops !
                        </Typography>
                        <Typography className="App__error_popup__text">
                          {'An error occurred with the following API calls:'}
                          {errorArray.map((err) => (
                            <>
                              <span key={err.error?.config?.url} className="App__error_popup__text__url">
                                Code <b>{err.error?.response.status}</b>: {err.error?.config?.url}
                              </span>
                              <b>
                                retry {err.retryCount} of {maxRetry}
                              </b>
                            </>
                          ))}
                        </Typography>
                        <Box className="App__error_popup__footer">
                          <Button
                            className="App__error_popup__footer__button"
                            target="_blank"
                            href="https://foodpairing.slack.com/archives/C04D2EBFWV8"
                          >
                            Message on Slack
                          </Button>
                        </Box>
                      </Box>
                    </Dialog>
                  </ReduxProvider>
                ) : (
                  <LoginView />
                )}
                {error && <ErrorNotification error={error} handleClose={() => setError(null)} />}
              </div>
            )}
          </LayoutProvider>
        </userContext.Provider>
      </CacheProvider>
    </>
  );
};

export default App;
