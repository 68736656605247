import '../../../../layout/common.scss';
import '../../../../layout/project-flow.scss';

import { Delete, Warning, WarningOutlined } from '@mui/icons-material';
import Add from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';
import { Box, Button, Dialog, DialogContent, DialogTitle, Tooltip, Typography } from '@mui/material';
import axios, { AxiosResponse } from 'axios';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DataMan } from '../../../../components/dataman';
import { DataManRecordsType } from '../../../../components/dataman/types';
import JobHandler, { JobTypes } from '../../../../components/JobHandler';
import Loading from '../../../../components/Loading';
import SectionTitle from '../../../../components/SectionTitle';
import { DocLink, DocLinkUrl, DocTypeEnum } from '../../../../features/docs';
import PhaseGlobalContexts from '../../../../features/project/components/PhaseGlobalContexts';
import { buildActionUrl, TYPE_DUPLICATE_INPUT_RUN, TYPE_LOAD_RUNS, TYPE_PHASE_RUN_INPUT } from '../../../../shared/url';
import { ContextType, JobExecutor } from '../../../../types';
import CreateRunDialog from '../_components/CreateRunDialog';
import RunSelector from '../_components/RunSelector';
import { sortRunsByCreateAt } from '../_utils/datasources';
import { FlowActions } from '../_utils/reducer';
import { JobStatus, ProjectFlowStepProps, ResourceKeys, RunType } from '../_utils/types';
import buildStructure from './_utils/config-define-scope';
import { DefineScopeDatamanRecord } from './_utils/types';

const ScopeDefinition: React.FC<ProjectFlowStepProps> = ({
  project,
  selectedRun,
  runId,
  runs,
  phaseId,
  dispatch,
  simulateFail,
  isPhaseReadOnly,
  stepDescription,
  stepTitle,
}) => {
  const [phaseError, setPhaseError] = useState<string | null>(null);
  const [runNameDialogOpen, setRunNameDialogOpen] = useState<boolean>(false);
  const [isContextEditing, setContextEditing] = useState<boolean>(false);
  const [projectContexts, setProjectContexts] = useState<DataManRecordsType<DefineScopeDatamanRecord>>([]);
  const [contextsTableLoading, setContextsTableLoading] = useState<boolean>(false);
  const [removeContextDialogOpen, setRemoveContextDialogOpen] = useState<boolean>(false);
  const [contextToRemove, setContextToRemove] = useState<string>('');
  const history = useNavigate();
  const [displayWarning, setDisplayWarning] = useState<boolean>(false);
  const [fetchContextsLoading, setFetchContextsLoading] = useState<boolean>(false);
  const [fetchContextsError, setFetchContextsError] = useState<string | null>(null);

  const projectId = project?.projectId;
  const selectedRunId = selectedRun?.runId;
  const getRuns = useCallback(async () => {
    if (!projectId || !phaseId) return;
    const url = buildActionUrl({ projectId, phaseId }, TYPE_LOAD_RUNS);
    const response: { runs: RunType[] } | null = await axios.get(url).then((res: AxiosResponse) => res.data);
    if (response?.runs) {
      dispatch({
        type: FlowActions.setRuns,
        runs: sortRunsByCreateAt(response?.runs || []),
        selectedRun: selectedRun || undefined,
      });
    }
  }, [dispatch, projectId, phaseId, selectedRun]);

  // Refresh contexts
  const refreshContexts = useCallback<(id?: string | number | undefined) => void>(
    (runIdToRefresh: string | number | undefined) => {
      if (projectId && phaseId && selectedRunId && selectedRunId === runIdToRefresh) {
        const url = buildActionUrl({ projectId, phaseId, runId: selectedRunId }, TYPE_PHASE_RUN_INPUT);
        setFetchContextsLoading(true);
        axios
          .post(url, { scopeDefinition: { listContextSelection: {} } })
          .then((response: AxiosResponse) => {
            const contextSelection: DataManRecordsType<DefineScopeDatamanRecord> =
              response.data?.scopeDefinition?.listContextSelection?.contextSelection || [];
            setProjectContexts(contextSelection);
            setFetchContextsLoading(false);
          })
          .catch((error: any) => {
            setFetchContextsError(error?.message || null);
            setFetchContextsLoading(false);
          });
      }
    },
    [projectId, phaseId, selectedRunId],
  );

  useEffect(() => {
    const largeContext = projectContexts.find((context) => context.recipeCount > 10000);
    setDisplayWarning(largeContext !== undefined);
  }, [projectContexts]);

  const updateRun = useCallback<(run: RunType) => void>(
    (run) => {
      dispatch({ type: FlowActions.setSelectedRun, run });
    },
    [dispatch],
  );

  // Fetch Contexts

  // When fetchContexts is loading
  useEffect(() => setContextsTableLoading(fetchContextsLoading), [fetchContextsLoading]);

  // When fetchContexts throws an error
  useEffect(() => setPhaseError(fetchContextsError || null), [fetchContextsError]);

  // Initialize contexts
  useEffect(() => {
    if (!isContextEditing) {
      refreshContexts(runId);
    }
  }, [refreshContexts, isContextEditing, runId]);

  const openNewRunDialog = () => {
    setRunNameDialogOpen(true);
  };

  const closeNewRunDialog = () => {
    setRunNameDialogOpen(false);
  };

  const createNewRun = (runName?: string) => {
    createRunAsync(projectId, phaseId, runName || '').then((r) => r);
    closeNewRunDialog();
  };

  const createDuplicateRun = (sourceRunId: string, runName?: string) => {
    duplicateRunAsync(projectId, phaseId, sourceRunId, runName || '').then((r) => r);
    closeNewRunDialog();
  };

  // Run selector
  const handleRunChange = useCallback(
    (run: RunType | null) => {
      if (!run) return;
      history(`/projects/${projectId}/phases/${phaseId}/runs/${run.runId}`);
    },
    [projectId, phaseId, history],
  );

  // Create Run
  const createRunAsync = useCallback(
    async (projectIdentifier?: string, phaseIdentifier?: string, runName?: string) => {
      if (!projectIdentifier || !phaseIdentifier) return;

      const url = buildActionUrl(
        {
          projectId: projectIdentifier,
          phaseId: phaseIdentifier,
        },
        TYPE_LOAD_RUNS,
      );
      const response: { run: RunType } | null = await axios
        .post(url, { runName: runName || '' })
        .then((res) => res.data);

      if (response?.run) {
        getRuns().then((r) => r);
        handleRunChange(response.run);
      }
    },
    [getRuns, handleRunChange],
  );

  const duplicateRunAsync = useCallback(
    async (projectIdentifier?: string, phaseIdentifier?: string, sourceRunId?: string, runName?: string) => {
      if (!projectIdentifier || !phaseIdentifier || !sourceRunId) return;

      const url = buildActionUrl(
        {
          projectId: projectIdentifier,
          phaseId: phaseIdentifier,
          runId: sourceRunId,
        },
        TYPE_DUPLICATE_INPUT_RUN,
      );
      const response: { run: RunType } | null = await axios
        .post(url, { target_run_name: runName || '' })
        .then((res) => res.data);

      if (response?.run) {
        getRuns();
        handleRunChange(response.run);
      }
    },
    [getRuns, handleRunChange],
  );

  const removeContextFromProject = async (contextId: string) => {
    setContextsTableLoading(true);
    const url = buildActionUrl({ projectId, phaseId, runId: selectedRunId }, TYPE_PHASE_RUN_INPUT);
    try {
      await axios.post(url, {
        scopeDefinition: {
          deleteContextSelection: {
            contextId,
          },
        },
      });
      const id = projectContexts.findIndex((item: ContextType) => item.contextId === contextId);
      projectContexts.splice(id, 1);
      setProjectContexts(projectContexts);
    } catch (error) {
      // error log
    }
    setRemoveContextDialogOpen(false);
    setContextsTableLoading(false);
  };

  const openRemoveContextDialog = (contextId: string) => {
    setRemoveContextDialogOpen(true);
    setContextToRemove(contextId);
  };

  // OVERALL

  // Page is loading
  // When createRun or fetchRun is loading

  // Table configuration
  const dataManConfig = buildStructure({ records: projectContexts }, openRemoveContextDialog, !!selectedRun?.readOnly);

  useEffect(() => {
    if (!isContextEditing) {
      dispatch({
        type: FlowActions.setApplyButton,
        button: null,
      });

      dispatch({
        type: FlowActions.setPrimaryNavigationVisible,
        visible: true,
      });

      dispatch({
        type: FlowActions.setNavigationButtonStatusCallback,
        callback: () => ({
          next: selectedRun?.outputs?.output_generation?.status === JobStatus.ok,
          back: true,
        }),
      });
    } else {
      dispatch({
        type: FlowActions.setNavigationButtonStatusCallback,
        callback: () => ({
          next: false,
          back: false,
        }),
      });
    }
  }, [isContextEditing, selectedRun, dispatch]);

  // if no runs yet created - open modal
  useEffect(() => {
    if (!runId) {
      setRunNameDialogOpen(true);
    }
  }, [runId]);

  if (phaseError) return <>Something went wrong: {phaseError}</>;

  if (!phaseId) return <Loading />;

  if (isContextEditing) {
    if (!project || !selectedRun) return <></>;
    return (
      <PhaseGlobalContexts
        dispatch={dispatch}
        project={project}
        projectId={project?.projectId}
        run={selectedRun}
        phaseId={selectedRun.phaseId}
        onClose={() => setContextEditing(false)}
        existingProjectContexts={projectContexts}
      />
    );
  }

  return (
    <Box position="relative">
      <Box display="flex" flexDirection="row" className="ProjectFlow__stepTitleWrapper">
        <SectionTitle
          title={stepTitle}
          action={
            <>
              <DocLink link={DocLinkUrl[DocTypeEnum.ScopeDefinitionStep]} />
              <Tooltip title="Create or duplicate run">
                <>
                  <Button data-id-cypress="defineScopeAddRun" disabled={isPhaseReadOnly} onClick={openNewRunDialog}>
                    <Add />
                  </Button>
                </>
              </Tooltip>
            </>
          }
        />
        <RunSelector runs={runs || []} selected={selectedRun || null} handleRunChange={handleRunChange} />
        {isPhaseReadOnly && (
          <Box marginLeft={1} display={'flex'} alignItems={'center'}>
            <Typography
              className="WithWarning"
              color={'error'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <WarningOutlined />
              This phase is read-only
            </Typography>
          </Box>
        )}
        {selectedRun?.readOnly && (
          <Box marginLeft={1} display={'flex'} alignItems={'center'}>
            <Typography
              className="WithWarning"
              color={'error'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <WarningOutlined />
              This run is read-only
            </Typography>
          </Box>
        )}
      </Box>

      <Box display="flex" flexDirection="row" marginTop={1}>
        {/* Define Scope */}
        <Box>
          <Typography>{stepDescription}</Typography>
        </Box>
      </Box>

      {/* Scope input */}
      <Box data-id-cypress="scopeDefinitionInput" mt={3}>
        <Box display="flex" flexDirection="row">
          <Box flexGrow={1}>
            <SectionTitle variant="body1" title="Scope Input" useCommonStyles={true} />
          </Box>
          <Box>
            <Button
              data-id-cypress="scopeDefinitionAddContextsButton"
              startIcon={<Edit />}
              onClick={() => setContextEditing(true)}
              disabled={!!selectedRun?.readOnly || contextsTableLoading}
            >
              Add Contexts
            </Button>
          </Box>
        </Box>
        <DataMan
          loading={contextsTableLoading}
          {...dataManConfig}
          records={[...(dataManConfig.records || [])]}
          key={`data-tables-${JSON.stringify(dataManConfig.records || [])}`}
        />
      </Box>

      {/* Scope output */}
      {!fetchContextsLoading && selectedRun ? (
        <>
          <JobHandler
            title="Scope"
            updateView={getRuns}
            updateRun={updateRun}
            run={runs?.find((run) => run.runId === selectedRun?.runId)}
            selectedRunId={selectedRun?.runId}
            project={project}
            type={JobTypes.output}
            simulateFail={simulateFail}
            key={`output-handler-${selectedRun?.runId}`}
            jobSheetResourceKey={ResourceKeys.SCOPE_DEFINITION_INGREDIENT_MAPPING}
            externalLinkLabel="Ingredients"
            isDisabled={projectContexts?.length <= 0 || isPhaseReadOnly || !!selectedRun?.readOnly}
            executor={JobExecutor.default}
          />
          {displayWarning && !isPhaseReadOnly && !selectedRun?.readOnly && (
            <Typography color={'orange'} display={'flex'} alignItems={'center'} marginTop={2}>
              <Warning></Warning>
              Warning, you are using a context with a very large amount of recipes
            </Typography>
          )}
        </>
      ) : null}
      <CreateRunDialog
        open={runNameDialogOpen}
        onClose={closeNewRunDialog}
        onSubmit={(newRunName) => createNewRun(newRunName || '')}
        onDuplicate={(newRunName, sourceRunId) => createDuplicateRun(sourceRunId, newRunName || '')}
        runsList={runs}
        defaultRun={selectedRun}
        loading={false}
      />
      <Dialog open={removeContextDialogOpen} onClose={() => setRemoveContextDialogOpen(false)}>
        <DialogTitle>Remove context</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to remove {contextToRemove} from this phase ?</Typography>
          <Box display={'flex'} justifyContent={'flex-end'} marginTop={1}>
            <Button onClick={() => setRemoveContextDialogOpen(false)}>Close</Button>
            <Button
              data-id-cypress="defineScopeRemoveContextButton"
              onClick={() => removeContextFromProject(contextToRemove)}
            >
              <Delete></Delete>
              Delete Context
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default memo(ScopeDefinition);
