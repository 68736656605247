import './cell-renderer.scss';

import { Check, Delete, WarningOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ColumnIdentifier, HeadCellRenderProps } from '../../../../../components/dataman/types';
import ExternalLink from '../../../../../components/ExternalLink';
import JobHandler, { JobTypes } from '../../../../../components/JobHandler';
import {
  resetConceptGenerationConfig,
  setConceptGenerationConfigV2,
  setConceptGenerationConfigV3,
  setRunError,
  setSelectedRunId,
  showConfigurationWorkflow,
  SLICE_PROJECT_WORKFLOW,
} from '../../../../../features/project/store';
import { InputGenerationType } from '../../../../../features/project/types/common';
import { TYPE_INPUT_PREPARATION, TYPE_OUTPUT_GENERATION } from '../../../../../shared/url';
import { useAppSelector } from '../../../../../store';
import { ContextType, JobExecutor, ProjectType } from '../../../../../types';
import { getDataSheetUrl } from '../../_utils/datasources';
import { JobStatus, ResourceKeys, RunType } from '../../_utils/types';
import ConfigureButton from '../_components/ConfigureButton';
import { ConceptGenerationDatamanRecord, ConceptGenerationWFVersion, ShowConceptGenWorkflow } from './types';

/**
 * Generate concept step input/output cell renderer.

 * @param row
 * @param runs
 * @param updateView
 * @param simulateFail
 * @param updateRun
 * @param project
 * @param isPhaseReadOnly
 */
export const RenderGenerateConceptOutputCell = (
  cellIdentifier: ColumnIdentifier,
  row: ConceptGenerationDatamanRecord,
  runs: RunType[],
  updateView: () => Promise<void>,
  simulateFail: boolean,
  updateRun: (run: RunType) => void,
  project: ProjectType | null,
  isPhaseReadOnly: boolean,
): React.FC<HeadCellRenderProps> | JSX.Element => {
  const type = JobTypes.output;
  const { runs: stateRuns } = useAppSelector((store) => store[SLICE_PROJECT_WORKFLOW]);
  let externalLinks: JSX.Element | null = null;
  let run: RunType | undefined;
  const { output_status: runStatus } = row;
  const { runId: rowRunId } = row;
  const inputId = stateRuns?.[rowRunId]?.inputId;

  if (rowRunId) {
    run = runs?.find((runItem) => runItem.runId === rowRunId);
  }

  const outputStatusOk: boolean = run?.outputs?.output_generation?.status === JobStatus.ok;
  const hasConceptsSheet: boolean =
    run?.outputs?.output_generation?.jobOutput?.resources?.concept_generation_concepts_sheet?.sheets !== undefined;
  const isSampleDefined: boolean =
    run?.outputs?.output_generation?.jobOutput?.resources?.concept_generation_concept_samples_sheet !== undefined;

  if (outputStatusOk) {
    const getExternalLinks = () => {
      const childrenLinks: JSX.Element[] = [];
      const firstResourceKey = ResourceKeys.CONCEPT_GENERATION_CONCEPTS_SHEET;
      const secondResourceKey = ResourceKeys.CONCEPT_GENERATION_CONCEPT_SAMPLE_SHEET;

      if (hasConceptsSheet) {
        childrenLinks.push(
          <ExternalLink
            key={firstResourceKey}
            url={getDataSheetUrl(TYPE_OUTPUT_GENERATION, firstResourceKey, run || null)}
            title={'Concepts'}
            size="small"
          />,
        );
      }

      if (isSampleDefined) {
        childrenLinks.push(
          <ExternalLink
            key={secondResourceKey}
            url={getDataSheetUrl(TYPE_OUTPUT_GENERATION, secondResourceKey, run || null)}
            title={'Sample'}
            size="small"
          />,
        );
      }

      return (
        <Box marginLeft={1} display={'flex'} alignItems={'center'}>
          {childrenLinks}
        </Box>
      );
    };

    externalLinks = getExternalLinks();
  }
  const inputConfig = stateRuns?.[rowRunId]?.config;
  const nInputFields = inputConfig ? Object.keys(inputConfig).length : 0;
  const inputType =
    inputConfig?.input_type || (nInputFields === 0 ? InputGenerationType.Sheets : InputGenerationType.Wizard);
  let errorMessage = '';
  let isDisabled = isPhaseReadOnly || run?.readOnly;
  switch (inputType) {
    case InputGenerationType.Sheets:
      if (!run?.readOnly) {
        errorMessage = 'A run configured with sheets cannot be configured anymore';
        isDisabled = true;
      }
      break;
    case InputGenerationType.Wizard:
      if (!run?.readOnly && nInputFields < 3) {
        errorMessage = 'You need to configure the input first';
        isDisabled = true;
      }
      break;
    default:
      errorMessage = `Unexpected InputGenerationType ${inputType}`;
      break;
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      className="CellRenderer__outputColumn"
      data-id-cypress="generateConceptOutput"
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        {outputStatusOk && (
          <Box marginLeft={1} display={'flex'} alignItems={'center'}>
            <Typography>Concepts ready</Typography>
            <Check color={'success'} />
          </Box>
        )}
        {externalLinks}
        <JobHandler
          isDisabled={isDisabled}
          title={'Output'}
          updateView={updateView}
          updateRun={updateRun}
          run={run}
          selectedRunId={rowRunId}
          project={project || null}
          type={type}
          simulateFail={simulateFail}
          key={`${type}-handler-${rowRunId}-${runStatus}`}
          actionName={'generation'}
          inProgressText={'Generating output'}
          successText="Metrics ready"
          isTableJob={true}
          executor={JobExecutor.default}
          errorMessage={errorMessage}
          waitForInputId={!inputId}
        />
        {run?.readOnly && (
          <Box marginLeft={1} display={'flex'} alignItems={'center'}>
            <Typography
              className="WithWarning"
              color={'error'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <WarningOutlined />
              This run is read-only
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

/**
 * Generate concept step input/output cell renderer.
 * @param row
 * @param runs
 * @param updateView
 * @param simulateFail
 * @param updateRun
 * @param project
 * @param isPhaseReadOnly
 * @param showInputTypeDropdown
 */
export const RenderGenerateConceptInputCell = (
  cellIdentifier: ColumnIdentifier,
  row: ConceptGenerationDatamanRecord,
  runs: RunType[],
  updateView: () => Promise<void>,
  simulateFail: boolean,
  updateRun: (run: RunType) => void,
  project: ProjectType | null,
  isPhaseReadOnly: boolean,
  showInputTypeDropdown: boolean,
): React.FC<HeadCellRenderProps> | JSX.Element => {
  const dispatch = useDispatch();
  const { runs: stateRuns, loading } = useAppSelector((store) => store[SLICE_PROJECT_WORKFLOW]);
  let externalLinks: JSX.Element | null = null;
  let run: RunType | undefined;
  let ingredientTable: string | undefined;
  const { input_status: runStatus, runId: rowRunId } = row;
  const inputConfig = stateRuns?.[rowRunId]?.config;
  const nInputFields = inputConfig ? Object.keys(inputConfig).length : 0;
  const inputType =
    inputConfig?.input_type || (nInputFields === 0 ? InputGenerationType.Sheets : InputGenerationType.Wizard);
  const isWizardType = inputType === InputGenerationType.Wizard;
  const inputId = stateRuns?.[rowRunId]?.inputId;
  const type = JobTypes.input;
  const [selectedConceptGenerationVersion, setSelectedConceptGenerationVersion] = useState<ConceptGenerationWFVersion>(
    ConceptGenerationWFVersion.v2,
  );

  if (rowRunId) {
    run = runs?.find((runItem) => runItem.runId === rowRunId);
    ingredientTable =
      project?.outputGenerationByRunId.jobOutput?.resources?.metrics_calculation_single?.tables?.tables?.[0]?.tableName;
  }

  const inputStatusOk: boolean = run?.outputs?.input_preparation?.status === JobStatus.ok;
  if (inputStatusOk) {
    const firstResourceKey = ResourceKeys.METRICS_CALCULATION_INGREDIENT_LABELS;
    const secondResourceKey = ResourceKeys.METRICS_CALCULATION_COMPOSITION_TEMPLATE;

    const getExternalLinks = () => {
      const childrenLinks: JSX.Element[] = [];

      childrenLinks.push(
        <ExternalLink
          key={firstResourceKey}
          url={getDataSheetUrl(TYPE_INPUT_PREPARATION, firstResourceKey, run || null)}
          title={'Ingredients'}
          size="small"
        />,
      );
      childrenLinks.push(
        <ExternalLink
          key={secondResourceKey}
          url={getDataSheetUrl(TYPE_INPUT_PREPARATION, secondResourceKey, run || null)}
          title={'Composition'}
          size="small"
        />,
      );

      return <Box display="flex"> {childrenLinks} </Box>;
    };

    externalLinks = getExternalLinks();
  }

  /**
   * This callback is triggered when the user clicks on the "Configure" button
   */
  const showConfigurationWF = useCallback(
    (workflowVersion: ShowConceptGenWorkflow) => {
      if (showInputTypeDropdown && ingredientTable && selectedConceptGenerationVersion) {
        const selectedRun = stateRuns[rowRunId];
        // dispatch based on found version
        if (selectedConceptGenerationVersion === ConceptGenerationWFVersion.v2) {
          dispatch(setConceptGenerationConfigV2(selectedRun.config));
        } else {
          dispatch(setConceptGenerationConfigV3(selectedRun.config));
        }
        dispatch(resetConceptGenerationConfig());
        dispatch(setSelectedRunId(rowRunId));
        dispatch(setRunError({ runId: rowRunId, error: undefined }));
        dispatch(showConfigurationWorkflow(workflowVersion));
      }
    },
    [showInputTypeDropdown, ingredientTable, dispatch, rowRunId, stateRuns, selectedConceptGenerationVersion],
  );

  /**
   * This effect is used to set the selected version of the concept generation workflow
   * when the run is loaded
   */
  useEffect(() => {
    if (run && stateRuns) {
      const stateRun = stateRuns[run.runId];
      const { config } = stateRun;
      const foundVersion = config?.version || ConceptGenerationWFVersion.v2;
      setSelectedConceptGenerationVersion(foundVersion);
    }
  }, [stateRuns, run]);

  const configureButtonOptions = [
    {
      buttonText: 'Configure using compositions',
      version: ConceptGenerationWFVersion.v2,
      callback: () => showConfigurationWF(ShowConceptGenWorkflow.showV2),
    },
    {
      buttonText: 'Configure using concept territories',
      version: ConceptGenerationWFVersion.v3,
      callback: () => showConfigurationWF(ShowConceptGenWorkflow.showV3),
    },
  ];

  return (
    <Box
      display="flex"
      alignItems="center"
      className="CellRenderer__inputColumn"
      data-id-cypress="generateConceptInput"
    >
      {(!isWizardType || !showInputTypeDropdown) && externalLinks}
      {isWizardType && showInputTypeDropdown && (
        <Box>
          <ConfigureButton
            selectedVersion={selectedConceptGenerationVersion}
            disabled={loading}
            disableDropDown={isPhaseReadOnly || run?.readOnly}
            options={configureButtonOptions}
            setSelectedVersion={setSelectedConceptGenerationVersion}
          />
        </Box>
      )}
      <Box display="flex" alignItems="center" justifyContent="center">
        <JobHandler
          isDisabled={isPhaseReadOnly || run?.readOnly}
          title={'Input'}
          updateView={updateView}
          updateRun={updateRun}
          run={run}
          selectedRunId={rowRunId}
          project={project || null}
          type={type}
          simulateFail={simulateFail}
          key={`${type}-handler-${rowRunId}-${runStatus}`}
          actionName={'preparation'}
          inProgressText={'Preparing input'}
          successText="Metrics ready"
          isTableJob={true}
          executor={JobExecutor.default}
          waitForInputId={!inputId}
        />
      </Box>
    </Box>
  );
};

/**
 * Generate changed at cell renderer.
 *
 * @param cellIdentifier
 * @param value
 */
export const RenderNameCell = (
  cellIdentifier: ColumnIdentifier,
  value?: string | null | undefined,
): React.FC<HeadCellRenderProps> | JSX.Element => (
  <Box data-id-cypress="name" display="flex">
    {value}
  </Box>
);

/**
 * Generate changed at cell renderer.
 *
 * @param cellIdentifier
 * @param value
 */
export const RenderChangedAtCell = (
  cellIdentifier: ColumnIdentifier,
  value?: string | null | undefined,
): React.FC<HeadCellRenderProps> | JSX.Element => (
  <Box data-id-cypress="changedAt" display="flex">
    {Moment(value).format('YYYY-MM-DD HH:mm')}
  </Box>
);

/**
 * Generate changed at cell renderer.
 *
 * @param cellIdentifier
 * @param value
 */
export const RenderChangedByCell = (
  cellIdentifier: ColumnIdentifier,
  value: string | null | undefined,
): React.FC<HeadCellRenderProps> | JSX.Element => (
  <Box data-id-cypress="changedBy" display="block" className="CellRenderer__changeByColumn">
    {value}
  </Box>
);

/**
 * Generate remove context cell
 *
 * @param cellIdentifier
 * @param value
 * @param row
 * @param removeContextCallback
 */
export const RenderRemoveContextCell = (
  cellIdentifier: ColumnIdentifier,
  value: undefined,
  row: ContextType,
  removeContextCallback: any,
): React.FC<HeadCellRenderProps> | JSX.Element => (
  <Box display="flex" justifyContent={'center'}>
    <Button color={'inherit'} onClick={() => removeContextCallback(row.contextId)}>
      <Delete></Delete>
    </Button>
  </Box>
);
