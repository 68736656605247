import Moment from 'moment';
import React from 'react';

import { ColumnIdentifier, DataManRenderRowCellCallback } from '../../../components/dataman/types';
import { ContextVersionType } from '../../../types';
import { ContextStatusCreated } from '../types';

export const renderChangedByCell: DataManRenderRowCellCallback = (
  cellIdentifier: ColumnIdentifier,
  value: string | undefined | null,
  row: ContextVersionType,
) => <>{row.resourceMetadata.changedBy}</>;

export const renderChangedAtCell: DataManRenderRowCellCallback = (
  cellIdentifier: ColumnIdentifier,
  value: string | undefined | null,
  row: ContextVersionType,
) => <>{Moment(row.resourceMetadata.changedAt).format('YYYY-MM-DD HH:mm')}</>;

export const renderContextStatusCell: DataManRenderRowCellCallback = (
  cellIdentifier: ColumnIdentifier,
  value: string | undefined | null,
) => <>{String(value || ContextStatusCreated.value).replace('CONTEXT_VERSION_STATUS_', '')}</>;
