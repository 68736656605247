import '../../../layout/common.scss';

import { Warning } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import sortBy from 'lodash/sortBy';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Loading from '../../../components/Loading';
import SectionTitle from '../../../components/SectionTitle';
import { useSelectIngredientDialog } from '../../../components/SelectIngredientDialog';
import { useDataSources } from '../../../context/DataSourcesContext';
import { buildActionUrl, TYPE_SHOW_PROJECT_JOB } from '../../../shared/url';
import { ingredientToString, InputChangeEvent } from '../../../shared/utils';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  DataSourceType,
  DataVersionsType,
  GenericIngredientFields,
  IngredientDBIngredientType,
  JobStatus,
  ProjectStatus,
  ProjectType,
} from '../../../types';
import { VersionList, VersionListProps } from '../../datasources/DataSources';
import { changeIngredientDbVersion, setError, SLICE_DATASOURCE_NAME } from '../../datasources/store';
import { DataSourceState } from '../../datasources/store/types';
import { DocLink, DocLinkUrl, DocTypeEnum } from '../../docs';
import { ErrorAction } from '../reducer';

/* eslint-disable */
type ConfigProps = {
  projectId: string;
  project: ProjectType;
  updateField: (name: string, value: IngredientDBIngredientType[] | null | string | string[]) => void;
  dispatchError: (action: ErrorAction) => void;
  provisionOutput: any;
  submitConfig?: any;
};

type ConfigSectionProps = {
  project: ProjectType;
  dataVersions: DataVersionsType;
  coreIngredients: IngredientDBIngredientType[];
  handleChange: (name: string) => (event: Pick<InputChangeEvent, 'preventDefault' | 'target'>) => void;
  handleChangeArray: (name: string) => (values: string[] | IngredientDBIngredientType[]) => void;
  inputVariant: 'filled' | 'outlined';
  provisionOutput?: any;
  submitConfig?: any;
};

const ProjectInfo: React.FC<Omit<ConfigSectionProps, 'dataVersions' | 'coreIngredients'>> = ({
  project,
  handleChange,
  inputVariant,
  provisionOutput,
  submitConfig,
}) => {
  const history = useNavigate();

  const navigateToJob = () => {
    const url = buildActionUrl({ projectId: project.projectId, jobId: provisionOutput.jobId }, TYPE_SHOW_PROJECT_JOB);
    history(url);
  };

  const regenerateProvisionning = () => {
    submitConfig(project);
  };

  return (
    <Grid container spacing={3} className="common__projectInfoContainer">
      <Grid item xs={3} key="projectId">
        <TextField
          id="projectId"
          label="Project Id"
          className="common__textField"
          value={project.projectId}
          variant="filled"
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item xs={6} key="description">
        <TextField
          id="description"
          label="Description"
          className="common__largeTextField"
          value={project.description}
          onChange={handleChange('description')}
          fullWidth
          variant={inputVariant}
        />
      </Grid>
      <Grid item xs={3} key="placeholder" />
      <Grid item xs={2} key="projectType">
        <TextField
          id="projectType"
          label="Project type"
          className="common__textField"
          value={project.type.replace('PROJECT_TYPE_', '').toLowerCase()}
          variant="filled"
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item xs={2} key="company">
        <TextField
          id="company"
          label="Company"
          className="common__textField"
          value={project.company}
          variant="filled"
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item xs={2} key="status">
        <TextField
          id="status"
          label="Internal status"
          error={project.status !== ProjectStatus.Provisioned && provisionOutput?.status === JobStatus.Failed}
          className="common__textField"
          value={(project.status || 'UNKNOWN').replace('PROJECT_STATUS_', '').toLowerCase()}
          variant="filled"
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item xs={4} key="warning">
        {project.status !== ProjectStatus.Provisioned && provisionOutput?.status === JobStatus.Failed && (
          <Box display="flex" justifyContent={'flex-start'}>
            <Box display="flex" color="red" paddingTop={1}>
              <Warning style={{ marginRight: '5px' }}></Warning>
              <Typography>The project provisionning failed</Typography>
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => regenerateProvisionning()}
              style={{ marginLeft: '15px' }}
            >
              Regenerate
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => navigateToJob()}
              style={{ marginLeft: '15px' }}
            >
              Job
            </Button>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

const InputSelection: React.FC<
  Omit<ConfigSectionProps, 'coreIngredients'> & { coreIngredients?: IngredientDBIngredientType[] }
> = ({ inputVariant, project, dataVersions, coreIngredients, handleChange, handleChangeArray }) => {
  const existingBaseProducts = sortBy(project.baseIngredients || [], GenericIngredientFields.name);
  const existingBaseProductsStr = existingBaseProducts
    .filter((x) => x !== undefined)
    .map(ingredientToString<IngredientDBIngredientType>('ingredientId'));
  const coreIngredientsStr =
    coreIngredients
      ?.filter((x) => x !== undefined)
      .map(ingredientToString<IngredientDBIngredientType>('ingredientId')) || [];
  // TODO: change data structure to map
  const handleChangeBaseProduct = (values: string[]) => {
    if (coreIngredients) {
      const ingredients = values.map((value) => coreIngredients[coreIngredientsStr.indexOf(value)]);
      handleChangeArray('baseIngredients')(ingredients);
    }
  };
  const { dialog, openDialog } = useSelectIngredientDialog(
    true,
    coreIngredientsStr,
    existingBaseProductsStr,
    handleChangeBaseProduct,
  );

  return (
    <Grid container spacing={3} className="common__projectInfoContainer">
      <Grid item key="countries" xs={3}>
        <FormControl fullWidth>
          <InputLabel id="countries">Countries</InputLabel>
          <Select
            data-id-cypress="countrySelector"
            disabled={inputVariant === 'filled'}
            id="select-countries"
            variant={inputVariant}
            multiple
            className="common__textField"
            value={(project.countries || []).sort()}
            onChange={(e) => handleChangeArray('countries')(e.target.value as string[])}
            input={<Input id="select-countries-input" />}
            renderValue={(selected) => (
              <div className="common__chips">
                {(selected as string[]).sort().map((value) => (
                  <Chip key={value} label={value} className="common__chip" />
                ))}
              </div>
            )}
            MenuProps={{ className: 'common__menu' }}
            error={!project.countries || project.countries.length < 1}
          >
            {dataVersions.availableCountries.map((country: string) => (
              <MenuItem data-id-cypress="countryOption" key={`country_${country}`} value={country}>
                {country}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item key="baseproducts" xs={5}>
        <FormControl fullWidth required>
          <InputLabel id="baseProducts">Base products</InputLabel>
          <Select
            data-id-cypress="baseProductSelector"
            disabled={inputVariant === 'filled' || !coreIngredients}
            variant={inputVariant}
            id="select-baseproducts"
            multiple
            className="common__textField"
            value={existingBaseProductsStr || []}
            input={<Input id="select-baseProducts-input" />}
            open={false}
            onOpen={openDialog}
            renderValue={(selected) => (
              <div className={coreIngredients ? 'common__chips' : ''}>
                {coreIngredients ? (
                  (selected as string[]).map((value) => <Chip key={value} label={value} className="common__chip" />)
                ) : (
                  <Loading message=" " />
                )}
              </div>
            )}
          />
        </FormControl>
        {dialog}
      </Grid>
      <Grid item xs={4} key="placeholder" />
      <Grid item xs={3} key="min_recipes">
        <TextField
          variant={inputVariant}
          id="min-recipes"
          label="Minimum recipe count threshold"
          type="number"
          className="common__textField"
          value={project.minimumRecipeCount}
          onChange={handleChange('minimumRecipeCount')}
          margin="normal"
        />
      </Grid>
      <Grid item xs={3} key="min_popularity">
        <TextField
          variant={inputVariant}
          id="min-popularity"
          label="Ingredient popularity threshold"
          type="number"
          inputProps={{
            step: 0.001,
            max: 1,
            min: 0.0001,
          }}
          className="common__textField"
          value={project.ingredientPopularityThreshold}
          onChange={handleChange('ingredientPopularityThreshold')}
          margin="normal"
        />
      </Grid>
      <Grid item xs={3} key="cfi_score_range_min">
        <TextField
          variant={inputVariant}
          id="cfi-score-range-min"
          label="Minimum CFI score"
          type="number"
          inputProps={{
            step: 0.01,
            max: 1.0,
            min: 0.0,
          }}
          className="common__textField"
          value={project.cfiScoreRangeMinimum}
          onChange={handleChange('cfiScoreRangeMinimum')}
          margin="normal"
          error={!(project.cfiScoreRangeMinimum >= 0.0 && project.cfiScoreRangeMinimum < project.cfiScoreRangeMaximum)}
        />
      </Grid>
      <Grid item xs={3} key="cfi_score_range_max">
        <TextField
          variant={inputVariant}
          id="cfi-score-range-max"
          label="Maximum CFI score"
          type="number"
          inputProps={{
            step: 0.01,
            max: 1.0,
            min: 0.0,
          }}
          className="common__textField"
          value={project.cfiScoreRangeMaximum}
          onChange={handleChange('cfiScoreRangeMaximum')}
          margin="normal"
          error={!(project.cfiScoreRangeMaximum <= 1.0 && project.cfiScoreRangeMinimum < project.cfiScoreRangeMaximum)}
        />
      </Grid>
    </Grid>
  );
};

const VersionSelection: React.FC<Omit<ConfigSectionProps, 'coreIngredients'> & { disabled?: boolean }> = ({
  project,
  handleChange,
  dataVersions,
  disabled,
}) => {
  const prepareSelectProps = useCallback<(type: keyof typeof dataVersions) => VersionListProps>(
    (type) => {
      const sourceType =
        type === 'recipeVersions'
          ? type.replace('recipe', 'recipes').slice(0, type.length)
          : type.slice(0, type.length - 1);

      return {
        as: 'autocomplete',
        changeVersion: (value) =>
          handleChange(sourceType)({
            target: { value },
            preventDefault: () => void 0,
          } as InputChangeEvent),
        disabled: disabled || !dataVersions[type].length,
        inputName: sourceType,
        label: sourceType,
        required: type?.toLowerCase()?.indexOf('ingredient') > -1,
        selected: String(project[sourceType as keyof typeof project] || ''),
        versions: dataVersions[type],
      };
    },
    [dataVersions, project, disabled],
  );

  return (
    <Grid data-id-cypress="dataVersions" container className="common__dataVersionsContainer">
      {Object.keys({ ...dataVersions })
        .filter((type) => type?.toLowerCase()?.indexOf('version') > -1)
        .map((type, idx) => (
          <Grid item xs={3} key={`thirdrow-${idx + 1}`}>
            <VersionList {...prepareSelectProps(type as keyof typeof dataVersions)} />
          </Grid>
        ))}
    </Grid>
  );
};

VersionSelection.defaultProps = { disabled: false };

const initialDataVersions: DataVersionsType = {
  coreVersions: [],
  recipeVersions: [],
  socialPostVersions: [],
  trendsVersions: [],
  availableCountries: [],
  ingredientDbVersions: [],
  sensoryVersions: [],
  dishTypesVersions: [],
};

const Config: React.FC<ConfigProps> = ({
  project,
  updateField,
  dispatchError,
  projectId,
  provisionOutput,
  submitConfig,
}) => {
  const dataSources = useDataSources();
  const dispatch = useAppDispatch();
  const dataSourceState: DataSourceState = useAppSelector((store) => store[SLICE_DATASOURCE_NAME]);
  const [loading, setLoading] = useState(true);
  const {
    error: dataSourceError,
    ingredientsLoading,
    ingredients,
    ingredientDbVersion: dataSourceIngredientDbVersion,
  } = dataSourceState;
  const [dataVersions, setDataVersions] = useState({ ...initialDataVersions });
  const [coreIngredients, setCoreIngredients] = useState([] as IngredientDBIngredientType[]);
  const ingredientDBVersion = project?.ingredientDbVersion || dataVersions.ingredientDbVersions[0] || '';

  useEffect(() => {
    setLoading(dataSources.loading);
  }, [dataSources.loading]);

  // update data versions
  useEffect(() => setDataVersions({ ...initialDataVersions, ...dataSources.versions }), [dataSources.versions]);

  // set latest initial versions
  useEffect(() => {
    Object.keys(dataVersions).forEach((key) => {
      const versions = dataVersions?.[key as keyof DataVersionsType] || [];

      if (key?.toLowerCase()?.indexOf('version') > -1 && versions.length && !project?.[key as keyof ProjectType]) {
        updateField(key, versions[0].toString());
      }
    });
  }, [dataVersions, updateField, project]);

  // when ingredient version is changing - load ingredients
  useEffect(() => {
    if (ingredientDBVersion) {
      setCoreIngredients([]);
      dispatch(setError({ error: null }));
      dispatch(
        changeIngredientDbVersion({
          version: ingredientDBVersion,
          sourceType: DataSourceType.IngredientDB,
        }),
      );
    }
  }, [ingredientDBVersion]);

  // update core ingredients when ingredient version the same as in datasource
  useEffect(() => {
    if (ingredientDBVersion === dataSourceIngredientDbVersion) {
      setCoreIngredients([...ingredients]);
    }
  }, [ingredientDBVersion, dataSourceIngredientDbVersion, ingredients]);

  // if datasource error fetched - pass it
  useEffect(() => {
    if (dataSourceError) {
      dispatchError({ type: 'FetchError', error: dataSourceError });
    }
  }, [dataSourceError]);

  const handleChange = useCallback<ConfigSectionProps['handleChange']>(
    (name) => (event) => {
      event.preventDefault();
      updateField(name, event.target.value);
    },
    [updateField],
  );

  const handleChangeArray = useCallback<ConfigSectionProps['handleChangeArray']>(
    (name) => (val) => updateField(name, val),
    [updateField],
  );

  const isProvisioningOrLockStatus = [ProjectStatus.Locked, ProjectStatus.Provisioning].includes(project.status);
  const inputVariant = isProvisioningOrLockStatus ? 'filled' : 'outlined';
  const commonProps = useMemo<
    Pick<ConfigSectionProps, 'handleChange' | 'handleChangeArray' | 'project' | 'inputVariant'>
  >(
    () => ({ handleChange, handleChangeArray, project, inputVariant }),
    [handleChange, handleChangeArray, project, inputVariant],
  );

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <fieldset key={projectId} style={{ border: 'none' }} disabled={isProvisioningOrLockStatus}>
          <Grid container spacing={3} direction="column" justifyContent="flex-start">
            <Grid item key="firstrow">
              <Paper className="common__configSection">
                <SectionTitle
                  title="Configure project"
                  action={<DocLink link={DocLinkUrl[DocTypeEnum.ConfigureProjectStep]} />}
                  useCommonStyles
                />
                <Typography align="left" paragraph variant="h5">
                  {` Project info `}
                </Typography>
                <ProjectInfo {...commonProps} provisionOutput={provisionOutput} submitConfig={submitConfig} />
              </Paper>
            </Grid>
            <Grid item key="thirdrow">
              <Paper className="common__configSection">
                <Typography align="left" paragraph variant="h5">
                  {` Input selection `}
                </Typography>
                <InputSelection
                  {...commonProps}
                  dataVersions={dataVersions}
                  coreIngredients={ingredientsLoading ? undefined : coreIngredients}
                />
              </Paper>
            </Grid>
            <Grid item key="fourthrow">
              <Paper className="common__configSection">
                <Typography align="left" paragraph variant="h5">
                  {` Data versions `}
                </Typography>
                <VersionSelection {...commonProps} disabled={isProvisioningOrLockStatus} dataVersions={dataVersions} />
              </Paper>
            </Grid>
          </Grid>
        </fieldset>
      )}
    </>
  );
};

export default Config;
