import axios, { AxiosResponse } from 'axios';

import { DataManListSortOptions, DataManSortMapping, LoadRecordsResult } from '../../../../components/dataman/types';
import { prepareSortQuery } from '../../../../shared/filter-sort-query';
import { buildActionUrl, TYPE_JOB_QUERY } from '../../../../shared/url';
import { JobQueryBody, JobSortFields } from '../../../../types';
import { JobListDataManRecord } from './types';

export async function loadJobRecords(
  nextId: string | null,
  dataSort: DataManListSortOptions,
  cleanAll?: boolean,
): Promise<LoadRecordsResult<JobListDataManRecord> | null> {
  const method = 'POST';
  const result: LoadRecordsResult<JobListDataManRecord> = {
    records: [],
    nextId: null,
    error: '',
  };

  const queryOptions: JobQueryBody = {
    startAtId: cleanAll ? undefined : nextId,
    sortOptions: prepareSortQuery(dataSort, sortMapping),
  };

  try {
    const serviceUrl = buildActionUrl({}, TYPE_JOB_QUERY);
    const {
      error,
      jobs,
      nextId: newNextId,
    } = await axios.post(serviceUrl, { method, ...queryOptions }).then((res: AxiosResponse) => res.data);
    result.error = error || '';

    if (!error && jobs) {
      result.records = jobs || [];
      result.nextId = newNextId || null;
    }
  } catch (e: any) {
    result.error = e.toLocaleString();
  }

  return result;
}

/**
 * Mapping of sort fields to prepare sort query.
 */
const sortMapping: DataManSortMapping<JobSortFields> = {
  createdAt: {
    field: JobSortFields.createdAt,
  },
};
