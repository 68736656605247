import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import React from 'react';

interface ContextArchiveDialogProps {
  handleClose: () => void;
  contextVersion: any;
  publishVersion: () => void;
}

const PublishContextVersionDialog: React.FC<ContextArchiveDialogProps> = ({
  handleClose,
  contextVersion,
  publishVersion,
}) => {
  const isLoading = false;

  return (
    <Dialog fullWidth={true} open={true} onClose={() => !isLoading && handleClose()} maxWidth="md">
      <DialogTitle>{`Are you sure you want to publish ${contextVersion.contextVersionId}?`}</DialogTitle>
      <DialogContent>It will no longer possible to edit this context version</DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button disabled={isLoading} onClick={publishVersion} color="primary">
          Publish
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PublishContextVersionDialog;
