import { AutocompleteValue } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import React, { ChangeEvent } from 'react';

import { DataManFilterComponentProps } from '../types';

interface AutocompleteFilterOption {
  value?: string;
  label?: string;
}

const SelectOptionsListFilter: React.FC<DataManFilterComponentProps> = ({
  options,
  value,
  handleChangeFilter,
  filterIndex,
}) => (
  <div style={{ padding: 2 }}>
    <FormControl variant="outlined" size="small">
      <Autocomplete
        id="combo-box-demo"
        options={options.options}
        style={{ width: 300 }}
        getOptionLabel={(option: AutocompleteFilterOption) => option?.label || ''}
        isOptionEqualToValue={(option, currentValue) => option?.value === currentValue?.value}
        renderInput={(params) => (
          <TextField {...params} label={options.inputLabel || 'Choose one'} variant="outlined" />
        )}
        onChange={(
          e: ChangeEvent<{}>,
          currentValue: AutocompleteValue<AutocompleteFilterOption, false, false, false>,
        ) => handleChangeFilter(filterIndex, { value: currentValue?.value || '' })}
        value={value?.value ? { value: value.value, label: value.value } : null}
      />
    </FormControl>
  </div>
);

export default SelectOptionsListFilter;
