import './InputTypeSelector.scss';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import React, { ChangeEvent } from 'react';

import { InputGenerationType } from '../../../../features/project/types/common';

interface InputTypeSelectorProps {
  disabled: boolean;
  value?: InputGenerationType;
  onChangeHandler: (e: ChangeEvent<{ value: unknown }>) => void;
  dropdownClassName?: string;
  loaderClass?: string;
}

const InputTypeSelector: React.FC<InputTypeSelectorProps> = ({
  disabled,
  value,
  onChangeHandler,
  dropdownClassName,
  loaderClass,
}) => (
  <Box style={{ marginRight: 14 }}>
    {value ? (
      <TextField
        disabled={disabled}
        margin="none"
        variant="outlined"
        value={value}
        onChange={onChangeHandler}
        style={{ height: '95%', width: 'auto' }}
        select={true}
        className={dropdownClassName}
      >
        {[InputGenerationType.Sheets, InputGenerationType.Wizard].map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </TextField>
    ) : (
      <Skeleton animation="wave" className={loaderClass || 'InputTypeSelector__skeleton'} />
    )}
  </Box>
);

InputTypeSelector.defaultProps = {
  dropdownClassName: undefined,
  loaderClass: undefined,
  value: undefined,
};

export default InputTypeSelector;
