export const TYPE_PROJECT_SHOW = 'project_show';
export const TYPE_COMPANIES = 'type_companies';
export const TYPE_PROJECTS = 'type_projects';
export const TYPE_ARCHIVE_PROJECT = 'type_archive_project';
export const TYPE_RESTORE_PROJECT = 'type_restore_project';
export const TYPE_DATASOURCES_VERSIONS = 'type_datasources_versions';
export const TYPE_DATASOURCES_INGREDIENTS_BY_VERSIONS = 'type_datasources_ingredients_by_versions';
export const TYPE_EDIT_VERSION = 'edit_version';
export const TYPE_CLONE_VERSION = 'clone_version';
export const TYPE_CREATE_CONTEXT_VERSION = 'create_context_version';
export const TYPE_GET_CONTEXT_VERSIONS = 'create_get_context_version';
export const TYPE_PROJECT_BY_ID = 'project_by_id';
export const TYPE_PHASE_RUN_INPUT = 'phase_run_input';
export const TYPE_DUPLICATE_INPUT_RUN = 'duplicate_input_run';
export const TYPE_SHOW_PROJECT_JOB = 'show_project_job';
export const TYPE_LOAD_PHASE_BY_ID = 'load_phase_by_id';
export const TYPE_LOAD_RUNS = 'load_runs';
export const TYPE_CANCEL_JOB = 'cancel_job';
export const TYPE_INPUT_PREPARATION = 'input_preparation';
export const TYPE_OUTPUT_GENERATION = 'output_generation';
export const TYPE_VIEW_JOB = 'view_job';
export const TYPE_VIEW_JOB_STATUS = 'view_job_status';
export const TYPE_CREATE_NEW_PHASE = 'create_new_phase';
export const TYPE_JOB_QUERY = 'job_query';
export const TYPE_UPDATE_PHASE = 'type_update_phase';
export const TYPE_INGREDIENT_AROMA_VIZ_PAGE = 'type_ingredient_aroma_viz_page';
export const TYPE_AROMA_DATA_BY_PRODUCT_PREP_ID = 'type_aroma_data_by_product_prep_id';
export const TYPE_LIST_AROMA_PROFILES = 'type_list_aroma_profiles';
export const TYPE_ADD_NEW_AROMA_PROFILE_VARIANT = 'type_add_new_aroma_profile_variant';
export const TYPE_UPDATE_AROMA_PROFILE_VARIANT = 'type_update_aroma_profile_variant';
export const TYPE_PROJECT_COMPANIES = 'type_project_companies';
export const TYPE_CONCEPT_TERRITORY_DEFINITIONS = 'concept_territory_definitions';
export const TYPE_POST_SELECTABLE_CONTEXTS_VERSION = 'post_selectable_contexts_version';
export const TYPE_POST_CONTEXT_QUERY = 'post_context_query';
export const TYPE_POST_CONCEPT_UPDATE = 'post_concept_update';
export const TYPE_GET_CONTEXT = 'get_context';
export const TYPE_DATASOURCES_SOCIAL_CATEGORIES = 'datasources_social_categories';
export const TYPE_DATASOURCES_SOCIAL_CATEGORY = 'datasources_social_category';
export const TYPE_RECIPE_FILTER = 'recipe_filter';
export const TYPE_DATASOURCES_DISHTYPE_VERSION = 'datasources_dishtype_version';
export const TYPE_CONTEXT_ARCHIVE = 'context_archive';
export const TYPE_GET_CONTEXT_VERSION = 'get_context_version';
export const TYPE_CLONE_PROJECT = 'clone_project';
export const TYPE_PROJECT_QUERY = 'project_query';
export const TYPE_BQ_CONNECTOR = 'bq_connector';
export const TYPE_GET_INGREDIENT_DB = 'get_ingredient_db';
export const TYPE_UPDATE_PROJECT = 'update_project';
export const TYPE_PROJECT_ID = 'project_id';
export const TYPE_GET_CONTEXT_VERSION_PUBLISH_ARCHIVE = 'get_context_version_publish_archive';

export type BuildActionRecordType = {
  projectId?: string | number;
  name?: string | number;
  contextVersionId?: string | number;
  phaseId?: string | number;
  selectedRunId?: string | number;
  runId?: string | number;
  jobId?: string | number;
  contextId?: string | number;
  version?: string | number;
  queryParams?: string;
  productPreparationId?: string | number;
  profileName?: string;
  socialPostVersion?: string | number;
  category?: string;
  isEditing?: boolean;
  isPublish?: boolean;
};

/**
 * Build url by type.
 *
 * @param record
 * @param type
 */
export function buildActionUrl(record: BuildActionRecordType, type: string): string {
  switch (type) {
    case TYPE_PROJECT_SHOW:
      return `/v1/projects/${record.projectId}/show`;
    case TYPE_PROJECT_ID:
      return `/v1/projects/${record.projectId}`;
    case TYPE_PROJECT_COMPANIES:
      return `/v1/projects/companies`;
    case TYPE_COMPANIES:
      return `/v1/companies`;
    case TYPE_PROJECTS:
      return `/v1/projects`;
    case TYPE_ARCHIVE_PROJECT:
      return `/v1/projects/${record.projectId}/archive`;
    case TYPE_RESTORE_PROJECT:
      return `/v1/projects/${record.projectId}/restore`;
    case TYPE_DATASOURCES_VERSIONS:
      return `/v1/datasources/versions`;
    case TYPE_DATASOURCES_INGREDIENTS_BY_VERSIONS:
      return `/v1/datasources/core/${record.version}/ingredients`;
    case TYPE_GET_CONTEXT:
      return `/v1/contexts/${record.contextId}`;
    case TYPE_EDIT_VERSION:
      return `/v1/contexts/${record.contextId}/versions/edit/${record.contextVersionId}`;
    case TYPE_CREATE_CONTEXT_VERSION:
      return `/v1/contexts/${record.contextId}/versions`;
    case TYPE_GET_CONTEXT_VERSIONS:
      return `/v1/contexts/${record.contextId}/versions`;
    case TYPE_GET_CONTEXT_VERSION:
      return `/v1/contexts/${record.contextId}/versions/${record.contextVersionId}${record.isEditing ? '/update' : ''}`;
    case TYPE_GET_CONTEXT_VERSION_PUBLISH_ARCHIVE:
      return `/v1/contexts/${record.contextId}/versions/${record.contextVersionId}/${
        record.isPublish ? 'publish' : 'archive'
      }`;
    case TYPE_CLONE_VERSION:
      return `/v1/contexts/${record.contextId}/versions/${record.contextVersionId}/clone`;
    case TYPE_PROJECT_BY_ID:
      return `/v1/projects/${record.projectId}`;
    case TYPE_PHASE_RUN_INPUT:
      return `/v1/projects/${record.projectId}/phases/${record.phaseId}/runs/${record.runId}/input`;
    case TYPE_DUPLICATE_INPUT_RUN:
      return `/v1/projects/${record.projectId}/phases/${record.phaseId}/runs/${record.runId}/clone`;
    case TYPE_SHOW_PROJECT_JOB:
      return `/v1/projects/${record.projectId}/jobs/${record.jobId}`;
    case TYPE_LOAD_PHASE_BY_ID:
      return `/v1/projects/${record.projectId}/phases/${record.phaseId}`;
    case TYPE_CANCEL_JOB:
      return `/v1/projects/${record.projectId}/jobs/${record.jobId}/abort`;
    case TYPE_INPUT_PREPARATION:
      return `/v1/projects/${record.projectId}/phases/${record.phaseId}/runs/${record.runId}/output/input_preparation`;
    case TYPE_OUTPUT_GENERATION:
      return `/v1/projects/${record.projectId}/phases/${record.phaseId}/runs/${record.runId}/output/output_generation`;
    case TYPE_VIEW_JOB:
      return `/v1/projects/${record.projectId}/jobs/${record.jobId}`;
    case TYPE_JOB_QUERY:
      return `/v1/jobs/query`;
    case TYPE_VIEW_JOB_STATUS:
      return `/v1/projects/${record.projectId}/jobs/${record.jobId}/status`;
    case TYPE_CREATE_NEW_PHASE:
      return `/v1/projects/${record.projectId}/phases/`;
    case TYPE_LOAD_RUNS:
      if (record.runId) {
        return `/v1/projects/${record.projectId}/phases/${record.phaseId}/runs/${record.runId}`;
      }
      return `/v1/projects/${record.projectId}/phases/${record.phaseId}/runs`;
    case TYPE_UPDATE_PHASE:
      return `/v1/projects/${record.projectId}/phases/${record.phaseId}/update`;
    case TYPE_INGREDIENT_AROMA_VIZ_PAGE:
      return `/v1/data/aroma/${record.version}${record.queryParams ? record.queryParams : ''}`;
    case TYPE_AROMA_DATA_BY_PRODUCT_PREP_ID: {
      const { profileName = 'default', productPreparationId, version } = record;
      const suffix = profileName && profileName.toLowerCase() !== 'default' ? `variant/${profileName}` : 'default';
      return `/v1/datasources/core/${version}/ingredient/${productPreparationId}/aroma/${suffix}`;
    }
    case TYPE_LIST_AROMA_PROFILES:
      return `/v1/datasources/core/${record.version}/ingredient/${record.productPreparationId}/aroma`;
    case TYPE_ADD_NEW_AROMA_PROFILE_VARIANT:
      return `/v1/datasources/core/${record.version}/ingredient/${record.productPreparationId}/aroma/variant`;
    case TYPE_UPDATE_AROMA_PROFILE_VARIANT:
      return `/v1/datasources/core/${record.version}/ingredient/${record.productPreparationId}/aroma/variant/${record.profileName}/update`;
    case TYPE_CONCEPT_TERRITORY_DEFINITIONS:
      return `/v1/concept_territory_definitions/${record.projectId}`;
    case TYPE_POST_SELECTABLE_CONTEXTS_VERSION:
      return `/v1/projects/${record.projectId}/selectable_contexts_versions`;
    case TYPE_POST_CONTEXT_QUERY:
      return `/v1/contexts/query`;
    case TYPE_POST_CONCEPT_UPDATE:
      return `/v1/contexts${record.contextId ? `/${record.contextId}/update` : ''}`;
    case TYPE_DATASOURCES_SOCIAL_CATEGORIES:
      return `/v1/datasources/social/${record?.version}/categories/`;
    case TYPE_DATASOURCES_SOCIAL_CATEGORY:
      return `/v1/datasources/social/${record?.version}/categories/${record?.category}`;
    case TYPE_RECIPE_FILTER:
      return `/v1/datasources/recipes/${record?.version}/filters`;
    case TYPE_DATASOURCES_DISHTYPE_VERSION:
      return `/v1/datasources/dish_types/${record?.version}`;
    case TYPE_CONTEXT_ARCHIVE:
      return `/v1/contexts/${record.contextId}/archive`;
    case TYPE_CLONE_PROJECT:
      return `/v1/projects/${record.projectId}/clone`;
    case TYPE_PROJECT_QUERY:
      return `/v1/projects/query`;
    case TYPE_BQ_CONNECTOR:
      return `/v1/bqconnector/query`;
    case TYPE_GET_INGREDIENT_DB:
      return `/v1/datasources/ingredientdb/${record.version}/ingredients`;
    case TYPE_UPDATE_PROJECT:
      return `/v1/projects/${record.projectId}/update`;
    default:
      return '';
  }
}
