import '../../layout/card.scss';

import { Add } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import WarningIcon from '@mui/icons-material/Warning';
import { Checkbox, CircularProgress, InputLabel } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import axios, { AxiosResponse } from 'axios';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Routes } from 'react-router';
import { generatePath, Link, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';

import { useFetchWithAuth } from '../../auth';
import ErrorNotification from '../../components/ErrorNotification';
import PageTitle from '../../components/PageTitle';
import { useLayout } from '../../context/LayoutContext';
import { DocLink, DocLinkUrl, DocTypeEnum } from '../../features/docs';
import {
  buildActionUrl,
  TYPE_ARCHIVE_PROJECT,
  TYPE_PROJECT_QUERY,
  TYPE_PROJECTS,
  TYPE_RESTORE_PROJECT,
} from '../../shared/url';
import {
  capitalizedFirstChar,
  compareProperty,
  getUrlFiltersAndSort,
  setUrlFilter,
  setUrlSort,
} from '../../shared/utils';
import { ProjectStatus, ProjectType } from '../../types';
import { getAllUserBookmarks, ProjectBookmarks } from './bookmark';
import ProjectCreate from './ProjectCreate';

const projectStatusToText = {
  PROJECT_STATUS_LOCKED: 'Locked',
  PROJECT_STATUS_CONFIGURED: 'Configured',
  PROJECT_STATUS_CREATED: 'Created',
  PROJECT_STATUS_PROVISIONED: 'Provisioned',
  PROJECT_STATUS_PROVISIONING: 'Provisioning',
  PROJECT_STATUS_UNKNOWN: 'Unknown',
  PROJECT_STATUS_ARCHIVING: 'Archiving',
  PROJECT_STATUS_ARCHIVED: 'Archived',
  PROJECT_STATUS_RESTORING: 'Restoring',
  PROJECT_STATUS_RESTORED: 'Restored',
  PROJECT_STATUS_REMOVING: 'Removing',
  PROJECT_STATUS_REMOVED: 'Removed',
  PROJECT_STATUS_REMOVE_FAILED: 'Failed to remove',
  PROJECT_STATUS_RESTORE_FAILED: 'Failed to restore',
  PROJECT_STATUS_ARCHIVE_FAILED: 'Failed to archive',
};

const projectListSortOptions = {
  PROJECT_CREATION_DATE: {
    sortOption: 'PROJECT_CREATION_DATE',
    value: 'PROJECT_CREATION_DATE',
    label: 'Project creation date',
  },
  PROJECT_STATUS: {
    sortOption: 'PROJECT_STATUS',
    value: 'PROJECT_STATUS',
    label: 'Project status',
  },
  PROJECT_NAME: {
    sortOption: 'PROJECT_NAME',
    value: 'PROJECT_NAME',
    label: 'Project name',
  },
};

const getProjectListSortOptionsArray = () =>
  Object.keys(projectListSortOptions).map((option) => {
    const sortOptionKey = option as keyof typeof projectListSortOptions;
    const sortOption = projectListSortOptions[sortOptionKey] || {};
    return { label: sortOption.label, value: sortOption.sortOption };
  });

type LockProjectDialogProps = {
  projectId: string;
  handleClose: () => void;
  handleSubmit: (projectId: string) => () => Promise<void>;
  open: boolean;
};

type MoreMenuProps = {
  handleClone: () => void;
  handleClose: (project?: any) => void;
  handleLock: () => void;
  handleError: (string: string) => void;
  anchorEl: null | HTMLElement;
  selectedProject: string;
  project: ProjectType;
};

const MoreMenu: FC<MoreMenuProps> = ({
  handleClose,
  handleClone,
  handleLock,
  handleError,
  anchorEl,
  selectedProject,
  project,
}) => {
  const handleClickClone = useCallback(() => {
    handleClone();
    handleClose();
  }, [handleClone, handleClose]);
  const handleClickLock = useCallback(() => {
    handleLock();
    handleClose();
  }, [handleLock, handleClose]);
  const projectConceptsUrl = useMemo(
    () =>
      generatePath('/projects/:idProject/concepts', {
        idProject: selectedProject,
      }),
    [selectedProject],
  );

  const handleArchive = useCallback(() => {
    axios
      .post(buildActionUrl({ projectId: selectedProject }, TYPE_ARCHIVE_PROJECT))
      .catch(() => {
        handleError('Unable to archive the project, check the network tab in the console for more details');
      })
      .then(() => {
        project.status = ProjectStatus.Archiving;
        handleClose(project);
      });
  }, [selectedProject, handleClose, handleError, project]);

  const handleRestore = useCallback(() => {
    axios
      .post(buildActionUrl({ projectId: selectedProject }, TYPE_RESTORE_PROJECT))
      .catch(() => {
        handleError('Unable to restore the project, check the network tab in the console for more details');
      })
      .then(() => {
        project.status = ProjectStatus.Restoring;
        handleClose(project);
      });
  }, [selectedProject, handleClose, handleError, project]);

  const canArchiveProject =
    project.status === ProjectStatus.Provisioned ||
    project.status === ProjectStatus.ArchiveFailed ||
    project.status === ProjectStatus.Locked ||
    project.status === ProjectStatus.Configured;

  const canRestoreProject =
    project.status === ProjectStatus.Archived ||
    project.status === ProjectStatus.RestoreFailed ||
    project.status === ProjectStatus.Removed;

  const canRemoveProject = project.status === ProjectStatus.Restored || project.status === ProjectStatus.RemoveFailed;
  const canLockProject =
    [
      ProjectStatus.Locked,
      ProjectStatus.Archived,
      ProjectStatus.Archiving,
      ProjectStatus.Removing,
      ProjectStatus.Removed,
      ProjectStatus.Restoring,
      ProjectStatus.Restored,
    ].indexOf(project.status) === -1;

  return (
    <Menu keepMounted={true} id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
      <MenuItem component={Link} to={projectConceptsUrl}>
        Concepts
      </MenuItem>
      <Divider />
      {canArchiveProject && (
        <MenuItem data-id-cypress="buttonArchive" onClick={handleArchive}>
          Archive
          {project.status === ProjectStatus.Archiving && <CircularProgress size={10} style={{ marginLeft: 5 }} />}
        </MenuItem>
      )}
      {canRestoreProject && (
        <MenuItem data-id-cypress="buttonRestore" onClick={handleRestore}>
          Restore
          {project.status === ProjectStatus.Restoring && <CircularProgress size={10} style={{ marginLeft: 5 }} />}
        </MenuItem>
      )}
      {canRemoveProject && (
        <MenuItem data-id-cypress="buttonRemove" onClick={handleArchive}>
          Re-archive
          {project.status === ProjectStatus.Removing && <CircularProgress size={10} style={{ marginLeft: 5 }} />}
        </MenuItem>
      )}
      <MenuItem onClick={handleClickClone}>Clone</MenuItem>
      <MenuItem onClick={handleClickLock} disabled={!canLockProject}>
        <ListItemText primary="Lock" />
        <ListItemIcon style={{ paddingLeft: 30 }}>
          <WarningIcon fontSize="small" />
        </ListItemIcon>
      </MenuItem>
    </Menu>
  );
};

const LockProjectDialog: FC<LockProjectDialogProps> = ({ projectId, handleClose, handleSubmit, open }) => (
  <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">{`Are you sure you want to lock ${projectId}?`}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        This will lock all input/actions for the project. Currently, this can&lsquo;t be unlocked by users.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Cancel
      </Button>
      <Button onClick={() => handleSubmit(projectId)} color="primary">
        Lock
      </Button>
    </DialogActions>
  </Dialog>
);

const ProjectList: FC<{}> = (props) => {
  const history = useNavigate();
  const openModal = useCallback(() => {
    history('/projects', { replace: true });
  }, [history]);

  return (
    <>
      <ProjectListView />
      <Routes>
        <Route
          path="/create"
          element={
            <Dialog open={true} onClose={openModal}>
              <ProjectCreate onCancel={openModal} {...props} />
            </Dialog>
          }
        />
      </Routes>
    </>
  );
};

const ProjectListView: FC = () => {
  const { setPageTitle } = useLayout();
  const history = useNavigate();
  const location = useLocation();
  const { idProject = '' } = useParams<{ idProject: string }>();
  const numberOfProjectsLoaded = 40;

  // prepare initial data
  const data: { [key: string]: ProjectType } = {};
  const ids: string[] = Object.keys(data);
  const urlFilters = useMemo(() => getUrlFiltersAndSort(location), [location]);
  const goToCreate = useCallback(() => {
    history('/projects/create', { replace: true });
  }, [history]);

  // basic error message handling
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [bookmarkedProjects, setBookmarkedProjects] = useState<ProjectBookmarks | null>(null);

  // note: the dialog logic needs to be abstracted/deduped/put in a reducer!!
  // create project dialog
  const [open, setOpen] = useState<boolean>(false);
  const [selectedProject, setSelectedProject] = useState<ProjectType | null>(null);
  const [moreMenuOpen, setMoreMenuOpen] = useState<boolean>(false);
  const handleDialogOpen = useCallback(
    (isOpen: boolean) => {
      setOpen(isOpen);
    },
    [setOpen],
  );

  // lock dialog
  const [lockOpen, setLockOpen] = useState(false);
  const handleLockOpenClose = useCallback(
    (isOpen: boolean) => {
      setLockOpen(isOpen);
    },
    [setLockOpen],
  );
  const [projects, setProjects] = React.useState<ProjectType[]>([] as ProjectType[]);
  const projectPath = buildActionUrl({}, TYPE_PROJECTS);
  const fetchWithAuth = useFetchWithAuth();
  const mounted = React.useRef(false);
  projects.forEach((project) => {
    data[project.projectId] = project;
    ids.push(project.projectId);
  });

  useEffect(() => {
    if (mounted.current) return;
    mounted.current = true;
    setPageTitle('Projects');
  }, [setPageTitle]);

  let companies = ids ? ids.map((id) => data[id].company) : [];
  companies = companies.filter((value, index, self) => self.indexOf(value) === index).sort();

  const loadProjects = useCallback((startAtId: string | null, limit: number) => {
    const url = buildActionUrl({}, TYPE_PROJECT_QUERY);
    axios
      .post(url, {
        sortOptions: [
          {
            field: 'QUERY_PROJECTS_SORT_FIELD_STATUS',
            sortDirection: 'ASCENDING',
          },
        ],
        start_at_id: startAtId,
        limit,
      })
      .then((res: AxiosResponse) => res.data)
      .then((result: any) => {
        setProjects((prevProjects) => [...prevProjects, ...result.projects]);
        if (result.nextId) {
          loadProjects(result.nextId, limit);
        }
      });
  }, []);

  useEffect(() => {
    loadProjects(null, numberOfProjectsLoaded);
  }, [loadProjects]);

  useEffect(() => {
    const bookmarks = getAllUserBookmarks();
    setBookmarkedProjects(bookmarks);
  }, []);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleLockSubmit = useCallback(
    (projectId: string) => async () => {
      handleLockOpenClose(false);
      const { error } = await fetchWithAuth(`${projectPath}${projectId}/lock`, {
        method: 'POST',
      });
      setErrorMessage(error || null);
      loadProjects(null, numberOfProjectsLoaded);
    },
    [handleLockOpenClose, fetchWithAuth, projectPath, setErrorMessage, loadProjects],
  );
  let dataToShow: any[] = ids.map((id: string) => data[id]);

  const handleCloseMore = useCallback(
    (project?: ProjectType) => {
      const id = projects.findIndex((p: ProjectType) => p.projectId === project?.projectId);
      if (id !== -1) {
        projects[id] = project as ProjectType;
        setProjects([...projects]);
      }
      setAnchorEl(null);
    },
    [projects],
  );

  const handleClickMore = (project: ProjectType) => (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setSelectedProject(project);
    setMoreMenuOpen(true);
  };

  const getProjectSortFromString = (value: string | undefined) => {
    if (value) {
      const sortOptionKey = value as keyof typeof projectListSortOptions;
      return projectListSortOptions[sortOptionKey];
    }

    return projectListSortOptions.PROJECT_CREATION_DATE;
  };

  const setUrlFilterCallback = (k: string, v: string | null) => setUrlFilter(location, history, k, v);
  const getProjectSortFromUrl = () => getProjectSortFromString(urlFilters.sort);
  const [projectSort, setProjectSort] = useState<{
    sortOption: string;
    value: string;
    label: string;
  }>(getProjectSortFromUrl());
  const [hideArchivedProject, setHideArchivedProject] = useState<boolean>(true);

  const [projectNameFilter, setProjectNameFilter] = useState<string>(urlFilters?.filters?.project_name || '');
  const [companyFilter, setCompanyFilter] = useState<string>(urlFilters.filters.company || '');

  const getActiveStatus = (project: ProjectType) =>
    [ProjectStatus.Archiving, ProjectStatus.Restoring, ProjectStatus.Removing, ProjectStatus.Provisioning].indexOf(
      project.status,
    ) !== -1;

  const hiddenArchiveStatuses = [ProjectStatus.Archived, ProjectStatus.Archiving, ProjectStatus.Removed];
  dataToShow = dataToShow.filter(
    (item) =>
      (!projectNameFilter || item.projectId.toLowerCase().includes(projectNameFilter.toLowerCase())) &&
      (!companyFilter || item.company === companyFilter) &&
      (!hideArchivedProject || hiddenArchiveStatuses.indexOf(item.status) === -1),
  );

  if (projectSort.value === projectListSortOptions.PROJECT_NAME.value) {
    dataToShow = dataToShow.sort((a, b) => (a.projectId > b.projectId ? 1 : -1));
  } else if (projectSort.value === projectListSortOptions.PROJECT_STATUS.value) {
    dataToShow = dataToShow.sort((a, b) => (a.status > b.status ? 1 : -1));
  } else if (projectSort.value === projectListSortOptions.PROJECT_CREATION_DATE.value) {
    dataToShow = dataToShow.sort((a, b) =>
      compareProperty(b.resourceMetadata?.createdAt, a.resourceMetadata?.createdAt),
    );
  }

  return (
    <>
      <div style={{ display: 'none' }}>{errorMessage}</div>
      <Routes>
        <Route
          path="/projects/:idProject"
          element={
            <>
              {idProject !== 'create' ? (
                <Navigate to={generatePath('/projects/:idProject/show', { idProject })} />
              ) : (
                <></>
              )}
            </>
          }
        >
          <></>
        </Route>
      </Routes>

      <PageTitle
        title="Projects"
        action={
          <>
            <DocLink link={DocLinkUrl[DocTypeEnum.CreateCloneProjectDialog]} />
            <Tooltip title="Create project">
              <Button onClick={goToCreate} data-id-cypress="createProjectButton">
                <Add />
              </Button>
            </Tooltip>
          </>
        }
      />

      <Card style={{ marginBottom: '20px' }}>
        <CardContent>
          <Grid container={true} spacing={3} style={{ width: '100%' }}>
            <Grid item={true} md={3}>
              <TextField
                data-id-cypress="projectNameFilter"
                fullWidth={true}
                label="Search projects by name"
                variant="outlined"
                onChange={(event) => {
                  setProjectNameFilter(String(event.currentTarget.value || ''));
                  setUrlFilterCallback('project_name', String(event.currentTarget.value || ''));
                }}
                value={projectNameFilter}
              />
            </Grid>
            <Grid item={true} md={3}>
              <div style={{ display: 'flex' }}>
                <Autocomplete
                  data-id-cypress="companyFilter"
                  fullWidth={true}
                  value={companyFilter || null}
                  options={companies}
                  onChange={(e, value: string | null) => {
                    setCompanyFilter(value || '');
                    setUrlFilterCallback('company', value || null);
                  }}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => <TextField {...params} label="Filter By Company" variant="outlined" />}
                />
              </div>
            </Grid>
            <Grid item={true} md={3}>
              <Autocomplete
                fullWidth={true}
                value={{
                  label: projectSort.label,
                  value: projectSort.sortOption,
                }}
                options={getProjectListSortOptionsArray()}
                getOptionLabel={(option) => option?.label || ''}
                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                onChange={(e, value) => {
                  setProjectSort(getProjectSortFromString(value?.value));
                  setUrlSort(location, history, value?.value || null);
                }}
                renderInput={(params) => (
                  <div>
                    <TextField {...params} label="Sort by" variant="outlined" />
                  </div>
                )}
              />
            </Grid>
            <Grid item={true} md={3}>
              <InputLabel>
                <Checkbox
                  data-id-cypress="hideArchivedProject"
                  checked={hideArchivedProject}
                  onChange={(e) => setHideArchivedProject(e.target.checked)}
                ></Checkbox>
                Hide archived projects
              </InputLabel>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Grid container={true} spacing={3} direction="row" justifyContent="flex-start" alignItems="stretch">
        {dataToShow.map((project: ProjectType) => {
          const bookmark = bookmarkedProjects?.[project.projectId] || null;
          const url = bookmark
            ? `/projects/${bookmark.projectId}/phases/${bookmark.phaseId}/runs/${bookmark.runId}`
            : `/projects/${project.projectId}/show/`;

          return (
            <Grid item={true} xs={12} sm={6} md={4} lg={4} xl={3} key={url}>
              <Card className="card__container" data-id-cypress="card">
                <CardHeader
                  data-id-cypress="cardHeader"
                  classes={{ content: 'card__headerContent' }}
                  titleTypographyProps={{ noWrap: true, variant: 'h6' }}
                  avatar={<Avatar> {capitalizedFirstChar(project.company, 'o')} </Avatar>}
                  title={
                    <Typography noWrap={true} gutterBottom={true} variant="h6" component="h4" className="card__title">
                      {project.projectId}
                      <br />
                      {project.company || ''}
                    </Typography>
                  }
                  action={
                    <IconButton data-id-cypress="moreButton" onClick={handleClickMore(project)} aria-label="settings">
                      <MoreVertIcon />
                    </IconButton>
                  }
                />
                <CardActionArea component={Link} to={url} data-id-cypress="cardContent">
                  <CardContent>
                    <Typography paragraph={true} align="left" variant="body1" noWrap={true} color="textSecondary">
                      {project.description || '<no description>'}
                    </Typography>
                    <Typography align="left" variant="body2">
                      {' '}
                      Customer: {project.company}{' '}
                    </Typography>
                    <Typography align="left" variant="body2">
                      {' '}
                      Countries: {(project.countries || []).join(', ')}{' '}
                    </Typography>
                    <Typography align="left" variant="body2">
                      {' '}
                      Base ingredient(s): {(project.baseIngredients || []).map((x: any) => x.name).join(', ')}{' '}
                    </Typography>
                    <Typography align="left" variant="body2">
                      {' '}
                      Status: {projectStatusToText[project.status]}{' '}
                      {getActiveStatus(project) && <CircularProgress size={10} />}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button
                    data-id-cypress="cardProject"
                    onClick={() =>
                      history(
                        generatePath('/projects/:idProject/show', {
                          idProject: project.projectId,
                        }),
                      )
                    }
                  >
                    Brief
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      {selectedProject && (
        <>
          <Dialog open={open} onClose={() => handleDialogOpen(false)}>
            <ProjectCreate onCancel={() => handleDialogOpen(false)} originalProject={selectedProject} />
          </Dialog>
          <LockProjectDialog
            projectId={selectedProject.projectId}
            handleClose={() => handleLockOpenClose(false)}
            handleSubmit={handleLockSubmit}
            open={lockOpen}
          />
          {moreMenuOpen && (
            <MoreMenu
              handleClose={handleCloseMore}
              handleClone={() => handleDialogOpen(true)}
              handleLock={() => handleLockOpenClose(true)}
              handleError={setErrorMessage}
              anchorEl={anchorEl}
              selectedProject={selectedProject.projectId}
              project={selectedProject}
            />
          )}
        </>
      )}
      {errorMessage && (
        <ErrorNotification
          data-id-cypress="errorMessage"
          error={errorMessage}
          handleClose={() => setErrorMessage(null)}
        />
      )}
    </>
  );
};

export default ProjectList;
