import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import axios, { AxiosResponse } from 'axios';
import React from 'react';

import { buildActionUrl, TYPE_CONTEXT_ARCHIVE } from '../../shared/url';
import { ContextType } from '../../types';

interface ContextArchiveDialogProps {
  handleClose: () => void;
  handleArchived?: () => void;
  context: ContextType;
}

const ContextArchiveDialog: React.FC<ContextArchiveDialogProps> = ({ handleClose, context, handleArchived }) => {
  const isLoading = false;
  const url = buildActionUrl({ contextId: context.contextId }, TYPE_CONTEXT_ARCHIVE);
  const archiveContext = () => {
    axios
      .post(url, {})
      .then((res: AxiosResponse) => res.data)
      .then(() => {
        if (handleArchived) handleArchived();
        else handleClose();
      });
  };

  return (
    <Dialog fullWidth={true} open={true} onClose={() => !isLoading && handleClose()} maxWidth="md">
      <DialogTitle>{`Are you sure you want to archive ${context.name}?`}</DialogTitle>
      <DialogContent>It will no longer be shown in the list and can no longer be used for projects</DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button disabled={isLoading} onClick={archiveContext} color="primary">
          Archive
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ContextArchiveDialog.defaultProps = { handleArchived: undefined };

export default ContextArchiveDialog;
