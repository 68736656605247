import React, { Dispatch } from 'react';

import { DishType } from '../../features/concepts/types';
import {
  CategoryType,
  ContextVersionType,
  RecipeFiltersType,
  RecipeSelectionType,
  SocialPostSelectionType,
  SocialTagErrorItem,
} from '../../types';

export interface LoadingStatuses {
  init: boolean;
  socialCategory: boolean;
  socialTagsCheck: boolean;
  recipeFilters: boolean;
  socialCategoryTags: boolean;
  dishTypesList: boolean;
}

export type ContextStateType = {
  description: string;
  socialPostsCategories: { [name: string]: CategoryType };
  selectedSocialPostsCategoryName: string | null;
  selectSocialPostsCategoryName: string | null;
  recipeSelections: RecipeSelectionType[];
  socialPostsSelections: SocialPostSelectionType[];
  socialPosts: SocialPostSelectionType[];
  recipeFilters: RecipeFiltersType;
  recipes: RecipeSelectionType[];
  dishTypesList: DishType[];
  dishTypesSelection: DishType[];
  recipeVersions: string[];
  socialPostVersions: string[];
  recipesVersion: string;
  socialPostVersion: string;
  dishTypesVersion: string;
  dishTypesVersions: string[];
  contextVersion?: ContextVersionType;
  loadingStatuses: LoadingStatuses;
  socialErrors: SocialTagErrorItem[] | null;
  recipeError: boolean;
  selectedTab: number;
  isSaving: boolean;
  openArchiveDialog: boolean;
  openPublishDialog: boolean;
  loadingTarget: 'SAVE' | 'PUBLISH' | 'ARCHIVE' | null;
};

const initialState: ContextStateType = {
  description: '',
  socialPostsCategories: {},
  selectedSocialPostsCategoryName: null,
  selectSocialPostsCategoryName: null,
  recipeSelections: [],
  socialPostsSelections: [],
  socialPosts: [],
  recipeFilters: { dishTypes: [], regionTypes: [], tasteTypes: [] },
  recipes: [],
  dishTypesList: [],
  dishTypesSelection: [],
  recipeVersions: [],
  socialPostVersions: [],
  recipesVersion: '',
  socialPostVersion: '',
  dishTypesVersion: '',
  dishTypesVersions: [],
  selectedTab: 0,
  isSaving: false,
  openArchiveDialog: false,
  openPublishDialog: false,
  loadingTarget: null,
  loadingStatuses: {
    init: true,
    socialCategory: false,
    socialTagsCheck: false,
    recipeFilters: false,
    socialCategoryTags: false,
    dishTypesList: false,
  },
  socialErrors: null,
  recipeError: false,
};

export enum LoadingActions {
  init = 'setInitLoading',
  socialCategory = 'setSocialCategoriesLoading',
  socialTagsCheck = 'setSocialTagsCheckLoading',
  dishTypesList = 'setDishTypesListLoading',
  recipeFilters = 'setRecipeFiltersLoading',
  socialCategoryTags = 'setSocialCategoryTagsLoading',
}

export enum RecipeActions {
  add = 'addRecipeSelection',
  remove = 'removeRecipeSelection',
}

export enum SocialActions {
  set = 'setSocialTagSelection',
  load = 'loadSocialPostsCategory',
  select = 'selectSocialPostsCategory',
}

export type MetadataAction = {
  type: 'setMetadataDescription';
  description: string;
};

export type LoadingAction =
  | { type: LoadingActions.init; loading: boolean }
  | { type: LoadingActions.socialCategory; loading: boolean }
  | { type: LoadingActions.socialTagsCheck; loading: boolean }
  | { type: LoadingActions.dishTypesList; loading: boolean }
  | { type: LoadingActions.recipeFilters; loading: boolean }
  | { type: LoadingActions.socialCategoryTags; loading: boolean };

export type RecipeAction =
  | { type: RecipeActions.add; currentRow: RecipeSelectionType }
  | { type: RecipeActions.remove; index: number };

export type SocialAction =
  | { type: SocialActions.set; items: SocialPostSelectionType[] }
  | { type: SocialActions.load; name: string; category: CategoryType }
  | { type: SocialActions.select; name: string };

export type ErrorAction =
  | { type: 'FetchError'; error: string }
  | { type: 'SubmitError'; error: string }
  | { type: 'setSocialErrors'; errors: SocialTagErrorItem[] | null }
  | { type: 'setRecipesError'; error: boolean };

export type StateAction =
  | { type: 'loadState'; data: ContextStateType }
  | { type: 'loadContextVersion'; data: ContextVersionType }
  | { type: 'loadInitialSocialPostsCategories'; items: CategoryType[] }
  | { type: 'loadRecipeFilters'; data: RecipeFiltersType }
  | { type: 'loadDishTypesList'; data: DishType[] }
  | { type: 'setDishTypesSelection'; data: DishType[] }
  | { type: 'setSocialPostVersion'; data: { socialPostVersion: string } }
  | { type: 'setSocialPostVersions'; versions: string[] }
  | { type: 'setRecipesVersion'; data: { recipesVersion: string } }
  | { type: 'setRecipeVersions'; versions: [] }
  | { type: 'setDishTypesVersion'; data: { dishTypesVersion: string } }
  | { type: 'setDishTypesVersions'; versions: [] }
  | { type: 'saveContext' }
  | { type: 'setSelectedTab'; selectedTab: number }
  | { type: 'setSaving'; isSaving: boolean }
  | { type: 'setOpenArchiveDialog'; openArchiveDialog: boolean }
  | { type: 'setOpenPublishDialog'; openPublishDialog: boolean }
  | {
      type: 'setLoadingTarget';
      loadingTarget: 'SAVE' | 'PUBLISH' | 'ARCHIVE' | null;
    };

export type Action = MetadataAction | RecipeAction | SocialAction | StateAction | LoadingAction | ErrorAction;

export type ContextActionDispatcher = (action: Action) => void;

export function reducer(state: ContextStateType, action: Action): ContextStateType {
  return reducerImplementation(state, action);
}

function reducerImplementation(state: ContextStateType, action: Action): ContextStateType {
  switch (action.type) {
    /* loading statues */
    case LoadingActions.init:
      return {
        ...state,
        loadingStatuses: { ...state.loadingStatuses, init: action.loading },
      };
    case LoadingActions.socialCategory:
      return {
        ...state,
        loadingStatuses: {
          ...state.loadingStatuses,
          socialCategory: action.loading,
        },
      };
    case LoadingActions.socialTagsCheck:
      return {
        ...state,
        loadingStatuses: {
          ...state.loadingStatuses,
          socialTagsCheck: action.loading,
        },
      };
    case LoadingActions.recipeFilters:
      return {
        ...state,
        loadingStatuses: {
          ...state.loadingStatuses,
          recipeFilters: action.loading,
        },
      };
    case LoadingActions.socialCategoryTags:
      return {
        ...state,
        loadingStatuses: {
          ...state.loadingStatuses,
          socialCategoryTags: action.loading,
        },
      };
    case LoadingActions.dishTypesList:
      return {
        ...state,
        loadingStatuses: {
          ...state.loadingStatuses,
          dishTypesList: action.loading,
        },
      };

    /* Errors */

    case 'setSocialErrors':
      return { ...state, socialErrors: action.errors };
    case 'setRecipesError':
      return { ...state, recipeError: action.error };

    /* the rest */
    case 'setMetadataDescription':
      return { ...state, description: action.description };
    case RecipeActions.add: {
      const addRecipes = state.recipeSelections.concat([action.currentRow]);
      return { ...state, recipeSelections: addRecipes };
    }
    case RecipeActions.remove: {
      const removeRecipes = state.recipeSelections
        .slice(0, action.index)
        .concat(state.recipeSelections.slice(action.index + 1, state.recipeSelections.length));
      return { ...state, recipeSelections: removeRecipes };
    }
    case SocialActions.set:
      return { ...state, socialPostsSelections: action.items };
    case SocialActions.load: {
      // load specific category with tags
      const updatedCategories = {
        ...state.socialPostsCategories,
        [action.name]: action.category,
      };
      return {
        ...state,
        socialPostsCategories: updatedCategories,
        selectedSocialPostsCategoryName: action.name,
      };
    }
    case SocialActions.select:
      return { ...state, selectSocialPostsCategoryName: action.name };
    case 'loadInitialSocialPostsCategories':
      // load without tags
      return {
        ...state,
        socialPostsCategories: Object.fromEntries(action.items.map((category) => [category.name, category])),
      };
    case 'loadRecipeFilters':
      return { ...state, recipeFilters: action.data };
    case 'loadState':
      return { ...state, ...action.data };
    case 'loadDishTypesList':
      return { ...state, dishTypesList: action.data };
    case 'setDishTypesSelection':
      return { ...state, dishTypesSelection: action.data };
    case 'setSocialPostVersions':
      return { ...state, socialPostVersions: action.versions };
    case 'setSocialPostVersion':
      return { ...state, socialPostVersion: action.data.socialPostVersion };
    case 'setRecipeVersions':
      return { ...state, recipeVersions: action.versions };
    case 'setRecipesVersion':
      return { ...state, recipesVersion: action.data.recipesVersion };
    case 'setDishTypesVersion':
      return { ...state, dishTypesVersion: action.data.dishTypesVersion };
    case 'setDishTypesVersions':
      return { ...state, dishTypesVersions: action.versions };
    case 'loadContextVersion':
      return {
        ...state,
        ...action.data,
        recipeSelections: action.data.recipes || [],
        socialPostsSelections: action.data.socialPosts || [],
      };
    case 'saveContext':
      return { ...state };
    case 'setSelectedTab':
      return { ...state, selectedTab: action.selectedTab };
    case 'setSaving':
      return { ...state, isSaving: action.isSaving };
    case 'setOpenArchiveDialog':
      return { ...state, openArchiveDialog: action.openArchiveDialog };
    case 'setOpenPublishDialog':
      return { ...state, openPublishDialog: action.openPublishDialog };
    case 'setLoadingTarget':
      return { ...state, loadingTarget: action.loadingTarget };
    default:
      throw new Error();
  }
}

export function useContextReducer(): [ContextStateType, Dispatch<any>] {
  return React.useReducer(reducer, initialState);
}
