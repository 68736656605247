import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import React from 'react';
import { Link } from 'react-router-dom';

import { JobType } from '../../../types';

type MaybeJobType = JobType | null;
type JobNotificationHookType = {
  handleJobNotification: (jobInfo: JobType | null) => void;
  jobNotification: JobType | null;
  handleCloseJobNotification: (event?: React.SyntheticEvent | Event, reason?: string) => void;
};

const useJobNotification = (): JobNotificationHookType => {
  const [jobInfoState, setJobInfo] = React.useState(null as MaybeJobType);

  const handleOpen = React.useCallback((jobInfo: JobType | null) => {
    setJobInfo(jobInfo);
  }, []);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setJobInfo(null);
  };
  return {
    jobNotification: jobInfoState,
    handleJobNotification: handleOpen,
    handleCloseJobNotification: handleClose,
  };
};

type JobNotificationProps = {
  job: MaybeJobType;
  projectId: string;
  handleClose: (event?: React.SyntheticEvent | Event, reason?: string) => void;
};

const JobNotification: React.FC<JobNotificationProps> = ({ job, handleClose, projectId }) => (
  <Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    open={job !== null}
    onClose={handleClose}
    ContentProps={{
      'aria-describedby': 'message-id',
    }}
    message={
      <span id="message-id">
        {job !== null ? job.jobType.replace('JOB_TYPE_', '').toLowerCase() : 'unknown'} job failed
      </span>
    }
    action={[
      <Button
        component={Link}
        key="show-details"
        color="primary"
        size="small"
        to={job ? `/projects/${projectId}/jobs/${job.jobId}` : '/jobs'}
      >
        Job details
      </Button>,
      <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon />
      </IconButton>,
    ]}
  />
);

export { JobNotification, useJobNotification };
