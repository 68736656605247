export function sliceIntoChunks<T>(arr: T[], chunkSize: number): T[][] {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}

export function getLatestVersion(versions: string[]): string {
  return [...versions].sort((a, b) => parseInt(b, 10) - parseInt(a, 10))[0];
}
