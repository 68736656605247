import { DataManRecordsType } from '../../../../../components/dataman/types';
import { RunType } from '../../_utils/types';
import { ConceptGenerationDatamanRecord } from './types';

function prepareRecords(runs: RunType[]): DataManRecordsType<ConceptGenerationDatamanRecord> {
  return runs?.map((run) => ({
    name: run.runName,
    startedAt: run.resourceMetadata?.createdAt,
    startedBy: run.resourceMetadata?.createdBy,
    phaseId: run.phaseId,
    runId: run.runId,
    input_status: run.outputs?.input_preparation?.status,
    output_status: run.outputs?.output_generation?.status,
  }));
}

export default prepareRecords;
