import { all, AllEffect, ForkEffectDescriptor, SimpleEffect } from 'redux-saga/effects';

import { aromaWatcher } from '../features/aroma/store';
import { versionWatcher } from '../features/datasources/store';

export default function* rootSaga(): Generator<
  AllEffect<Generator<SimpleEffect<'FORK', ForkEffectDescriptor<never>>, void, unknown>>,
  void,
  unknown
> {
  yield all([versionWatcher(), aromaWatcher()]);
}
