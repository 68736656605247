import AddIcon from '@mui/icons-material/Add';
import { Grid, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React, { FC, memo, useCallback, useState } from 'react';

import { DotGraphFormRecord } from '../../features/aroma/types';
import { CoreIngredientType } from '../../types';
import DotGraphFormRow from './DotGraphFormRow';

interface DotGraphFormProps {
  coreIngredients: CoreIngredientType[];
  coreIngredientsStr: string[];
  loading: boolean;
  version: string;
  dotGraphOtherIngredients: DotGraphFormRecord[];
  updateFormRows: (data: { update?: DotGraphFormRecord[]; add?: DotGraphFormRecord }) => void;
}

const DotGraphForm: FC<DotGraphFormProps> = ({
  coreIngredients,
  coreIngredientsStr,
  loading,
  version,
  dotGraphOtherIngredients,
  updateFormRows,
}) => {
  const [rows, setRows] = useState<DotGraphFormRecord[]>(dotGraphOtherIngredients);
  const showForm = coreIngredients.length > 0;

  // add new row to form
  const handleAddUpdateRow = useCallback(
    (row?: DotGraphFormRecord) =>
      setRows((oldRows) => {
        const newRows = [...oldRows];
        const add = {
          id: undefined,
          name: '',
          aromaTypes: [],
          rowPosition: newRows.length,
          aromaTypeToMolecules: {},
          variants: row?.variants,
          selectedVariant: row?.selectedVariant,
          ...(row || {}),
        };
        delete add.aromaRecords;
        newRows[add.rowPosition] = add;
        updateFormRows({
          add,
          update: !add.id
            ? dotGraphOtherIngredients.filter((r) => !!r.id && r.rowPosition !== add.rowPosition)
            : undefined,
        });

        return newRows;
      }),
    [dotGraphOtherIngredients, updateFormRows],
  );

  // remove input row handler
  const handleRemoveRow = useCallback(
    (position: number) =>
      setRows(() => {
        const update = dotGraphOtherIngredients
          .filter((row) => row.rowPosition !== position)
          .map((el, rowPosition) => ({ ...el, rowPosition }));
        updateFormRows({ update: update.filter((i) => Number(i.id) > 0) });

        return update;
      }),
    [dotGraphOtherIngredients, updateFormRows],
  );

  return (
    <>
      <form>
        <fieldset disabled={false} style={{ border: 'none' }}>
          <Grid container={true} style={{ margin: 10 }}>
            <Grid item={true} md={showForm ? 4 : 6} style={{ display: 'flex', alignItems: 'center' }}>
              <>
                <Typography component="p">Compare with...</Typography>
                <IconButton disabled={!showForm} onClick={() => handleAddUpdateRow()}>
                  <AddIcon />
                </IconButton>
              </>
            </Grid>
          </Grid>
          {showForm &&
            rows.map((row) => (
              <DotGraphFormRow
                autoFocusOnNewRow={true}
                label="Product prep with id"
                key={`${row.name || ''}_${row.rowPosition}_${row.id || ''}`}
                row={row}
                version={version}
                loading={loading}
                handleRemoveRow={handleRemoveRow}
                coreIngredients={coreIngredients}
                coreIngredientsStr={coreIngredientsStr}
                selectIngredientAfterCallback={handleAddUpdateRow}
              />
            ))}
        </fieldset>
      </form>
    </>
  );
};

export default memo(DotGraphForm, (prev, current) => {
  const prevIds = prev.dotGraphOtherIngredients.map((i) => i.id).join(',');
  const currentIds = current.dotGraphOtherIngredients.map((i) => i.id).join(',');

  return (
    JSON.stringify(prev.coreIngredients) === JSON.stringify(current.coreIngredients) &&
    JSON.stringify(prev.coreIngredientsStr) === JSON.stringify(current.coreIngredientsStr) &&
    prev.loading === current.loading &&
    prevIds === currentIds
  );
});
