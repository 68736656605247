import './cell-renderer.scss';

import Box from '@mui/material/Box';
import Moment from 'moment';
import React from 'react';

import { ColumnIdentifier, DataManRenderRowCellCallback } from '../../../../components/dataman/types';
import { prepareCellValue } from '../../utils';

const STATUS_CLASS_MAP: { [key: string]: string } = {
  JOB_STATUS_ACCEPTED: 'warning',
  JOB_STATUS_PROGRESS: 'warning',
  JOB_STATUS_TO_ABORT: 'warning',
  JOB_STATUS_MANUAL: 'warning',
  JOB_STATUS_OK: 'success',
  JOB_STATUS_CREATED: 'grayscale4',
  JOB_STATUS_ABORTED: 'danger',
  JOB_STATUS_FAILED: 'danger',
};

export const renderRowCell: DataManRenderRowCellCallback = (
  columnId: ColumnIdentifier,
  value: string | null | undefined,
) => {
  switch (columnId) {
    case 'jobId':
      return <Box style={{ maxWidth: 200, wordBreak: 'break-all' }}>{prepareCellValue(value, false, 'JOB_TYPE_')}</Box>;
    case 'jobType':
      return <>{prepareCellValue(value, true, 'JOB_TYPE_')}</>;
    case 'workflowStepId':
      return <>{prepareCellValue(value, true)}</>;
    case 'createdAt':
      return <>{Moment(value).format('YYYY-MM-DD HH:mm')}</>;
    case 'status': {
      return (
        <Box className={value ? `RowCellStatus__${STATUS_CLASS_MAP[value]}` : ''} style={{ fontWeight: 'bold' }}>
          {prepareCellValue(value, true, 'JOB_STATUS_')}
        </Box>
      );
    }
    case 'startedByUser':
      return <Box style={{ maxWidth: 200, wordBreak: 'break-all' }}>{value}</Box>;
    default:
      return <></>;
  }
};
