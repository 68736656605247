import _ from 'lodash';

import { JobStatus, JobType } from '../../types';

type TransformJobType = Omit<JobType, 'status'> & {
  jobType: string;
  status: string;
  errorMessage: string;
  failed: boolean;
  jobMessage: string;
};

export const getJobMessage = (job: JobType): string => {
  switch (job.status) {
    case JobStatus.Ok:
      return 'Completed';
    case JobStatus.Failed:
      return 'Error';
    case JobStatus.Aborted:
      return 'Aborted by user';
    case JobStatus.InProgress:
      return 'Job in progress';
    case JobStatus.Created:
      return 'Job created';
    default:
      return `Unknown job status "${job.status}"`;
  }
};

export const transformJob = (job: JobType): TransformJobType => ({
  ...job,
  jobType: job.jobType.replace('JOB_TYPE_', '').toLowerCase(),
  status: job.status.replace('JOB_STATUS_', '').toLowerCase(),
  errorMessage: job.errorMessage || '',
  failed: job.status === 'JOB_STATUS_FAILED',
  jobMessage: getJobMessage(job),
});

/**
 * Prepare value of job field.
 *
 * @param value
 * @param startCase
 * @param replaceValue
 */
export function prepareCellValue(value: string | number | any, startCase: boolean, replaceValue?: string) {
  let result = String(value)
    .replace(replaceValue || '', '')
    .toLowerCase();

  if (startCase) {
    result = _.startCase(result);
  }

  return result;
}
