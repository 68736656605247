import './index.scss';

import { enableMapSet } from 'immer';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

/* eslint-disable no-undef */
const root = createRoot(document?.getElementById('root'));
// Enables setting values for a redux state variable of type Map
enableMapSet();

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
);
/* eslint-enable no-undef */

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
