import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import React from 'react';

type ErrorNotificationProps = {
  error: string | null | React.FC | React.ReactNode;
  handleClose: () => void;
};

const ErrorNotification: React.FC<ErrorNotificationProps> = ({ error, handleClose }) => (
  <Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    open={error !== null}
    onClose={handleClose}
    message={error ? <>{error}</> : null}
    action={[
      <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon />
      </IconButton>,
    ]}
  />
);

/** const useErrorNotification = () => {
  const [error, setError] = React.useState(null as string | null);
  const notifier = (
    <ErrorNotification error={error} handleClose={() => setError(null)} />
  );
  return [notifier, setError];
}; */

export default ErrorNotification;
