import { FilterOptionsState } from '@mui/base/useAutocomplete';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';
import { matchSorter } from 'match-sorter';
import React, { useCallback } from 'react';

import { emptyIngredientSlot } from '../../../../../../features/project/types/concept-generation';
import { IngredientDBIngredientType } from '../../../../../../types';
import { IngredientDBMap } from '../../../_utils/datasources';

const MAX_INGREDIENT_OPTIONS = 100;

const IngredientSlotEditCell: React.FC<{
  slotNo: number;
  ingredientSlotMap: IngredientDBMap;
  row: GridRenderEditCellParams;
}> = ({ slotNo, ingredientSlotMap, row }) => {
  const apiRef = useGridApiContext();

  const filterOptions = useCallback(
    (options: IngredientDBIngredientType[], state: FilterOptionsState<IngredientDBIngredientType>) =>
      matchSorter(options, state.inputValue, {
        keys: [
          { maxRanking: matchSorter.rankings.EQUAL, key: 'ingredientId' },
          { maxRanking: matchSorter.rankings.CONTAINS, key: 'name' },
        ],
      }).slice(0, MAX_INGREDIENT_OPTIONS),
    [],
  );

  return (
    <Autocomplete
      id={`ingredientSlot_${slotNo}`}
      autoSelect={true}
      selectOnFocus={true}
      autoHighlight={true}
      blurOnSelect={true}
      getOptionLabel={(option) => (typeof option !== 'string' ? `${option.name} (${option.ingredientId})` : '')}
      options={ingredientSlotMap.ingredients}
      filterSelectedOptions={true}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField type="text" {...params} autoFocus={true} InputProps={{ ...params.InputProps, type: 'search' }} />
      )}
      onChange={async (event, newValue, reason) => {
        // only change the value in the cell on blur
        if (reason === 'selectOption') return;

        let ingredient = emptyIngredientSlot;
        // type is either same as options being IngredientDBIngredientType
        // or type is string, which is the default for Autocomplete components
        if (newValue && typeof newValue !== 'string') {
          ingredient = newValue;
        }
        const templateId = row.id as number;

        // Use grid api to update the value, but update the store onRowChange
        await apiRef.current.setEditCellValue({
          id: templateId,
          field: `ingredientSlot_${slotNo}`,
          value: ingredient?.ingredientId || null,
        });
      }}
      style={{ width: '95%' }}
      fullWidth={true}
    />
  );
};

export default IngredientSlotEditCell;
