import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/types/types-external';

import { ConceptGenerationWFVersion } from '../../../admin/projects/ProjectFlow/ConceptGeneration/_utils/types';
import { GenericInputConfig } from '../types/common';
import {
  CompositionConfig,
  ConceptGenerationConfigV2,
  IngredientClass,
  MetricWeights,
  SelectedIngredient,
} from '../types/concept-generation';
import { getAvailableClasses } from '../utils';

export interface ConceptGenerationWorkflowV2State {
  selectedIngredients: SelectedIngredient[];
  ingredientSlots: number;
  metricWeights: MetricWeights;
  compositions: CompositionConfig[];
  availableIngredientClasses: IngredientClass[];
}

export const defaultConceptGenerationWFV2State: ConceptGenerationWorkflowV2State = {
  selectedIngredients: [],
  ingredientSlots: 2,
  metricWeights: {
    popularity_contexts: 0.1,
    popularity_combo: 0.3,
    associativity_contexts: 0.1,
    associativity_combo: 0.3,
    trends_country: 0.1,
    foodpairing_match: 0.1,
  },
  compositions: [],
  availableIngredientClasses: [],
};

function resetState(state: WritableDraft<ConceptGenerationWorkflowV2State>) {
  state.selectedIngredients = defaultConceptGenerationWFV2State.selectedIngredients;
  state.ingredientSlots = defaultConceptGenerationWFV2State.ingredientSlots;
  state.metricWeights = defaultConceptGenerationWFV2State.metricWeights;
  state.compositions = defaultConceptGenerationWFV2State.compositions;
  state.availableIngredientClasses = defaultConceptGenerationWFV2State.availableIngredientClasses;
}

export const SLICE_CONCEPT_GENERATION_V2 = 'concept_generation_v2';
export const conceptGenerationV2Slice = createSlice({
  name: SLICE_CONCEPT_GENERATION_V2,
  initialState: defaultConceptGenerationWFV2State,
  reducers: {
    setConceptGenerationConfig: (state, action: PayloadAction<GenericInputConfig | undefined>) => {
      if (action.payload && action.payload?.version === ConceptGenerationWFVersion.v2) {
        const genericInput = action.payload as ConceptGenerationConfigV2;
        const { ingredients, ingredient_slots, compositions, weights } = genericInput;
        state.selectedIngredients = ingredients;
        state.ingredientSlots = ingredient_slots;
        state.compositions = compositions;
        state.metricWeights = weights;
      } else {
        resetState(state);
      }
    },
    setSelectedIngredients: (state, { payload: { items } }: PayloadAction<{ items: SelectedIngredient[] }>) => {
      state.selectedIngredients = items;
      state.availableIngredientClasses = getAvailableClasses(items);
    },
    setIngredientSlots: (state, action: PayloadAction<number>) => {
      state.ingredientSlots = action.payload;
    },
    setMetricWeights: (state, action: PayloadAction<MetricWeights>) => {
      state.metricWeights = action.payload;
    },
    setTemplatesRows: (state, action: PayloadAction<CompositionConfig[]>) => {
      state.compositions = action.payload;
    },
    setAvailableClasses: (state, { payload: { classes } }: PayloadAction<{ classes: IngredientClass[] }>) => {
      state.availableIngredientClasses = classes;
    },
  },
});

export default conceptGenerationV2Slice.reducer;
export const {
  setSelectedIngredients,
  setIngredientSlots,
  setMetricWeights,
  setTemplatesRows,
  setConceptGenerationConfig,
} = conceptGenerationV2Slice.actions;
