import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FC, ReactNode } from 'react';

import { GenericInputConfig, InputGenerationType, RunConfigurationFlowState } from '../types/common';

const initialState: RunConfigurationFlowState = {
  runId: '',
  runs: {},
  loading: false,
  error: null,
};

export const SLICE_PROJECT_WORKFLOW = 'project_workflow';
/* eslint-disable @typescript-eslint/no-unused-vars */
export const projectWorkFlowSlice = createSlice({
  name: SLICE_PROJECT_WORKFLOW,
  initialState,
  reducers: {
    reset: () => initialState,
    initRun: (state, action: PayloadAction<{ runId: string }>) => {
      const { runId } = action.payload;
      state.runs[runId] = {
        inputId: undefined,
        config: undefined,
        readOnly: false,
        loading: true,
      };
    },
    setSelectedRunId: (state, action: PayloadAction<string>) => {
      const runId = action.payload;
      state.runId = runId;
    },
    setRunError: (state, action: PayloadAction<{ runId: string; error: string | undefined }>) => {
      const { runId, error } = action.payload;
      if (state.runs[runId]) {
        state.runs[runId] = { ...state.runs[runId], error };
      }
    },
    setRunLoading: (state, action: PayloadAction<{ runId: string; loading: boolean }>) => {
      const { runId, loading } = action.payload;
      if (state.runs[runId]) {
        state.runs[runId] = { ...state.runs[runId], loading };
      }
    },
    setRunGenericInput: (
      state,
      action: PayloadAction<{
        inputId: string;
        config?: GenericInputConfig;
        runId: string;
        readOnly?: boolean;
      }>,
    ) => {
      const { inputId, config, runId, readOnly } = action.payload;
      const currentReadOnlyState = state.runs[runId]?.readOnly || false;
      state.runs[runId] = {
        inputId,
        config,
        readOnly: readOnly || currentReadOnlyState,
        loading: false,
      };
    },
    setInputType: (
      state,
      {
        payload: { inputType, runId, inputId = undefined },
      }: PayloadAction<{
        inputType: InputGenerationType;
        runId: string;
        inputId?: string;
      }>,
    ) => {
      if (runId) {
        if (!state.runs[runId]) {
          state.runs[runId] = {
            inputId,
            config: { input_type: inputType },
            readOnly: false,
            loading: false,
          };
        } else if (state.runs[runId].config) {
          const { config } = state.runs[runId];
          state.runs[runId] = {
            ...state.runs[runId],
            config: { ...config, input_type: inputType },
          };
        } else {
          state.runs[runId].config = { input_type: inputType };
        }
      }
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | ReactNode | FC | null>) => {
      state.error = action.payload;
    },
  },
});

export default projectWorkFlowSlice.reducer;
export const {
  initRun,
  reset,
  setInputType,
  setSelectedRunId,
  setLoading,
  setError,
  setRunLoading,
  setRunGenericInput,
  setRunError,
} = projectWorkFlowSlice.actions;
