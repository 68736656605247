import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SetErrorPayload, SetLoadingPayload } from '../../../store/types';
import { CoreIngredientType, DataSourceType, IngredientDBIngredientType, SensoryIngredientType } from '../../../types';
import { ChangeVersionPayload, DataSourceState, IngredientResponse } from './types';

export const SLICE_DATASOURCE_NAME = 'dataSources';

const initialState: DataSourceState = {
  coreVersion: '',
  socialPostsVersion: '',
  recipeVersion: '',
  trendsVersion: '',
  ingredientDbVersion: '',
  sensoryVersion: '',
  ingredients: [],
  ingredientsLoading: false,
  coreIngredients: [],
  coreIngredientsLoading: false,
  sensoryIngredients: [],
  sensoryIngredientsLoading: false,
  loading: false,
  error: null,
};

export const dataSourcesSlice = createSlice({
  name: SLICE_DATASOURCE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    changeCoreVersion: (state, action: PayloadAction<ChangeVersionPayload>) => {
      state.coreVersion = action.payload.version;
    },
    changeIngredientDbVersion: (state, action: PayloadAction<ChangeVersionPayload>) => {
      state.ingredientDbVersion = action.payload.version;
    },
    changeSensoryVersion: (state, action: PayloadAction<ChangeVersionPayload>) => {
      state.sensoryVersion = action.payload.version;
    },
    changeSocialPostsVersion: (state, action: PayloadAction<ChangeVersionPayload>) => {
      state.socialPostsVersion = action.payload.version;
    },
    changeRecipeVersion: (state, action: PayloadAction<ChangeVersionPayload>) => {
      state.recipeVersion = action.payload.version;
    },
    changeTrendsVersion: (state, action: PayloadAction<ChangeVersionPayload>) => {
      state.trendsVersion = action.payload.version;
    },
    setError: (state, action: PayloadAction<SetErrorPayload>) => {
      const { error } = action?.payload || {};
      state.error = error;
    },
    setIngredients: (
      state,
      action: PayloadAction<IngredientResponse> & {
        sourceType?: DataSourceType;
      },
    ) => {
      const { ingredients = [] } = (action?.payload || {}) as IngredientResponse;
      if (action?.sourceType === DataSourceType.Core) {
        state.coreIngredients = ingredients as CoreIngredientType[];
      } else if (action?.sourceType === DataSourceType.IngredientDB) {
        state.ingredients = ingredients as IngredientDBIngredientType[];
      } else if (action?.sourceType === DataSourceType.Sensory) {
        state.sensoryIngredients = ingredients as SensoryIngredientType[];
      }
    },
    setIngredientsLoading: (state, action: PayloadAction<{ loading: boolean; sourceType: DataSourceType }>) => {
      const { loading, sourceType } = action?.payload || {};
      if (sourceType === DataSourceType.Core) {
        state.coreIngredientsLoading = !!loading;
      } else if (sourceType === DataSourceType.Sensory) {
        state.sensoryIngredientsLoading = !!loading;
      } else if (sourceType === DataSourceType.IngredientDB) {
        state.ingredientsLoading = !!loading;
      }
    },
    setLoading: (state, action: PayloadAction<SetLoadingPayload>) => {
      const { loading } = action?.payload || {};
      state.loading = !!loading;
    },
  },
});

export default dataSourcesSlice.reducer;
export const {
  reset,
  setError,
  setIngredients,
  setIngredientsLoading,
  setLoading,
  changeCoreVersion,
  changeIngredientDbVersion,
  changeSensoryVersion,
  changeSocialPostsVersion,
  changeRecipeVersion,
  changeTrendsVersion,
} = dataSourcesSlice.actions;
