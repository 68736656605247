import AccountTreeIcon from '@mui/icons-material/AccountTree';
import Dashboard from '@mui/icons-material/Dashboard';
import MenuBook from '@mui/icons-material/MenuBook';
import Public from '@mui/icons-material/Public';
import Storage from '@mui/icons-material/Storage';
import Box from '@mui/material/Box';
import * as React from 'react';
import { FC, ReactElement, useState } from 'react';

import { isProduction } from '../shared/utils';
import MenuItemLink from './MenuItemLink';
import SubMenu from './SubMenu';

const openDocumentation = (url: string) => {
  window.open(url, '_blank');
};

interface MenuItem {
  id: string;
  name?: string;
  icon?: ReactElement;
  children?: MenuItem[];
  to?: string;
  onClick?: () => void;
}

/**
 * TODO: It is simply static. But in the future it could be dynamically populated via API with based on user permissions
 */
const menuItems: MenuItem[] = [
  {
    id: 'project',
    icon: <Public />,
    name: 'Project',
    children: [
      {
        id: 'project.manage',
        name: 'Manage',
        to: '/projects',
      },
      {
        id: 'project.contexts',
        name: 'Input contexts',
        to: '/contexts',
      },
      {
        id: 'project.jobs',
        name: 'Jobs',
        to: '/jobs',
      },
    ],
  },
  {
    id: 'dashboards',
    icon: <Dashboard />,
    name: 'Dashboards',
    children: [
      {
        id: 'dashboard.powerbi',
        name: 'PowerBI',
        onClick: () =>
          openDocumentation(
            'https://app.powerbi.com/groups/me/apps/4841bf8b-74fc-45be-b789-9bd76bbf2139/reports/7d04ba6f-581b-4fe9-a0bc-c5fb0c8ec80f/ReportSection?experience=power-bi',
          ),
      },
      {
        id: 'dashboard.aromaviz',
        name: 'Aroma Viz',
        to: '/data/aroma',
      },
    ],
  },
  {
    id: 'data',
    icon: <Storage />,
    name: 'Datasources',
    children: [
      {
        id: 'Datasources.Datasources',
        name: 'Datasources',
        to: '/data',
      },
    ],
  },
  {
    id: 'pipelines',
    icon: <AccountTreeIcon />,
    name: 'Pipelines',
    children: [
      {
        id: 'Pipelines.Airflow',
        name: 'Airflow',
        onClick: () =>
          openDocumentation(
            isProduction()
              ? 'https://airflow.internal.foodpairing.com'
              : 'https://airflow.internal.staging.foodpairing.com',
          ),
      },
      {
        id: 'Pipelines.Dagster',
        name: 'Dagster',
        onClick: () =>
          openDocumentation(
            isProduction()
              ? 'https://dagster.internal.foodpairing.com/assets'
              : 'https://dagster.internal.staging.foodpairing.com/assets',
          ),
      },
    ],
  },
  {
    id: 'docs',
    icon: <MenuBook />,
    name: 'Documentation',
    children: [
      {
        id: 'docs.cfiTactical',
        name: 'SOP CFI Tactical',
        onClick: () =>
          openDocumentation('https://staging-fp.atlassian.net/wiki/spaces/SD/pages/171835469/SOP%3A+Tactical+CFI'),
      },
      {
        id: 'docs.cfiCreateContext',
        name: 'SOP Create Context and versions',
        onClick: () =>
          openDocumentation(
            'https://staging-fp.atlassian.net/wiki/spaces/SD/pages/191496215/SOP+Create+a+context+and+versions',
          ),
      },
      {
        id: 'docs.cfiConceptSelection',
        name: 'SOP Concept Selection for trial model',
        onClick: () =>
          openDocumentation(
            'https://staging-fp.atlassian.net/wiki/spaces/SD/pages/186384385/SOP+Concept+selection+for+trial+modeling',
          ),
      },
      {
        id: 'docs.cfiSurveyCreation',
        name: 'SOP Survey Creation and launch for LEP project',
        onClick: () =>
          openDocumentation(
            'https://staging-fp.atlassian.net/wiki/spaces/SD/pages/179634188/SOP+Survey+creation+and+launch+for+LEP+projects',
          ),
      },
      {
        id: 'docs.cfiResultProcessing',
        name: 'SOP Result Processing',
        onClick: () =>
          openDocumentation(
            'https://staging-fp.atlassian.net/wiki/spaces/SD/pages/194904084/SOP+Survey+Results+Processing',
          ),
      },
      {
        id: 'docs.cfiConceptSelection',
        name: 'SOP Concept Selection for LEP',
        onClick: () =>
          openDocumentation(
            'https://staging-fp.atlassian.net/wiki/spaces/SD/pages/187138056/SOP+Concept+Selection+for+LEP',
          ),
      },
    ],
  },
];

interface MenuProps {
  onMenuClick: () => void;
  dense?: boolean;
}

const Menu: FC<MenuProps> = ({ onMenuClick, dense = false }) => {
  const [state, setState] = useState<{
    expandedMenus: { [key: string]: boolean };
  }>({
    expandedMenus: {},
  });

  const open = true;

  const handleToggle = (menuName: string) => {
    if (state.expandedMenus[menuName] === undefined) state.expandedMenus[menuName] = false;
    state.expandedMenus[menuName] = !state.expandedMenus[menuName];
    setState({ expandedMenus: state.expandedMenus });
  };

  const buildMenuItem = (items: MenuItem[], subs?: boolean): ReactElement => (
    <>
      {items.map((item) => {
        if (Array.isArray(item.children) && item.children.length) {
          return (
            <SubMenu
              dense={dense}
              handleToggle={() => handleToggle(item.id)}
              icon={item.icon}
              isOpen={state.expandedMenus[item.id] || false}
              name={item.name || ''}
              sidebarIsOpen={open}
              key={item.name || item.id}
            >
              {buildMenuItem(item.children, true)}
            </SubMenu>
          );
        }

        return (
          <MenuItemLink
            to={item.to || ''}
            primaryText={item.name}
            leftIcon={item.icon}
            onClick={item.onClick ? item.onClick : onMenuClick}
            dense={dense}
            key={item.name || item.id}
            subMenu={subs}
          />
        );
      })}
    </>
  );

  return <Box mt={1}>{buildMenuItem(menuItems)}</Box>;
};

Menu.defaultProps = {
  dense: false,
};

export default Menu;
