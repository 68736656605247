import axios, { AxiosResponse } from 'axios';
import orderBy from 'lodash/orderBy';
import React, { PropsWithChildren, useCallback, useEffect, useMemo } from 'react';

import { buildActionUrl, TYPE_DATASOURCES_VERSIONS } from '../shared/url';
import { DataVersionsType } from '../types';

export enum DataSourcesActions {
  RELOAD_VERSIONS = 'reloadVersions',
  SET_VERSIONS = 'setVersions',
}

export interface DataSourcesContextState {
  versions: DataVersionsType | null;
  loading: boolean;
}

export type DataSourcesAction =
  | {
      type: DataSourcesActions.RELOAD_VERSIONS;
    }
  | { type: DataSourcesActions.SET_VERSIONS; versions: DataVersionsType };

interface DataSourcesProviderContext {
  versions: DataVersionsType;
  loading: boolean;
}

const DataSourcesInitialState: DataSourcesContextState = {
  versions: null,
  loading: true,
};

type Actions = DataSourcesAction;

const DataSourcesContext = React.createContext<DataSourcesProviderContext | undefined>(undefined);

const DataSourcesReducer = (state: DataSourcesContextState, action: Actions) => {
  switch (action.type) {
    case DataSourcesActions.RELOAD_VERSIONS:
      return { ...state };
    case DataSourcesActions.SET_VERSIONS: {
      const versions = { ...action.versions };

      Object.keys(versions).forEach((k) => {
        const key = k as keyof typeof versions;
        const isNumber = key.includes('version') || (versions[key]?.[0] && !Number.isNaN(Number(versions[key][0])));
        versions[key] = orderBy(versions[key], (v) => (isNumber && Number(v)) || v, [isNumber ? 'desc' : 'asc']);
      });

      return { ...state, versions };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
};

const initialDataVersions: DataVersionsType = {
  coreVersions: [],
  recipeVersions: [],
  socialPostVersions: [],
  trendsVersions: [],
  availableCountries: [],
  ingredientDbVersions: [],
  sensoryVersions: [],
  dishTypesVersions: [],
};

const DataSourcesProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const [state, dispatch] = React.useReducer(DataSourcesReducer, DataSourcesInitialState);

  const memoizedVersions = useMemo(
    () =>
      state.versions ? { versions: state.versions, loading: false } : { versions: initialDataVersions, loading: true },
    [state.versions],
  );

  const fetchDataSources = useCallback(async (): Promise<void> => {
    const url = buildActionUrl({}, TYPE_DATASOURCES_VERSIONS);
    const versionsResponse = await axios.get(url).then((res: AxiosResponse) => res.data);
    dispatch({
      type: DataSourcesActions.SET_VERSIONS,
      versions: versionsResponse,
    });
  }, []);

  useEffect(() => {
    fetchDataSources().then(() => null);
  }, [fetchDataSources]);

  return <DataSourcesContext.Provider value={memoizedVersions}>{children}</DataSourcesContext.Provider>;
};

const useDataSources = (): DataSourcesProviderContext => {
  const context = React.useContext(DataSourcesContext);
  if (context === undefined) {
    throw new Error('useDataSources must be used within a DataSourcesProvider');
  }
  return context;
};

export { DataSourcesProvider, useDataSources };
