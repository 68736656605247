import { GenericInputConfig } from './common';

export const SLOTS_MAX_NUM = 5;

export enum IngredientType {
  base1 = 'BASE1',
  base2 = 'BASE2',
  base3 = 'BASE3',
  base4 = 'BASE4',
  base5 = 'BASE5',
  other = 'Others',
}

export type IngredientClass = string & IngredientType;

export type Composition = [
  IngredientClass?,
  IngredientClass?,
  IngredientClass?,
  IngredientClass?,
  IngredientClass?,
  IngredientClass?,
];

export interface ConceptGenerationConfigResponse {
  inputId: string;
  config?: ConceptGenerationConfigV2;
}

export enum ConceptGenerationWFStepsV2 {
  SelectIngredients = 0,
  OverrideIngredientClass = 1,
  CompositionTemplate = 2,
  MetricWeights = 3,
}

export enum ConceptGenerationWFStepsV3 {
  SelectIngredients = 0,
  SensoryRemapping = 1,
  ConceptTemplates = 2,
}

export enum SingleMetricIngredientQuery {
  ingredient_id = 'ingredient_id',
  ingredient_name = 'ingredient_name',
  class = 'class',
  is_base_ingredient = 'is_base_ingredient',
  sensory_remap_to = 'sensory_remap_to',
  sensory_remap_created_at = 'sensory_remap_created_at',
  sensory_remap_project_id = 'sensory_remap_project_id',
}

export interface SelectedIngredient {
  selected: boolean;
  ingredient_id: number;
  ingredient_name: string;
  class: IngredientClass;
  is_base_ingredient?: boolean;
  sensory_remap_to?: number;
  sensory_remap_created_at?: number;
  sensory_remap_project_id?: string;
}

// using snake_case to match the backend responses
export type MetricWeights = {
  popularity_contexts: number;
  popularity_combo: number;
  associativity_contexts: number;
  associativity_combo: number;
  trends_country: number;
  foodpairing_match: number;
};

export interface CompositionConfig {
  slots: Composition;
  category: IngredientClass;
}

export interface ConceptGenerationConfigV2 extends GenericInputConfig {
  ingredients: SelectedIngredient[];
  ingredient_slots: number;
  compositions: CompositionConfig[];
  weights: MetricWeights;
}

export type SensoryRemapping = Map<number, number>;

export interface ConceptTerritoryDefinition {
  name: string;
  version: number;
  territories: string[];
}

export interface ConceptTerritoryDefinitionOption {
  name: string;
  version: number;
}

/**
 * Concept territory definition as used in the frontend
 */
export interface ConceptTemplate {
  ingredientSlots: number[];
  territories: string[];
}

/**
 * Concept territory definition as used in the API
 */
export interface APIConceptTemplate {
  bases: { values: number[] }[];
  concept_territory_names: string[];
  no_free_ingredients: number;
}

/**
 * V3 of Concept Generation Configuration
 * fields are snake_case to match the backend responses
 */
export interface ConceptGenerationConfigV3 extends GenericInputConfig {
  ingredients: number[];
  sensory_remapping: {
    [key: number]: number;
  };
  concept_territories_definition_name: string;
  concept_territories_definition_version: number;
  templates: APIConceptTemplate[];
}

export const freeIngredientSlot = {
  ingredientId: 0,
  name: 'Free slot',
};

export const emptyIngredientSlot = {
  ingredientId: -9999,
  name: 'Empty slot',
};

export interface APIConceptTerritoryDefinitionResponse {
  definitions: {
    name: string;
    version: number;
    definition: { territories: { name: string }[] };
  }[];
}
