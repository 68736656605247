import DeleteIcon from '@mui/icons-material/Delete';
import { MenuItem } from '@mui/material';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import React, { FC, memo, useCallback, useState } from 'react';

import { useSelectIngredientDialog } from '../../components/SelectIngredientDialog';
import { DotGraphFormRecord } from '../../features/aroma/types';
import { buildActionUrl, TYPE_LIST_AROMA_PROFILES } from '../../shared/url';
import { ingredientToString } from '../../shared/utils';
import { CoreIngredientType } from '../../types';

interface DotGraphFormRowProps {
  autoFocusOnNewRow: boolean;
  loading: boolean;
  row: DotGraphFormRecord;
  handleRemoveRow: (position: number) => void;
  coreIngredients: CoreIngredientType[];
  coreIngredientsStr: string[];
  required?: boolean;
  label?: string;
  version?: string;
  selectIngredientAfterCallback: (row?: DotGraphFormRecord) => void;
}

const DotGraphFormRow: FC<DotGraphFormRowProps> = ({
  autoFocusOnNewRow,
  loading,
  row,
  handleRemoveRow,
  coreIngredients,
  coreIngredientsStr,
  required,
  label,
  version,
  selectIngredientAfterCallback,
}) => {
  const [id, setId] = useState<number | undefined>(row.id);
  const [name, setName] = useState<string>(row.name || '');

  const handleChangeBaseProduct = useCallback(
    (values: string[]) => {
      const ingredients = values.map((value) => coreIngredients[coreIngredientsStr.indexOf(value)]);
      const { productPreparationId, name: ingredientName = '' } = ingredients[0] || {};

      setId(Number(productPreparationId));
      setName(String(ingredientName));
      const url = buildActionUrl({ version, productPreparationId }, TYPE_LIST_AROMA_PROFILES);

      axios.get(url).then((res) => {
        selectIngredientAfterCallback({
          ...row,
          id: productPreparationId,
          name: ingredientName,
          aromaTypes: [],
          variants: res.data.profile,
          selectedVariant: res.data.profile?.[0],
        });
      });
    },
    [coreIngredients, coreIngredientsStr, row, selectIngredientAfterCallback, version],
  );

  const { dialog, openDialog } = useSelectIngredientDialog(
    false,
    coreIngredientsStr,
    [
      (id &&
        ingredientToString<Partial<DotGraphFormRecord>>('id')({
          id: Number(id),
          name,
        })) ||
        '',
    ].filter(String),
    handleChangeBaseProduct,
    'Select ingredient',
  );

  return (
    <Grid container={true} key={row.rowPosition} style={{ display: 'flex', margin: 10 }}>
      <Grid item={true}>
        <Typography>{label}</Typography>
        <Select
          autoFocus={autoFocusOnNewRow}
          style={{ minWidth: 160, height: '40px' }}
          label={label ? `${label} ID` : ''}
          required={required}
          disabled={loading}
          input={<Input id="select-baseProducts-input" />}
          open={false}
          onOpen={openDialog}
          value={id || ''}
          renderValue={(selected: unknown) => <Chip key={String(selected)} label={<>{`${selected} ${name}`}</>} />}
        >
          {id && <option value={id || ''}>{name}</option>}
        </Select>
      </Grid>
      <Grid item={true} style={{ marginLeft: 16 }}>
        <Typography>Variant</Typography>
        <Select
          style={{ minWidth: 160, height: '40px' }}
          input={<Input id="select-baseProductVariant-input" />}
          disabled={loading}
          value={row.selectedVariant?.name || ''}
          onChange={(event) => {
            const variant = row.variants?.filter((p) => p?.name === event.target.value)[0];
            selectIngredientAfterCallback({ ...row, selectedVariant: variant });
          }}
          renderValue={(selected: unknown) => <Chip key={String(selected)} label={<>{selected}</>} />}
        >
          {row.variants &&
            row.variants
              .filter((p) => p.name)
              .map((test) => (
                <MenuItem key={test.name} value={test.name}>
                  {test.name}
                </MenuItem>
              ))}
        </Select>
      </Grid>
      <Grid item={true} style={{ display: 'flex', alignItems: 'flex-end' }}>
        <IconButton onClick={() => handleRemoveRow(row.rowPosition)}>
          <DeleteIcon />
        </IconButton>
      </Grid>
      {dialog}
    </Grid>
  );
};

DotGraphFormRow.defaultProps = { required: false, label: '', version: '' };

export default memo(
  DotGraphFormRow,
  (prev, current) => prev.row.id === current.row.id && prev.loading === current.loading,
);
