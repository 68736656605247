import '../../../layout/common.scss';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import React, { memo, useState } from 'react';

import WarningIcon from '../../../components/WarningIcon';
import { RecipeFiltersType, RecipeSelectionType } from '../../../types';
import { RecipeAction, RecipeActions } from '../reducer';

type RecipeTagSelectionProps = {
  recipeFilters: RecipeFiltersType;
  recipeSelections: RecipeSelectionType[];
  dispatch: (action: RecipeAction) => void;
  disabled: boolean;
};

const RecipeTagSelection: React.FC<RecipeTagSelectionProps> = ({
  recipeFilters,
  recipeSelections,
  dispatch,
  disabled,
}) => {
  const [currentRow, setCurrentRow] = useState({
    dishType: '',
    regionType: '',
    tasteType: '',
  } as RecipeSelectionType);

  const handleChange = (name: string) => (value: string) => {
    setCurrentRow({ ...currentRow, [name]: value });
  };

  const saveRow = () => {
    if (currentRow.dishType) {
      dispatch({ type: RecipeActions.add, currentRow });
      setCurrentRow({
        dishType: '',
        regionType: '',
        tasteType: '',
      });
    }
  };

  const { dishTypes = [], regionTypes = [], tasteTypes = [] } = recipeFilters;

  return (
    <Grid container={true} spacing={2} justifyContent="center" alignItems="center" className="common__root">
      <Grid item={true}>
        <Paper className="common__root">
          <Table className="common__transferListTable">
            <TableHead>
              <TableRow>
                <TableCell>Dish type</TableCell>
                <TableCell>Region type</TableCell>
                <TableCell>Taste type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key="new-row">
                <TableCell>
                  <Autocomplete
                    fullWidth={true}
                    disabled={disabled}
                    value={currentRow.dishType || null}
                    options={dishTypes.sort()}
                    getOptionLabel={(option) => option}
                    onChange={(e, value: string | null) => handleChange('dishType')(value || '')}
                    renderInput={(params) => <TextField {...params} label="Dish" variant="outlined" />}
                  />
                </TableCell>
                <TableCell>
                  <Autocomplete
                    fullWidth={true}
                    disabled={disabled}
                    value={currentRow.regionType || null}
                    options={regionTypes.sort()}
                    getOptionLabel={(option) => option}
                    onChange={(e, value: string | null) => handleChange('regionType')(value || '')}
                    renderInput={(params) => <TextField {...params} label="Region" variant="outlined" />}
                  />
                </TableCell>
                <TableCell>
                  <Autocomplete
                    disabled={disabled}
                    value={currentRow.tasteType || null}
                    options={tasteTypes.sort()}
                    getOptionLabel={(option) => option}
                    fullWidth={true}
                    onChange={(e, value: string | null) => handleChange('tasteType')(value || '')}
                    renderInput={(params) => <TextField {...params} label="Taste" variant="outlined" />}
                  />
                </TableCell>
                <TableCell align="right">
                  <IconButton onClick={saveRow} disabled={disabled}>
                    <AddIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
              {recipeSelections.map((row: RecipeSelectionType, i: number) => (
                <TableRow key={row.dishType + row.regionType + row.tasteType}>
                  <TableCell>
                    <Box display="flex">
                      {row.dishType && !dishTypes.includes(row.dishType) && <WarningIcon />} {row.dishType}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex">
                      {row.regionType && !regionTypes.includes(row.regionType) && <WarningIcon />} {row.regionType}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex">
                      {row.tasteType && !tasteTypes.includes(row.tasteType) && <WarningIcon />} {row.tasteType}
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton disabled={disabled} onClick={() => dispatch({ type: RecipeActions.remove, index: i })}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default memo(RecipeTagSelection);
