import '../layout/common.scss';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

const PageTitle: React.FC<{
  title: string;
  action?: React.ReactNode;
  rightActions?: React.ReactNode;
}> = ({ title, action, rightActions }) => (
  <Box display="flex">
    <Box sx={{ flexGrow: 1 }} className="common__pageTitle">
      <Typography variant="h4" color="textPrimary">
        <strong> {title} </strong>
        {action}
      </Typography>
    </Box>
    {rightActions && <Box>{rightActions}</Box>}
  </Box>
);

PageTitle.defaultProps = {
  action: undefined,
  rightActions: undefined,
};

export default PageTitle;
