import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import React, { FC, useEffect, useRef, useState } from 'react';

import { ConceptGenerationWFVersion } from '../_utils/types';

export interface ConfigureButton {
  buttonText: string;
  version: ConceptGenerationWFVersion;
  callback: () => void;
}

const ConfigureButtonGroup: FC<{
  options: ConfigureButton[];
  disabled: boolean;
  disableDropDown: boolean | undefined;
  selectedVersion: ConceptGenerationWFVersion;
  setSelectedVersion: (version: ConceptGenerationWFVersion) => void;
}> = ({ options, disabled, disableDropDown, selectedVersion, setSelectedVersion }) => {
  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedOption, setSelectedOption] = useState<ConfigureButton>(options[0]);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    version: ConceptGenerationWFVersion,
  ) => {
    const option = options.find((o) => o.version === version);
    if (option) {
      setSelectedOption(option);
      setSelectedVersion(version);
      setOpen(false);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  /**
   * This effect is triggered when the selected version changes in the parent
   * this is used to set the selected option that was saved in the backend.
   */
  useEffect(() => {
    const option = options.find((o) => o.version === selectedVersion);
    if (option) {
      setSelectedOption(option);
    }
  }, [selectedVersion, setSelectedOption, options]);

  return (
    <>
      <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button" disabled={disabled}>
        <Button onClick={selectedOption.callback}>{selectedOption.buttonText}</Button>
        <Button
          size="small"
          disabled={disableDropDown}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select concept generation configuration style"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition={true}
        disablePortal={true}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem={true}>
                  {options.map((option) => (
                    <MenuItem
                      key={option.version}
                      selected={selectedOption.version === option.version}
                      onClick={(event) => handleMenuItemClick(event, option.version)}
                    >
                      {option.buttonText}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default ConfigureButtonGroup;
