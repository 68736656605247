import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import React, { MouseEvent, useCallback, useEffect } from 'react';

import { useFetchWithAuth } from '../../../../auth';
import Loading from '../../../../components/Loading';
import Config from '../../../../features/project/components/Config';
import { JobNotification } from '../../../../features/project/components/JobNotification';
import useWatchJob from '../../../../features/project/hooks/watchJob';
import { useProjectReducer } from '../../../../features/project/reducer';
import { buildActionUrl, TYPE_PROJECT_ID, TYPE_UPDATE_PROJECT } from '../../../../shared/url';
import { IngredientDBIngredientType, JobType, ProjectStatus, ProjectType } from '../../../../types';
import { FlowActions } from '../_utils/reducer';
import { ProjectFlowStepProps } from '../_utils/types';

const ProjectConfiguration: React.FC<ProjectFlowStepProps> = ({ project, dispatch }) => {
  const projectId = project?.projectId || '';
  const projectPath = buildActionUrl({ projectId }, TYPE_PROJECT_ID);
  const fetchWithAuth = useFetchWithAuth();
  const isDataLoading = React.useRef(false);

  const [configState, configDispatch] = useProjectReducer();
  const { outputs } = configState.project;
  const provisionOutput = outputs.provision || { jobId: null };

  const updateJobStatus = useCallback(
    (outputName: string, jobInfo: JobType) => {
      configDispatch({
        type: 'UpdateJobStatus',
        outputName,
        jobStatus: jobInfo,
      });
    },
    [configDispatch],
  );

  const { jobNotification, handleCloseJobNotification } = useWatchJob(
    projectId,
    'provision',
    provisionOutput.jobId,
    provisionOutput.status,
    updateJobStatus,
  );

  const configureProject = async (config: ProjectType) => {
    const { error, data: newProject } = await fetchWithAuth(buildActionUrl({ projectId }, TYPE_UPDATE_PROJECT), {
      method: 'POST',
      body: JSON.stringify(config),
    });
    if (!error) {
      configDispatch({ type: 'UpdateProjectData', data: newProject.project });
    } else {
      configDispatch({ type: 'SubmitError', error });
    }
  };

  const submitConfig = async (event: MouseEvent) => {
    event.preventDefault();
    await configureProject(configState.project);
  };

  const updateField = useCallback(
    (name: string, value: IngredientDBIngredientType[] | null | string | string[]) => {
      if (value !== null) {
        if (name.substr(0, 6) === 'brief.') {
          configDispatch({
            type: 'UpdateProjectBriefField',
            name: name.slice(6),
            value,
          });
          return;
        }

        configDispatch({ type: 'UpdateProjectConfigField', name, value });
      }
    },
    [configDispatch],
  );

  const initialize = useCallback(async () => {
    const { error, data: initialProject } = await fetchWithAuth(projectPath, {});

    if (!error) {
      configDispatch({ type: 'LoadProjectData', data: initialProject.project });
    } else {
      configDispatch({ type: 'FetchError', error });
    }
  }, [fetchWithAuth, projectPath, configDispatch]);

  useEffect(() => {
    if (!projectId || (isDataLoading.current && !configState.reloadRequired)) {
      return;
    }

    isDataLoading.current = true;
    initialize().then(() => null);
  }, [initialize, configState.reloadRequired, projectId]);

  useEffect(() => {
    dispatch({ type: FlowActions.setLoading, loading: false });
  }, [dispatch]);

  useEffect(() => {
    dispatch({
      type: FlowActions.setNavigationButtonStatusCallback,
      callback: () => ({
        next: configState.project.status && configState.project.status === ProjectStatus.Provisioned,
        back: true,
      }),
    });
    dispatch({
      type: FlowActions.setApplyButton,
      button: (
        <>
          <Button
            data-id-cypress="configureProjectApplyButton"
            style={{ marginRight: 10 }}
            disabled={!configState.configChanged || configState.project.status === ProjectStatus.Locked}
            variant="contained"
            color="secondary"
            onClick={submitConfig}
          >
            Apply
            {configState.project.status === ProjectStatus.Provisioning && (
              <Loading message=" " size="1rem" marginLeft={10} />
            )}
          </Button>
        </>
      ),
    });
    // eslint-disable-next-line
  }, [configState, dispatch]);

  if (!project) return <Loading />;

  return (
    <>
      <JobNotification job={jobNotification} handleClose={handleCloseJobNotification} projectId={projectId} />

      {configState.project.status === ProjectStatus.Provisioning && (
        <Box m={2}>
          <Card variant="outlined">
            <Box p={2}>
              <Loading message="Provisioning project..." />
            </Box>
          </Card>
        </Box>
      )}

      <Config
        project={configState.project}
        projectId={configState.project.projectId}
        updateField={updateField}
        dispatchError={() => null}
        provisionOutput={provisionOutput}
        submitConfig={configureProject}
      />
    </>
  );
};

export default ProjectConfiguration;
