import '../../../../layout/common.scss';

import { WarningOutlined } from '@mui/icons-material';
import Add from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DataManRecordsType } from '../../../../components/dataman/types';
import SectionTitle from '../../../../components/SectionTitle';
import { changeIngredientDbVersion, changeSensoryVersion } from '../../../../features/datasources/store';
import { DocLink, DocLinkUrl, DocTypeEnum } from '../../../../features/docs';
import {
  setIngredientsLoadingStatus,
  setRunGenericInput,
  SLICE_CONCEPT_GENERATION,
} from '../../../../features/project/store';
import { BackendLoadingStatus, InputGenerationType } from '../../../../features/project/types/common';
import { getConceptGenerationConfig, getSingleMetricIngredients } from '../../../../features/project/utils';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { DataSourceType } from '../../../../types';
import CreateRunDialog from '../_components/CreateRunDialog';
import { createRun, duplicateRun, getAllRuns } from '../_utils/datasources';
import { FlowActions } from '../_utils/reducer';
import { JobStatus, ProjectFlowStepProps, RunType, RunTypeWithInputId } from '../_utils/types';
import ConceptGenerationRunTable from './_components/ConceptGenerationRunTable';
import prepareRecords from './_utils/prepare-records';
import { ConceptGenerationDatamanRecord, ShowConceptGenWorkflow } from './_utils/types';
import RunConfigFlowV2 from './ConfigureConceptGenerationRun/RunConfigFlowV2';
import RunConfigFlowV3 from './ConfigureConceptGenerationRun/RunConfigFlowV3';

const ConceptGeneration: React.FC<ProjectFlowStepProps> = ({
  project,
  runs,
  simulateFail = false,
  phaseId,
  dispatch,
  isPhaseReadOnly,
  stepDescription,
  stepTitle,
}) => {
  const reduxDispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const {
    showWorkflow: showConfigurationWorkflow,
    inputTable: loadedTable,
    ingredientsLoadingStatus,
  } = useAppSelector((store) => store[SLICE_CONCEPT_GENERATION]);
  const [loading, setLoadingStatus] = useState<boolean>(false);
  const [runNameDialogOpen, setRunNameDialogOpen] = useState<boolean>(false);
  const [dataManData, setDataManData] = useState<DataManRecordsType<ConceptGenerationDatamanRecord>>([]);
  const projectId = project ? project.projectId : undefined;
  const inputTable: string | undefined =
    project?.outputGenerationByRunId?.jobOutput?.resources?.metrics_calculation_single?.tables?.tables[0]?.tableName;
  const oneOutputsGenerated = useMemo(() => {
    let oneGenerated = false;

    runs?.forEach((run) => {
      if (run?.outputs?.output_generation?.status === JobStatus.ok) {
        oneGenerated = true;
      }
    });

    return oneGenerated;
  }, [runs]);
  const nextButtonDisabled = loading || !runs?.length || !oneOutputsGenerated;
  const [sensoryVersion, setSensoryVersion] = useState<string | undefined>(undefined);
  const [ingredientDbVersion, setIngredientDbVersion] = useState<string | undefined>(undefined);
  const [runLoader, setRunLoader] = useState<boolean>(false);

  const setLoading = useCallback(
    (isLoading: boolean) => {
      dispatch({ type: FlowActions.setLoading, loading: isLoading });
      setLoadingStatus(isLoading);
    },
    [dispatch],
  );

  const addNewRun = (result?: RunTypeWithInputId | null) => {
    if (result) {
      setRunLoader(true);
      const newRun: RunType = result.run;
      const newRuns = runs ? [...runs, newRun] : [newRun];
      dispatch({
        type: FlowActions.setRuns,
        runs: newRuns,
        selectedRun: result.run,
      });
      appDispatch(
        setRunGenericInput({
          inputId: result.inputId,
          config: { input_type: InputGenerationType.Wizard },
          runId: result.run.runId,
        }),
      );
    }
    setLoading(false);
  };

  const createNewRun = (runName?: string) => {
    if (!phaseId) return;

    setLoading(true);
    createRun(project?.projectId, phaseId, runName || '').then(addNewRun);
    setRunNameDialogOpen(false);
  };

  const createDuplicateRun = (sourceRunId: string, runName?: string) => {
    if (!phaseId) return;
    setRunLoader(true);
    setLoading(true);
    duplicateRun(project?.projectId, phaseId, sourceRunId, runName || '').then(addNewRun);
    setRunNameDialogOpen(false);
  };

  const updateRun = useCallback(
    async (selectedRun: RunType) => {
      const updatedRuns = (runs || []).map((r) => (r.runId === selectedRun.runId ? selectedRun : r));
      if (JSON.stringify(updatedRuns) !== JSON.stringify(runs)) {
        dispatch({
          type: FlowActions.setRuns,
          runs: updatedRuns,
          selectedRun,
          lastRun: null,
        });
      }
    },
    [dispatch, runs],
  );

  useEffect(() => {
    dispatch({ type: FlowActions.setApplyButton, button: null });
  }, [dispatch]);

  const refreshRuns = useCallback(async () => {
    const newRuns = await getAllRuns(projectId, phaseId);

    dispatch({ type: FlowActions.setRuns, runs: newRuns || [] });
    setLoading(false);
  }, [projectId, phaseId, dispatch, setLoading]);

  useEffect(() => {
    dispatch({
      type: FlowActions.setNavigationButtonStatusCallback,
      callback: () => ({ next: !nextButtonDisabled, back: true }),
    });
    dispatch({ type: FlowActions.setLoading, loading: false });
  }, [dispatch, nextButtonDisabled]);

  /**
   * This effect loads the single metrics ingredients for the current phase in the background
   * This table is the same for all runs, so we can load it only once
   */
  useEffect(() => {
    if (ingredientsLoadingStatus === BackendLoadingStatus.Initial && projectId && inputTable) {
      if (loadedTable !== inputTable) {
        getSingleMetricIngredients(reduxDispatch, projectId, inputTable);
      } else {
        reduxDispatch(setIngredientsLoadingStatus(BackendLoadingStatus.Loaded));
      }
    }
  }, [reduxDispatch, projectId, inputTable, ingredientsLoadingStatus, loadedTable]);

  // prepare data man records
  useEffect(() => {
    if (runs) {
      setDataManData(prepareRecords(runs));
      runs.forEach((run) => {
        getConceptGenerationConfig(reduxDispatch, run.runId, run.phaseId, run.projectId, run.readOnly);
      });
    }
  }, [runs, reduxDispatch]);

  /**
   * This effect makes sure that the correct sensory version is loaded into local storage
   * we only want to load the sensory version data once
   * for that reason, sensoryVersion is kept in a state variable
   */
  useEffect(() => {
    if (!sensoryVersion && project?.sensoryVersion) {
      setSensoryVersion(project?.sensoryVersion);
      reduxDispatch(
        changeSensoryVersion({
          version: project.sensoryVersion,
          sourceType: DataSourceType.Sensory,
        }),
      );
    }
  }, [reduxDispatch, sensoryVersion, project?.sensoryVersion]);

  /**
   * This effect makes sure that the correct ingredientdb version is loaded into local storage
   */
  useEffect(() => {
    if (!ingredientDbVersion && project?.ingredientDbVersion) {
      setIngredientDbVersion(project?.ingredientDbVersion);
      reduxDispatch(
        changeIngredientDbVersion({
          version: project.ingredientDbVersion,
          sourceType: DataSourceType.IngredientDB,
        }),
      );
    }
  }, [reduxDispatch, ingredientDbVersion, project?.ingredientDbVersion]);

  return (
    <Box>
      {showConfigurationWorkflow === ShowConceptGenWorkflow.showV2 && (
        <RunConfigFlowV2 phaseId={phaseId || ''} projectId={projectId || ''} />
      )}
      {showConfigurationWorkflow === ShowConceptGenWorkflow.showV3 && (
        <RunConfigFlowV3 phaseId={phaseId || ''} projectId={projectId || ''} isPhaseReadOnly={isPhaseReadOnly} />
      )}

      {showConfigurationWorkflow === ShowConceptGenWorkflow.hide && (
        <>
          <Box display={'flex'}>
            <SectionTitle
              title={stepTitle}
              action={
                <>
                  <DocLink link={DocLinkUrl[DocTypeEnum.ConceptGenerationStep]} />
                  <Tooltip title="Create or duplicate a run">
                    <>
                      <Button
                        disabled={isPhaseReadOnly}
                        onClick={() => setRunNameDialogOpen(true)}
                        data-id-cypress="addNewRunButton"
                      >
                        <Add />
                      </Button>
                    </>
                  </Tooltip>
                </>
              }
              useCommonStyles={true}
            />
            {isPhaseReadOnly && (
              <Box marginLeft={1} display={'flex'} alignItems={'center'} marginBottom={1.5}>
                <Typography
                  className="WithWarning"
                  color={'error'}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <WarningOutlined />
                  This phase is read-only
                </Typography>
              </Box>
            )}
          </Box>
          <Box display="flex" flexDirection="row">
            {/* Generate Concepts */}
            <Box>
              <Typography>{stepDescription}</Typography>
            </Box>
            <Box>
              <div style={{ padding: 2 }} />
            </Box>
          </Box>
          <ConceptGenerationRunTable
            records={dataManData}
            loading={loading}
            updateView={refreshRuns}
            updateRun={updateRun}
            runs={runs}
            simulateFail={simulateFail}
            project={project}
            isPhaseReadOnly={isPhaseReadOnly}
          />
          <CreateRunDialog
            open={runNameDialogOpen}
            onClose={() => setRunNameDialogOpen(false)}
            onSubmit={(newRunName) => createNewRun(newRunName || '')}
            onDuplicate={(newRunName, sourceRunId) => createDuplicateRun(sourceRunId, newRunName || '')}
            runsList={runs}
            defaultRun={runs ? runs[runs.length - 1] : undefined}
            loading={runLoader}
          />
        </>
      )}
    </Box>
  );
};

export default memo(ConceptGeneration);
