import './MenuItemLink.scss';

import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useLayout } from '../context/LayoutContext';

interface MenuItemProps {
  to: string;
  primaryText?: string;
  leftIcon?: JSX.Element;
  onClick?: () => void;
  dense: boolean;
  subMenu?: boolean;
}

const MenuItemLink: React.FC<MenuItemProps> = ({ to, primaryText, leftIcon, onClick, dense, subMenu }) => {
  const history = useNavigate();
  const location = useLocation();
  const { setPageTitle } = useLayout();

  return (
    <MenuItem
      style={subMenu ? { paddingLeft: 60 } : {}}
      dense={dense}
      onClick={() => {
        if (to !== location.pathname) setPageTitle('');
        if (to) history(to);
        if (onClick) onClick();
      }}
    >
      {leftIcon && <ListItemIcon className="MenuItemLink__icon">{leftIcon}</ListItemIcon>}
      <ListItemText primary={primaryText} className="MenuItemLink__text" />
    </MenuItem>
  );
};

MenuItemLink.defaultProps = {
  primaryText: undefined,
  leftIcon: undefined,
  onClick: undefined,
  subMenu: undefined,
};

export default MenuItemLink;
