import './ExternalLink.scss';

import { OpenInNew } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';

import { openUrlInNewWindow } from '../shared/utils';

const ExternalLink: React.FC<{
  url: string;
  title: string;
  style?: any;
  size?: 'normal' | 'small';
}> = ({ url, title, style, size = 'normal' }) => {
  const linkStyle = { ...style };

  if (size === 'small') {
    linkStyle.marginTop = 0;
    linkStyle.fonSize = '8px';
    linkStyle.padding = '4px 6px';
  }

  return (
    <Box
      data-id-cypress="externalLink"
      onClick={() => openUrlInNewWindow(url)}
      className="ExternalLink__jobHandlerLinkContainer"
      style={linkStyle}
    >
      <Box style={{ flexGrow: 1 }}>{title}</Box>
      <Box>
        <OpenInNew />
      </Box>
    </Box>
  );
};

ExternalLink.defaultProps = {
  style: undefined,
  size: 'normal',
};

export default ExternalLink;
