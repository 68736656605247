import './CorrectedIngredientAutoComplete.scss';

import { FilterOptionsState } from '@mui/base/useAutocomplete';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';
import { matchSorter } from 'match-sorter';
import React, { FC, memo, useCallback, useState } from 'react';

import { DBIngredientFields, GenericIngredientFields, IngredientDBIngredientType } from '../../../../types';
import { IngredientDBMap } from '../_utils/datasources';

const MAX_INGREDIENT_OPTIONS = 100;
const { ingredientId, name } = {
  ...DBIngredientFields,
  ...GenericIngredientFields,
};

interface CorrectedIngredientAutoCompleteProps {
  ingredientMap: IngredientDBMap;
  cellParams: GridRenderEditCellParams;
  correctedIdKey: string;
}

const CorrectedIngredientAutoComplete: FC<CorrectedIngredientAutoCompleteProps> = (props) => {
  const { ingredientMap, cellParams, correctedIdKey } = props;
  const { id } = cellParams;
  const apiRef = useGridApiContext();
  const [error, setError] = useState<boolean>(false);

  const filterOptions = useCallback(
    (options: IngredientDBIngredientType[], state: FilterOptionsState<IngredientDBIngredientType>) =>
      matchSorter(options, state.inputValue, {
        keys: [
          { maxRanking: matchSorter.rankings.EQUAL, key: ingredientId },
          { maxRanking: matchSorter.rankings.CONTAINS, key: name },
        ],
      }).slice(0, MAX_INGREDIENT_OPTIONS),
    [],
  );

  const renderInputCell = (params: AutocompleteRenderInputParams) => (
    <TextField
      type="text"
      autoFocus={false}
      error={error}
      {...params}
      label={error ? "Ingredient doesn't exist" : 'Corrected Ingredient Id'}
      InputProps={{ ...params.InputProps, type: 'search' }}
    />
  );

  return (
    <Autocomplete
      data-id-cypress="correctedIngredientAutoComplete"
      clearOnEscape={true}
      id="correction-id-select"
      style={{ width: '95%' }}
      freeSolo={true}
      options={ingredientMap.ingredients}
      getOptionLabel={(option) => (typeof option !== 'string' ? `${option[name]} (${option[ingredientId]})` : '')}
      filterOptions={filterOptions}
      filterSelectedOptions={true}
      autoSelect={true}
      selectOnFocus={true}
      autoHighlight={false}
      blurOnSelect={true}
      className="CorrectedIngredientAutoComplete__autocomplete"
      renderInput={(params) => renderInputCell(params)}
      onChange={async (event, input, reason) => {
        // only change the value in the cell on blur
        if (['selectOption'].includes(reason)) return;
        const ingredient =
          typeof input === 'string'
            ? ingredientMap.searchIngredient(input)
            : ((input || {}) as IngredientDBIngredientType);
        const ingredientError = !!input && !ingredient?.ingredientId;
        setError(ingredientError);

        if (!ingredientError) {
          await apiRef.current.setEditCellValue({
            id,
            field: correctedIdKey,
            value: Number(ingredient?.ingredientId) || null,
          });
        }
      }}
    />
  );
};

export default memo(CorrectedIngredientAutoComplete) as FC<CorrectedIngredientAutoCompleteProps>;
