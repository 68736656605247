import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import React from 'react';

interface RemoveContextFromProjectDialogProps {
  handleClose: () => void;
  contextId: string;
  projectId: string;
  removeContext: () => void;
}

const RemoveContextFromProjectDialog: React.FC<RemoveContextFromProjectDialogProps> = ({
  handleClose,
  contextId,
  removeContext,
  projectId,
}) => {
  const isLoading = false;

  return (
    <Dialog key={projectId} fullWidth={true} open={true} onClose={() => !isLoading && handleClose()} maxWidth="md">
      <DialogTitle>{`Are you sure you want to remove ${contextId} from this project?`}</DialogTitle>
      <DialogContent> </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button disabled={isLoading} onClick={removeContext} color="primary">
          <DeleteIcon /> Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveContextFromProjectDialog;
