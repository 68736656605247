import Box from '@mui/material/Box';
import React, { memo } from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  // update only currently selected tab panel
  if (value !== index) {
    return null;
  }

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      <Box p={3} display={value === index ? 'block' : 'none'}>
        {children}
      </Box>
    </Box>
  );
};

TabPanel.defaultProps = { children: undefined };

export default memo(TabPanel);
