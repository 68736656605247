import { DataManRecord } from '../../../../../components/dataman/types';
import { JobStatus } from '../../_utils/types';

export interface ConceptGenerationRunRow {
  name: string;
  startedAt: string;
  startedBy: string;
  phaseId: string;
  runId: string;
  input_status?: JobStatus;
  output_status?: JobStatus;
}

export interface ConceptGenerationDatamanRecord extends DataManRecord, ConceptGenerationRunRow {}

export enum ShowConceptGenWorkflow {
  hide = 'hide',
  showV2 = 'v2',
  showV3 = 'v3',
}

export enum ConceptGenerationWFVersion {
  v2 = 2,
  v3 = 3,
}
