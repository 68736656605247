export enum DocTypeEnum {
  CreateCloneProjectDialog,
  ConfigureProjectStep,
  ScopeDefinitionStep,
  SelectProjectContexts,
  MetricsCalculationStep,
  ConceptGenerationStep,
  ModelPreparationStep,
  Contexts,
  ContextVersions,
  EditCreateContext,
  EditCreateContextVersion,
}

export const DocLinkUrl = {
  [DocTypeEnum.CreateCloneProjectDialog]:
    'https://staging-fp.atlassian.net/wiki/spaces/SD/pages/171835469/SOP+Tactical+CFI',
  [DocTypeEnum.ConfigureProjectStep]:
    'https://staging-fp.atlassian.net/wiki/spaces/SD/pages/171835469/SOP+Tactical+CFI#1.-Configure-project-(Phase-0)',
  [DocTypeEnum.ScopeDefinitionStep]:
    'https://staging-fp.atlassian.net/wiki/spaces/SD/pages/171835469/SOP+Tactical+CFI#2.-Define-Scope-and-Contexts-(Phase-1)',
  [DocTypeEnum.MetricsCalculationStep]:
    'https://staging-fp.atlassian.net/wiki/spaces/SD/pages/171835469/SOP+Tactical+CFI#3.-Calculate-Metrics-(Phase-2)',
  [DocTypeEnum.ConceptGenerationStep]:
    'https://staging-fp.atlassian.net/wiki/spaces/SD/pages/171835469/SOP+Tactical+CFI#4.-Generate-Concepts-(Phase-3)',
  [DocTypeEnum.ModelPreparationStep]:
    'https://staging-fp.atlassian.net/wiki/spaces/SD/pages/171835469/SOP+Tactical+CFI#5.-Model-Preparation',
  [DocTypeEnum.Contexts]: 'https://staging-fp.atlassian.net/wiki/x/FwBqCw',
  [DocTypeEnum.ContextVersions]:
    'https://staging-fp.atlassian.net/wiki/spaces/SD/pages/191496215/SOP+Create+a+context+and+versions#Create-a-version',
  [DocTypeEnum.EditCreateContext]: 'https://staging-fp.atlassian.net/wiki/x/FwBqCw',
  [DocTypeEnum.EditCreateContextVersion]:
    'https://staging-fp.atlassian.net/wiki/spaces/SD/pages/191496215/SOP+Create+a+context+and+versions#Create-a-version',
};
