import { GenericInputConfig } from './common';

export enum LemmaQueryIngredient {
  ingredient = 'ingredient',
  ingredient_id = 'ingredient_id',
  ingredient_name = 'ingredient_name',
  reference_ingredient_id = 'reference_ingredient_id',
  reference_ingredient_name = 'reference_ingredient_name',
  corrected_ingredient_id = 'corrected_ingredient_id',
  corrected_ingredient_name = 'corrected_ingredient_name',
  relevant_ingredient = 'relevant_ingredient',
  hide_lemma = 'hide_lemma',
  creation_time = 'creation_time',
  project = 'project',
}

export enum MetricsCalculationPayloadKeys {
  lemmas = 'lemmas',
}

export enum LemmaTableRowKeys {
  lemmaKey = 'lemma',
  ingredientKey = 'ingredient',
  idKey = 'ingredient_id',
  nameKey = 'ingredient_name',
  refIdKey = 'reference_ingredient_id',
  refNameKey = 'reference_ingredient_name',
  correctedIdKey = 'corrected_ingredient_id',
  correctedNameKey = 'corrected_ingredient_name',
  correctedRefIdKey = 'corrected_reference_ingredient_id',
  correctedRefNameKey = 'corrected_reference_ingredient_name',
  messageKey = 'message',
  isRelevantKey = 'relevant_ingredient',
  creationTimeKey = 'creation_time',
  projectKey = 'project',
  hideLemmasKey = 'hide_lemma',
}

export type LemmaTableRow = {
  [LemmaTableRowKeys.lemmaKey]: string;
  [LemmaTableRowKeys.idKey]: number | null;
  [LemmaTableRowKeys.nameKey]: string | null;
  [LemmaTableRowKeys.refIdKey]: number | null;
  [LemmaTableRowKeys.refNameKey]: string | null;
  [LemmaTableRowKeys.correctedIdKey]: number | null;
  [LemmaTableRowKeys.correctedNameKey]: string | null;
  [LemmaTableRowKeys.correctedRefIdKey]: number | null;
  [LemmaTableRowKeys.correctedRefNameKey]: string | null;
  [LemmaTableRowKeys.isRelevantKey]: boolean;
  [LemmaTableRowKeys.creationTimeKey]: any | null;
  [LemmaTableRowKeys.projectKey]: string | null;
  [LemmaTableRowKeys.hideLemmasKey]: boolean | null;
  [LemmaTableRowKeys.messageKey]: string | null;
};

export interface MetricsCalculationConfig extends GenericInputConfig {
  lemmas?: LemmaTableRow[];
}

export interface MetricsCalculationConfigResponse {
  inputId: string;
  config?: MetricsCalculationConfig;
}
