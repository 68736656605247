import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import React, { ChangeEvent, FC, memo } from 'react';

import { IngredientClass } from '../../../../../features/project/types/concept-generation';

export type ClassChangeHandler<T> = (event: ChangeEvent<{ value: T }>) => void;
interface IngredientClassSelectorProps {
  onChangeHandler: ClassChangeHandler<IngredientClass>;
  value: string | number;
  options: IngredientClass[];
  isInvalid?: boolean;
}

const IngredientClassSelector: FC<IngredientClassSelectorProps> = ({ onChangeHandler, value, options, isInvalid }) => (
  <Select
    data-id-cypress="ingredientClassSelector"
    variant="outlined"
    value={value}
    onChange={onChangeHandler as SelectInputProps['onChange']}
    fullWidth={true}
    style={{ height: '95%', width: '98%' }}
    error={isInvalid}
  >
    {options?.map((item) => (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    ))}
  </Select>
);

IngredientClassSelector.defaultProps = { isInvalid: false };

export default memo(IngredientClassSelector);
