import {
  DataManFilterMapping,
  DataManFilters,
  DataManListSortOptions,
  DataManSortMapping,
} from '../components/dataman/types';
import { ConceptQueryFilters, FilterOperands, QuerySort, SortDirections } from '../types';

// @TODO CREATE GENERIC TYPE FOR ENUM OF FIELD NAMES DataManFilterMapping<any, FilterOperands>
export function prepareFilterQuery(
  filters: DataManFilters,
  filterMapping: DataManFilterMapping<any, FilterOperands>,
): ConceptQueryFilters | [] {
  const filterFields = Object.keys(filters || {});
  const result: ConceptQueryFilters = [];

  filterFields.forEach((filterField) => {
    if (filters[filterField]?.length > 0) {
      const { field, operand, getValue } = filterMapping[filterField];
      result.push({ field, operand, value: getValue(filters[filterField]) });
    }
  });

  return result;
}

// @TODO CREATE GENERIC TYPE FOR ENUM OF FIELD NAMES DataManSortMapping<any>
export function prepareSortQuery<T>(
  sort: DataManListSortOptions,
  sortMapping: DataManSortMapping<any>,
): QuerySort<T>[] {
  const result: QuerySort<T>[] = [];
  sort.forEach((sortItem) => {
    const { field } = sortMapping[sortItem.field];
    result.push({ field, sortDirection: prepareSort(sortItem.direction) });
  });

  return result;
}

function prepareSort(direction: string): SortDirections {
  return direction === SORT_DIRECTION_ASC ? SortDirections.Ascending : SortDirections.Descending;
}

export const SORT_DIRECTION_ASC = 'ASC';
export const SORT_DIRECTION_DESC = 'DESC';
