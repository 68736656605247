import '../../../../../../layout/run-config.scss';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColumns, GridRenderCellParams, GridRowId, GridToolbar } from '@mui/x-data-grid';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { AnyAction, Dispatch } from 'redux';

import { setError, setIngredientSlots, setTemplatesRows } from '../../../../../../features/project/store';
import { GenericDataGridRow } from '../../../../../../features/project/types/common';
import {
  CompositionConfig,
  IngredientClass,
  IngredientType,
  SLOTS_MAX_NUM,
} from '../../../../../../features/project/types/concept-generation';
import {
  convertCompositionToTableRows,
  getInvalidRowAnySlots,
  tableRowToCompositions,
} from '../../../../../../features/project/utils';
import CompositionGenerationDialog from '../CompositionGenerationDialog';
import IngredientClassSelector from '../IngredientClassSelector';

interface CompositionTemplateProps {
  compositions: CompositionConfig[];
  dispatch: Dispatch<AnyAction>;
  ingredientSlots: number;
  maxNumOfSlots?: number;
  ingredientClasses: IngredientClass[];
}

const CompositionTemplate: FC<CompositionTemplateProps> = ({
  compositions,
  dispatch,
  ingredientSlots,
  maxNumOfSlots,
  ingredientClasses,
}) => {
  const [isGenerateDialogOpen, setIsGenerateDialogOpen] = useState<boolean>(false);
  const compositionTableRows = useMemo<GenericDataGridRow[]>(
    () => convertCompositionToTableRows(compositions),
    [compositions],
  );

  const invalidRowSlots = useMemo<{ [id: string]: string[] }>(() => {
    const result = {} as { [id: string]: string[] };
    compositionTableRows.forEach((row) => {
      result[String(row.id)] = getInvalidRowAnySlots(row);
    });
    return result;
  }, [compositionTableRows]);

  const duplicatedRowIds = useMemo<number[]>(() => {
    const duplicatedIds: number[] = [];
    const hashedArray = compositions.map((row) => JSON.stringify(row));
    compositions.forEach((row, idx) => {
      const occurrenceIdx = hashedArray.indexOf(JSON.stringify(row));
      if (occurrenceIdx !== -1 && occurrenceIdx !== idx) {
        duplicatedIds.push(idx);
      }
    });
    return duplicatedIds;
  }, [compositions]);

  const [tableHeight, setTableHeight] = useState<number>(compositionTableRows.length || 0);
  const allowAddSlot = Number(maxNumOfSlots) > ingredientSlots;
  const allowRemoveSlot = ingredientSlots > 1;

  const removeCompositionHandler = useCallback<(id: GridRowId) => void>(
    (id) => {
      const updatedCompositions = compositions.filter((value, idx) => value && idx !== id);
      setTableHeight(() => updatedCompositions?.length || 0);
      dispatch(setTemplatesRows(updatedCompositions));
    },
    [dispatch, compositions],
  );

  const clearCompositionsHandler = useCallback<() => void>(() => {
    setTableHeight(0);
    dispatch(setTemplatesRows([]));
  }, [dispatch]);

  const ingredientSlotAddHandler = useCallback<() => void>(() => {
    dispatch(setIngredientSlots(ingredientSlots + Number(allowAddSlot)));
  }, [dispatch, ingredientSlots, allowAddSlot]);

  const ingredientSlotRemoveHandler = useCallback<() => void>(() => {
    const updatedRows: GenericDataGridRow[] = compositionTableRows.map((row) => {
      const newRow = { ...row };
      delete newRow[`ingredient${ingredientSlots}`];
      return newRow;
    });

    dispatch(setTemplatesRows(tableRowToCompositions(updatedRows)));
    dispatch(setIngredientSlots(ingredientSlots - 1));
  }, [dispatch, compositionTableRows, ingredientSlots]);

  const compositionAddHandler = useCallback<() => void>(() => {
    setTableHeight((value) => value + 1);
  }, []);
  const renderRowDeleteCell = useCallback<(params: GridRenderCellParams) => React.ReactNode>(
    ({ id }) => (
      <IconButton onClick={() => removeCompositionHandler(id)} disabled={!compositions?.length}>
        <DeleteIcon />
      </IconButton>
    ),
    [removeCompositionHandler, compositions?.length],
  );

  const columns: GridColumns = new Array(ingredientSlots).fill({}).map((item, index) => ({
    field: `ingredient${index + 1}`,
    align: 'center',
    headerAlign: 'center',
    headerName: `ingredient ${index + 1}`,
    minWidth: 180,
    editable: true,
    sortable: true,
    filterable: true,
    valueOptions: ingredientClasses,
    renderCell: ({ id, value: val, field, colDef }) => (
      <IngredientClassSelector
        onChangeHandler={({ target: { value } }) => {
          const idx = compositionTableRows.findIndex((i) => Number(i.id) === Number(id));
          const updated = {
            ...compositionTableRows,
            [idx]: { ...compositionTableRows[idx], [field]: value },
          };
          dispatch(setTemplatesRows(tableRowToCompositions(Object.keys(updated).map((key: any) => updated[key]))));
        }}
        value={val && ingredientClasses.includes(val as IngredientClass) ? val?.toString() : IngredientType.other}
        options={(colDef.valueOptions || []) as IngredientClass[]}
        isInvalid={!!invalidRowSlots[Number(id)]?.includes(field) || duplicatedRowIds.includes(Number(id))}
      />
    ),
  }));

  columns.push(
    {
      field: 'category',
      align: 'center',
      headerAlign: 'center',
      headerName: 'Concept category',
      minWidth: 250,
      filterable: true,
      sortable: true,
      editable: true,
      valueOptions: ingredientClasses,
      renderCell: ({ id, field, value: val, colDef }) => (
        <IngredientClassSelector
          onChangeHandler={({ target: { value } }) => {
            dispatch(
              setTemplatesRows(
                tableRowToCompositions(
                  compositionTableRows.map((row) => ({
                    ...row,
                    [field]: row.id === Number(id) ? value : row[field],
                  })),
                ),
              ),
            );
          }}
          value={val && ingredientClasses.includes(val as IngredientClass) ? val?.toString() : IngredientType.other}
          options={(colDef.valueOptions || []) as IngredientClass[]}
        />
      ),
    },
    {
      field: 'action',
      align: 'center',
      headerAlign: 'center',
      headerName: ' ',
      width: 100,
      renderCell: (params) => renderRowDeleteCell(params),
    },
  );

  const createTableRows = useCallback<(rows: GenericDataGridRow[]) => void>(
    (tableRows) => {
      const rows = [...tableRows];
      let prevId: number | null = null;

      for (let i = 0; i < tableHeight; i++) {
        let row: GenericDataGridRow = rows[i];
        prevId = rows[i]?.id || null;

        for (let j = 1; j <= ingredientSlots; j++) {
          const value =
            (ingredientClasses.includes(row?.[`ingredient${j}`]) && row?.[`ingredient${j}`]) || IngredientType.other;
          row = { ...row, [`ingredient${j}`]: value };
        }

        row.id = rows[i]?.id === prevId ? i + 1 : rows[i]?.id;
        rows[i] = row;
      }

      dispatch(setTemplatesRows(tableRowToCompositions(rows)));
    },
    [dispatch, tableHeight, ingredientSlots, ingredientClasses],
  );

  const generateCompositionsCallback = useCallback<(templates: CompositionConfig[]) => void>(
    (templates) => {
      dispatch(setTemplatesRows([...compositions, ...templates]));
      setIsGenerateDialogOpen(false);
    },
    [dispatch, compositions],
  );

  // eslint-disable-next-line
  useEffect(() => createTableRows(compositionTableRows), [createTableRows]);

  useEffect(() => {
    const ids = Object.keys(invalidRowSlots)
      .filter((id) => invalidRowSlots[id].length)
      .map((id) => Number(id) + 1);

    const error: FC = () => (
      <>
        {!!ids.length && (
          <div>{`Composition rows: (${ids.join(
            ', ',
          )}) have invalid position of Base ingredients: they must be at the start of composition.`}</div>
        )}
        {!!duplicatedRowIds.length && (
          <div>{`Composition cannot be duplicated. Check rows (${duplicatedRowIds.join(', ')}).`}</div>
        )}
      </>
    );

    dispatch(setError(ids.length || duplicatedRowIds.length ? error : null));
  }, [dispatch, invalidRowSlots, duplicatedRowIds]);

  return (
    <Box className="RunConfig__compositionTemplateContainer" data-id-cypress="compositionTemplate">
      <Box className="RunConfig__stepTitle">
        <Typography>Create composition templates</Typography>
      </Box>
      <Box className="RunConfig__ingredientsNumberBlock">
        <Typography>Number of ingredients</Typography>
        <Box>
          <IconButton onClick={ingredientSlotRemoveHandler} disabled={!allowRemoveSlot}>
            <RemoveIcon />
          </IconButton>
          <Box>
            <Typography variant="h4">{ingredientSlots}</Typography>
          </Box>
          <IconButton
            data-id-cypress="addIngredientCompositionTemplateButton"
            onClick={ingredientSlotAddHandler}
            disabled={!allowAddSlot}
          >
            <AddIcon />
          </IconButton>
        </Box>
      </Box>
      <DataGrid
        className="RunConfig__compositionTable"
        columns={columns}
        rows={compositionTableRows}
        autoPageSize={true}
        disableSelectionOnClick={true}
        density="compact"
        components={{ Toolbar: GridToolbar }}
      />
      <Box display="flex" flexDirection="row" justifyContent="center">
        <Box className="RunConfig__actionBlock" marginRight={4}>
          <IconButton onClick={compositionAddHandler} style={{ borderRadius: 0 }}>
            <AddIcon data-id-cypress="addCompositionTemplateButton" />
            <Typography>Add composition template</Typography>
          </IconButton>
        </Box>
        <Box className="RunConfig__actionBlock" marginRight={4}>
          <IconButton onClick={clearCompositionsHandler} style={{ borderRadius: 0 }}>
            <DeleteIcon />
            <Typography>Remove all composition templates</Typography>
          </IconButton>
        </Box>
        <Box className="RunConfig__actionBlock">
          <IconButton onClick={() => setIsGenerateDialogOpen(true)} style={{ borderRadius: 0 }}>
            <SettingsIcon />
            <Typography>Generate composition templates</Typography>
          </IconButton>
        </Box>
      </Box>
      <CompositionGenerationDialog
        slots={ingredientSlots}
        categories={ingredientClasses}
        callback={generateCompositionsCallback}
        open={isGenerateDialogOpen}
        setOpenDialog={setIsGenerateDialogOpen}
      />
    </Box>
  );
};

CompositionTemplate.defaultProps = { maxNumOfSlots: SLOTS_MAX_NUM };

export default CompositionTemplate;
