import { SelectChangeEvent } from '@mui/material/Select';
import { GridColDef } from '@mui/x-data-grid';
import axios from 'axios';
// eslint-disable-next-line import/no-unresolved
import { Location } from 'history';
import { ChangeEvent } from 'react';
import { NavigateFunction } from 'react-router-dom';

import { JobStatus, PhaseType, RunType } from '../admin/projects/ProjectFlow/_utils/types';
import { CompanyType, ProjectType } from '../types';
import {
  buildActionUrl,
  TYPE_CREATE_NEW_PHASE,
  TYPE_PROJECT_BY_ID,
  TYPE_PROJECT_COMPANIES,
  TYPE_PROJECTS,
} from './url';

export type UrlFiltersType = { [key: string]: any };

export interface PhaseIdWithRuns {
  phaseId: string;
  runs: RunType[];
  selected: RunType | null;
}

export interface UrlFiltersAndSortType {
  sort?: any | null;
  filters: UrlFiltersType;
}

export type InputChangeEvent =
  | SelectChangeEvent
  | ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;

const capitalize = (text: string): string => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

const capitalizedFirstChar = (text?: string, defaultValue?: string): string => {
  const firstDefaultChar = defaultValue ? defaultValue[0] : '';
  return String(text ? text[0] : firstDefaultChar).toUpperCase();
};

const compareProperty = (a?: string, b?: string): number => {
  let result = 0;

  if (a || b) {
    if (!a) {
      result = -1;
    } else {
      result = !b ? 1 : a.localeCompare(b);
    }
  }

  return result;
};

const setUrlFilters = (location: Location, filters: UrlFiltersType): void => {
  const queryParams = new URLSearchParams(location.search);

  Object.keys(filters).forEach((key) => {
    const value = filters[key];
    if (value === null || value === '') {
      queryParams.delete(`filters[${key}]`);
    } else {
      queryParams.set(`filters[${key}]`, JSON.stringify(value));
    }
  });

  const newUrl = `${location.pathname}?${queryParams.toString()}`;
  window.history.pushState({ path: newUrl }, '', newUrl);
};

const setUrlFilter = (location: Location, history: NavigateFunction, key: string, value: string | null): void => {
  setUrlFilters(location, { [key]: value });
};

const setUrlSort = (location: Location, history: NavigateFunction, sort?: any | null): void => {
  const queryParams = new URLSearchParams(location.search);

  if (sort === null || sort === undefined || sort === '') {
    queryParams.delete(`sort`);
  } else {
    queryParams.set('sort', JSON.stringify(sort));
  }

  history(`?${queryParams.toString()}`, { replace: true });
};

const getUrlFiltersAndSort = (location: Location): UrlFiltersAndSortType => {
  const queryParams = new URLSearchParams(location.search);
  const filterItems: UrlFiltersType = {};
  let urlKey: string | null;

  const queryParamKeys = queryParams.keys();
  /* eslint-disable no-cond-assign */
  while ((urlKey = queryParamKeys.next().value)) {
    const matches: string[] | null = urlKey.match(/filters\[(.*)\]/);
    if (matches) {
      try {
        if (matches[1]) filterItems[matches[1]] = JSON.parse(queryParams.get(urlKey) || 'null');
      } catch (e) {
        /** Nothing to do.
         *  The try & catch is used to not throw the error in case of not well formed JSON
         */
      }
    }
  }
  /* eslint-enable no-cond-assign */

  const urlSort = queryParams.get('sort');
  let sort: any = null;

  try {
    if (urlSort) sort = JSON.parse(urlSort || 'null');
  } catch (e) {
    /** Nothing to do.
     *  The try & catch is used to not throw the error in case of not well formed JSON
     */
  }

  return {
    sort,
    filters: filterItems,
  };
};

export const timeoutPromise = (ms: number): Promise<void> =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export {
  capitalize,
  capitalizedFirstChar,
  compareProperty,
  getUrlFiltersAndSort,
  setUrlFilter,
  setUrlFilters,
  setUrlSort,
};

export const isProduction = (): boolean => window.location.hostname === 'cfi.internal.foodpairing.com';

export const openUrlInNewWindow = (url: string): void => {
  window.open(url);
};

export const URLPhaseRunKeyName = 'phase_run';

export const getUrlPhaseRun = (phaseIndex: number): string | null => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(`${URLPhaseRunKeyName}[${phaseIndex}]`) || null;
};

export const buildUrlPhaseRun = (phaseIndex: number, runIndex: string): string => {
  const urlParams = new URLSearchParams(window.location.search);
  const newUrlParams = new URLSearchParams(window.location.search);
  urlParams.forEach((value, key) => {
    const found = key.match(`${URLPhaseRunKeyName}\\[([0-9]+)\\]`);
    if (found) {
      const tN = Number(found[1]);
      if (tN > phaseIndex) {
        newUrlParams.delete(`${URLPhaseRunKeyName}[${tN}]`);
      }
    }
  });

  newUrlParams.set(`${URLPhaseRunKeyName}[${phaseIndex}]`, runIndex);

  return decodeURIComponent(newUrlParams.toString());
};

export const jobStatusCodeToText = (jobStatus: JobStatus): string => jobStatus.replace('JOB_STATUS_', '');

/**
 * Send post request to create new phase and get back created phase.
 *
 * @param post
 * @param projectId
 * @param phaseId
 * @param runId
 */
export async function createDependingPhase(
  projectId: string,
  phaseId: string,
  runId: string,
): Promise<{ projectPhase: PhaseType | null | undefined }> {
  const response = await axios
    .post(buildActionUrl({ projectId }, TYPE_CREATE_NEW_PHASE), {
      project_id: projectId,
      depends_on_phases: [phaseId],
      depends_on_runs: [runId],
    })
    .then((res) => res.data);

  return response;
}

export function fetchProjects(
  get: (route?: string | undefined) => Promise<any>,
): Promise<{ projects?: ProjectType[] }> {
  return get(buildActionUrl({}, TYPE_PROJECTS));
}

export function fetchCompanies(
  get: (route?: string | undefined) => Promise<any>,
): Promise<{ companies?: CompanyType[] }> {
  return get(buildActionUrl({}, TYPE_PROJECT_COMPANIES));
}

export function fetchEditableProject(
  projectId: string,
  get: (route?: string | undefined) => Promise<any>,
): Promise<{ project?: ProjectType }> {
  return get(buildActionUrl({ projectId }, TYPE_PROJECT_BY_ID));
}

/**
 * Check if job is in progress.
 *
 * @param jobStatus
 */
export function isJobIsInProgress(jobStatus: JobStatus): boolean {
  return (
    jobStatus === JobStatus.inProgress ||
    jobStatus === JobStatus.created ||
    jobStatus === JobStatus.aborting ||
    jobStatus === JobStatus.toAbort ||
    jobStatus === JobStatus.accepted ||
    jobStatus === JobStatus.manual
  );
}

export function ingredientToString<T>(...keys: string[]) {
  return (ingredient: T): string =>
    keys[0] ? `${ingredient[(keys[1] ?? 'name') as keyof T]} (${ingredient[keys[0] as keyof T]})` : '';
}

export function getIngredientsString<T>(ingredients: T[], ...keys: string[]): string[] {
  return ingredients.filter((x) => x !== undefined).map(ingredientToString<T>(...keys));
}

export function replaceItemInArray<T>(array: T[], replacement: T, replaceIndex: number): T[] {
  return [...array.slice(0, replaceIndex), replacement, ...array.slice(replaceIndex + 1)];
}

export function getCollectionItemByFieldValue<T>(
  items: T[],
  id: any,
  field: keyof T,
  castFunction = Number,
): [T, number] {
  const index = items.findIndex((i) => !!i[field] && castFunction(i[field]) === castFunction(id));
  const item = items[index] || ({} as T);

  return [{ ...item, [field]: castFunction(item[field]) || null }, index];
}

const AlignToTypeMap: {
  [type: string]: Pick<GridColDef, 'align' | 'headerAlign'>;
} = {
  text: { align: 'left', headerAlign: 'center' },
  numeric: { align: 'right', headerAlign: 'center' },
  any: { align: 'center', headerAlign: 'center' },
};

export function getDataGridCellAlignment(type: keyof typeof AlignToTypeMap): (typeof AlignToTypeMap)[string] {
  return AlignToTypeMap[type] || AlignToTypeMap.any;
}

const LOCALE_DATE_FORMAT: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',
};

export function timestampToLocalDateSting(timestamp?: number): string {
  if (!timestamp) return '';
  const date = new Date(timestamp * 1000);
  return date.toLocaleDateString('en-eu', LOCALE_DATE_FORMAT);
}
