import '../../../../../../layout/run-config.scss';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { FC, memo, useCallback } from 'react';
import { AnyAction, Dispatch } from 'redux';

import { SelectedIngredient } from '../../../../../../features/project/types/concept-generation';
import { prepareIngredient } from '../../../../../../features/project/utils';
import IngredientTable from '../../../_components/IngredientTable';

interface SelectIngredientProps {
  dispatch: Dispatch<AnyAction>;
  ingredients: SelectedIngredient[];
  selectedIngredientIds: number[];
  loading: boolean;
  setSelectedIngredients: (payload: { items: SelectedIngredient[] }) => any;
  readOnly?: boolean;
}

const SelectIngredients: FC<SelectIngredientProps> = ({
  dispatch,
  ingredients,
  loading,
  selectedIngredientIds,
  setSelectedIngredients,
  readOnly,
}) => {
  const setSelection = useCallback<(ids: number[], rows?: SelectedIngredient[]) => void>(
    (ids, rows) => {
      const selection = new Set(ids);
      dispatch(
        setSelectedIngredients({
          items:
            rows?.filter((i) => selection.has(Number(i.ingredient_id)))?.map((i) => prepareIngredient(i, true)) || [],
        }),
      );
    },
    [dispatch, setSelectedIngredients],
  );

  return (
    <Box className="RunConfig__stepContainer">
      <Box className="RunConfig__stepTitle">
        <Typography>Select ingredients to be included in the concepts</Typography>
      </Box>
      <IngredientTable
        identifier="ingredient_id"
        checkboxSelection={true}
        columns={[
          {
            field: 'ingredient_id',
            align: 'center',
            headerAlign: 'center',
            headerName: 'Ingredient ID',
            minWidth: 300,
          },
          {
            field: 'ingredient_name',
            headerName: 'Ingredient name',
            align: 'center',
            headerAlign: 'center',
            minWidth: 200,
            flex: 1,
          },
          {
            field: 'class',
            headerName: 'Ingredient class',
            align: 'center',
            headerAlign: 'center',
            minWidth: 200,
            flex: 1,
          },
        ]}
        ingredients={ingredients}
        initialSelection={selectedIngredientIds}
        loading={loading}
        setSelectedIngredients={setSelection}
        height="100%"
        density="standard"
        isReadOnly={readOnly}
      />
    </Box>
  );
};

SelectIngredients.defaultProps = {
  readOnly: false,
};

export default memo(
  SelectIngredients,
  (prev, next) =>
    prev.ingredients.length === next.ingredients.length &&
    prev.loading === next.loading &&
    JSON.stringify(prev.selectedIngredientIds) === JSON.stringify(next.selectedIngredientIds),
);
