import { ProjectFlowSteps, ProjectPhaseType } from './_utils/types';
import ConceptGeneration from './ConceptGeneration/ConceptGeneration';
import GenericPhase from './GenericPhase/GenericPhase';
import MetricsCalculation from './MetricsCalculation/MetricsCalculation';
import ModelPreparation from './ModelPreparation/ModelPreparation';
import ProjectConfiguration from './ProjectConfiguration/ProjectConfiguration';
import ScopeDefinition from './ScopeDefinition/ScopeDefinition';

const steps: ProjectFlowSteps = {
  [ProjectPhaseType.ProjectConfiguration]: { component: ProjectConfiguration },
  [ProjectPhaseType.ScopeDefinition]: { component: ScopeDefinition },
  [ProjectPhaseType.MetricsCalculation]: { component: MetricsCalculation },
  [ProjectPhaseType.ConceptGeneration]: { component: ConceptGeneration },
  [ProjectPhaseType.ModelPreparation]: { component: ModelPreparation },
  [ProjectPhaseType.GenericPhase]: { component: GenericPhase },
};

export {
  ConceptGeneration,
  GenericPhase,
  MetricsCalculation,
  ModelPreparation,
  ProjectConfiguration,
  ScopeDefinition,
  steps,
};
