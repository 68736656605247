import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import ContextCard from '../../components/ContextCard';
import { getUrlFiltersAndSort, setUrlFilter } from '../../shared/utils';
import { ContextType } from '../../types';

interface ContextListGridProps {
  contexts: ContextType[];
  cardTitleAction?: (project: ContextType) => React.ReactElement;
  cardActionUrl?: string | ((project: ContextType) => string);
  cardBottomActions?: (project: ContextType) => React.ReactElement;
  cardAction?: (context: ContextType) => () => void;
  filterable?: boolean;
  selectedContexts?: ContextType[];
  disabledCardMessage?: React.ReactElement;
  headerActions?: React.ReactElement;
}

const sortOptions = [
  {
    value: 'CONTEXT_CREATION_DATE',
    label: 'Created At',
  },
];

const ContextListGrid: React.FC<ContextListGridProps> = ({
  contexts,
  cardTitleAction,
  cardActionUrl,
  selectedContexts,
  cardBottomActions,
  cardAction,
  filterable,
  disabledCardMessage,
  headerActions,
}) => {
  let dataToShow = contexts;

  const location = useLocation();
  const history = useNavigate();

  const urlFilters = filterable ? getUrlFiltersAndSort(location) : { sort: null, filters: {} };

  const sortSelectionLabel = urlFilters.sort ? sortOptions.find((f) => f.value === urlFilters.sort) : null;

  const [contextSearchFilter, setContextNameFilter] = useState<string>(urlFilters.filters.context_name || '');
  const [sortBy, setSortBy] = useState<string | null>(urlFilters.sort || 'CONTEXT_CREATION_DATE');
  const [sortByLabel, setSortByLabel] = useState<string | null>(
    (sortSelectionLabel && sortSelectionLabel.label) || 'Created At',
  );

  const setUrlFilterCallback = (parameter: string, searchString: string | null) => {
    setUrlFilter(location, history, parameter as string, searchString);
  };

  const isCardDisabled = (context: ContextType) =>
    selectedContexts?.some((c) => c.contextId === context.contextId && c.contextVersionId !== context.contextVersionId);

  dataToShow = dataToShow.filter(
    (item) =>
      !contextSearchFilter ||
      item.contextId?.toLowerCase().includes(contextSearchFilter.toLowerCase()) ||
      item.description?.toLowerCase().includes(contextSearchFilter.toLowerCase()),
  );

  return (
    <>
      {filterable !== false && (
        <Card style={{ marginBottom: 25 }}>
          <CardContent>
            <Grid container={true} spacing={3} style={{ width: '100%' }}>
              <Grid item={true} md={4}>
                <TextField
                  data-id-cypress="inputContextSearchInput"
                  type="search"
                  label="Search contexts by name or description"
                  fullWidth={true}
                  variant="outlined"
                  onChange={(event) => {
                    setContextNameFilter(String(event.currentTarget.value));
                    setUrlFilterCallback('context_name', String(event.currentTarget.value));
                  }}
                  value={contextSearchFilter}
                />
              </Grid>
              <Grid item={true} md={4}>
                <Box display="flex">{headerActions}</Box>
              </Grid>
              <Grid item={true} md={4}>
                <Autocomplete
                  fullWidth={true}
                  value={sortBy ? { label: sortByLabel, value: sortBy } : null}
                  options={sortOptions}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option?.value === value?.value}
                  onChange={(e, value) => {
                    setSortBy(value?.value || null);
                    setSortByLabel(value?.label || '');
                    setUrlFilterCallback('sort', value?.value || null);
                  }}
                  renderInput={(params) => <TextField {...params} label="Sort by" variant="outlined" />}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}

      <Grid container={true} spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
        {dataToShow.map((context, index) => (
          <Grid item={true} xs={12} sm={6} md={4} lg={4} xl={3} key={context.contextId + String(index)}>
            <ContextCard
              context={context}
              cardActionUrl={cardActionUrl}
              cardBottomActions={cardBottomActions}
              cardTitleAction={cardTitleAction}
              cardAction={cardAction}
              disabled={isCardDisabled(context)}
              disabledMessage={disabledCardMessage}
              searchString={contextSearchFilter}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

ContextListGrid.defaultProps = {
  cardTitleAction: undefined,
  cardActionUrl: undefined,
  cardBottomActions: undefined,
  cardAction: undefined,
  filterable: undefined,
  selectedContexts: [],
  disabledCardMessage: undefined,
  headerActions: undefined,
};

export default ContextListGrid;
