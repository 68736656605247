import '../../../layout/common.scss';

import Public from '@mui/icons-material/Public';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React from 'react';
import { useMatch, useNavigate } from 'react-router-dom';

import { useFetchWithAuth } from '../../../auth';
import ErrorNotification from '../../../components/ErrorNotification';
import Loading from '../../../components/Loading';
import PageTitle from '../../../components/PageTitle';
import { useLayout } from '../../../context/LayoutContext';
import { buildActionUrl, TYPE_VIEW_JOB } from '../../../shared/url';
import { JobType } from '../../../types';
import { prepareCellValue } from '../utils';

/* eslint-disable react/no-danger */
const JobInfo: React.FC<{ job: JobType }> = ({ job }) => {
  const resources = job?.output?.resources || {};
  Object.keys(resources).forEach((key) => {
    const resource = resources[key];

    if (resource.sheets) {
      resource.sheets = resource.sheets || {};
      resource.sheets.sheets = resource?.sheets?.sheets || [];

      /* eslint-disable */
      resource.sheets.sheets = resource.sheets.sheets.map((el) => ({
        ...el,
        sheetUrl: `<a href='${el.sheetUrl}' target='_blank'>${el.sheetType}</a>`,
      }));

      resources[key] = resource;
    }
  });

  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
      <Grid
        container
        item
        spacing={3}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        marginTop={0}
      >
        <Grid item xs={6} key="projectId">
          <TextField
            data-id-cypress="projectIdField"
            id="projectId"
            label="Project ID"
            className="common__textField"
            value={job.projectId}
            disabled
            variant="standard"
            margin="normal"
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6} key="jobId">
          <TextField
            data-id-cypress="jobIdField"
            id="jobId"
            label="Job Id"
            className="common__textField"
            value={job.jobId || 'N/A'}
            disabled
            variant="standard"
            margin="normal"
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6} key="jobType">
          <TextField
            data-id-cypress="jobTypeField"
            id="jobType"
            label="Type"
            className="common__textField"
            value={prepareCellValue(job.jobType, true, 'JOB_TYPE_')}
            disabled
            variant={job.jobType ? 'standard' : 'filled'}
            margin="normal"
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6} key="status">
          <TextField
            data-id-cypress="jobStatusField"
            id="status"
            label="Status"
            className="common__textField"
            value={prepareCellValue(job.status || 'UNKNOWN', true, 'JOB_STATUS_')}
            variant={job.status ? 'standard' : 'filled'}
            disabled
            margin="normal"
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6} key="startedAt">
          <TextField
            data-id-cypress="jobStartedAtField"
            id="startedAt"
            label="Started"
            className="common__textField"
            value={job.startedAt ? moment(job.startedAt).format('YYYY-MM-DD HH:mm') || '' : 'N/A'}
            variant={job.startedAt ? 'standard' : 'filled'}
            disabled
            margin="normal"
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6} key="finishedAt">
          <TextField
            data-id-cypress="jobFinishedAtField"
            id="finishedAt"
            label="Finished"
            className="common__textField"
            value={job?.finishedAt ? moment(job.finishedAt).format('YYYY-MM-DD HH:mm') : 'N/A'}
            variant={job.finishedAt ? 'standard' : 'filled'}
            disabled
            margin="normal"
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6} key="startedBy">
          <TextField
            data-id-cypress="jobStartedByField"
            id="startedBy"
            label="Started by"
            className="common__textField"
            value={job.startedByUser || 'unknown'}
            variant={job.startedByUser ? 'standard' : 'filled'}
            disabled
            margin="normal"
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6} key="externalJobId">
          <TextField
            data-id-cypress="jobExternalJobIdField"
            id="externalJobId"
            label="External job id"
            className="common__textField"
            value={job.externalJobId || 'N/A'}
            variant={job.externalJobId ? 'standard' : 'filled'}
            disabled
            fullWidth
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6} key="workflowStepId">
          <TextField
            data-id-cypress="jobWorkflowStepIdField"
            id="workflowStepId"
            label="Step"
            className="common__textField"
            value={job.workflowStepId ? prepareCellValue(job.workflowStepId, true, '') : 'N/A'}
            variant={job.workflowStepId ? 'standard' : 'filled'}
            disabled
            fullWidth
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6} key="errorMessage">
          <TextField
            data-id-cypress="jobErrorMessageField"
            id="errorMessage"
            label="Error message"
            className="common__largeTextField"
            value={job.errorMessage || 'N/A'}
            variant={job.errorMessage ? 'standard' : 'filled'}
            disabled
            fullWidth
            multiline
            maxRows={5}
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            error={!!job.errorMessage}
          />
        </Grid>
        <Grid item xs={6} key="values">
          <Typography className="common__textAreaLabel">Values</Typography>
          <TextareaAutosize
            data-id-cypress="jobValuesField"
            id="values"
            aria-label="Values"
            className="common__textArea"
            value={JSON.stringify(job?.output?.values || {}, undefined, 2)}
            disabled
            minRows={10}
            readOnly
          />
        </Grid>
        <Grid item xs={6} key="resources">
          <Typography className="common__textAreaLabel">Resources</Typography>
          <TextareaAutosize
            data-id-cypress="jobResourcesField"
            aria-label="Resources"
            className="common__textArea"
            style={{ whiteSpace: 'pre-wrap', fontSize: '12px', wordBreak: 'break-word', border: '1px solid grey' }}
            value={JSON.stringify(resources, undefined, 2)}
            readOnly
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const JobDetail: React.FC<{}> = () => {
  const { idProject: projectId = '', idJob: jobId = '' } =
    useMatch<'idProject' | 'idJob', string>('/projects/:idProject/jobs/:idJob')?.params || {};
  const { setPageTitle } = useLayout();
  // we should probably make error reporting a global feature with top level state
  const [error, setError] = React.useState<string | null>(null);
  const [job, setJob] = React.useState<JobType | null>(null);
  const requestPath = buildActionUrl({ projectId, jobId }, TYPE_VIEW_JOB);
  const fetchWithAuth = useFetchWithAuth();
  const history = useNavigate();

  const initialize = React.useCallback(async () => {
    const { error: initialError, data } = await fetchWithAuth(requestPath, {});
    if (!initialError) {
      setJob(data.job);
    } else {
      setJob({} as JobType);
      setError(initialError);
    }
  }, [requestPath, fetchWithAuth]);

  const mounted = React.useRef(false);
  React.useEffect(() => {
    if (mounted.current) return;
    mounted.current = true;
    initialize().then(() => null);
    setPageTitle(`Job ${jobId}`);
  }, [jobId, initialize, setPageTitle]);
  return (
    <>
      <Box className="common__jobHeader">
        <PageTitle title={`Job ${jobId}`} />
        {job?.runId && job?.phaseId && (
          <Public onClick={() => history(`/projects/${job.projectId}/phases/${job.phaseId}/runs/${job.runId}`)} />
        )}
      </Box>
      {job ? (
        <Paper className="common__configSection">
          <JobInfo job={{ ...job, projectId }} />
        </Paper>
      ) : (
        <Loading />
      )}
      <ErrorNotification error={error} handleClose={() => setError(null)} />
    </>
  );
};
export default JobDetail;
