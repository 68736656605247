import './ContextForm.scss';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import Moment from 'moment';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { buildActionUrl, TYPE_POST_CONCEPT_UPDATE } from '../../shared/url';
import { ContextType } from '../../types';
import ArchiveContextDialog from './ArchiveContextDialog';

export interface ContextFormProps {
  context?: ContextType;
  handleClose: () => void;
}

type FormValues = {
  name: string;
  description: string;
  imageUrl: string;
};

const ContextForm: React.FC<ContextFormProps> = ({ context, handleClose }) => {
  let defaultValues = {};
  const [isLoading, setLoading] = useState(false);
  const history = useNavigate();
  const [imageUrl, setImageUrl] = useState(context?.imageUrl || '');
  const [openArchive, setOpenArchive] = useState<boolean>(false);
  const isEditing = context !== undefined;

  if (isEditing && context) {
    defaultValues = {
      name: context.name,
      description: context.description,
      imageUrl: context.imageUrl,
    };
  }

  const { register, handleSubmit } = useForm<FormValues>({ defaultValues });

  const onSubmit = (data: any) => {
    const dataToUpdate = { ...data };
    const submitUrl = buildActionUrl(
      { contextId: isEditing ? context?.contextId : undefined },
      TYPE_POST_CONCEPT_UPDATE,
    );
    setLoading(true);
    axios
      .post(submitUrl, dataToUpdate)
      .then((res) => res.data())
      .then((result) => {
        setLoading(false);

        if (isEditing) {
          handleClose();
          return;
        }

        if (result?.context?.contextId) {
          history(`/contexts/${result.context.contextId}/versions`);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset disabled={isLoading} style={{ border: 'none' }}>
        {isEditing && (
          <Grid container={true} className="contextForm__gridContainer">
            <Grid item={true} md={4} className="contextForm__gridItem">
              <TextField
                value={context?.contextId}
                disabled={true}
                name="contextId"
                label="Context id"
                variant="filled"
                fullWidth={true}
              />
            </Grid>
            <Grid item={true} md={4} className="contextForm__gridItem">
              <TextField
                value={context?.resourceMetadata?.changedBy}
                disabled={true}
                name="changedBy"
                label="Changed By"
                variant="filled"
                fullWidth={true}
              />
            </Grid>
            <Grid item={true} md={4} className="contextForm__gridItem">
              <TextField
                value={Moment(context?.resourceMetadata?.changedAt).format('YYYY-MM-DD HH:mm')}
                disabled={true}
                name="changedAt"
                label="Changed At"
                variant="filled"
                fullWidth={true}
              />
            </Grid>
          </Grid>
        )}
        <Grid container={true} className="contextForm__gridContainer">
          <Grid item={true} md={6} className="contextForm__gridItem">
            <TextField
              label="Name"
              variant="outlined"
              {...register('name', {
                required: 'Required',
              })}
              fullWidth={true}
            />
          </Grid>
        </Grid>
        <Grid container={true} className="contextForm__gridContainer">
          <Grid item={true} md={12} className="contextForm__gridItem">
            <TextField label="Description" variant="outlined" {...register('description')} fullWidth={true} />
          </Grid>
        </Grid>
        <Grid container={true} className="contextForm__gridContainer">
          <Grid item={true} md={12} className="contextForm__gridItem">
            <TextField
              label="Image Url"
              variant="outlined"
              {...register('imageUrl')}
              onChange={(e) => setImageUrl(e.target.value)}
              fullWidth={true}
            />
          </Grid>
        </Grid>
        <div>
          <div
            style={{
              background: '#eee',
              height: 112,
              margin: '0 auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
              maxWidth: 400,
            }}
          >
            {imageUrl ? (
              <img src={imageUrl} style={{ width: '100%' }} alt="Context" />
            ) : (
              <img src="/card-no-image.svg" alt="Context" />
            )}
          </div>
        </div>
      </fieldset>
      <div className="contextForm__actions">
        <Button disabled={isLoading} onClick={handleClose} type="button">
          Cancel
        </Button>
        {isEditing && (
          <Button onClick={() => setOpenArchive(true)} disabled={isLoading} type="button" color="secondary">
            Archive
          </Button>
        )}
        <Button disabled={isLoading} type="submit" color="primary">
          {isEditing ? 'Update' : 'Add'}
          {isLoading && <CircularProgress size={24} className="contextForm__buttonProgress" />}
        </Button>
      </div>
      {isEditing && context && openArchive && (
        <ArchiveContextDialog
          key={context.contextId}
          handleClose={() => setOpenArchive(false)}
          handleArchived={handleClose}
          context={context}
        />
      )}
    </form>
  );
};

ContextForm.defaultProps = { context: undefined };

export default ContextForm;
