import '../../../../../../layout/run-config.scss';

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import React, { FC, useCallback } from 'react';
import { AnyAction, Dispatch } from 'redux';

import { setMetricWeights } from '../../../../../../features/project/store';
import { MetricWeights } from '../../../../../../features/project/types/concept-generation';

interface MetricWeightProps {
  dispatch: Dispatch<AnyAction>;
  metricWeights: MetricWeights;
}

const metricLabels = {
  popularity_contexts: 'Popularity within context',
  popularity_combo: 'Popularity of ingr. combination',
  associativity_contexts: 'Associativity with context',
  associativity_combo: 'Associativity of ingr. combination',
  trends_country: 'Trend score',
  foodpairing_match: 'Foodpairing match',
};

const Metrics: FC<MetricWeightProps> = ({ dispatch, metricWeights }) => {
  const metricWeightsHandler = useCallback(
    (event: any, newValue: number | number[], field: string) => {
      dispatch(
        setMetricWeights({
          ...metricWeights,
          [field as keyof MetricWeights]: Number(newValue),
        }),
      );
    },
    [dispatch, metricWeights],
  );

  return (
    <Box data-id-cypress="metricWeights" className="RunConfig__stepContainer">
      <Box className="RunConfig__stepTitle">
        <Typography className="RunConfig__stepTitle">Set metric weights</Typography>
      </Box>
      <Box className="RunConfig__metricItemsContainer">
        {Object.keys(metricLabels).map((metric) => (
          <Box key={metric} className="RunConfig__metricItem">
            <Typography>{metricLabels[metric as keyof MetricWeights]}</Typography>
            <Slider
              onChange={(e, newValue) => {
                metricWeightsHandler(e, newValue, metric);
              }}
              value={metricWeights[metric as keyof MetricWeights]}
              defaultValue={0}
              getAriaValueText={() => metricWeights[metric as keyof MetricWeights].toString()}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              step={0.1}
              marks={true}
              min={0}
              max={1}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Metrics;
