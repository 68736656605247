import '../layout/project-flow.scss';
import '../layout/common.scss';

import Box from '@mui/material/Box';
import { Variant } from '@mui/material/styles/createTypography';
import Typography from '@mui/material/Typography';
import React from 'react';

const SectionTitle: React.FC<{
  title: string;
  action?: React.ReactNode;
  rightActions?: React.ReactNode;
  variant?: Variant;
  useCommonStyles?: boolean;
}> = ({ variant, title, action, rightActions, useCommonStyles }) => (
  <Box display="flex" alignItems={'center'} className={useCommonStyles ? '' : 'ProjectFlow__sectionTitleWrapper'}>
    <Box sx={{ flexGrow: 1 }}>
      <Typography
        variant={variant || 'h6'}
        color="textPrimary"
        className={useCommonStyles ? 'common__sectionTitle' : 'ProjectFlow__sectionTitle'}
      >
        <strong> {title} </strong>
        {action}
      </Typography>
    </Box>
    {rightActions && <Box>{rightActions}</Box>}
  </Box>
);

SectionTitle.defaultProps = {
  action: undefined,
  rightActions: undefined,
  variant: undefined,
  useCommonStyles: false,
};

export default SectionTitle;
