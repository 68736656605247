import '../../../../layout/project-flow.scss';

import Add from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, memo, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import JobHandler, { JobTypes } from '../../../../components/JobHandler';
import SectionTitle from '../../../../components/SectionTitle';
import { GenericInput, InputGenerationType } from '../../../../features/project/types/common';
import { isJobIsInProgress } from '../../../../shared/utils';
import { JobExecutor } from '../../../../types';
import CreateRunDialog from '../_components/CreateRunDialog';
import PreviousRunSelector from '../_components/PreviousRunSelector';
import RunSelector from '../_components/RunSelector';
import {
  createGenericInput,
  createRun,
  duplicateRun,
  getAllRuns,
  getGenericInput,
  updateGenericInput,
} from '../_utils/datasources';
import { FlowActions } from '../_utils/reducer';
import { JobStatus, ProjectFlowStepProps, RunType, RunTypeWithInputId } from '../_utils/types';

const GenericInputValidationError = {
  type: 'custom',
  message: 'Invalid json object provided!',
};
const INPUT_CONFIG_FIELD_NAME = 'inputConfig';
type FormValues = { [INPUT_CONFIG_FIELD_NAME]: string };

const GenericPhase: React.FC<ProjectFlowStepProps> = ({
  project,
  currentStep,
  selectedRun,
  runs,
  simulateFail,
  phaseId,
  dispatch,
  isPhaseReadOnly,
  stepTitle,
  stepDescription,
  phase,
}) => {
  const navigateTo = useNavigate();
  const projectId = project?.projectId;
  const selectedRunId = selectedRun?.runId;
  if (!projectId || !phaseId) {
    throw new Error('Project id or phase id is not provided!');
  }
  const [loading, setLoading] = useState<boolean>(false);
  const [runNameDialogOpen, setRunNameDialogOpen] = useState<boolean>(false);
  const [inputId, setInputId] = useState<string>('');
  const [inputConfig, setInputConfig] = useState<string>('');
  const [runLoader, setRunLoader] = useState<boolean>(false);
  const outputStatus = selectedRun?.outputs?.output_generation?.status;
  const inputStatus = selectedRun?.outputs?.input_preparation?.status;
  const outputIsInProgress = outputStatus ? isJobIsInProgress(outputStatus) : false;
  const inputIsInProgress = inputStatus ? isJobIsInProgress(inputStatus) : false;
  const isButtonDisabled =
    outputIsInProgress ||
    inputIsInProgress ||
    !(projectId && phaseId) ||
    loading ||
    !!selectedRun?.readOnly ||
    isPhaseReadOnly;
  const [editMode, setEditMode] = useState<{
    parentRuns: boolean;
    params: boolean;
  }>({
    parentRuns: false,
    params: false,
  });
  const numOfSteps = (project?.workflowConfig?.length || 0) - 1;
  const currentWorkflowConfig = project?.workflowConfig.filter(
    (workflow) => workflow.workflowStepId === phase?.workflowStepId,
  );
  const { select_previous_runs: showParentRunSelector, input_preparation: showInputPreparation } =
    currentWorkflowConfig?.[0]?.options || {};

  // prepare utilities to manage form submit/validate process
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues: { inputConfig } });

  const setLoadingCallback = useCallback(
    (isLoading: boolean): void => {
      dispatch({ type: FlowActions.setLoading, loading: isLoading });
      setLoading(isLoading);
    },
    [dispatch],
  );

  // textarea onchange handler
  const textAreaHandler = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (e.target.value) {
        setInputConfig(e.target.value);
      }
    },
    [setInputConfig],
  );

  const updateRun = useCallback<(run: RunType) => void>(
    (run) => dispatch({ type: FlowActions.setSelectedRun, run }),
    [dispatch],
  );

  // callback to update generic phase's runs data
  const refreshView = useCallback(async () => {
    setLoadingCallback(true);
    const newRuns = await getAllRuns(projectId, phaseId);
    dispatch({
      type: FlowActions.setRuns,
      runs: newRuns || [],
      selectedRun,
    });
    setLoadingCallback(false);
  }, [dispatch, phaseId, projectId, setLoadingCallback, selectedRun]);

  // callback to change current run
  const handleRunChange = useCallback(
    (run?: RunType | null): void => {
      setRunLoader(false);
      if (run) {
        navigateTo(`/projects/${run.projectId}/phases/${run.phaseId}/runs/${run.runId}`);
      }
    },
    [navigateTo],
  );

  // callback to handle new run creation
  const createNewRun = useCallback(
    (runName?: string) => {
      setLoadingCallback(true);
      setRunLoader(true);
      createRun(projectId, phaseId, runName || '').then((result: RunTypeWithInputId | null) => {
        handleRunChange(result?.run);
      });
      setRunNameDialogOpen(false);
    },
    [projectId, phaseId, handleRunChange, setLoadingCallback, setRunNameDialogOpen],
  );

  const createDuplicateRun = useCallback(
    (sourceRunId: string, runName?: string) => {
      setLoadingCallback(true);
      setRunLoader(true);
      duplicateRun(projectId, phaseId, sourceRunId, runName || '').then((result: RunTypeWithInputId | null) => {
        handleRunChange(result?.run);
      });
      setRunNameDialogOpen(false);
    },
    [projectId, phaseId, handleRunChange, setLoadingCallback, setRunNameDialogOpen],
  );

  // callback to update generic input data in component's local state
  const updateLocalInputData = useCallback(
    (input?: GenericInput): void => {
      setInputId(input?.inputId || '');
      const config = input?.config ? JSON.stringify(input?.config, undefined, 2) : '';
      setInputConfig(config);
      setLoadingCallback(false);
      reset({ [INPUT_CONFIG_FIELD_NAME]: config });
    },
    [setInputId, setInputConfig, setLoadingCallback, reset],
  );

  /**
   * This callback creates an empty generic input configuration for the current run
   * It only sets the input type to wizard.
   */
  const initGenericInput = useCallback(() => {
    if (selectedRunId) {
      createGenericInput(projectId, phaseId, selectedRunId, {
        config: { input_type: InputGenerationType.Wizard },
      }).then((response) => {
        if (response) {
          updateLocalInputData(response);
        }
      });
    }
  }, [projectId, phaseId, selectedRunId, updateLocalInputData]);

  // when component initialized - fetch run's generic input
  useEffect(() => {
    if (selectedRunId) {
      setLoadingCallback(true);
      getGenericInput(projectId, phaseId, selectedRunId).then((response) => {
        if (response) {
          updateLocalInputData(response);
        } else {
          initGenericInput();
        }
      });
    }
  }, [projectId, phaseId, selectedRunId, setLoadingCallback, updateLocalInputData, initGenericInput]);

  // form with generic input submit handler - to validate JSON and display error
  const onSubmit: SubmitHandler<FormValues> = useCallback(
    (data: FormValues, event: any): void => {
      if (selectedRunId) {
        event?.preventDefault();
        try {
          setLoadingCallback(true);
          updateGenericInput(projectId, phaseId, selectedRunId, {
            inputId,
            config: JSON.parse(data?.inputConfig),
          }).then((response) => {
            if (response) {
              updateLocalInputData(response);
            }
          });
        } catch (e) {
          setError(INPUT_CONFIG_FIELD_NAME, GenericInputValidationError);
        }

        setLoadingCallback(false);
        setEditMode({ parentRuns: false, params: false });
      }
    },
    [setEditMode, inputId, projectId, phaseId, selectedRunId, setError, setLoadingCallback, updateLocalInputData],
  );

  useEffect(() => {
    dispatch({
      type: FlowActions.setNavigationButtonStatusCallback,
      callback: () => ({
        next: outputStatus === JobStatus.ok && (currentStep || 0) < numOfSteps,
        back: true,
      }),
    });
  }, [dispatch, currentStep, outputStatus, numOfSteps]);

  const isJobGenerationDisabled = () =>
    (showParentRunSelector && phase?.dependsOnRuns?.length === 0) ||
    editMode.params ||
    editMode.parentRuns ||
    !!errors?.inputConfig?.message ||
    isPhaseReadOnly ||
    !!selectedRun?.readOnly;

  return (
    <Box position="relative" data-id-cypress="genericPhase">
      {showParentRunSelector && (
        <PreviousRunSelector
          phase={phase}
          projectId={projectId}
          isButtonDisabled={isButtonDisabled}
          isEditMode={editMode.parentRuns || false}
          toggleEditMode={(toggle: boolean) => {
            setEditMode({ ...editMode, parentRuns: toggle });
          }}
        />
      )}
      <Box display="flex" flexDirection="row" className="ProjectFlow__stepTitleWrapper">
        <SectionTitle
          title={stepTitle}
          action={
            <Tooltip title="Create or duplicate a run">
              <>
                <Button disabled={isPhaseReadOnly} onClick={() => setRunNameDialogOpen(true)}>
                  <Add />
                </Button>
                <RunSelector runs={runs || []} selected={selectedRun || null} handleRunChange={handleRunChange} />
              </>
            </Tooltip>
          }
        />
      </Box>

      <Box display="flex" flexDirection="row" marginBottom={2}>
        <Box>
          <Typography>{stepDescription}</Typography>
        </Box>
      </Box>

      {/* Generic phase input */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <TextareaAutosize
            required={true}
            {...register(INPUT_CONFIG_FIELD_NAME, { required: true })}
            id="config"
            aria-label="Generic Input"
            className="ProjectFlow__textArea"
            value={inputConfig}
            minRows={15}
            disabled={!editMode.params}
            onChange={textAreaHandler}
          />
        </Box>
        {errors?.inputConfig?.message && <Box color="red">{errors.inputConfig.message}</Box>}
        <Button
          type="submit"
          variant="outlined"
          className="ProjectFlow__button"
          disabled={!editMode.params || isButtonDisabled}
        >
          Save
        </Button>
        <Button
          variant="outlined"
          className="ProjectFlow__button"
          disabled={isButtonDisabled || !inputId}
          onClick={() => {
            if (selectedRunId) {
              setLoadingCallback(true);
              clearErrors();
              updateGenericInput(projectId, phaseId, selectedRunId, {
                inputId,
                config: { input_type: InputGenerationType.Wizard },
              }).then((response) => {
                if (response) {
                  updateLocalInputData(response);
                }
              });
            }
          }}
        >
          Reset
        </Button>
        <Button
          type="button"
          variant="outlined"
          className="ProjectFlow__button"
          disabled={isButtonDisabled || editMode.params}
          onClick={() => setEditMode({ ...editMode, params: true })}
        >
          Edit
        </Button>
      </form>

      {showInputPreparation && !loading && (
        <JobHandler
          project={project}
          updateView={refreshView}
          type={JobTypes.input}
          executor={JobExecutor.default}
          title={stepTitle}
          key={`input-handler-${selectedRun?.runId}`}
          simulateFail={simulateFail}
          isDisabled={isJobGenerationDisabled}
          selectedRunId={selectedRun?.runId}
          updateRun={updateRun}
          run={runs?.find((run) => run.runId === selectedRun?.runId)}
          inProgressText="Generating input"
        />
      )}
      {/* Generic phase output generation */}
      {!loading && (
        <JobHandler
          project={project}
          title={stepTitle}
          updateRun={updateRun}
          selectedRunId={selectedRun?.runId}
          updateView={refreshView}
          type={JobTypes.output}
          run={runs?.find((run) => run.runId === selectedRun?.runId)}
          inProgressText="Generating output"
          simulateFail={simulateFail}
          isDisabled={isJobGenerationDisabled}
          key={`output-handler-${selectedRun?.runId}`}
          executor={JobExecutor.manual}
        />
      )}

      <CreateRunDialog
        open={runNameDialogOpen}
        onClose={() => setRunNameDialogOpen(false)}
        onSubmit={(newRunName) => createNewRun(newRunName || '')}
        onDuplicate={(newRunName, sourceRunId) => createDuplicateRun(sourceRunId, newRunName || '')}
        runsList={runs}
        defaultRun={selectedRun}
        loading={runLoader}
      />
    </Box>
  );
};

export default memo(GenericPhase);
