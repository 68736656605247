import '../../../layout/common.scss';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import React, { ChangeEvent, memo, MouseEvent, useEffect, useMemo, useState } from 'react';

import MultiTagList from '../../../components/MultiTagList';
import TagList from '../../../components/TagList';
import { CategoryType, SocialPostSelectionType, SocialTagErrorItem } from '../../../types';
import { SocialAction, SocialActions, StateAction } from '../reducer';
import {
  getCategoryNames,
  prepareAvailableTags,
  SocialPostTagsSorted,
  SocialPostTagsWithCategory,
} from '../utils/tags';

type SocialTagSelectionProps = {
  dispatch: (action: SocialAction | StateAction) => void;
  disabled: boolean;
  socialErrors: SocialTagErrorItem[] | null;
  socialPostsSelections: SocialPostSelectionType[];
  selectedSocialPostsCategoryName: string | null;
  socialPostsCategories: { [name: string]: CategoryType };
};

const SocialTagSelection: React.FC<SocialTagSelectionProps> = ({
  dispatch,
  disabled,
  socialErrors,
  socialPostsSelections,
  selectedSocialPostsCategoryName,
  socialPostsCategories,
}) => {
  const selectedCategory =
    selectedSocialPostsCategoryName !== null ? socialPostsCategories[selectedSocialPostsCategoryName] : null;

  // do recalculation of category names only if social post categories is changed
  const categoryNames = useMemo(() => getCategoryNames(socialPostsCategories), [socialPostsCategories]);

  const setRightItems = (items: SocialPostTagsWithCategory[]) => {
    dispatch({
      type: SocialActions.set,
      items,
    });
  };

  const [checkLeftWithCategory, setLeftCheckedWithCategory] = useState<SocialPostTagsWithCategory[]>([]);
  const [checkRightWithCategory, setRightCheckedWithCategory] = useState<SocialPostTagsWithCategory[]>([]);
  const [checkedRight, setCheckedRight] = useState<SocialPostTagsWithCategory[]>([...socialPostsSelections]);

  useEffect(() => {
    if (JSON.stringify(socialPostsSelections) !== JSON.stringify(checkedRight)) {
      setCheckedRight([...socialPostsSelections]);
    }
  }, [socialPostsSelections, checkedRight]);

  const [categorySearchFilter, setCategorySearchFilter] = useState('');
  const [availableSearchFilter, setAvailableSearchFilter] = useState('');
  const [selectedSearchFilter, setSelectedSearchFilter] = useState('');

  const leftTags = selectedCategory !== null ? selectedCategory.tags || [] : [];
  const filteredCategories =
    categorySearchFilter !== '' ? categoryNames.filter((value) => value.includes(categorySearchFilter)) : categoryNames;
  const availableLeftTags = leftTags.filter(
    (value) =>
      !checkedRight.find((category) => category.category === selectedCategory?.name) ||
      checkedRight.find(
        (category) => category.category === selectedCategory?.name && !category.posts.find((i) => i.tag === value),
      ),
  );

  const availableRightTags: SocialPostTagsSorted[] = useMemo(
    () => prepareAvailableTags(checkedRight, socialErrors),
    [checkedRight, socialErrors],
  );

  const filteredLeft =
    availableSearchFilter !== ''
      ? availableLeftTags.filter((tag) => tag.includes(availableSearchFilter))
      : availableLeftTags;

  const filteredRight =
    selectedSearchFilter !== ''
      ? availableRightTags
          .map((category) => ({
            ...category,
            items: category.items.filter((i) => i.includes(selectedSearchFilter)),
          }))
          .filter((category) => category.items.length > 0)
      : availableRightTags;

  const handleSelectCategory = (value: string) => (event: MouseEvent) => {
    event.preventDefault();
    dispatch({ type: SocialActions.select, name: value });
  };

  const handleToggleLeft = (value: string) => (event: MouseEvent) => {
    event.preventDefault();
    let newChecked = [...checkLeftWithCategory];

    if (selectedCategory) {
      const found = newChecked.find(
        (category) => category.category === selectedCategory.name && category.posts.find((i) => i.tag === value),
      );
      if (found) {
        newChecked = newChecked.map((category) => {
          if (category.category === selectedCategory.name) {
            return {
              category: category.category,
              posts: category.posts.filter((i) => i.tag !== value),
            };
          }

          return category;
        });
      } else {
        if (!newChecked.find((category) => category.category === selectedCategory.name)) {
          newChecked.push({ category: selectedCategory.name, posts: [] });
        }

        newChecked = newChecked.map((category) => {
          if (category.category === selectedCategory.name) {
            category.posts.push({ tag: value });
            return { category: category.category, posts: [...category.posts] };
          }

          return category;
        });
      }

      newChecked = newChecked.filter((category) => category.posts.length > 0);

      setLeftCheckedWithCategory(newChecked);
    }

    /* if (currentIndex === -1) {
                              newChecked.push(value);
                            } else {
                              newChecked.splice(currentIndex, 1);
                            }
                            setChecked(newChecked); */
  };

  const handleToggleRight = (value: string, _category: string) => (event: MouseEvent) => {
    event.preventDefault();

    let newChecked = [...checkRightWithCategory];

    if (_category) {
      const found = newChecked.find(
        (category) => category.category === _category && category.posts.find((i) => i.tag === value),
      );
      if (found) {
        newChecked = newChecked.map((category) => {
          if (category.category === _category) {
            return {
              category: category.category,
              posts: category.posts.filter((i) => i.tag !== value),
            };
          }

          return category;
        });
      } else {
        if (!newChecked.find((category) => category.category === _category)) {
          newChecked.push({ category: _category, posts: [] });
        }

        newChecked = newChecked.map((category) => {
          if (category.category === _category) {
            category.posts.push({ tag: value });
            return {
              category: category.category,
              posts: [...category.posts],
            };
          }

          return category;
        });
      }

      newChecked = newChecked.filter((category) => category.posts.length > 0);
      setRightCheckedWithCategory(newChecked);
    }
  };

  const handleAllRight = () => {
    let newCheckedRight = [...checkedRight];

    if (selectedCategory) {
      const category = newCheckedRight.find((checkedCategory) => checkedCategory.category === selectedCategory.name);

      if (!category) {
        newCheckedRight.push({
          category: selectedCategory.name,
          posts: filteredLeft.map((item) => ({ tag: item })),
        });
      } else {
        filteredLeft.forEach((item) => {
          newCheckedRight = newCheckedRight.map((checkedCategory) =>
            checkedCategory.category !== selectedCategory.name
              ? checkedCategory
              : {
                  ...checkedCategory,
                  posts: checkedCategory.posts.concat([{ tag: item }]),
                },
          );
        });
      }
    }

    setRightItems(newCheckedRight);
  };

  const handleCheckedRight = () => {
    let newCheckedRight = [...checkedRight];

    checkLeftWithCategory.forEach((checked) => {
      const category = newCheckedRight.find((checkedCategory) => checkedCategory.category === checked.category);
      if (category) {
        newCheckedRight = newCheckedRight.map((newChecked) =>
          newChecked.category !== checked.category
            ? newChecked
            : {
                ...newChecked,
                posts: newChecked.posts.concat(checked.posts),
              },
        );
      } else {
        newCheckedRight.push(checked);
      }
    });

    setLeftCheckedWithCategory([]);
    setRightItems(newCheckedRight);

    // setRight(right.concat(leftChecked));
    // setChecked(not(checked, leftChecked));
  };

  const handleAllLeft = () => {
    setRightItems([]);
  };

  const handleCheckedLeft = () => {
    let newCheckedRight = [...checkedRight];

    checkRightWithCategory.forEach((checked) => {
      newCheckedRight = newCheckedRight.map((newChecked) =>
        newChecked.category !== checked.category
          ? newChecked
          : {
              ...newChecked,
              posts: newChecked.posts.filter((i) => !checked.posts.find((i2) => i2.tag === i.tag)),
            },
      );
    });

    newCheckedRight = newCheckedRight.filter((category) => category.posts.length > 0);

    setRightCheckedWithCategory([]);
    setRightItems(newCheckedRight);
  };

  // todo: clean up duplication
  const handleSearchCategoriesChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCategorySearchFilter(event.target.value);
  };

  const handleSearchAvailableChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAvailableSearchFilter(event.target.value);
  };

  const handleSearchSelectedChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedSearchFilter(event.target.value);
  };

  const selectedCategoryName = selectedCategory?.name || null;
  const checkedCategoryNames = useMemo(
    () => (selectedCategoryName ? [selectedCategoryName] : []),
    [selectedCategoryName],
  );

  // memoize checked tags to void rerender or tags list and void recalculations in list
  const checkedAsObject = useMemo(() => {
    const result: { [key: string]: string[] } = {};
    checkRightWithCategory.forEach((category) => {
      if (category.category) {
        result[category.category] = category.posts.map((i) => i.tag);
      }
    });
    return result;
  }, [checkRightWithCategory]);

  return (
    <Grid container={true} spacing={2} justifyContent="center" alignItems="center" className="common__root">
      <Grid item={true}>
        <TagList
          disabled={disabled}
          name="categories"
          items={filteredCategories}
          checked={checkedCategoryNames}
          onSearchChange={handleSearchCategoriesChange}
          handleToggle={handleSelectCategory}
          value={categorySearchFilter}
        />
      </Grid>
      <Grid item={true}>
        <TagList
          disabled={disabled}
          name="available"
          items={filteredLeft.sort()}
          checked={
            checkLeftWithCategory
              .find((category) => category.category === selectedCategory?.name)
              ?.posts.map((i) => i.tag) || []
          }
          onSearchChange={handleSearchAvailableChange}
          handleToggle={handleToggleLeft}
          value={availableSearchFilter}
        />
      </Grid>
      <Grid item={true}>
        <Grid container={true} direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className="common__transferListButton"
            onClick={handleAllRight}
            disabled={selectedCategory === null || filteredLeft.length === 0 || disabled}
            aria-label="move all from category right"
          >
            &gt;&gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className="common__transferListButton"
            onClick={handleCheckedRight}
            disabled={checkLeftWithCategory.length === 0 || disabled}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className="common__transferListButton"
            onClick={handleCheckedLeft}
            disabled={checkRightWithCategory.length === 0 || disabled}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className="common__transferListButton"
            onClick={handleAllLeft}
            disabled={checkedRight.length === 0 || disabled}
            aria-label="move all from category left"
          >
            &lt;&lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item={true}>
        <MultiTagList
          disabled={disabled}
          name="selected"
          items={filteredRight}
          errors={socialErrors}
          checked={checkedAsObject}
          onSearchChange={handleSearchSelectedChange}
          handleToggle={handleToggleRight}
        />
      </Grid>
    </Grid>
  );
};

export default memo(SocialTagSelection);
