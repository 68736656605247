import '../../../../../layout/run-config.scss';

import { WarningOutlined } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import ErrorNotification from '../../../../../components/ErrorNotification';
import Loading from '../../../../../components/Loading';
import {
  resetConceptGenerationConfig,
  setActiveStep,
  setSelectedIngredientsV2,
  showConfigurationWorkflow,
  SLICE_CONCEPT_GENERATION,
  SLICE_CONCEPT_GENERATION_V2,
  SLICE_PROJECT_WORKFLOW,
} from '../../../../../features/project/store';
import { BackendLoadingStatus, InputGenerationType } from '../../../../../features/project/types/common';
import {
  ConceptGenerationConfigV2,
  ConceptGenerationWFStepsV2,
} from '../../../../../features/project/types/concept-generation';
import { isWeightConfigValid, updateConceptGenerationConfig } from '../../../../../features/project/utils';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { ConceptGenerationWFVersion, ShowConceptGenWorkflow } from '../_utils/types';
import CompositionTemplate from './_components/CompositionTemplate';
import MetricWeights from './_components/MetricWeights';
import OverrideIngredientClass from './_components/OverrideIngredientClass';
import SelectIngredients from './_components/SelectIngredients';

interface RunConfigFlowProps {
  projectId: string;
  phaseId: string;
}

/* eslint-disable prefer-object-spread */
const RunConfigFlowV2: FC<RunConfigFlowProps> = ({ projectId, phaseId }) => {
  const dispatch = useAppDispatch();
  const { loading, runId, runs, error } = useAppSelector((store) => store[SLICE_PROJECT_WORKFLOW]);
  const conceptGenerationConfigCommonState = useAppSelector((store) => store[SLICE_CONCEPT_GENERATION]);
  const { activeStep, initialIngredients, ingredientsLoadingStatus } = conceptGenerationConfigCommonState;
  const conceptGenerationConfigV2State = useAppSelector((store) => store[SLICE_CONCEPT_GENERATION_V2]);
  const { selectedIngredients, metricWeights, availableIngredientClasses, ingredientSlots, compositions } =
    conceptGenerationConfigV2State;
  const { inputId, readOnly } = runId ? runs[runId] : { inputId: undefined, readOnly: false };

  const isFirstStep = activeStep === ConceptGenerationWFStepsV2.SelectIngredients;
  const isLastStep = activeStep === ConceptGenerationWFStepsV2.MetricWeights;
  const areIngredientsLoading =
    ingredientsLoadingStatus === BackendLoadingStatus.Loading ||
    ingredientsLoadingStatus === BackendLoadingStatus.Initial;

  const [errorMsg, setErrorMsg] = useState<string | ReactNode | FC | null>(null);

  /**
   * This callback is called when the user clicks on the "Finish" button.
   * If the configuration is valid, it can be saved in the backend
   */
  const onWorkflowComplete = useCallback<() => void>(async () => {
    if (runId && runs[runId]) {
      const isWeightValid = !!metricWeights && isWeightConfigValid(metricWeights);

      if (isWeightValid) {
        const config: ConceptGenerationConfigV2 = {
          version: ConceptGenerationWFVersion.v2,
          input_type: InputGenerationType.Wizard,
          ingredients: selectedIngredients,
          weights: metricWeights,
          compositions,
          ingredient_slots: ingredientSlots,
        };
        await updateConceptGenerationConfig(dispatch, runId, phaseId, projectId, inputId, config, runs[runId].readOnly);
        dispatch(resetConceptGenerationConfig());
      } else {
        setErrorMsg('At least one metric must be non-zero');
      }
    }
  }, [
    runId,
    dispatch,
    inputId,
    metricWeights,
    projectId,
    phaseId,
    runs,
    compositions,
    ingredientSlots,
    selectedIngredients,
  ]);

  /**
   * This callback is called when the user clicks on the "Next" or "Finish" button.
   */
  const onNextWorkflowStep = useCallback<() => void>(() => {
    if (isLastStep) {
      onWorkflowComplete();
    } else {
      dispatch(setActiveStep(activeStep + 1));
    }
  }, [activeStep, dispatch, isLastStep, onWorkflowComplete]);

  /**
   * This callback is called when the user clicks the "Back" button.
   */
  const handleBack = useCallback<() => void>(() => {
    if (isFirstStep) {
      dispatch(resetConceptGenerationConfig());
    } else {
      dispatch(setActiveStep(activeStep - 1));
    }
  }, [dispatch, activeStep, isFirstStep]);

  /**
   * This effect will set the error message if the error prop changes
   */
  useEffect(() => setErrorMsg(error || null), [error]);

  const selectedIngredientIds = useMemo<number[]>(
    () => selectedIngredients.map((i) => i.ingredient_id),
    [selectedIngredients],
  );

  return areIngredientsLoading ? (
    <Loading message="Loading ingredients" />
  ) : (
    <Paper data-id-cypress="runConfigV2CompositionWizard" className="RunConfig__wizardWrapper">
      <Box>
        <Box display={'flex'} alignItems={'center'}>
          <Typography variant="h4" style={{ fontWeight: 'bold' }}>
            Composition Wizard
          </Typography>
          {readOnly && (
            <Box marginLeft={1} display={'flex'} alignItems={'center'}>
              <Typography
                className="WithWarning"
                color={'error'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <WarningOutlined />
                This run is read-only
              </Typography>
            </Box>
          )}
        </Box>
        <Box className="RunConfig__flowData">
          {activeStep === ConceptGenerationWFStepsV2.SelectIngredients && (
            <SelectIngredients
              setSelectedIngredients={setSelectedIngredientsV2}
              dispatch={dispatch}
              ingredients={initialIngredients || []}
              selectedIngredientIds={selectedIngredientIds || []}
              loading={loading}
            />
          )}
          {activeStep === ConceptGenerationWFStepsV2.OverrideIngredientClass && (
            <OverrideIngredientClass
              dispatch={dispatch}
              loading={loading}
              ingredientClasses={availableIngredientClasses || []}
              ingredients={selectedIngredients || []}
            />
          )}
          {activeStep === ConceptGenerationWFStepsV2.CompositionTemplate && (
            <CompositionTemplate
              ingredientSlots={ingredientSlots || 3}
              compositions={compositions || []}
              dispatch={dispatch}
              ingredientClasses={availableIngredientClasses || []}
            />
          )}
          {activeStep === ConceptGenerationWFStepsV2.MetricWeights && (
            <MetricWeights metricWeights={metricWeights} dispatch={dispatch} />
          )}
        </Box>
        <Paper className="RunConfig__dialogActions">
          <Button
            disabled={loading}
            onClick={() => dispatch(showConfigurationWorkflow(ShowConceptGenWorkflow.hide))}
            className="RunConfig__backButton"
          >
            Back to generate concept
          </Button>
          {!isFirstStep && (
            <Button disabled={loading} onClick={handleBack} className="RunConfig__backButton">
              Back
            </Button>
          )}
          <Button
            data-id-cypress="nextButtonV2"
            disabled={
              loading ||
              !!error ||
              (activeStep === ConceptGenerationWFStepsV2.CompositionTemplate && !compositions?.length) ||
              (readOnly && isLastStep)
            }
            variant="contained"
            color="primary"
            onClick={isLastStep ? onWorkflowComplete : onNextWorkflowStep}
          >
            {isLastStep ? 'Save' : 'Next'}
          </Button>
        </Paper>
        <ErrorNotification error={errorMsg || null} handleClose={() => setErrorMsg(null)} />
      </Box>
    </Paper>
  );
};

export default RunConfigFlowV2;
