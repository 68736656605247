import React from 'react';

import { ContextType, ProjectType, Resource } from '../../../../types';
import { FlowAction } from './reducer';

export enum ProjectPhaseType {
  ProjectConfiguration = 'CFI_PROVISION',
  ScopeDefinition = 'CFI_SCOPE_DEFINITION',
  MetricsCalculation = 'CFI_METRICS_CALCULATION',
  ConceptGeneration = 'CFI_CONCEPT_GENERATION',
  ModelPreparation = 'CFI_MODEL_PREPARATION',
  GenericPhase = 'CFI_GENERIC_PHASE',
}

export enum JobStatus {
  ok = 'JOB_STATUS_OK',
  created = 'JOB_STATUS_CREATED',
  inProgress = 'JOB_STATUS_IN_PROGRESS',
  failed = 'JOB_STATUS_FAILED',
  aborted = 'JOB_STATUS_ABORTED',
  accepted = 'JOB_STATUS_ACCEPTED',
  toAbort = 'JOB_STATUS_TO_ABORT',
  aborting = 'JOB_STATUS_ABORTING',
  manual = 'JOB_STATUS_MANUAL',
}

export enum PhaseRunStatus {
  generated = 'PROJECT_PHASE_RUN_GENERATED',
  prepared = 'PROJECT_PHASE_RUN_PREPARED',
}

export interface ProjectFlowStepProps {
  currentStep?: number;
  project: ProjectType | null;
  dispatch: React.Dispatch<FlowAction>;
  selectedRun?: RunType;
  runs?: RunType[];
  phaseId?: string;
  runId?: string;
  lastRun?: RunType;
  simulateFail?: boolean;
  isPhaseReadOnly: boolean;
  stepTitle: string;
  stepDescription: string;
  phase: PhaseType | null;
}

export type ProjectFlowStepType = {
  label?: string;
  visible?: boolean;
  component: React.FC<ProjectFlowStepProps>;
};

export type PhaseType = {
  dependingPhases: string[];
  dependsOnPhases: string[];
  dependsOnRuns: string[];
  projectId: string;
  phaseId: string;
  type?: ProjectPhaseType;
  parentRunName: string;
  parentRunId: string;
  parentPhaseId: string;
  readOnly?: boolean | undefined;
  workflowStepId?: string | undefined;
  outputGenerationByRunId?: any;
};

export type RunType = {
  phaseId: string;
  projectId: string;
  runId: string;
  runName: string;
  resourceMetadata: {
    createdBy: string;
    createdAt: string;
    changedBy: string;
    changedAt: string;
  };
  outputs: {
    input_preparation: {
      urls: {
        output?: string;
        template?: string;
        extra?: string;
      } | null;
      jobOutput?: {
        resources?: {
          [key: string]: Resource;
        };
      } | null;
      jobId?: string;
      status?: JobStatus;
    };
    output_generation: {
      urls: {
        output?: string;
        template?: string;
        extra?: string;
      } | null;
      jobOutput?: {
        resources?: {
          [key: string]: Resource;
        };
        values?: any;
      } | null;
      jobId?: string;
      status?: JobStatus;
    };
  };
  readOnly: boolean;
  status?: PhaseRunStatus;
  childPhaseId?: string; // @deprecated
  dependingPhases?: string[];
};

export type RunTypeWithInputId = {
  run: RunType;
  inputId: string;
};

export enum ResourceKeys {
  SCOPE_DEFINITION_INGREDIENT_MAPPING = 'scope_definition_ingredient_mapping',
  METRICS_CALCULATION_INGREDIENT_LABELS = 'metrics_calculation_ingredient_labels',
  METRICS_CALCULATION_COMPOSITION_TEMPLATE = 'metrics_calculation_composition_template',
  CONCEPT_GENERATION_CONCEPTS_SHEET = 'concept_generation_concepts_sheet',
  CONCEPT_GENERATION_CONCEPT_SAMPLE_SHEET = 'concept_generation_concept_samples_sheet',
  MODEL_PREPARATION_CONCEPT_SELECTION_TEMPLATE = 'model_preparation_concept_selection_template',
}

export interface ContextTypeWithVersions extends ContextType {
  socialPostsVersion?: string;
  recipesVersion?: string;
}

export interface ContextWithVersions {
  context: ContextType;
  contextVersions: string[];
}

export interface FlowMatchParams {
  idProject?: string;
  phaseId?: string;
  runId?: string;
}

export interface ProjectFlowSteps {
  [ProjectPhaseType.ProjectConfiguration]: ProjectFlowStepType;
  [ProjectPhaseType.ScopeDefinition]: ProjectFlowStepType;
  [ProjectPhaseType.MetricsCalculation]: ProjectFlowStepType;
  [ProjectPhaseType.ConceptGeneration]: ProjectFlowStepType;
  [ProjectPhaseType.ModelPreparation]: ProjectFlowStepType;
  [ProjectPhaseType.GenericPhase]: ProjectFlowStepType;
}

export interface RowErrorMessageType {
  severity: 'error' | 'warning' | 'info';
  message: string;
}
