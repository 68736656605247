import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import React from 'react';

interface ContextArchiveDialogProps {
  handleClose: () => void;
  contextVersion: any;
  archiveVersion: () => void;
}

const ArchiveContextVersionDialog: React.FC<ContextArchiveDialogProps> = ({
  handleClose,
  contextVersion,
  archiveVersion,
}) => {
  const isLoading = false;

  return (
    <Dialog fullWidth={true} open={true} onClose={() => !isLoading && handleClose()} maxWidth="md">
      <DialogTitle>{`Are you sure you want to archive ${contextVersion.contextVersionId}?`}</DialogTitle>
      <DialogContent>It will no longer be used for projects</DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button disabled={isLoading} onClick={archiveVersion} color="primary">
          Archive
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArchiveContextVersionDialog;
